import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DesignBarComponent} from './design-bar.component';
import {SanitiseHtmlPipeModule} from '../../pipes/sanitise-html/sanitise-html-pipe.module';

@NgModule({
  declarations: [DesignBarComponent],
  exports: [
    DesignBarComponent,
  ],
  imports: [
    CommonModule,
    SanitiseHtmlPipeModule,
  ],
})
export class DesignBarModule {
}
