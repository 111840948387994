import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'customUnit',
})
export class CustomUnitPipe implements PipeTransform {
  transform(value: any, unit: string): string {
    return this.formatValue(value, unit);
  }

  private formatValue(value: any, unit: string) {
    return value === undefined || value === null ? '' : value + unit;
  }
}
