export enum TableFilterOperators {
  Contains = 'contains',
  DoesNotContain = 'doesNotContain',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  LessThan = 'lessTha',
}

export interface TableFilterConfig {
  label: string;
  field: string;
  type: 'string' | 'number' | 'date';
  unit?: string;
  unitsList?: string[];
  isHidden?: boolean;

  [key: string]: any;
}

export interface TableFilterData {
  field: string;
  operator: TableFilterOperators;
  value: string | number;
  unit?: string;
}

export interface TableFilter {
  label: string;
  data: TableFilterData;
}
