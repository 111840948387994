import {HttpErrorResponse} from '@angular/common/http';

import {EvseState, EvState, FacilityHeadroom} from '../models/entity-states';
import {Evse} from '../models/entities';

export function isHttpError(something: any): something is HttpErrorResponse {
  return (something as HttpErrorResponse)?.name === 'HttpErrorResponse';
}

export function isEvseState(something: any): something is EvseState {
  return (something as EvseState)?.evseId !== undefined && !((something as EvState)?.longitude);
}

export function isFacilityHeadroom(something: any): something is FacilityHeadroom {
  return (something as FacilityHeadroom).facilityId !== undefined;
}

export function isEvState(something: any): something is EvState {
  return (something as EvState)?.longitude !== undefined;
}

export function isEvse(something: any): something is Evse {
  return (something as Evse)?.extEvseId !== undefined;
}
