import {Component, EventEmitter, Input, Output} from '@angular/core';

import {TableColumn} from '../table-column.model';

@Component({
  selector: 'table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
})
export class TableHeaderComponent {
  @Input() isCondensed = false;
  @Input() columns: TableColumn[];
  @Input() isMobile: boolean;
  @Output() sorted = new EventEmitter<string>();

  public sortedColumnId = '';

  public sort(sortEvent: { columnId: string; isOn: boolean }) {
    this.sortedColumnId = sortEvent.isOn
      ? sortEvent.columnId
      : ''; // clean up the sorted column Id if the sorting has been switched off

    this.sorted.emit(sortEvent.columnId);
  }
}
