import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationHelperService} from '../../services/navigation-helper.service';

export interface Tab {
  label: string;
  value: string;
  permission?: string;
  redirectToFacility?: boolean; // true for tabs which  need to keep previous facility
}

@Component({
  selector: 'tab-switch-header',
  templateUrl: './tab-switch-header.component.html',
  styleUrls: ['./tab-switch-header.component.scss'],
})
export class TabSwitchHeaderComponent implements OnInit {
  @Input() tabs: Tab[] = [];
  @Input() selectedTabValue = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationHelper: NavigationHelperService,
  ) {
  }

  public ngOnInit(): void {
    const matchingTab = this.tabs.find(tab => this.router.url.includes(tab.value));
    if (matchingTab) {
      this.selectedTabValue = matchingTab.value;
    }
  }

  public select(tabValue: string, redirectToFacility?: boolean) {
    const facilityId = this.navigationHelper.searchForParamInAllChildComponents(this.route, 'facilityId');
    const redirectionPath = (facilityId && redirectToFacility)
      ? tabValue + '/' + facilityId
      : tabValue;
    this.router.navigate([redirectionPath], {relativeTo: this.route}).then(() => this.selectedTabValue = tabValue);
  }
}
