import {ChargingStation, Evse, Vehicle} from '../../shared/models/entities';
import {EvseState, EvState} from '../../shared/models/entity-states';
import {unavailable} from '../consts/text.consts';

import {getEvseStatus, isEligibleToBlock, isWithinBlockingDistance, toPerc} from '../utils';

export enum FvDataState {
  IN_ZONE = 'inZone',
  BLOCKED = 'blocked',
  PLUGGED_OUT = 'pluggedOut',
  PLUGGED_IN = 'pluggedIn',
  FAULTED = 'faulted',
  MALFORMED = 'ERROR'
}

export class FvData {
  private triggersRecalculate = ['evseState', 'evState'];

  settable = ['evseState', 'evState', 'vehicle', 'evse'];

  evseState?: EvseState;
  evState?: EvState;
  vehicle?: Vehicle;
  evse?: Evse;
  cs?: ChargingStation;

  state: FvDataState = FvDataState.MALFORMED;
  meta: {
    stateOfChargeInPercent?: number,
    estimatedMileage?: number,
    estimatedTimeToFullCharge?: string,
    isDuplicate?: boolean
  } = {};

  constructor({evState, evseState, vehicle, evse, cs}: {
    evState?: EvState,
    evseState?: EvseState,
    vehicle?: Vehicle,
    evse?: Evse,
    cs?: ChargingStation
  }) {
    this.evState = evState;
    this.evseState = evseState;
    this.vehicle = vehicle;
    this.evse = evse;
    this.cs = cs;
    this.calculateStateAndMeta();
  }

  set(property: string, value: any) {
    if (!this.settable.includes(property)) {
      console.debug('FvData: Illegal property access', property);
      return;
    }

    this[property] = value;

    if (this.triggersRecalculate.includes(property)) {
      this.calculateStateAndMeta();
    }
  }

  delete(property: string) {
    if (!this.settable.includes(property)) {
      console.debug('FvData: Illegal property delete', property);
      return;
    }

    delete this[property];

    if (this.triggersRecalculate.includes(property)) {
      this.calculateStateAndMeta();
    }
  }

  private calculateStateAndMeta() {
    if (this.evseState && this.evse) {
      if (getEvseStatus(this) === unavailable) this.state = FvDataState.FAULTED;
      else if (this.evseState.isPluggedIn) this.state = FvDataState.PLUGGED_IN;
      else if (!this.evseState.isPluggedIn && this.evState && isEligibleToBlock(this.evState) && isWithinBlockingDistance(this.cs, this.evState)) {
        this.state = FvDataState.BLOCKED;
      } else this.state = FvDataState.PLUGGED_OUT;
    } else if (this.evState && this.vehicle) {
      this.state = FvDataState.IN_ZONE;
    } else if (this.evse) {
      this.state = FvDataState.PLUGGED_OUT;
    } else {
      this.state = FvDataState.MALFORMED;
    }
    
    let stateOfChargeInPercent = this.evState?.stateOfChargeInPercent;

    // Unregistered EV
    if (!this.vehicle && this.evseState?.isPluggedIn && this.evseState?.stateOfChargeInPercent) {
      stateOfChargeInPercent = this.evseState.stateOfChargeInPercent;
    }

    const estimatedMileage = (this.vehicle && this.evState)
      && Math.round(((this.vehicle.batteryCapacity * (stateOfChargeInPercent / 100)) * 1000) / this.vehicle.vehicleEfficiency);
    const estimatedTimeToFullCharge = (this.vehicle && this.evseState?.power && this.evseState?.isPluggedIn && this.evState)
      && this.getHMStringFromHours(Math.round(((this.vehicle.batteryCapacity * this.vehicle?.maxSoc / 100) - this.evState.stateOfCharge) / this.evseState.power * 100) / 100);

    this.meta = {
      stateOfChargeInPercent,
      estimatedMileage,
      estimatedTimeToFullCharge,
    };
  }

  private getHMStringFromHours(hourse: number): string {
    if ((!hourse && hourse !== 0) || isNaN(hourse) || !isFinite(hourse)) {
      return '';
    }
    const h = Math.floor(hourse);
    const min = Math.round((hourse - h) * 60);

    let timeString = '';

    if (h) {
      timeString += h + 'h';
    }

    if (min || !h) {
      timeString += (timeString.length ? ' ' : '') + min + 'm';
    }

    return timeString;
  }
}
