import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

import {parseTags} from '../asset-tags.utils';

@Component({
  selector: 'asset-tags-icon',
  templateUrl: './asset-tags-icon.component.html',
  styleUrls: ['./asset-tags-icon.component.scss'],
})
export class AssetTagsIconComponent implements OnInit, AfterViewInit {
  @ViewChild('tooltipData') tooltipData: ElementRef;
  @Input() tags: string | { [key: string]: string };

  public tooltipHtml: string;
  public parsedTags: { [key: string]: string };

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  public ngOnInit() {
    if (!this.tags) {
      return;
    }

    const parsedTags = parseTags(this.tags);
    if (Object.keys(parsedTags).length) {
      this.parsedTags = parsedTags;
    }
  }

  public ngAfterViewInit() {
    if (!this.parsedTags || !this.tooltipData?.nativeElement) {
      return;
    }

    this.tooltipHtml = this.tooltipData.nativeElement.innerHTML;

    this.changeDetectorRef.detectChanges();
  }
}
