import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import tippy, {Instance, Props} from 'tippy.js';

@Directive({selector: '[tooltip],[tooltipTitle]'})
export class TooltipDirective implements OnInit, OnDestroy, OnChanges {
  @Input() tooltip: string;
  @Input() tooltipTitle: string;
  @Input() tooltipOptions: Partial<Props>;

  private instance: Instance<Props> = null;

  constructor(
    private el: ElementRef,
  ) {
  }

  public ngOnInit() {
    this.instance = tippy(this.el.nativeElement as Element, {});

    this.setProps(this.createOptions());
  }

  public ngOnDestroy(): void {
    if (this.instance) {
      this.instance.destroy();
      this.instance = null;
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.instance) {
      return;
    }

    if (changes.tooltip?.currentValue || changes.tooltipTitle?.currentValue
      || changes.tooltip?.currentValue !== changes.tooltip?.previousValue) {
      this.setProps(this.createOptions());
    }
  }

  private createOptions() {
    const options: Partial<Props> = {};

    if (this.tooltipTitle) {
      options.delay = [800, 200];
      options.content = this.tooltipTitle || null;
    } else {
      options.content = this.tooltip || null;
    }

    return {
      ...(this.tooltipOptions ?? {}),
      ...options,
    };
  }

  private setProps(props: Partial<Props>) {
    this.instance.setProps(props);

    if (!props.content) {
      this.instance.disable();
    } else {
      this.instance.enable();
    }
  }
}
