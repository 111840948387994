import {Component, OnInit} from '@angular/core';
import {ToastService} from '../../../shared/services/toast.service';
import {timer} from 'rxjs';

export enum ToastColors {
  green = 'green',
  red = 'red',
  blue = 'blue',
  amber = 'amber'
}

@Component({
  selector: 'toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit {

  private options = {
    fullwidth: true,
    showFor: 1800,
    position: 'top',
  };

  toastOptions;
  toastMessage = '';
  show = false;

  constructor(private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.toastService
      .listener()
      .subscribe(toast => {
        this.pop(toast);
      });
  }

  private pop({message, options}) {
    if (!options) this.toastOptions = Object.assign({}, this.options);
    this.toastMessage = message;
    this.show = true;
    timer(this.toastOptions.showFor).subscribe(_ => {
      this.show = false;
      this.toastMessage = '';
      this.toastOptions = undefined;
    });
  }

}
