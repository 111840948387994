<div
    class="table-header__main bdd-id--table-header--table-header-row"
    [class.table-header__main--condensed]="isCondensed"
>
    <ng-container *ngFor="let column of columns">
        <div *ngIf="!column.isHidden"
            class="bdd-id--table-header--table-column"
            [style.width.%]="column.widthPerc"
            [style.flex]="isMobile ? (column.mobileWidthFlex || column.widthFlex) : column.widthFlex"
            [attr.data-bdd-value]="column.id"
        >
            <table-header-cell
                class="bdd-id--table-header--table-header-cell"
                [column]="column"
                [isSorted]="column.id === sortedColumnId"
                (sorted)="sort($event)"
                [attr.data-bdd-value]="column.id">
            </table-header-cell>
        </div>
    </ng-container>
</div>
