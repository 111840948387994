import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

export interface ChartData {
  title: string;
  data: {
    name: string;
    value: number;
  }[];
  colours: string[];
}

@Component({
  selector: 'fleet-location-donut-chart',
  templateUrl: './fleet-location-donut-chart.component.html',
  styleUrls: ['./fleet-location-donut-chart.component.scss'],
})
export class FleetLocationDonutChartComponent implements OnChanges {
  @Input() chartData: ChartData;
  @Input() fontSize: number;
  @Input() lineHeight: number;

  public chartOptions: any;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData?.currentValue !== changes.chartData?.previousValue) {
      this.chartOptions = this.createChartOptions();
    }
  }

  private createChartOptions() {
    const chartOptions = {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
        formatter: '<strong>{c}</strong> {b}',
      },
      toolbox: {
        show: false,
      },
      title: {
        text: '',
        left: 'center',
        top: 'center',
        textStyle: {
          color: '#fff',
          fontSize: this.fontSize || 12,
          lineHeight: this.lineHeight || 16,
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['65%', '85%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          data: [],
        },
      ],
    };

    return this.updateChartSeriesData(chartOptions);
  }

  private updateChartSeriesData(chartOptions: any) {
    if (!chartOptions || !this.chartData) {
      return;
    }

    const {title, data, colours} = this.chartData;

    chartOptions.title.text = title;
    chartOptions.series[0].data = data;
    chartOptions.series[0].color = colours;

    return {...chartOptions};
  }
}
