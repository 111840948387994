import {NgModule} from '@angular/core';
import {DashWhenNoValuePipe} from './dash-when-no-value.pipe';

@NgModule({
  declarations: [
    DashWhenNoValuePipe,
  ],
  exports: [
    DashWhenNoValuePipe,
  ],
})
export class NoValueDashPipeModule {
}
