import {Injectable} from '@angular/core';
import {Feature as GeoJSONFeature, FeatureCollection as GeoJSONFeatureCollection} from 'geojson';
import {Subject} from 'rxjs';
import mapboxgl, {Popup} from 'mapbox-gl';

import {FleetLocationState} from '../../shared/models/entity-states';
import {isValidFloat} from '../../shared/utils/number';
import {getEvseStatus} from '../../facility-view/utils';
import {isCsOffline} from '../../facility-view/utils/offline-status.util';

export interface PopupValues {
  coords: [number, number];
  properties: { [key: string]: any };
}

const donutColors = ['#d5283c', '#fa8b45', '#4ab471'];

export const createMapboxPopup = (
  map: mapboxgl.Map,
  domElement: HTMLElement,
  coordinates: [number, number],
  onCloseCallback?: () => void,
) => {
  const popupCloseFunc = () => {
    if (!map || (map as any)._removed === true) {
      return;
    }

    if (map?.getLayer('iso_layer')) {
      map.removeLayer('iso_layer');
    }

    if (onCloseCallback) {
      onCloseCallback();
    }
  };

  return new Popup({
    offset: {
      top: [0, 12],
      'top-left': [0, 12],
      'top-right': [0, 12],
      bottom: [0, -12],
      'bottom-left': [0, -12],
      'bottom-right': [0, -12],
      left: [12, 0],
      right: [-12, 0],
    },
  })
    .setLngLat(coordinates)
    .setDOMContent(domElement)
    .setMaxWidth('400px')
    .on('close', popupCloseFunc)
    .addTo(map);
};

@Injectable()
export class FleetLocationMapService {
  private popupDataSubject$ = new Subject<PopupValues>();

  public popupDataSubject() {
    return this.popupDataSubject$;
  }

  public addIsoChroneLayerToMap(values: PopupValues) {
    this.popupDataSubject$.next(values);
  }

  public createVehiclesGeoJSONFeatures(
    {evState, evsesState, vehicles, stateOfChargeInPercent}: FleetLocationState,
  ): GeoJSONFeatureCollection {
    const vehiclesHashMap = new Map(vehicles.map(vehicle => [vehicle.evId, vehicle]));

    const vehiclesWithLatLng = evState
      .filter(item => item.latitude !== null && item.longitude !== null && vehiclesHashMap.get(item.evId));

    const vehicleTemplate = (
      position: [number, number],
      id: number,
      soc: number,
      extra: { [key: string]: any },
    ): GeoJSONFeature => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: position,
      },
      properties: {
        id,
        soc,
        ...extra,
      },
    });

    const vehicleFeatures = vehiclesWithLatLng.map(vehicleState => {
      const {evIdentifier, manufacturer, model, batteryCapacity, vehicleEfficiency} = vehiclesHashMap.get(vehicleState.evId);
      const {evId, latitude, longitude} = vehicleState;

      const evseState = evsesState.find(item => item.evId === vehicleState.evId && item.isPluggedIn === true);

      const estimatedMileage = Math.round(((batteryCapacity * (stateOfChargeInPercent[evId] / 100)) * 1000) / vehicleEfficiency);

      const chargingState: any = {
        isCharging: !!evseState,
      };

      if (evseState) {
        chargingState.chargingStationId = evseState.chargingStationId;
        chargingState.evseId = evseState.evseId;
      }

      return vehicleTemplate(
        [longitude, latitude],
        evId,
        stateOfChargeInPercent[evId] || 0,
        {evIdentifier, manufacturer, model, estimatedMileage, ...chargingState},
      );
    });

    return {
      type: 'FeatureCollection',
      features: vehicleFeatures,
    };
  }

  public createChargingStationGeoJSONFeatures(chargingStations: any[]): GeoJSONFeatureCollection {
    const features = [];

    chargingStations
      .filter(cs => isValidFloat(cs.chargingStation.longitude) && isValidFloat(cs.chargingStation.latitude))
      .forEach(cs => {
        const charger = this.createFeatureBase(cs);
        charger.properties.iconName =  this.calculateChargerIconName(cs);
        features.push(charger);

        if (isCsOffline(cs)) {
          const offlineBorder = this.createFeatureBase(cs);
          offlineBorder.properties.iconName = 'offline';
          features.push(offlineBorder);
        }
      });

    return {
      type: 'FeatureCollection',
      features: features,
    };
  }

  public createEvseGeoJSONFeatures(chargingStations: any[]): GeoJSONFeatureCollection {
    const features = [];

    chargingStations.forEach(cs => {
      cs.evses
      .filter(evse => isValidFloat(evse.evse.longitude) && isValidFloat(evse.evse.latitude))
      .forEach(evse => {
        const socket = this.createEvseFeatureBase(evse);
        socket.properties.iconName =  this.calculateEvseIconName(evse);
        features.push(socket);

        if (isCsOffline(cs)) {
          const offlineBorder = this.createEvseFeatureBase(evse);
          offlineBorder.properties.iconName = 'offline';
          features.push(offlineBorder);
        }
      });
    })

    return {
      type: 'FeatureCollection',
      features: features,
    };
  }

  private createFeatureBase(cs) {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [cs.chargingStation.longitude, cs.chargingStation.latitude],
      },
      properties: {
        id: cs.chargingStation.chargingStationId,
        iconName: null,
      },
    };
  }

  private createEvseFeatureBase(evse) {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [evse.evse.longitude, evse.evse.latitude],
      },
      properties: {
        csId: evse.evse.chargingStationId,
        evseId:evse.evse.evseId,
        iconName: null,
      },
    };
  }

  private calculateChargerIconName(cs): string {
    const statuses = [getEvseStatus(cs.evses[0]), getEvseStatus(cs.evses[1])].filter(x => x).map(x => x.toLowerCase());

    if (statuses.length == 2) {
      return 'double-' + statuses.join('-');
    } else if (statuses.length == 1) {
      return 'single-' + statuses[0];
    } else {
      return 'charger'
    }
  }

  private calculateEvseIconName(evse): string {
    const status = getEvseStatus(evse).toLowerCase();

    if (status) {
      return 'single-' + status;
    } else {
      return 'charger'
    }
  }

  public createChargingStationSimpleGeoJSONFeatures(chargingStations): GeoJSONFeatureCollection {
    return {
      type: 'FeatureCollection',
      features: chargingStations
        .filter(cs => isValidFloat(cs.longitude) && isValidFloat(cs.latitude))
        .map(cs => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [cs.longitude, cs.latitude],
          },
          properties: {
            id: cs.chargingStationId,
            extId: cs.extChargingStationId,
            name: cs.name,
            evses: cs.evSupplyEquipments.length,
            chargerType: cs.chargerTypeName,
          },
        })),
    };
  }

  public findFeatureByEvId(mapData: GeoJSONFeatureCollection, evId: number) {
    return (mapData.features || [])
      .find(item => item.properties.id === evId);
  }

  public findFeatureCoordsByEvId(mapData: GeoJSONFeatureCollection, evId: number) {
    const feature = this.findFeatureByEvId(mapData, evId);

    return feature
      ? (feature.geometry as any).coordinates
      : false;
  }

  public createDonutChart(props: { [key: string]: any }) {
    const offsets = [];
    const counts = [
      props.red,
      props.amber,
      props.green,
    ];

    let total = 0;
    for (const count of counts) {
      offsets.push(total);
      total += count;
    }

    const getFontSize = (totalValue: number) => {
      if (totalValue >= 1000) {
        return 22;
      }
      if (totalValue >= 100) {
        return 22;
      }
      if (totalValue >= 10) {
        return 18;
      }
      return 16;
    };

    const getRadius = (totalValue: number) => {
      if (totalValue >= 1000) {
        return 50;
      }
      if (totalValue >= 100) {
        return 32;
      }
      if (totalValue >= 10) {
        return 24;
      }
      return 18;
    };

    const fontSize = getFontSize(total);
    const r = getRadius(total);

    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    let html = `<div>
    <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

    for (let i = 0; i < counts.length; i++) {
      html += this.donutSegment(
        offsets[i] / total,
        (offsets[i] + counts[i]) / total,
        r,
        r0,
        donutColors[i],
      );
    }

    html += `
    <circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
    <text dominant-baseline="central" transform="translate(${r}, ${r})">
    ${total.toLocaleString()}
    </text>
    </svg>
    </div>`;

    const el = document.createElement('div');
    el.innerHTML = html;
    return el.firstChild as HTMLElement;
  }

  private donutSegment(start: number, end: number, radius: number, r0: number, color: string) {
    if (end - start === 1) {
      end -= 0.00001;
    }

    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0);
    const y0 = Math.sin(a0);
    const x1 = Math.cos(a1);
    const y1 = Math.sin(a1);

    const largeArc = end - start > 0.5 ? 1 : 0;

    // draw an SVG path
    return `<path d="M ${radius + r0 * x0} ${radius + r0 * y0} L ${radius + radius * x0} ${
      radius + radius * y0
    } A ${radius} ${radius} 0 ${largeArc} 1 ${radius + radius * x1} ${radius + radius * y1} L ${
      radius + r0 * x1
    } ${radius + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${radius + r0 * x0} ${
      radius + r0 * y0
    }" fill="${color}" />`;
  }
}
