import {Component, EventEmitter, Input, Output} from '@angular/core';

import {EvseStatus} from '../../../models';

export interface EvseStatusFilters {
  evseStatus: EvseStatus[];
  offlineStatus: boolean;
}

@Component({
  selector: 'evses-status-filters',
  templateUrl: './evses-status-filters.component.html',
  styleUrls: ['./evses-status-filters.component.scss'],
})
export class EvsesStatusFiltersComponent {
  @Input() selectedFilters: EvseStatusFilters = {
    evseStatus: [],
    offlineStatus: false,
  };

  @Output() filtered = new EventEmitter<EvseStatusFilters>();

  public readonly EvseStatus = EvseStatus;

  public selectEvseStatus(option: EvseStatus) {
    const index = this.selectedFilters.evseStatus.indexOf(option);

    if (index === -1) {
      this.selectedFilters.evseStatus.push(option);
    } else {
      this.selectedFilters.evseStatus.splice(index, 1);
    }

    this.filtered.emit(this.selectedFilters);
  }

  public selectOfflineStatus() {
    this.selectedFilters.offlineStatus = !this.selectedFilters.offlineStatus;
    this.filtered.emit(this.selectedFilters);
  }

  public evseStatusSelected(option: EvseStatus) {
    return this.selectedFilters.evseStatus.includes(option);
  }
}
