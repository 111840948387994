import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TabSwitchHeaderComponent} from './tab-switch-header.component';
import {AuthPermissionModule} from '../../directives/auth-permission/auth-permission.module';

@NgModule({
  declarations: [
    TabSwitchHeaderComponent,
  ],
  imports: [
    CommonModule,
    AuthPermissionModule,
  ],
  exports: [
    TabSwitchHeaderComponent,
  ],
})
export class TabSwitchHeaderModule {
}
