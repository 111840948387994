import {Component, Input} from '@angular/core';

import {Props} from 'tippy.js';

@Component({
  selector: 'tooltip-icon',
  templateUrl: './tooltip-icon.component.html',
  styleUrls: ['./tooltip-icon.component.scss'],
})
export class TooltipIconComponent {
  @Input() public text: string;
  @Input() public tooltipOptions: Partial<Props>;
}
