import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';

import {environment} from '../../../environments/environment';
import {CurrentSessionService} from '../../shared/services/current-session.service';
import {DataDistributor} from './data-distributor.service';
import {createSocket, Socket} from '../../shared/utils/createSocket';

@Injectable()
export class FacilityConnectionHandlerService {
  private connection: Socket;
  private messages: Subscription;

  constructor(
    private currentSession: CurrentSessionService,
    private dataDistributor: DataDistributor,
  ) {
  }

  public connectToSocket(facilityId: number, token: string) {
    const tenantId = this.currentSession.getTenantId();
    const params = {facilityId, tenantId, token};
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    const url = `${environment.api.fsas.ws}?${queryString}`;

    const preCloseHook = (ws: WebSocket) => {
      ws.send(JSON.stringify({
        tenantId: this.currentSession.getTenantId(),
        facilityId,
        action: 'DISCONNECT',
        message: 'action',
      }));
    };

    if (!this.connection) {
      this.connection = createSocket(url, {preCloseHook});

      if (this.messages) {
        this.messages.unsubscribe();
      }

      this.messages = this.connection.messages
        .subscribe((data) => {
          this.dataDistributor.emit(data);
        });
    } else {
      this.connection.reestablish(url, {preCloseHook});
    }
  }

  public closeSocket() {
    if (this.connection) {
      this.connection.close();
      this.connection = null;
    }

    if (this.messages) {
      this.messages.unsubscribe();
      this.messages = null;
    }
  }
}
