import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

import {AuthPermissionService} from '../../services/auth-permission.service';

@Directive({
  selector: '[authPermission]',
})
export class AuthPermissionDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private authPermissionService: AuthPermissionService,
  ) {
  }

  public resourceType: string;
  public elseRef: TemplateRef<any>;

  @Input() set authPermission(resourceType: string) {
    this.resourceType = resourceType;
    this.updateView();
  }

  @Input() set authPermissionElse(elseRef: TemplateRef<any>) {
    this.elseRef = elseRef;
    this.updateView();
  }

  private updateView() {
    this.viewContainerRef.clear();

    const isHidden = this.resourceType && this.authPermissionService.checkPermission(this.resourceType);
    if (!isHidden) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.elseRef) {
      this.viewContainerRef.createEmbeddedView(this.elseRef);
    }
  }
}
