<div *ngIf="notification" class="cs-notification-popup">
    <div class="cs-notification-popup__title">
        {{ name }} EVSE Latest Notification Status
    </div>

    <div class="cs-notification-popup__row">
        <div class="cs-notification-popup__column mr-3">
            <span class="label">Status</span>
            {{ notification.status | sentenceCase }}
        </div>

        <div class="cs-notification-popup__column">
            <span class="label">Timestamp</span>
            {{ notification.timestamp | date:'d MMM yy HH:mm:ss' }}
        </div>
    </div>

    <div class="cs-notification-popup__row">
        <div class="cs-notification-popup__column mr-3">
            <span class="label">Level</span>
            {{ notification.level }}
        </div>

        <div class="cs-notification-popup__column">
            <span class="label">Connector ID</span>
            {{ notification.connectorId }}
        </div>
    </div>

    <div class="cs-notification-popup__row">
        <div class="cs-notification-popup__column mr-3">
            <span class="label">Error</span>
            {{ notification.errorCode | sentenceCase }}
        </div>

        <div class="cs-notification-popup__column">
            <span class="label">Info</span>
            {{ notification.info }}
        </div>
    </div>
</div>
