import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ECharts} from 'echarts';
import {fromEvent} from 'rxjs';
import {auditTime} from 'rxjs/operators';
import {CHART_ICON} from '../../../../analytics/consts/icon.consts';
import {FullScreenDirective} from '../../../../shared/directives/full-screen/full-screen.directive';
import {FacilityCalculatedMeta} from '../../../../shared/models/entity-states';
import {OptimisationUnit} from '../../../../shared/models/enums';

@Component({
  selector: 'charging-groups-chart',
  templateUrl: './charging-groups-chart.component.html',
  styleUrls: ['./charging-groups-chart.component.scss']
})
export class ChargingGroupsChartComponent implements OnInit, OnChanges {
  public chartOptions: any;
  public maxCapacity: number;
  public chargingGroups = [];
  public echartsInstance: ECharts;

  private meta: FacilityCalculatedMeta[];

  @Input() fullScreen: FullScreenDirective;
  @Input() unit: OptimisationUnit;
  
  @Input()
  public calculatedMeta: FacilityCalculatedMeta[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.calculatedMeta?.currentValue.length > 0) {
      this.meta = this.calculatedMeta;

    this.chargingGroups = this.meta.filter(x => x.unit == this.unit).map(x => {
      return {chargingGroupId: x.chargingGroupId, chargingGroupName: x.chargingGroupName};
    }).sort((a, b) => a.chargingGroupName.localeCompare(b.chargingGroupName));

    if (!this.chartOptions) {
      this.createChartOptions();
    }

    this.updateChartSeriesData(this.chartOptions, this.calculatedMeta);
    }
  }

  ngOnInit(): void {
    const screenChange = fromEvent(window, 'resize')
      .pipe(
        auditTime(100),
      );

    screenChange.subscribe(() => {
      this.echartsInstance?.resize();
    });
  }

  onChartInit(ec) {
    this.echartsInstance = ec;
  }

  private createChartOptions() {
    const chargingGroupsNames = this.chargingGroups.map(x => x.chargingGroupName);
    const chartOptions = {
      toolbox: {
        show: true,
        right: 20,
        top: 0,
        itemSize: 13,
        itemGap: 10,
        emphasis: {
          iconStyle: {
            textPadding: 5,
          },
        },
        feature: {
          saveAsImage: {
            icon: CHART_ICON.photo,
          },
          myFullScreenToggle: {
            show: true,
            title: this.fullScreen.isFullScreen ? 'Minimize' : 'Full screen',
            icon: this.fullScreen.isFullScreen ? CHART_ICON.minimize : CHART_ICON.maximize,
            onclick: this.onFullScreenToggleClick,
          },
        },
      },
      grid: {
        top: 28,
        bottom: 10,
        left: 110,
        right: 25,
        containLabel: true,
      },
      animation: false,
      title: {
        textStyle: {
          fontSize: 18,
          fontWeight: 200,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
        order: 'seriesDesc',
        valueFormatter: (value: string) => `${Math.round(+value * 100) / 100}${this.unit}`,
        position: function (point, params, dom, rect, size) {
          // fixed at top
          return [point[0] + 15, point[1] + 15];
      },
      },
      legend: {
        left: 'left',
        top: 'middle',
        orient: 'vertical',
        padding: [0, 10, 0, 0],
        data: ['Headroom', 'EV Load', 'Facility Load', 'Buffer', 'Live Limit'],
      },
      xAxis: [
        {
          type: 'category',
          data: chargingGroupsNames,
          axisLabel: {
            interval: 0,
            width: '110',
            overflow: "break",
          },
        },
      ],
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value: string) => `${+value}${this.unit}`,
        },
      },
      series: [
        {
          name: 'Facility Load',
          type: 'bar',
          color: '#87CA8199',
          barWidth: '30%',
          barMaxWidth: 45,
          barMinWidth: 5,
          stack: 'Total',
          emphasis: {
            focus: 'series',
          },
          data: [],
        },
        {
          name: 'EV Load',
          type: 'bar',
          color: '#075fc188',
          barWidth: '30%',
          barMaxWidth: 45,
          barMinWidth: 5,
          stack: 'Total',
          emphasis: {
            focus: 'series',
          },
          data: [],
        },
        {
          name: 'Headroom',
          type: 'bar',
          color: '#FAC858BB',
          barWidth: '30%',
          barMaxWidth: 45,
          barMinWidth: 5,
          stack: 'Total',
          emphasis: {
            focus: 'series',
          },
          data: [],
        },
        {
          name: 'Live Limit',
          type: 'bar',
          color: '#ADD8E699',
          stack: 'maxCapacity',
          barWidth: 10,
          data: [],
          tooltip: {
            show: true,
          },
          emphasis: {
            focus: 'series'
          },
        },
        {
          name: 'Buffer',
          type: 'bar',
          barWidth: 10,
          stack: 'maxCapacity',
          color: '#E17C7C',
          data: [],
        },
        {
          name: 'Buffer Remaining',
          type: 'line',
          stack: 'buffer-tooltip',
          symbol: 'none',
          color: '#00000000',
          barWidth: 0,
          data: [],
        },
      ],
    };

    this.chartOptions = chartOptions;
  }

  private updateChartSeriesData(chartOptions: any, calculatedMeta?: FacilityCalculatedMeta[]) {
    if (!chartOptions || !calculatedMeta) {
      return;
    }

    const chartData = this.convertDataToChartFormat(calculatedMeta);

    ['facilityLoad', 'evLoad', 'headroom', 'liveLimit', 'buffer', 'bufferTooltip'].forEach((key, i) => {
      chartOptions.series[i].data = chartData[key];
    });

    chartOptions.xAxis[0].data = this.chargingGroups.map(x => x.chargingGroupName);

    this.chartOptions = {...chartOptions};
    this.echartsInstance?.resize();
  }

  private convertDataToChartFormat(calculatedMeta: FacilityCalculatedMeta[]) {
    return (this.chargingGroups || [])
      .reduce((results, group) => {
        const meta = this.meta.find(x => x.chargingGroupId === group.chargingGroupId);

        if (!meta) {
          return results;
        }

        results.facilityLoad.push(meta.facilityLoad);
        results.evLoad.push(meta.evLoad);
        results.headroom.push(meta.spare);
        results.liveLimit.push(meta.maxCapacity - meta.totalBuffer);
        results.buffer.push(meta.totalBuffer);

        const bufferRemaining = meta.maxCapacity - meta.facilityLoad - meta.evLoad - meta.spare;
        if (bufferRemaining !== meta.totalBuffer) {
          results.bufferTooltip.push();
        }

        return results;
      }, {
        facilityLoad: [],
        evLoad: [],
        headroom: [],
        liveLimit: [],
        buffer: [],
        bufferTooltip: [],
      });
  }

  public onFullScreenToggleClick = () => {
    if (this.fullScreen.isFullScreen) {
      this.fullScreen.minimize();
      this.chartOptions.toolbox.feature.myFullScreenToggle.title = 'Full screen';
      this.chartOptions.toolbox.feature.myFullScreenToggle.icon = CHART_ICON.maximize;
    } else {
      this.fullScreen.maximize();
      this.chartOptions.toolbox.feature.myFullScreenToggle.title = 'Minimize';
      this.chartOptions.toolbox.feature.myFullScreenToggle.icon = CHART_ICON.minimize;
    }
    this.echartsInstance.setOption(this.chartOptions);
    this.echartsInstance.resize();
  };

}
