import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {auditTime, takeUntil} from 'rxjs/operators';

import {Vehicle} from '../../shared/models/entities';
import {FleetLocationState} from '../../shared/models/entity-states';
import {
  FleetLocationMapFilterService,
  PluggedInOptions,
  StateOfChargeOptions,
} from './fleet-location-map-filter.service';

@Component({
  selector: 'fleet-location-map-filter',
  templateUrl: './fleet-location-map-filter.component.html',
  styleUrls: ['./fleet-location-map-filter.component.scss'],
})
export class FleetLocationMapFilterComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('filterInput') filterInput: ElementRef;
  @Input() state: FleetLocationState;

  public pluggedInSelected: PluggedInOptions;
  public stateOfChargeSelected: StateOfChargeOptions;
  public vehicles: Vehicle[] = [];

  public readonly PluggedInOptions = PluggedInOptions;
  public readonly StateOfChargeOptions = StateOfChargeOptions;

  public textFilterControl = new UntypedFormControl();
  public selectedVehicleControl = new UntypedFormControl();

  private updateMapFilters$ = new Subject<void>();

  private destroyed$ = new Subject<void>();

  constructor(
    private fleetLocationMapFilterService: FleetLocationMapFilterService,
  ) {
  }

  public ngOnInit() {
    this.fleetLocationMapFilterService.mapFiltersActionSubject()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        if (value.type === 'clear-vehicle') {
          this.selectedVehicleControl.reset(null, {emitEvent: false});
        }
      });

    this.selectedVehicleControl
      .valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        this.fleetLocationMapFilterService.selectFacilityVehicle(value.evId);
      });

    this.updateMapFilters$
      .pipe(
        auditTime(200),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.processFilters();
      });

    if (this.state) {
      this.updateMapFilters$.next();
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.state?.currentValue !== changes.state?.previousValue && this.state) {
      this.updateMapFilters$.next();
    }
  }

  public selectPluggedInOption(option: PluggedInOptions) {
    this.pluggedInSelected = (this.pluggedInSelected === option)
      ? null
      : option;

    this.updateMapFilters$.next();
  }

  public selectStateOfChargeOption(option: StateOfChargeOptions) {
    this.stateOfChargeSelected = (this.stateOfChargeSelected === option)
      ? null
      : option;

    this.updateMapFilters$.next();
  }

  public selectFacility() {
    this.fleetLocationMapFilterService.selectFacility();
  }

  public clearFilters() {
    this.pluggedInSelected = null;
    this.stateOfChargeSelected = null;

    this.textFilterControl.setValue(null);
    this.selectedVehicleControl.setValue(null, {emitEvent: false});

    this.updateMapFilters$.next();
  }

  public handleSelectOpenChanged(value: boolean) {
    if (value === true && !this.selectedVehicleControl.value) {
      this.filterInput.nativeElement.focus();
    }
  }

  private processFilters() {
    if (!this.state) {
      return;
    }

    this.updateVehicles();
    this.updateMapFilters();
  }

  private updateVehicles() {
    const {vehicles, pluggedInStatus, evState} = this.state;

    const vehiclesHashMap = new Map(vehicles.map(vehicle => [vehicle.evId, vehicle]));

    const filteredEvStates = evState.filter(item => {
      if (item.latitude === null || item.longitude === null || !vehiclesHashMap.get(item.evId)) {
        return false;
      }

      if (this.pluggedInSelected) {
        if (this.pluggedInSelected === PluggedInOptions.PluggedIn && pluggedInStatus[item.evId] === false) {
          return false;
        }

        if (this.pluggedInSelected === PluggedInOptions.NotPluggedIn && pluggedInStatus[item.evId] === true) {
          return false;
        }
      }

      if (this.stateOfChargeSelected) {
        if (this.stateOfChargeSelected === StateOfChargeOptions.Low && item.stateOfCharge > 33) {
          return false;
        }

        if (this.stateOfChargeSelected === StateOfChargeOptions.Medium && item.stateOfCharge < 33 || item.stateOfCharge > 66) {
          return false;
        }

        if (this.stateOfChargeSelected === StateOfChargeOptions.High && item.stateOfCharge < 66) {
          return false;
        }
      }

      return true;
    });

    this.vehicles = filteredEvStates.map(item => vehiclesHashMap.get(item.evId));
  }

  private updateMapFilters() {
    const filterToApply: { [key: string]: string } = {};

    if (this.pluggedInSelected) {
      filterToApply.pluggedIn = this.pluggedInSelected;
    }

    if (this.stateOfChargeSelected) {
      filterToApply.stateOfCharge = this.stateOfChargeSelected;
    }

    this.fleetLocationMapFilterService.updateMapFilters(filterToApply);
  }
}
