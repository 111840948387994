import {ChargingGroup} from '../../shared/models/entities/charging-group.model';
import {ChargingStation, Vehicle} from '../../shared/models/entities';
import {EvseStatusNotification} from '../../shared/models/entity-states';
import {OptimisationUnit} from '../../shared/models/enums';
import {VehicleGroup} from '../../shared/models/entities/vehicle-group.model';

export interface EvseView {
  // Basic static data
  evseId: number;
  extEvseId: number;
  chargingStationId: number;
  maxPower: number;
  maxCurrent: number;
  defaultCurrent: number;
  defaultPower: number;
  chargingPriority?: number;
  location: string;
  zone: string;
  facilityId: number;
  optimisationUnit: OptimisationUnit;

  // Status data
  evseStatus?: string;
  fvEvseStatus?: string;
  isPluggedIn?: boolean;
  power?: number;
  energy?: number;
  current?: number;
  evId?: number;
  regNo?: string;
  vipVehicle?: boolean;
  batteryCapacity?: number;
  setpoint?: number;
  timestamp?: Date | string;
  duration?: string;
  stateOfCharge?: number;
  stateOfChargeInPercent?: number;
  online?: boolean;
  evseStatusNotification?: EvseStatusNotification;
  sessionId?: string;
  sessionStart?: Date;
  hideSetpoint?: boolean;

  // Asset data
  chargingStation: ChargingStation;
  chargingGroup?: ChargingGroup;
  vehicleGroup?: VehicleGroup;
  vehicle?: Vehicle;

  isAnimated?: boolean;
}

export enum EvseStatus {
  InUse = 'InUse',
  Available = 'Available',
  Unavailable = 'Unavailable'
}
