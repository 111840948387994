import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';

import {AppBootstrapService} from '../services/app-bootstrap.service';
import {AuthPermissionService} from '../../shared/services/auth-permission.service';
import {RouterHelperService} from '../../shared/services/router-helper.service';

@Injectable()
export class AuthPermissionGuard implements CanActivate, CanActivateChild {
  constructor(
    private appBootstrapService: AppBootstrapService,
    private permissionService: AuthPermissionService,
    private routerHelperService: RouterHelperService,
  ) {
  }

  public async canActivate() {
    await this.appBootstrapService.initialiseDataServices();

    return true;
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const routePath = this.getPathFromRoute(route);

    const userHasPermission = !this.permissionService.checkPermission(`page/${routePath}`);
    if (!userHasPermission) {
      this.routerHelperService.handleErrorStatus(403, state.url);
      return false;
    }

    return true;
  }

  public getPathFromRoute(route: ActivatedRouteSnapshot): string {
    const routePaths = [];

    const traverseRoute = (routeObj: ActivatedRouteSnapshot) => {
      if (!routeObj) {
        return;
      }

      if (routeObj.routeConfig && routeObj.routeConfig.path) {
        routePaths.push(routeObj.routeConfig.path);
      }

      if (route.parent) {
        return traverseRoute(routeObj.parent);
      }
    };

    traverseRoute(route);

    routePaths.reverse();

    const fullPath = routePaths.join('/');

    return fullPath
      .split('/')
      .filter(segment => !segment.includes(':'))
      .join('/');
  }
}
