<div *ngIf="facility && facilityStateLoaded; then loaded else loading"></div>

<ng-template #loaded>
    <div class="stats-wrapper d-flex flex-row mt-2" *ngIf="state?.facilityHeadroom">
        <div class="charging-group-chart-wrapper row">
            <ng-container *ngIf="state?.facilityHeadroom?.groups?.length > 0">
                <charging-groups-chart *ngIf="showkWChart" fullScreen #fullScreenChargingGroupkW="fullScreen"
                    class="bdd-id--facility-overview--charging-groups-chart col-12 col-md-6"
                    [ngClass]="{'col-md-12': !showAChart, 'mb-2 mb-md-0': showAChart && showkWChart, 'px-1': !fullScreenChargingGroupkW.isFullScreen, 'px-0': fullScreenChargingGroupkW.isFullScreen}"
                    [calculatedMeta]="state?.calculatedMeta"
                    [fullScreen]="fullScreenChargingGroupkW"
                    [unit]="'kW'"
                    >
                </charging-groups-chart>
                
                <charging-groups-chart *ngIf="showAChart" fullScreen #fullScreenChargingGroupA="fullScreen"
                    class="bdd-id--facility-overview--charging-groups-chart col-12 col-md-6"
                    [ngClass]="{'col-md-12': !showkWChart, 'px-1': !fullScreenChargingGroupA.isFullScreen, 'px-0': fullScreenChargingGroupA.isFullScreen}"
                    [calculatedMeta]="state?.calculatedMeta"
                    [fullScreen]="fullScreenChargingGroupA"
                    [unit]="'A'"
                    >
                </charging-groups-chart>
            </ng-container>
            <ng-container *ngIf="state?.facilityHeadroom?.groups?.length == 0">
                <div class="no-data-row bdd-id--facility-overview--no-data-row ml-1 h-100 d-flex align-items-center justify-content-center">
                    <div>Insufficient data to draw the Live Load graph.</div>
                </div>
            </ng-container>
        </div>
        <div class="pie-chart-card-wrapper  status-pie-chart bdd-id--facility-overview--evse-status-pie-chart">
            <div class="pie-chart-card h-100" #evseStatusSelector>
                <facility-view-info-filter class="flex-fill status-filter bdd-id--facility-overview--evse-status-info" 
                                       [title]="'EVSE Status'"
                                       [data]="filterableCsData"
                                       [showSelector]="false"
                                       [cols]="2"
                                       [totalColour]="'#000000'"
                                       [showPieChartOnMobile]="true"
                                       >
                </facility-view-info-filter>
                
            </div>
            <div class="save-img-wrapper">
                <button class="btn p-0 custom-tooltip"
                    data-tooltip="Save as Image" (click)="downloadImage(evseStatusSelector, 'evse-status')">
                    <img [src]="photoIconBase64" alt="save as image" width="18px" />
                </button>
            </div>
        </div>
        
        <div class="pie-chart-card-wrapper  offline-pie-chart bdd-id--facility-overview--offline-status-pie-chart">
            <div class="pie-chart-card h-100" #offlineSelector>
                <facility-view-info-filter class="flex-fill offline-filter bdd-id--facility-overview--offline-status-info" 
                                       [title]="'Offline Status'"
                                       [data]="offlineData"
                                       [showSelector]="false"
                                       [cols]="1"
                                       [totalColour]="'#000000'"
                                       [showPieChartOnMobile]="true"
                                       >
                </facility-view-info-filter>
            </div>
            <div class="save-img-wrapper">
                <button class="btn p-0 custom-tooltip"
                    data-tooltip="Save as Image" (click)="downloadImage(offlineSelector, 'offline-status')">
                    <img [src]="photoIconBase64" alt="save as image" width="18px" />
                </button>
            </div>
        </div>
    </div>

    <div class="no-data-row bdd-id--facility-overview--no-data-row" *ngIf="!state?.facilityHeadroom">
        Insufficient data to draw the Live Load graph.
    </div>
</ng-template>

<ng-template #loading>
    <div
        class="d-flex flex-column main justify-content-center align-items-center bdd-id--facility-overview--loading-spinner w-100 my-4">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
