import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {formatDate} from '@angular/common';
import moment from 'moment';

@Pipe({
  name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
  ) {
  }

  public transform(date: string | Date, numberOfWeeks = 0, _forceTimeUpdate: number, dateFormat = 'd MMM yy HH:mm:ss'): string {
    if (!date) {
      return;
    }

    const reference = moment(date);
    const current = moment().startOf('day');

    if (numberOfWeeks) {
      const duration = moment.duration(reference.diff(current)).asWeeks();

      if (Math.abs(duration) >= numberOfWeeks) {
        return formatDate(reference.toDate(), dateFormat, this.locale);
      }
    }

    return reference.fromNow();
  }
}
