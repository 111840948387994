const green = '#4AB471';
const amber = '#F3AE4E';
const red = '#D5283C';
const unavailableRed = '#C31C00';
const grey = '#959595';
const lightGrey = '#e0e0e0';
const blue = '#0582CA';
const lightBlue = '#7bbed3';
const violet = '#7010e4';
const pink = '#c586c0';
const orange = '#D86900';

export const highSocColour = green;
export const mediumSocColour = amber;
export const lowSocColour = red;
export const unknownSocColour = grey;

export const pluggedInColour = green;
export const pluggedOutColour = red;
export const noConnectionColour = grey;

export const occupiedColour = blue;
export const unoccupiedColour = grey;
export const unavailableColour = red;
export const blockedColour = amber;

export const offlineColour = violet;
export const onlineColour = green;

export const availableColor = lightBlue;
export const preparingColor = grey;
export const chargingColor = green;
export const suspendedEVSEColor = pink;
export const suspendedEVColor = violet;
export const finishingColor = blue;
export const reservedColor = amber;
export const unavailableColor = unavailableRed;
export const faultedColor = orange;
export const unknownColor = lightGrey;
