import {Injectable} from '@angular/core';
import {forkJoin, Observable, of, Subject, timer} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {EvseStatus, EvseView} from '../models';
import {Vehicle} from '../../shared/models/entities';
import {EvseState} from '../../shared/models/entity-states';
import {AssetManagerService} from '../../shared/services/asset-manager.service';
import {FacilityStateService} from '../../shared/services/facility-state.service';
import {FvData} from '../../facility-view/models';
import {getEvseStatus} from '../../facility-view/utils';
import {OptimisationUnit} from '../../shared/models/enums';
import {VehicleGroup} from '../../shared/models/entities/vehicle-group.model';

export type EventType = 'refresh' | 'filterAndRefresh';

@Injectable()
export class EvseStateService {
  public eventEmitter = new Subject<EventType>();
  public evseStateUpdated$ = new Subject<EvseState>();

  private vehiclesStaticDataMap: Map<number, Vehicle>;

  constructor(
    private assetManagerService: AssetManagerService,
    private facilityStateService: FacilityStateService,
  ) {
    this.vehiclesStaticDataMap = new Map<number, Vehicle>();
  }

  public getEvsesViewByFacilityId(facilityId: number): Observable<EvseView[]> {
    const vehicles$ = this.assetManagerService.getElectricVehiclesByFacilityId(facilityId);
    const chargingGroups$ = this.assetManagerService.getChargingGroupsByFacilityId(facilityId);
    const vehicleGroups$ = this.assetManagerService.getVehicleGroupsByFacilityId(facilityId);
    const chargingStations$ = this.assetManagerService.getAllChargingStationsByFacilityId(facilityId);
    const facilityZones$ = this.assetManagerService.getFacilityZonesByFacilityId(facilityId)
      .pipe(catchError(() => of([])));
    const facilityOptimisationSettings$ = this.assetManagerService.getFacilityOptimisationSettingsByFacilityId(facilityId);

    // TODO: when ready, revert back to facilityId (not extFacilityId)
    const facilityState$ = this.facilityStateService.getFacilityStateById(facilityId, true);

    return forkJoin({
      vehicles: vehicles$,
      chargingStations: chargingStations$,
      chargingGroups: chargingGroups$,
      vehicleGroups: vehicleGroups$,
      facilityZones: facilityZones$,
      facilityState: facilityState$,
      facilityOptimisationSettings: facilityOptimisationSettings$,
    }).pipe(
      map(({vehicles, chargingStations, chargingGroups, vehicleGroups, facilityZones, facilityState, facilityOptimisationSettings}) => {
        vehicles.forEach(vehicle => this.vehiclesStaticDataMap.set(vehicle.evId, vehicle));

        chargingStations.sort((a, b) => {
          if (a.chargingStationId < b.chargingStationId) {
            return -1;
          }

          if (a.chargingStationId > b.chargingStationId) {
            return 1;
          }

          return 0;
        });

        const evsesView = chargingStations.flatMap(chargingStation => {
          const facilityZone = chargingStation?.facilityZoneId &&
            facilityZones.find(zone => zone.facilityZoneId === chargingStation.facilityZoneId);

          const chargingGroup = chargingGroups.find(x => x.chargingGroupId === chargingStation.chargingGroupId);
          let optimisationUnit = facilityOptimisationSettings.optimisationUnit;

          if (chargingGroup) {
            optimisationUnit = chargingGroup?.optimisationUnit;
          }

          const zone = facilityZone && facilityZone.name || '';

          return chargingStation.evSupplyEquipments.map(evse => ({
            evseId: evse.evseId,
            extEvseId: evse.extEvseId,
            maxPower: evse.maxPower,
            defaultPower: evse.defaultPower,
            chargingPriority: evse.chargingPriority,
            chargingStationId: chargingStation.chargingStationId,
            facilityId: chargingStation.facilityId,
            location: chargingStation.location,
            optimisationUnit: optimisationUnit === 'amps' ? OptimisationUnit.amps : OptimisationUnit.kW,
            zone,
            maxCurrent: evse.maxCurrent,
            defaultCurrent: evse.defaultCurrent,
            chargingStation,
            chargingGroup,
            evseStatus: EvseStatus.Available,
            hideSetpoint: chargingStation.excludeFromOptimisation || !(!!facilityOptimisationSettings.optimisationSchedule?.frequency)
          }));
        });

        if (facilityState) {
          // populate the current Evses State data
          const evsesStateInitialDataMap = new Map<number, EvseState>();
          facilityState.evsesState.forEach(evseState => evsesStateInitialDataMap.set(evseState.evseId, evseState));

          evsesView.forEach(evseView => {
            const evseState = evsesStateInitialDataMap.get(evseView.evseId);
            if (evseState) {
              this.updateEvseViewWithNewState(evseView, evseState, true, vehicleGroups);
            }
          });
        }

        return evsesView;
      }),
    );
  }

  public updateEvseViewWithNewState(evseView: EvseView, evseState: EvseState, firstRun: boolean, vehicleGroups: VehicleGroup[]) {
    const previousPlugInStatus = evseView.isPluggedIn ? evseView.isPluggedIn : false;

    // pass the evseState values onto the evseView
    Object.assign(evseView, evseState);

    if (evseView.isPluggedIn === true) {
      // 1/ populate the fields that show the plugged-in vehicle's data
      const vehicle = this.vehiclesStaticDataMap.get(evseView.evId);
      if (vehicle) {
        evseView.regNo = vehicle.regNo;
        evseView.batteryCapacity = vehicle.batteryCapacity;
        evseView.vehicle = vehicle;

        const vehcileGroup = vehicleGroups.find(x => x.vehicleGroupId === vehicle.vehicleGroupId);
        evseView.vehicleGroup = vehcileGroup;
      }

      // 2/ if it's a plug-in event, run the animation; else, update the EvseStatus immediately and refresh;
      if (previousPlugInStatus === false && !firstRun) {
        // set animation, set the status, refresh; after 2 seconds, disable animation, refresh
        evseView.isAnimated = true;
        evseView.evseStatus = EvseStatus.InUse;
        const fvData = new FvData({evseState, vehicle, cs: evseView.chargingStation});
        evseView.fvEvseStatus = getEvseStatus(fvData);
        this.eventEmitter.next('filterAndRefresh');

        timer(2000).subscribe(_ => {
          evseView.isAnimated = false;
          this.eventEmitter.next('filterAndRefresh');
        });
      } else {
        evseView.evseStatus = EvseStatus.InUse;
        const fvData = new FvData({evseState, vehicle, cs: evseView.chargingStation});
        evseView.fvEvseStatus = getEvseStatus(fvData);
        this.eventEmitter.next('refresh');
      }

    } else if (evseView.isPluggedIn === false) {

      // 1/ delete the fields that should not be displayed anymore
      delete evseView.power;
      delete evseView.energy;
      delete evseView.current;
      delete evseView.evId;
      delete evseView.regNo;
      delete evseView.batteryCapacity;
      delete evseView.stateOfCharge;
      delete evseView.stateOfChargeInPercent;
      delete evseView.vehicle;
      delete evseView.vehicleGroup;

      // 2/ if it's a plug-out event, run the animation; else, update the EvseStatus immediately and refresh;
      if (previousPlugInStatus === true && !firstRun) {

        // set animation, do not change the status (the evse should still be visible on the 'plugged in' table), refresh;
        // after 2 seconds, disable animation, update status, refresh
        evseView.isAnimated = true;
        this.eventEmitter.next('filterAndRefresh');

        timer(2000).subscribe(_ => {
          evseView.isAnimated = false;
          const fvData = new FvData({evseState, cs: evseView.chargingStation});
          evseView.fvEvseStatus = getEvseStatus(fvData);
          evseView.evseStatus = EvseStatus.Available; // This is a temporary solution; this field should be populated considering the
                                                      // online/offline status of charging station
          this.eventEmitter.next('filterAndRefresh');
        });
      } else {
        evseView.evseStatus = EvseStatus.Available;
        const fvData = new FvData({evseState, cs: evseView.chargingStation});
        evseView.fvEvseStatus = getEvseStatus(fvData);

        this.eventEmitter.next('refresh');
      }
    }
  }

  private isValidNumber(value: any) {
    return !Number.isNaN(Number.parseInt(value, 10));
  }
}
