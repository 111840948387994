import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrentSessionService {
  public readonly prefix = 'hevcos_';

  public setTenantId(tenantId: string) {
    this.set('tenantId', tenantId);
  }

  public getTenantId(): string {
    return this.get('tenantId');
  }

  public setUserId(userId: number) {
    this.set('userId', userId);
  }

  public getUserId(): number {
    const userId = this.get('userId');

    return userId
      ? parseInt(userId, 10)
      : null;
  }

  public setRestrictedFacilityAccess(isRestricted: boolean | string) {
    const value = typeof isRestricted === 'string'
      ? isRestricted === 'true'
      : isRestricted;

    this.set('restrictedFacilityAccess', value);
  }

  public getRestrictedFacilityAccess(): boolean {
    return this.get('restrictedFacilityAccess');
  }

  public setFacilityAccessList(facilityAccessList: string[] | string) {
    try {
      const value = facilityAccessList && typeof facilityAccessList === 'string'
        ? JSON.parse(facilityAccessList)
        : facilityAccessList;

      this.set('facilityAccessList', value);
    } catch (e) {
      console.error('Unable to set facilityAccessList: ', e);
    }
  }

  public getFacilityAccessList(): string[] {
    return this.get('facilityAccessList');
  }

  public clearAll() {
    const invalidList = [this.prefix + 'tenantId'];

    Object.keys(localStorage)
      .filter(key => key.startsWith(this.prefix) && !invalidList.includes(key))
      .forEach(key => localStorage.removeItem(key));
  }

  public set(key: string, value: any) {
    if (value === undefined) {
      return;
    }

    const storageKey = this.prefixKey(key);

    try {
      localStorage.setItem(storageKey, JSON.stringify(value));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  public get(key: string) {
    const storageKey = this.prefixKey(key);

    try {
      const value = localStorage.getItem(storageKey);

      return value
        ? JSON.parse(value)
        : value;
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

  private prefixKey(key: string) {
    return `${this.prefix}${key}`;
  }
}
