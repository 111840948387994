
import {offline, online} from '../consts/text.consts';
import {FacilityViewChargingStation} from '../models';
import {offlineColour, onlineColour} from '../consts/colour.consts';

export type OfflineStatus = typeof offline | typeof online;

export function isCsOffline(data: FacilityViewChargingStation): boolean {
  return data.evses.some(evse => evse.evseState && evse.evseState?.online != null && !evse.evseState?.online);
}

export function getOfflineStatus(data: FacilityViewChargingStation): OfflineStatus {
  const isOffline = isCsOffline(data);

  if (isOffline) {
    return offline;
  }
  else {
    return online;
  }
}

export function constructOfflineData(csList: FacilityViewChargingStation[]) {
  const offlineStats = {
    [online]: { value: 0, color: onlineColour },
    [offline]: { value: 0, color: offlineColour },
  };

  csList.forEach(x => {
    const isOffline = isCsOffline(x);

    if (isOffline) {
      offlineStats[offline].value += x.evses?.length;
    } else {
      offlineStats[online].value += x.evses?.length;
    }
  })

  return Object.keys(offlineStats).map(key => ({ name: key, ...offlineStats[key] }));
}
