<div *ngIf="initialized" class="main bdd-id--facility-select-router-outlet--main">
    <div *ngIf="facilities?.length > 0; else noFacilities" class="facilities">
        <div class="left d-none d-lg-block">
            <div class="collapse-toggle-container">
                <a (click)="toggleCollapse()">
                    <i class="fas fa-arrows-alt-h"></i>
                </a>
            </div>

            <facility-selection
                class="bdd-id--facility-select-router-outlet--facility-selection"
                (selected)="facilitySelected($event)"
                [facilities]="facilities"
                [selectedFacilityId]="selectedFacilityId"
                [showDefaultFacility]="true"
                [defaultFacilityId]="defaultFacilityId"
                (handleDefaultFacilitySelect)="setDefaultFacilityId($event)"
                [isCollapsed]="isCollapsed">
            </facility-selection>
        </div>

        <mobile-facility-selector
            class="d-lg-none"
            [facilities]="facilities"
            [selectedFacilityId]="selectedFacilityId"
            (facilitySelected)="facilitySelected($event)">
        </mobile-facility-selector>

        <div class="right">
            <router-outlet></router-outlet>
        </div>
    </div>

    <ng-template #noFacilities>
        <div class="no-data-row col-12 bdd-id--facility-select-router-outlet--no-data-row">
            No facilities to show. Please, contact your administrator.
        </div>
    </ng-template>
</div>
