import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';

import {TooltipsModule} from '../tooltips/tooltips.module';
import {SvgIconModule} from '../svg-icon/svg-icon.module';

import {AssetTagsInputComponent} from './asset-tags-input/asset-tags-input.component';
import {AssetTagsIconComponent} from './asset-tags-icon/asset-tags-icon.component';
import {AssetTagsViewComponent} from './asset-tags-view/asset-tags-view.component';

@NgModule({
  declarations: [
    AssetTagsInputComponent,
    AssetTagsIconComponent,
    AssetTagsViewComponent,
  ],
  exports: [
    AssetTagsInputComponent,
    AssetTagsIconComponent,
    AssetTagsViewComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatExpansionModule,
    TooltipsModule,
    SvgIconModule,
  ],
})
export class AssetTagsModule {
}
