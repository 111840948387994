import {Component, ElementRef, Input} from '@angular/core';

import {ChargingStation, Facility} from '../../models/entities';
import {NavigationHelperService} from '../../services/navigation-helper.service';
import {FleetLocationMapService} from '../../../fleet-location/fleet-location-map/fleet-location-map.service';

export interface IsochroneData {
  coords: [number, number];
  properties: { [key: string]: any };
}

@Component({
  selector: 'fleet-location-vehicle-popup',
  templateUrl: './fleet-location-vehicle-popup.component.html',
  styleUrls: ['./fleet-location-vehicle-popup.component.scss'],
})
export class FleetLocationVehiclePopupComponent {
  @Input() properties: { [key: string]: any };
  @Input() coords: [number, number];
  @Input() facility: Facility;
  @Input() chargingStations: ChargingStation[];
  @Input() showIsochrone = true;

  constructor(
    public element: ElementRef,
    private navigationHelper: NavigationHelperService,
    private fleetLocationMapService: FleetLocationMapService,
  ) {
  }

  public navigateToVehiclePage(event: MouseEvent) {
    event.preventDefault();

    this.navigationHelper.navigateToTenantPath(`vehicles/facility-vehicles/${this.facility.facilityId}/${this.properties.id}/view`);
  }

  public navigateToChargingStationPage(event: MouseEvent) {
    event.preventDefault();

    this.navigationHelper.navigateToTenantPath(`chargers/facility-charging-stations/${this.facility.facilityId}/${this.properties.chargingStationId}/view`);
  }

  public addIsoChroneLayerToMap(event: MouseEvent) {
    event.preventDefault();

    this.fleetLocationMapService.addIsoChroneLayerToMap({
      coords: this.coords,
      properties: this.properties,
    });
  }

  public getChargingStationName(chargingStationId: number) {
    const chargingStation = (this.chargingStations || [])
      .find(item => item.chargingStationId === chargingStationId);

    return chargingStation?.name || 'link';
  }
}
