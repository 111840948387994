<div class="password-requirements-card bdd-id--password-requirements--main">
    <div class="password-requirements-card__title">Password Minimum Requirements</div>

    <ul>
        <li>Minimum length: 8 characters.</li>
        <li>Must contain numbers.</li>
        <li>Must contain at least one special character.</li>
        <li>Must contain uppercase letters.</li>
        <li>Must contain lowercase letters.</li>
    </ul>
</div>
