import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormControl} from '@angular/forms';
import {AuthState, ChallengeName, CognitoUserInterface} from '@aws-amplify/ui-components';
import {Auth} from '@aws-amplify/auth';
import {Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {CurrentSessionService} from '../../../shared/services/current-session.service';
import {LoginService, LoginStateAction} from '../../../shared/services/login.service';
import {AppBootstrapService} from '../../services/app-bootstrap.service';
import {errorMessageAdaptor, ErrorType} from './landing.utils';

enum ForgotPasswordStages {
  Requested = 1,
  AwaitingCode = 2,
  CodeSubmitted = 3,
  Complete = 4
}

@Component({
  selector: 'landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  public stage: AuthState = null;
  public username = new UntypedFormControl('');
  public password = new UntypedFormControl('');
  public totp = new UntypedFormControl();
  public resetPasswordCode = new UntypedFormControl();
  public forgotPasswordStage: number = null;
  public error: string;
  public qrCode: string;
  public qrCodeDisabled: boolean;
  public isTOTPSetup = false;

  private user: CognitoUserInterface | undefined;
  private validUsername: string;
  private validPassword: string;
  private qrCodeTimeoutMinutes = 30;

  private destoyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private currentSession: CurrentSessionService,
    private loginService: LoginService,
    private appBotstrapService: AppBootstrapService,
  ) {
  }

  public ngOnInit() {
    this.error = '';

    const user = this.loginService.getUser();
    if (user) {
      this.user = user;
      this.onLogin();
    } else {
      this.loginService.clearUserToken();
      this.updateStage(AuthState.SignIn);
    }

    this.loginService.loginState$
      .pipe(takeUntil(this.destoyed$))
      .subscribe(async (value: string) => {
        if (value === LoginStateAction.Logout) {
          await this.onSignOut();
        }
      });
  }

  public ngOnDestroy() {
    this.destoyed$.next();
    this.destoyed$.unsubscribe();
  }

  public async signIn(username: string, password: string) {
    try {
      const user = await this.loginService.signIn(username, password);

      this.user = user;
      this.validUsername = username;
      this.validPassword = password;

      if (this.user.challengeName === ChallengeName.NewPasswordRequired) {
        this.updateStage(AuthState.ResetPassword);
      } else if (this.user.challengeName === ChallengeName.SoftwareTokenMFA) {
        this.updateStage(AuthState.TOTPSetup);
      } else if (this.user.challengeName === ChallengeName.MFASetup) {
        this.setupTOTP(user);
      } else {
        this.error = `Received unknown verification stage: ${this.user.challengeName}`;
      }
    } catch (e) {
      console.debug(e);
      this.error = errorMessageAdaptor(e.message);
    }
  }

  public async completeNewPassword(password: string) {
    try {
      if (password.endsWith(' ')) throw new Error(`Password can't end on a space`);

      const user = await Auth.completeNewPassword(this.user, password);

      this.loginService.setUser(user);

      this.user = user;
      this.validPassword = password;

      if (this.user.challengeName === ChallengeName.MFASetup) {
        this.setupTOTP(user);
      } else if (this.user.challengeName === ChallengeName.SoftwareTokenMFA) {
        this.updateStage(AuthState.TOTPSetup);
      }
    } catch (e) {
      console.debug(e);
      this.error = errorMessageAdaptor(ErrorType.PasswordReset);
    }
  }

  public async verifyTotpToken(totp: number) {
    try {
      // Then you will have your TOTP account in your TOTP-generating app (like Google Authenticator)
      // Use the generated one-time password to verify the setup
      await Auth.verifyTotpToken(this.user, String(totp));
    } catch (e) {
      console.debug(e);
      this.error = ErrorType.MFA_SETUP;
      return;
    }

    try {
      // set TOTP as the preferred MFA method
      await Auth.setPreferredMFA(this.user, 'TOTP');
    } catch (e) {
      console.error(e);
      this.error = ErrorType.MFA_SETUP;
      return;
    }

    this.signIn(this.validUsername, this.validPassword);
  }

  public async confirmSignInWithTOTP(totp: number) {
    try {
      await Auth.confirmSignIn(this.user, String(totp), 'SOFTWARE_TOKEN_MFA');

      this.onLogin(true);
    } catch (e) {
      console.debug(e);
      this.error = ErrorType.MFA_SIGNIN;
    }
  }

  public forgotPasswordStage1() {
    this.updateStage(AuthState.ForgotPassword);
    this.forgotPasswordStage = ForgotPasswordStages.Requested;
  }

  public async forgotPasswordStage2(username: string) {
    try {
      await Auth.forgotPassword(username);
      this.forgotPasswordStage = ForgotPasswordStages.AwaitingCode;
    } catch (e) {
      console.error(e);
      this.error = ErrorType.PasswordReset;
    }
  }

  public async forgotPasswordSubmit(username: string, code: string, newPassword: string) {
    try {
      this.forgotPasswordStage = ForgotPasswordStages.CodeSubmitted;
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      this.forgotPasswordStage = ForgotPasswordStages.Complete;
      timer(2000).subscribe(_ => this.updateStage(AuthState.SignIn));
    } catch (e) {
      console.debug(e);
      this.forgotPasswordStage = ForgotPasswordStages.AwaitingCode;
      this.error = ErrorType.PasswordReset;
    }
  }

  public async onSignOut() {
    try {
      await this.loginService.signOut();

      this.updateStage(AuthState.SignIn);
      // TODO: how to shut down all the sockets and API pollings?
    } catch (error) {
      console.debug('Error signing out: ', error);
    }
  }

  public backToSignIn() {
    this.loginService.clearUserToken();
    this.updateStage(AuthState.SignIn);
  }

  private updateStage(newStage: AuthState) {
    this.error = '';
    this.stage = newStage;
    this.username.reset();
    this.password.reset();
    this.totp.reset();

    delete this.forgotPasswordStage;
  }

  private async onLogin(forceReload = false) {
    try {
      await this.loginService.onLogin();
      await this.appBotstrapService.initialiseDataServices();

      this.updateStage(AuthState.SignedIn);

      if (forceReload) {
        this.router.navigateByUrl(this.router.url);
      }
    } catch (err) {
      this.updateStage(AuthState.SignIn);
    }
  }

  private async setupTOTP(user: CognitoUserInterface) {
    this.isTOTPSetup = true;
    this.qrCodeDisabled = false;

    timer(this.qrCodeTimeoutMinutes * 60 * 1000)
      .pipe(takeUntil(this.destoyed$))
      .subscribe(() => {
        this.qrCodeDisabled = true;
      });

    try {
      const code = await Auth.setupTOTP(user);
      const issuer = `${environment.environment_name}.hevcos.cloud/${this.currentSession.getTenantId()}`;

      this.qrCode = `otpauth://totp/${this.user.username}@${issuer}?secret=${code}&issuer=${issuer}`;

      this.updateStage(AuthState.SettingMFA);
    } catch (e) {
      console.debug(e);
      this.error = ErrorType.MFA_SETUP;
    }
  }

  setStage(name: string, substage?: number) {
    if (!Object.values(AuthState).includes(name as AuthState)) return;
    this.stage = name as AuthState;
    this.forgotPasswordStage = substage;
  }
}
