import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {CurrentSessionService} from './current-session.service';

interface RestrictedComponents {
  resources: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthPermissionService {
  private restrictedComponentList: string[];

  constructor(
    private httpClient: HttpClient,
    private currentSessionService: CurrentSessionService,
  ) {
  }

  public async initaliseAuthPermission() {
    if (this.hasValidPermissions()) {
      return;
    }

    try {
      await this.hydratePermissions();
    } catch (err) {
      console.error(err);
    }
  }

  public hasValidPermissions() {
    return !!this.restrictedComponentList;
  }

  public clearPermissions() {
    this.restrictedComponentList = undefined;
  }

  public async hydratePermissions() {
    this.restrictedComponentList = await this.getRestrictedComponents();
  }

  public checkPermission(resourceType: string) {
    return this.restrictedComponentList && this.restrictedComponentList.includes(resourceType);
  }

  public async getRestrictedComponents() {
    const userId = this.currentSessionService.getUserId();
    if (!userId) {
      return;
    }

    const restrictedComponentsUrl = `${environment.api.uam.http.proxy}/users/${userId}/restricted-components`;

    const response = await this.httpClient.get<RestrictedComponents>(restrictedComponentsUrl)
      .toPromise();

    return response.resources || [];
  }
}
