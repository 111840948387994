import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';

import {DesignBarModule} from '../shared/components/design-bar/design-bar.module';
import {CustomUnitPipeModule} from '../shared/pipes/custom-unit/custom-unit-pipe.module';
import {NoValueDashPipeModule} from '../shared/pipes/no-value-dash/no-value-dash-pipe.module';
import {TableAddonsModule} from '../shared/components/table-addons/table-addons.module';
import {FacilitySelectRouterOutletModule} from '../shared/components/facility-select-router-outlet/facility-select-router-outlet.module';
import {AuthPermissionModule} from '../shared/directives/auth-permission/auth-permission.module';
import {ActionBtnModule} from '../shared/components/action-btn/action-btn.module';
import {TooltipsModule} from '../shared/components/tooltips/tooltips.module';
import {AssetTagsModule} from '../shared/components/asset-tags/asset-tags.module';
import {SettingsModule} from '../shared/components/settings/settings.module';
import {ErrorMessageBannerModule} from '../shared/components/error-message-banner/error-message-banner.module';
import {CSNotificationsModule} from '../shared/components/cs-notifications/cs-notifications.module';
import {TextFormattersPipesModule} from '../shared/pipes/text-formatters/text-formatters.module';
import {DateFormattersPipesModule} from '../shared/pipes/date-formatters/date-formatters.module';
import {SvgIconModule} from '../shared/components/svg-icon/svg-icon.module';
import {TableFiltersModule} from '../shared/components/table-filters/table-filters.module';
import {ToggleBtnModule} from '../shared/components/toggle-btn/toggle-btn.module';

import {HomeRoutingModule} from './home.routing.module';
import {EvsesStatusTableComponent} from './components/evses-status-table/evses-status-table.component';
import {EvsesStatusTableRowComponent} from './components/evses-status-table/evses-status-table-row/evses-status-table-row.component';
import {EvsesStatusFiltersComponent} from './components/evses-status-table/evses-status-filters/evses-status-filters.component';
import {FacilityOverviewComponent} from './components/facility-overview/facility-overview.component';
import {FacilityOverviewFooterComponent} from './components/facility-overview/facility-overview-footer/facility-overview-footer.component';
import {FacilityOverviewGraphComponent} from './components/facility-overview/facility-overview-graph/facility-overview-graph.component';
import {DashboardSelectorRouterOutletComponent} from './components/dashboard-selector-router-outlet/dashboard-selector-router-outlet.component';
import {FacilityDashboardComponent} from './components/facility-dashboard/facility-dashboard.component';
import {FacilityConnectionHandlerService} from './services/facility-connection-handler.service';
import {DataDistributor} from './services/data-distributor.service';
import {
  EvsesStatusTableSettingsComponent,
} from './components/evses-status-table/evses-status-table-settings/evses-status-table-settings.component';
import {
  EvsesStatusTableSettingsService,
} from './components/evses-status-table/evses-status-table-settings/evses-status-table-settings.service';
import {EvseStateService} from './services/evse-state.service';
import {FacilityLiveCapacityGraphModule} from './components/facility-live-capacity-graph/facility-live-capacity-graph.module';
import {MatSelectModule} from '@angular/material/select';
import {FacilityChartGroupsChartModule} from './components/facility-overview/charging-groups-chart/charging-groups-chart.module';
import {InfoFilterModule} from '../shared/components/info-filter/info-filter.module';
import {FullScreenModule} from '../shared/directives/full-screen/full-screen.module';

@NgModule({
  declarations: [
    EvsesStatusTableComponent,
    EvsesStatusTableRowComponent,
    EvsesStatusFiltersComponent,
    EvsesStatusTableSettingsComponent,
    FacilityOverviewComponent,
    FacilityOverviewFooterComponent,
    FacilityOverviewGraphComponent,
    DashboardSelectorRouterOutletComponent,
    FacilityDashboardComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    MatPaginatorModule,
    MatTableModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    TableAddonsModule,
    DesignBarModule,
    CustomUnitPipeModule,
    NoValueDashPipeModule,
    FacilitySelectRouterOutletModule,
    AuthPermissionModule,
    ActionBtnModule,
    TooltipsModule,
    AssetTagsModule,
    SettingsModule,
    ErrorMessageBannerModule,
    CSNotificationsModule,
    TextFormattersPipesModule,
    DateFormattersPipesModule,
    SvgIconModule,
    TableFiltersModule,
    ToggleBtnModule,
    FacilityLiveCapacityGraphModule,
    FacilityChartGroupsChartModule,
    InfoFilterModule,
    FullScreenModule,
  ],
  providers: [
    FacilityConnectionHandlerService,
    DataDistributor,
    EvsesStatusTableSettingsService,
    EvseStateService,
  ],
})
export class HomeModule {
}
