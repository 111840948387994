import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'toggle-btn',
  templateUrl: './toggle-btn.component.html',
  styleUrls: ['./toggle-btn.component.scss'],
})
export class ToggleBtnComponent {
  @Input() selected: boolean;
  @Input() editable: boolean;
  @Output() selectedChange = new EventEmitter<boolean>();

  public toggle() {
    if (this.editable) {
      this.selected = !this.selected;

      this.selectedChange.emit(this.selected);
    }
  }
}
