import {UntypedFormGroup, ValidatorFn} from '@angular/forms';

export function tableColumnsValidator(minRequired = 0, maxLimit = 0): ValidatorFn {
  return function validate(formGroup: UntypedFormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked++;
      }
    });

    if (minRequired && checked < minRequired) {
      return {
        minRequired: true,
      };
    }

    if (maxLimit && checked > maxLimit) {
      return {
        maxLimit: true,
      };
    }

    return null;
  };
}
