import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Directive({
  selector: '[fullScreen]',
  exportAs: 'fullScreen',
})
export class FullScreenDirective {

  private isFullScreenSubject = new BehaviorSubject(false);
  isFullScreen$ = this.isFullScreenSubject.pipe();

  public get isFullScreen() {
    return this.isFullScreenSubject?.getValue();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const periodDropdownPanel = document.getElementsByClassName('period-dropdown-panel')?.[0];
    const aggregationBlock = document.querySelector('div.aggregation-block.maximized');
    const clickedInside = this.el.nativeElement.contains(target) || periodDropdownPanel?.contains(target) || aggregationBlock?.contains(target);

    if (this.isFullScreen && !clickedInside) {
      this.minimize();
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  toggle() {
    this.isFullScreenSubject?.getValue() ? this.minimize() : this.maximize();
  }

  maximize() {
    if (this.el) {
      this.isFullScreenSubject.next(true);
      this.renderer.addClass(this.el.nativeElement, "fullscreen");
    }
  }

  minimize() {
    if (this.el) {
      this.isFullScreenSubject.next(false);
      this.renderer.removeClass(this.el.nativeElement, "fullscreen");
    }
  }
}
