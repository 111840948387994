import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';

import {AppBootstrapService} from '../services/app-bootstrap.service';

@Injectable()
export class TenantGuard implements CanActivate {
  constructor(
    private router: Router,
    private appBootstrapService: AppBootstrapService,
  ) {
  }

  public async canActivate(route: ActivatedRouteSnapshot) {
    const tenantConfig = await this.appBootstrapService.initialiseTenant(route.params.tenant);
    if (tenantConfig === false) {
      this.router.navigateByUrl('/not-found');
      return false;
    }

    return true;
  }
}
