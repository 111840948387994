<div class="fleet-location-map__container">
    <div id="map"></div>
</div>

<div style="display: none">
    <fleet-location-vehicle-popup
        [properties]="popupProperties"
        [coords]="popupCoords"
        [facility]="facility"
        [chargingStations]="chargingStations">
    </fleet-location-vehicle-popup>
</div>

<div style="display: none">
  <fv-charging-station-popup [fvChargingStation]="csPopupData" [showAssetsLinks]="true" [evseIndex]="evsePopupIndex" #fvChargingStationPopup></fv-charging-station-popup>
</div>

<div class="satellite-view d-none d-lg-flex" (click)="satelliteViewToggle()">
  <img *ngIf="!issatelliteView" class="base-state" src="assets/images/map/satellite-view.svg" alt="satellite-base"/>
  <img *ngIf="!issatelliteView" class="hover" src="assets/images/map/satellite-view-hover.svg" alt="satellite-hover"/>
  <img *ngIf="issatelliteView" class="base-state" src="assets/images/map/map-view.svg" alt="default-view-base"/>
  <img *ngIf="issatelliteView" class="hover" src="assets/images/map/map-view-hover.svg" alt="default-view-hover"/>
</div>
