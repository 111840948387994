import {Component, Input} from '@angular/core';

import {OptimisationSettings} from '../../../../shared/models/entities';

@Component({
  selector: 'facility-overview-footer',
  templateUrl: './facility-overview-footer.component.html',
  styleUrls: ['./facility-overview-footer.component.scss'],
})
export class FacilityOverviewFooterComponent {
  @Input() updatedAt: Date;
  @Input() data: OptimisationSettings;
}
