<div class="settings-options-toggle">
    <label>
        <div class="label">{{ label }}</div>
    </label>

    <mat-slide-toggle
        class="float-right bdd-id--settings-options-toggle--slide-toggle"
        [formControl]="formControl">
    </mat-slide-toggle>
</div>
