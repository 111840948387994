import {Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';

import {EvsesStatusTableSettingsComponent} from './evses-status-table-settings.component';
import {TableColumn} from '../../../../shared/components/table-addons/table-column.model';
import {UserPreferencesService} from '../../../../shared/services/user-preferences.service';
import {SettingsService} from '../../../../shared/components/settings/settings.service';
import {updateTableColumnsFromChargeToPower} from '../../../../shared/utils/preferences';

@Injectable()
export class EvsesStatusTableSettingsService {
  private settingsKey = 'evsesStatusTableSettings';
  private defaultPageSize = 10;
  private defaultTableColumns = [
    'power',
    'current',
    'vehicle.evIdentifier',
    'status',
    'sessionStart',
    'chargingGroup.name',
    'energy',
    'setpoint',
    'timestamp',
  ];

  constructor(
    private userPreferencesService: UserPreferencesService,
    private settingsService: SettingsService,
  ) {
  }

  public getUpdateSubject() {
    return this.userPreferencesService.updateItemSubject$
      .pipe(filter(item => item.key === this.settingsKey));
  }

  public getPageSize() {
    return this.getSettingValue('pageSize') || this.defaultPageSize;
  }

  public getTableColumns() {
    const tableColumns = this.getSettingValue('tableColumns');
    if (tableColumns) return updateTableColumnsFromChargeToPower(tableColumns);
    else return this.defaultTableColumns;
  }

  public openSettingsDialog(tableColumns: TableColumn[]) {
    const values = this.userPreferencesService.get(this.settingsKey);

    const currentValues = {
      pageSize: values?.pageSize || this.defaultPageSize,
      tableColumns: values?.tableColumns || this.defaultTableColumns,
    };

    this.settingsService.openDialog(EvsesStatusTableSettingsComponent, {
      tableColumns,
      currentValues,
    });
  }

  public updateValues(tableColumns: string[], pageSize: number) {
    return this.userPreferencesService.setUserPreferences(this.settingsKey, {
      tableColumns,
      pageSize,
    });
  }

  private getSettingValue(property: string) {
    return this.userPreferencesService.get(this.settingsKey, property);
  }
}
