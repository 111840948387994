export const authenticationTypes = {
  ISO14443: 'RFID (ISO14443)',
  ISO15693: 'RFID (ISO15693)',
  KeyCode: 'Pin-code',
  Local: 'Local',
  Central: 'Central',
  MacAddress: 'MacAddress',
  eMAID: 'eMAID',
  NoAuthorization: 'NoAuthorization',
};
