import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {AssetManagerService} from '../../shared/services/asset-manager.service';
import {UserPreferencesService} from '../../shared/services/user-preferences.service';
import {NavigationHelperService} from '../../shared/services/navigation-helper.service';

@Component({
  selector: 'fleet-location-host',
  templateUrl: './fleet-location-host.component.html',
  styleUrls: ['./fleet-location-host.component.scss'],
})
export class FleetLocationHostComponent implements OnInit {

  public facilityId;
  constructor(
    private route: ActivatedRoute,
    private userPreferencesService: UserPreferencesService,
    private assetManagerService: AssetManagerService,
    private navigationHelper: NavigationHelperService,
  ) { }

  ngOnInit(): void {
    this.facilityId = parseInt(this.route.snapshot.paramMap.get('facilityId'), 10);

    if (!this.facilityId) {
      const defaultFacility = this.userPreferencesService.get('defaultFacilityId');

      if (defaultFacility) {
        this.setPathToLatestFacilityId(parseInt(defaultFacility, 10));
      } else {
        this.assetManagerService
          .getAllFacilities()
          .subscribe(res => {
            this.setPathToLatestFacilityId(res[0]?.facilityId);
          });
      }
    }
  }

  public facilityChanged(facilityId) {
    this.setPathToLatestFacilityId(facilityId);
  }

  private setPathToLatestFacilityId(facilityId) {
    this.navigationHelper.navigateToTenantPath(`fleet-location/${facilityId}`);
  }
}
