<div class="not-found bdd-id--not-found">
    <div class="not-found__text-block">
        <div class="not-found__message" [ngSwitch]="statusCode">
            <ng-container *ngSwitchCase="403">
                <div class="not-found__message-title not-found__message-title--bold">Oops,</div>
                <div class="not-found__message-title">{{ statusCode }}: Page not available</div>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <div class="not-found__message-title not-found__message-title--bold">Oops,</div>
                <div class="not-found__message-title">{{ statusCode }}: Page not found</div>

                We can't find the page you're looking for.
            </ng-container>
        </div>

        <div *ngIf="showNavigationButton" class="not-found__button bdd-id--not-found--button">
            <button class="back-btn" (click)="navigateAway()">
                <span>{{ buttonText }}</span>
            </button>
        </div>
    </div>
</div>
