import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  ViewChild,
} from '@angular/core';

import {ChargePointStatus, EvseStatusNotification} from '../../../models/entity-states';

@Component({
  selector: 'cs-notification-icon',
  templateUrl: './cs-notification-icon.component.html',
  styleUrls: ['./cs-notification-icon.component.scss'],
})
export class CSNotificationIconComponent implements AfterViewInit, DoCheck {
  @ViewChild('csNotificationPopup') popup: ElementRef;
  @Input() public name: string;
  @Input() public notification: EvseStatusNotification;

  public tooltipHtml: string;
  public readonly ChargePointStatus = ChargePointStatus;

  private differ: KeyValueDiffer<string, any>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private differs: KeyValueDiffers,
  ) {
    this.differ = this.differs.find({}).create();
  }

  public ngAfterViewInit() {
    this.updateTooltipHtml();
  }

  public ngDoCheck() {
    const change = this.differ.diff(this.notification);
    if (change) {
      this.updateTooltipHtml();
    }
  }

  private updateTooltipHtml() {
    if (!this.notification || !this.popup?.nativeElement) {
      return;
    }

    // Ensures Popup child element has updated
    this.changeDetectorRef.detectChanges();

    this.tooltipHtml = this.popup.nativeElement.innerHTML;

    // This is to keep the already checked error from happening.
    this.changeDetectorRef.detectChanges();
  }
}
