<div
    class="custom-column-cell bdd-id--table-header-cell--column-cell"
    *ngIf="column && !isColumnHidden()"
    [ngClass]="{'not-clickable': column.isNotSortable, 'column-align-right': column.alignRight}"
    (click)="sort(column.id)"
    (mouseenter)="enter()"
    (mouseleave)="leave()"
>
    <div *ngIf="!column.isColumnLabelHidden" class="table-header-label" [tooltipTitle]="column.label">{{ column.label }}</div>

    <ng-container *ngIf="!column.isNotSortable">
        <svg-icon *ngIf="!isSorted && hovered" type="arrow-up" class="arrow faded"></svg-icon>
        <svg-icon *ngIf="isSorted && sortingAsc" type="arrow-up" class="arrow"></svg-icon>
        <svg-icon *ngIf="isSorted && sortingDesc" type="arrow-down" class="arrow"></svg-icon>
    </ng-container>
</div>
