import {AfterViewInit, Component, ElementRef, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {PieData} from './models/pie-data';
import {PieHoverEvent} from './models/pie-hover-event';

@Component({
  selector: 'app-info-pie',
  templateUrl: './info-pie.component.html',
  styleUrls: ['./info-pie.component.scss'],
})
export class InfoPieComponent implements AfterViewInit, OnChanges {
  @Input() data: PieData[];
  @Input() chartHeight = 400;
  @Input() showTotal = true;
  @Input() totalColour = 'white';

  @Output() hoverEvents: Subject<PieHoverEvent> = new Subject();

  @ViewChild('chartElement') chartElement: ElementRef;

  public options;
  private width: number;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.previousValue !== changes.data.currentValue) {
      this.options = this.createOptions();
    }
  }

  public ngAfterViewInit(): void {
    this.width = this.chartElement.nativeElement.offsetWidth;
    this.options = this.createOptions();
  }

  public mouseEvents(event, eventType: string) {
    this.options.title.show = eventType !== 'chartMouseOver';

    this.options = {...this.options};

    this.hoverEvents.next({type: event.type, data: event.data});
  }

  private createOptions() {
    const rich = {};
    this.data.forEach(dataPoint => rich[dataPoint.name.replace(/\s/g, '')] = {color: dataPoint.color});

    const title = this.showTotal ? {
      show: true,
      text: this.data.reduce((acc, d) => acc + d.value, 0),
      left: 'center',
      top: 'center',
      textStyle: {
        color: this.totalColour,
        fontSize: this.width / 7,
        fontWeight: 'normal',
        overflow: 'breakAll',
      },
    } : null;

    return {
      title,
      height: this.chartHeight,
      series: [
        {
          type: 'pie',
          radius: ['70%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: this.width / 6,
              fontWeight: 'normal',
              formatter: (params) => {
                const key = params.name.replace(/\s/g, '');
                return `{${key}|${params.value}}`;
              },
              rich,
              overflow: 'breakAll',
            },
          },
          labelLine: {
            show: true,
          },
          data: this.data.map(dp => ({...dp, ...{itemStyle: {color: dp.color}}, value: dp.value})),
        },
      ],
    };
  }
}


