import {Component, OnInit} from '@angular/core';
import {UserPreferencesService} from '../../../shared/services/user-preferences.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {

  constructor(private userPreferencesService: UserPreferencesService, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  async ngOnInit() {
    const prefs = await this.userPreferencesService.getUserPreferences();
    const homepagePath = prefs['homepage'];
    console.debug('home page is', homepagePath);
    this.router.navigate([homepagePath], {relativeTo: this.activatedRoute});
  }

}
