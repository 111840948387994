import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'settings-options-dropdown',
  templateUrl: './settings-options-dropdown.component.html',
  styleUrls: ['./settings-options-dropdown.component.scss'],
})
export class SettingsOptionsDropdownComponent implements OnInit {
  @Input() public formKey: string;
  @Input() public formGroup: UntypedFormGroup;

  @Input() public label: string;
  @Input() public value: any;
  @Input() public isRequired: boolean;
  @Input() public options: string[] | number[] | { label: string, value: any }[];

  public formOptions: { key: string, label: number | string }[];
  public formControl: UntypedFormControl;

  public ngOnInit() {
    this.formControl = this.createFormOptions();
    this.formOptions = this.sanitiseOptions();

    if (this.formKey) {
      this.formGroup.addControl(this.formKey, this.formControl);
    }
  }

  private sanitiseOptions() {
    if (!this.options) {
      return [];
    }

    return this.options
      .map((item: any) => {
        switch (typeof item) {
          case 'number':
          case 'string':
            return {
              label: item,
              value: item,
            };

          case 'object':
            if (item.hasOwnProperty('label') && item.hasOwnProperty('value')) {
              return item;
            }
        }

        return null;
      })
      .filter(item => item !== null);
  }

  private createFormOptions() {
    const validators = this.isRequired
      ? [Validators.required]
      : [];

    return new UntypedFormControl(this.value || null, validators);
  }
}
