import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export interface MapFilterActionItem {
  type: 'select-facility' | 'select-vehicle' | 'clear-vehicle';
  evId?: number;
}

export enum PluggedInOptions {
  PluggedIn = 'Plugged in',
  NotPluggedIn = 'Plugged out',
}

export enum StateOfChargeOptions {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Unknown = 'Unknown'
}

@Injectable()
export class FleetLocationMapFilterService {
  private updateMapFiltersSubject$ = new Subject<{ [key: string]: string[] | string }>();
  private mapFiltersActionSubject$ = new Subject<MapFilterActionItem>();

  public updateMapFilters(filterToApply: { [key: string]: string[] | string }) {
    this.updateMapFiltersSubject$.next(filterToApply);
  }

  public updateMapFiltersSubject() {
    return this.updateMapFiltersSubject$;
  }

  public mapFiltersActionSubject() {
    return this.mapFiltersActionSubject$;
  }

  public selectFacilityVehicle(evId: number) {
    this.mapFiltersActionSubject$.next({type: 'select-vehicle', evId});
  }

  public selectFacility() {
    this.mapFiltersActionSubject$.next({type: 'select-facility'});
  }

  public clearVehicle() {
    this.mapFiltersActionSubject$.next({type: 'clear-vehicle'});
  }
}
