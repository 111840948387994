<div class="header__main bdd-id--header--main navbar navbar-expand-lg navbar-light">
    <div class="header__logo">
        <svg-icon type="hitachi"></svg-icon>
    </div>
    <button class="navbar-toggler" (click)="isMenuCollapsed = !isMenuCollapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="bar-icon" role="button">
        <i *ngIf="isMenuCollapsed" class="fa fa-bars" aria-hidden="true" style="color:#1c3755"></i>
        <i *ngIf="!isMenuCollapsed" class="fa fa-chevron-down" aria-hidden="true" style="color:#1c3755"></i>
      </span>
      <span class="bdd-id--header--tab-name header__tab-name">{{selectedTabDisplayName}}</span>
    </button>

    <div class="flex-fill d-none d-lg-block"></div>

    <div *ngIf="signedIn" class="collapse navbar-collapse header-nav" [ngClass]="{'show': !isMenuCollapsed}" id="navbarSupportedContent">
        <div class="item bdd-id--header--home-menu-item d-flex"
            (click)="selectTab('home')"
             [class.selected]="selectedTab === 'home'"
            *authPermission="'page/dashboard'">
          <svg-icon class="header__icon" type="home"></svg-icon>
          <div class="header__label">Dashboard</div>
        </div>


        <div class="item bdd-id--header--fleet-location-menu-item d-none d-lg-flex"
             (click)="selectTab('fleet-location')"
             [class.selected]="selectedTab === 'fleet-location'"
             *authPermission="'page/fleet-location'">
            <svg-icon class="header__icon" type="fleet-location"></svg-icon>
            <div class="header__label">Fleet Location</div>
        </div>

        <div class="item bdd-id--header--facility-view-menu-item d-flex"
             (click)="selectTab('facility-view')"
             [class.selected]="selectedTab === 'facility-view'"
             *authPermission="'page/facility-view'">
            <svg-icon class="header__icon" type="facility-map"></svg-icon>
            <div class="header__label">Facility View</div>
        </div>

        <div class="item bdd-id--header--analytics-menu-item d-none d-lg-flex"
             (click)="selectTab('analytics')"
             [class.selected]="selectedTab === 'analytics'"
             *authPermission="'page/analytics'">
            <svg-icon class="header__icon" type="analytics"></svg-icon>
            <span class="header__label">Analytics</span>
        </div>

        <div class="item bdd-id--header--facilities-menu-item d-none d-lg-flex d-md-none"
             (click)="selectTab('facilities')"
             [class.selected]="selectedTab === 'facilities'"
             *authPermission="'page/facilities'">
            <svg-icon class="header__icon" type="facility-thin"></svg-icon>
            <span class="header__label">Facilities</span>
        </div>

        <div class="item bdd-id--header--chargers-menu-item d-none d-lg-flex d-md-none"
             (click)="selectTab('chargers')"
             [class.selected]="selectedTab === 'chargers'"
             *authPermission="'page/chargers'">
            <svg-icon class="header__icon" type="charger-thin"></svg-icon>
            <span class="header__label">Chargers</span>
        </div>

        <div class="item bdd-id--header--vehicles-menu-item d-none d-lg-flex d-md-none"
             (click)="selectTab('vehicles')"
             [class.selected]="selectedTab === 'vehicles'"
             *authPermission="'page/vehicles'">
            <svg-icon class="header__icon" type="vehicle-thin"></svg-icon>
            <span class="header__label">Vehicles</span>
        </div>

        <div class="item bdd-id--header--sessions-menu-item d-none d-lg-flex d-md-none"
             (click)="selectTab('sessions')"
             [class.selected]="selectedTab === 'sessions'"
             *authPermission="'page/sessions'">
            <svg-icon class="header__icon" type="session"></svg-icon>
            <span class="header__label">Sessions</span>
        </div>

        <div class="item bdd-id--header--configurations-menu-item d-none d-lg-flex d-md-none"
             (click)="selectTab('configurations')"
             [class.selected]="selectedTab === 'configurations'"
             *authPermission="'page/configurations'">
            <svg-icon class="header__icon" type="settings"></svg-icon>
            <span class="header__label">Configurations</span>
        </div>

        <div class="item bdd-id--header--schedules-menu-item d-none d-lg-flex d-md-none"
             (click)="selectTab('schedules')"
             [class.selected]="selectedTab === 'schedules'"
             *authPermission="'page/schedules'">
            <svg-icon class="header__icon" type="schedule"></svg-icon>
            <span class="header__label">Schedules</span>
        </div>

        <div class="item bdd-id--header--users-menu-item d-none d-lg-flex d-md-none"
             (click)="selectTab('users')"
             [class.selected]="selectedTab === 'users'"
             *authPermission="'page/users'">
            <svg-icon class="header__icon" type="user"></svg-icon>
            <span class="header__label">Users</span>
        </div>

        <div class="item bdd-id--header--profile-menu-item d-none d-lg-flex d-md-none"
             (click)="selectTab('profile')"
             [ngClass]="{'selected': selectedTab === 'profile'}"
             *authPermission="'page/profile'">
            <svg-icon class="header__icon" type="profile"></svg-icon>
            <span class="header__label">Profile</span>
        </div>

        <div class="item bdd-id--header--logout-menu-item"
             (click)="selectTab('logout')"
             [ngClass]="{'selected': selectedTab==='logout'}">
            <svg-icon class="header__icon" type="logout"></svg-icon>
            <span class="header__label">Logout</span>
        </div>
    </div>
</div>
<div *ngIf="showBModeBanner" class="b-mode-banner">
    <svg-icon type="warning-sign" class="warning-icon"></svg-icon>
    <span>
        This facility is no longer being managed by this system, please log into 
        <a href="https://portal.hitachizerocarbon.com">portal.hitachizerocarbon.com</a> 
        for accurate monitoring of this facility.
    </span>
</div>
