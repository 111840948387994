export const parseTags = (tags: string | { [key: string]: string }) => {
  if (!tags) {
    return {};
  }

  if (typeof tags === 'string') {
    try {
      tags = JSON.parse(tags);
    } catch (e) {
      return {};
    }
  }

  return Object.fromEntries(Object.entries(tags).filter(([_, value]) => typeof value === 'string'));
};

export const createTableSearchArray = (tags: string | { [key: string]: string }) => {
  const parsedTags = parseTags(tags);

  return Object.entries(parsedTags)
    .flatMap(([key, value]) => [key, value]);
};
