import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import * as echarts from 'echarts';
import {NgxEchartsModule} from 'ngx-echarts';

import {FacilitySelectRouterOutletModule} from '../shared/components/facility-select-router-outlet/facility-select-router-outlet.module';
import {TabSwitchHeaderModule} from '../shared/components/tab-switch-header/tab-switch-header.module';
import {CurrentSessionService} from '../shared/services/current-session.service';
import {AuthPermissionGuard} from './guards/auth-permission.guard';

import {JwtInterceptor} from './interceptors/jwt.interceptor';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ToasterComponent} from './components/toaster/toaster.component';
import {LandingModule} from './components/landing/landing.module';
import {HomeModule} from '../home/home.module';
import {TenantGuard} from './guards/tenant.guard';
import {ChargersOutletComponent} from './components/chargers-outlet/chargers-outlet.component';
import {VehiclesOutletComponent} from './components/vehicles-outlet/vehicles-outlet.component';
import {HomepageComponent} from './components/homepage/homepage.component';
import {ConfigurationsOutletComponent} from './components/configurations-outlet/configurations-outlet.component';
import {FleetLocationModule} from '../fleet-location/fleet-location.module';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ToasterComponent,
    ChargersOutletComponent,
    VehiclesOutletComponent,
    ConfigurationsOutletComponent,
    HomepageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    AppRoutingModule,
    LandingModule,
    HomeModule,
    FleetLocationModule,
    TabSwitchHeaderModule,
    FacilitySelectRouterOutletModule,
  ],
  providers: [
    CurrentSessionService,
    TenantGuard,
    AuthPermissionGuard,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
