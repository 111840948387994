import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {FacilitySelectRouterOutletComponent} from '../shared/components/facility-select-router-outlet/facility-select-router-outlet.component';
import {DashboardSelectorRouterOutletComponent} from './components/dashboard-selector-router-outlet/dashboard-selector-router-outlet.component';
import {FacilityDashboardComponent} from './components/facility-dashboard/facility-dashboard.component';

const allowedSubRoutes = ['fleet-location'];

const routes: Routes = [
  {
    path: '',
    component: FacilitySelectRouterOutletComponent,
  },
  {
    path: ':facilityId',
    component: FacilitySelectRouterOutletComponent,
    data: {
      allowedSubRoutes,
    },
    children: [
      {
        path: '',
        component: DashboardSelectorRouterOutletComponent,
        children: [
          {
            path: '',
            component: FacilityDashboardComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {
}
