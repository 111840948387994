<label [formGroup]="formControl">
    <div class="label">
        {{ label }}<span *ngIf="isRequired">*</span>
    </div>

    <mat-select class="bdd-id--settings-options-dropdown--dropdown-select" [formControl]="formControl">
        <mat-option
            class="bdd-id--settings-options-dropdown--dropdown-option"
            *ngFor="let option of formOptions"
            [value]="option.value"
            [attr.data-bdd-value]="option.value"
        >
            {{ option.label }}
        </mat-option>
    </mat-select>

    <div *ngIf="isRequired && formControl.invalid && (formControl.dirty || formControl.touched)" class="text-danger">
        <div *ngIf="formControl.errors?.required">
            {{ label }} is required.
        </div>
    </div>
</label>
