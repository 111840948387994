import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {TableFilter, TableFilterConfig} from './table-filters.model';

@Component({
  selector: 'table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
})
export class TableFiltersComponent implements OnInit {
  @Input() tableFilterConfig: TableFilterConfig[];
  @Input() filters: TableFilter[] = [];
  @Output() updatedFilters = new EventEmitter<TableFilter[]>();

  public showPopup = false;
  public selectedOption: TableFilterConfig;

  public ngOnInit() {
    if (!this.filters) {
      this.filters = [];
    }
  }

  public selectOption(option: TableFilterConfig) {
    this.selectedOption = (option !== this.selectedOption)
      ? option
      : null;
  }

  public deselectOption() {
    this.selectedOption = null;
  }

  public addFilter(filter: TableFilter) {
    this.filters.push(filter);

    this.updatedFilters.emit(this.filters);

    this.selectedOption = null;
    this.showPopup = false;
  }

  public removeFilter(index: number) {
    this.filters.splice(index, 1);

    this.updatedFilters.emit(this.filters);
  }
}
