import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfoFilterComponent} from './info-filter.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {InfoPieModule} from '../info-pie/info-pie.module';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    InfoFilterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxEchartsModule,
    InfoPieModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
  ],
  exports: [
    InfoFilterComponent,
  ],
})
export class InfoFilterModule {
}
