import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';

import {SvgIconModule} from '../svg-icon/svg-icon.module';
import {ActionBtnModule} from '../action-btn/action-btn.module';

import {TableFiltersComponent} from './table-filters.component';
import {TableFiltersService} from './table-filters.service';
import {TableFiltersStringTypeComponent} from './table-filters-string-type/table-filters-string-type.component';
import {TableFiltersNumberTypeComponent} from './table-filters-number-type/table-filters-number-type.component';
import {TableFiltersInputComponent} from './table-filters-input/table-filters-input.component';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  declarations: [
    TableFiltersComponent,
    TableFiltersStringTypeComponent,
    TableFiltersNumberTypeComponent,
    TableFiltersInputComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatRadioModule,
    SvgIconModule,
    ActionBtnModule,
    MatSelectModule,
  ],
  exports: [
    TableFiltersComponent,
  ],
  providers: [
    TableFiltersService,
  ],
})
export class TableFiltersModule {
}
