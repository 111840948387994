import {Component, EventEmitter, Input, Output} from '@angular/core';

import {TableColumn} from '../../table-column.model';

@Component({
  selector: 'table-header-cell',
  templateUrl: './table-header-cell.component.html',
  styleUrls: ['./table-header-cell.component.scss'],
})
export class TableHeaderCellComponent {
  @Input() column: TableColumn;
  @Input() isSorted: boolean;
  @Output() sorted = new EventEmitter<{ columnId: string, isOn: boolean }>();

  public sortingAsc = true;
  public sortingDesc = false;
  public hovered: boolean;

  public sort(columnId: string) {
    // reset the hovering, so that the user needs to re-hover the element so see suggested sort
    this.hovered = false;

    if (this.isSorted) {
      if (this.sortingAsc) {
        this.sortingAsc = false;
        this.sortingDesc = true;
        this.sorted.emit({columnId, isOn: true});
      } else if (this.sortingDesc) {
        this.sortingAsc = false;
        this.sortingDesc = false;
        this.sorted.emit({columnId, isOn: false}); // inform the parent component that sorting has been switched off
      }
    } else {
      this.sortingAsc = true;
      this.sortingDesc = false;
      this.sorted.emit({columnId, isOn: true});
    }
  }

  public enter() {
    this.hovered = true;
  }

  public leave() {
    this.hovered = false;
  }

  public isColumnHidden() {
    if (!this.column.isHidden) {
      return false;
    }

    return typeof this.column.isHidden === 'boolean'
      ? this.column.isHidden
      : this.column.isHidden();
  }
}
