import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FleetLocationVehiclePopupComponent} from './fleet-location-vehicle-popup.component';
import { NoValueDashPipeModule } from '../../pipes/no-value-dash/no-value-dash-pipe.module';
import {CustomUnitPipeModule} from '../../pipes/custom-unit/custom-unit-pipe.module';

@NgModule({
  declarations: [
    FleetLocationVehiclePopupComponent,
  ],
  imports: [
    CommonModule,
    NoValueDashPipeModule,
    CustomUnitPipeModule,
  ],
  exports: [
    FleetLocationVehiclePopupComponent,
  ],
})
export class FleetLocationVehiclePopupModule {
}
