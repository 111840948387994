import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {Facility} from '../../models/entities';
import {AssetManagerService} from '../../services/asset-manager.service';
import {UserPreferencesService} from '../../services/user-preferences.service';

@Component({
  selector: 'facilities-router-view',
  templateUrl: './facility-select-router-outlet.component.html',
  styleUrls: ['./facility-select-router-outlet.component.scss'],
})
export class FacilitySelectRouterOutletComponent implements OnInit {
  public initialized = false;
  public facilities: Facility[];
  public selectedFacilityId: number;
  public defaultFacilityId: number;
  public allowedSubRoutes: string[];
  public isCollapsed = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private assetManagerService: AssetManagerService,
    private userPreferencesService: UserPreferencesService,
  ) {
  }

  public ngOnInit() {
    this.updateCollapsedState();

    this.defaultFacilityId = this.userPreferencesService.get('defaultFacilityId');

    this.allowedSubRoutes = Array.isArray(this.route.snapshot.data?.allowedSubRoutes)
      ? this.route.snapshot.data.allowedSubRoutes
      : [];

    this.assetManagerService
      .getAllFacilities()
      .pipe(catchError(() => of([])))
      .subscribe(facilities => {
        this.facilities = facilities;

        if (this.facilities && this.facilities.length > 0) {
          const initialFacilityId = this.getInitialFacilityId();

          // Make sure the url facility id is valid for the given list of facilities
          const selectedFacilityId = initialFacilityId && this.facilities.find(f => f.facilityId === initialFacilityId)
            ? initialFacilityId
            : this.facilities[0].facilityId;

          this.initialiseFacility(selectedFacilityId);
        }

        this.initialized = true;
      });
  }

  public facilitySelected(facilityId: number) {
    const commands = this.getRouterCommands(facilityId);

    this.router
      .navigate(commands, {relativeTo: this.route})
      .then(() => {
        this.selectedFacilityId = facilityId;
      });
  }

  public setDefaultFacilityId(facilityId: number) {
    this.userPreferencesService.setUserPreferences('defaultFacilityId', facilityId)
      .subscribe(() => {
        this.defaultFacilityId = facilityId;
      });
  }

  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    sessionStorage.setItem('facilitySelectionCollapsed', String(this.isCollapsed));
  }

  private updateCollapsedState() {
    const value = sessionStorage.getItem('facilitySelectionCollapsed');
    this.isCollapsed = (value === 'true');
  }

  private initialiseFacility(facilityId: number) {
    const urlFacilityId = parseInt(this.route.snapshot.paramMap.get('facilityId'), 10);

    if (urlFacilityId === facilityId) {
      this.selectedFacilityId = facilityId;
      return;
    }

    this.facilitySelected(facilityId);
  }

  private getInitialFacilityId() {
    const urlFacilityId = parseInt(this.route.snapshot.paramMap.get('facilityId'), 10);
    if (urlFacilityId) {
      return urlFacilityId;
    }

    if (this.defaultFacilityId) {
      return this.defaultFacilityId;
    }

    return false;
  }

  private getRouterCommands(facilityId: number) {
    const urlFacilityId = parseInt(this.route.snapshot.paramMap.get('facilityId'), 10);

    const extraFragment = this.allowedSubRoutes
      .find(fragment => this.router.url.includes(fragment));

    const needToGoBackFirst = urlFacilityId || (extraFragment && !urlFacilityId);

    return [
      ...(needToGoBackFirst ? ['..'] : []),
      facilityId,
      ...(extraFragment ? [extraFragment] : []),
    ];
  }
}
