import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';

import {PieHoverEvent} from '../info-pie/models/pie-hover-event';
import {PieData} from '../info-pie/models/pie-data';
import {MatSelect} from '@angular/material/select';
import {fromEvent} from 'rxjs';
import {auditTime, filter, map} from 'rxjs/operators';

@Component({
  selector: 'facility-view-info-filter',
  templateUrl: './info-filter.component.html',
  styleUrls: ['./info-filter.component.scss'],
})
export class InfoFilterComponent implements OnChanges, OnInit {
  @Input() data: PieData[];
  @Input() title = '';
  @Input() showValues = true;
  @Input() cols = 2;
  @Input() multiple = false;
  @Input() placeholder: string;
  @Input() selection?: string[];
  @Input() showSelector? = true;
  @Input() totalColour? = 'white';
  @Input() showPieChartOnMobile = false;

  @Output() filterChanges = new EventEmitter();
  @ViewChild('select') select: MatSelect;

  public columns = [];
  public hovered = undefined;
  public selected: (string | number)[] = [];
  public allSelected = false;

  private isMobileSize = false;

  private internalCols: number = 2;

  ngOnInit(): void {
    const isMobile = () => document.body.offsetWidth < 992;

    this.isMobileSize = isMobile();
    this.internalColumnSwitch();

    const screenChange = fromEvent(window, 'resize')
      .pipe(
        auditTime(100),
        map(isMobile),
        filter(isMob => isMob !== this.isMobileSize),
      );

    screenChange.subscribe(isMobileSize => {
      this.isMobileSize = isMobileSize;
      this.internalColumnSwitch();
      this.init();
    });
  }

  private internalColumnSwitch() {
    this.internalCols = this.isMobileSize && !this.showPieChartOnMobile ? 1 : this.cols;
  }

  public handleHover($event: PieHoverEvent) {
    if ($event.type === 'mouseout') return this.hovered = undefined;
    this.hovered = $event.data.name;
  }

  private init() {
    this.columns = [];
    for (let i = 0; i < this.internalCols; i++) this.columns.push([]);
    this.data.forEach((el, i) => {
      this.columns[i % this.internalCols].push(el);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.previousValue !== changes.data?.currentValue) {
      this.internalColumnSwitch();
      this.init();
    } else if (changes.selection?.previousValue !== changes.selection?.currentValue) {
      this.selected = this.selection;
    }
  }

  public change($event) {
    if (this.allSelected && this.selected.length < this.data.length) {
      this.allSelected = false;
    }
    this.filterChanges.emit(this.selected);
  }

  public clear(emit?: boolean) {
    this.selected = [];
    if (emit) this.change(null);
  }

  selectAll() {
    if (this.allSelected) this.selected = this.data.map(d => d.name);
    else this.selected = [];

    this.select.value = this.selected;
    this.filterChanges.emit(this.selected);
  }
}
