import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Facility} from '../../models/entities';

@Component({
  selector: 'mobile-facility-selector',
  templateUrl: './mobile-facility-selector.component.html',
  styleUrls: ['./mobile-facility-selector.component.scss'],
})
export class MobileFacilitySelectorComponent implements OnInit {
  @Input() selectedFacilityId: number;
  @Input() facilities: Facility[] = [];
  @Output() facilitySelected: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit(): void {
  }

  select(facilityId: number) {
    this.facilitySelected.emit(facilityId);
  }

}
