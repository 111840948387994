<div class="landing">
    <header
        class="bdd-id--landing--header"
        (signOut)="onSignOut()"
        [signedIn]="stage === 'signedin'">
    </header>

    <div *ngIf="stage && stage !== 'signedin'" class="landing__not-signed-in bdd-id--landing--not-signed-in">

        <div class="d-flex flex-column-reverse flex-lg-row">
            <div class="landing__form-section">
                <div class="landing__form-error">{{ error }}</div>

                <div *ngIf="stage === 'signin'" class="d-flex flex-column">
                    <label>
                        <div class="custom-label">Username</div>
                        <input type="text" class="input-field bdd-id--landing--signin-username-input"
                               [formControl]="username"
                               (keydown.enter)="signIn(username.value,password.value)"/>
                    </label>

                    <label>
                        <div class="custom-label">Password</div>
                        <input type="password" class="input-field bdd-id--landing--signin-password-input"
                               [formControl]="password"
                               (keydown.enter)="signIn(username.value,password.value)"/>
                    </label>

                    <div class="d-flex flex-row align-items-end">
                        <span class="landing__forgotten-password-link mr-auto" (click)="forgotPasswordStage1()">
                          Forgot password?
                        </span>

                        <action-btn
                            class="ml-2 ml-lg-auto bdd-id--landing--signin-login-button"
                            type="save"
                            label="Login"
                            (click)="signIn(username.value, password.value)">
                        </action-btn>
                    </div>
                </div>

                <div *ngIf="stage === 'forgotpassword'">

                    <div *ngIf="forgotPasswordStage === 1"
                         class="d-flex flex-column align-items-center align-items-lg-stretch">
                        <label>
                            <div class="custom-label">Username</div>
                            <input
                                type="text"
                                class="input-field bdd-id--landing--forgot-password-username-input"
                                [formControl]="username"
                                (keydown.enter)="forgotPasswordStage2(username.value)"/>
                        </label>

                        <action-btn
                            class="ml-auto bdd-id--landing--forgot-password-button-1"
                            type="save"
                            label="Reset your password"
                            (click)="forgotPasswordStage2(username.value)">
                        </action-btn>

                        <div class="action-link mt-3" (click)="setStage('signin', 0)">or, Return to login</div>
                    </div>

                    <div *ngIf="forgotPasswordStage >= 2" class="d-flex flex-column">
                        <label>
                            <div class="landing__verification-code-message">
                                If your email is registered you'll receive a verification code.
                            </div>
                        </label>

                        <label>
                            <div class="custom-label">Email Verification Code</div>
                            <input
                                type="text"
                                class="input-field bdd-id--landing--forgot-password-email-verify-code-input"
                                [formControl]="resetPasswordCode"
                                [disabled]="forgotPasswordStage > 2"
                                (keydown.enter)="forgotPasswordSubmit(username.value, resetPasswordCode.value, password.value)"/>
                        </label>

                        <label>
                            <div class="custom-label">New Password</div>
                            <input
                                type="password"
                                class="input-field bdd-id--landing--forgot-password-new-password-input"
                                [formControl]="password"
                                [disabled]="forgotPasswordStage > 2"
                                (keydown.enter)="forgotPasswordSubmit(username.value, resetPasswordCode.value, password.value)"/>
                        </label>

                        <action-btn
                            class="ml-auto bdd-id--landing--forgot-password-button-2"
                            type="save"
                            label="Set up new password"
                            [disabled]="forgotPasswordStage > 2"
                            (click)="forgotPasswordSubmit(username.value, resetPasswordCode.value, password.value)">
                        </action-btn>

                        <div class="action-link mt-3" (click)="setStage('signin', 0)">or, Return to login</div>


                        <label>
                            <span *ngIf="forgotPasswordStage === 3">Code submitted...</span>
                            <span *ngIf="forgotPasswordStage === 4">Password set successfully</span>
                        </label>
                    </div>
                </div>

                <div *ngIf="stage === 'resettingpassword'" class="d-flex flex-column">
                    <label>
                        <div class="custom-label">New Password</div>
                        <input
                            type="password"
                            class="input-field bdd-id--landing--reset-password-input"
                            [formControl]="password"
                            (keydown.enter)="completeNewPassword(password.value)">
                    </label>

                    <action-btn
                        class="ml-auto bdd-id--landing--reset-password-button"
                        type="save"
                        label="Set up new password"
                        (click)="completeNewPassword(password.value)">
                    </action-btn>
                </div>

                <div *ngIf="stage === 'settingMFA'" class="d-flex flex-column">
                    <div class="landing__help-container">
                        <div class="landing__help-text">
                            1. Open your selcted <b>Authenticator App</b><br>
                            2. Add a new account on the app, <b>usually by selecting "+" icon</b><br>
                            3. Scan the QR code below from the authenticator app
                        </div>
                        <div class="landing__help-title-important mt-3">DO NOT SCAN THE QR CODE DIRECTLY FROM A CAMERA APP</div>
                    </div>

                    <div class="d-flex justify-content-center landing__qrcode"
                         [class.landing__qrcode--disabled]="qrCodeDisabled">
                        <qrcode class="bdd-id--landing--setting-mfa-qr-code" [qrdata]="qrCode"></qrcode>
                    </div>

                    <ng-container *ngIf="!qrCodeDisabled">
                        <label>
                            <div class="custom-label">Time-based one-time password</div>
                            <input
                                class="input-field bdd-id--landing--setting-mfa-totp-code-input"
                                type="number"
                                [formControl]="totp"
                                (keydown.enter)="verifyTotpToken(totp.value)">
                        </label>

                        <action-btn
                            class="ml-auto bdd-id--landing--setting-mfa-verify-totp-code-button"
                            type="save"
                            label="Submit"
                            (click)="verifyTotpToken(totp.value)">
                        </action-btn>
                    </ng-container>

                    <div *ngIf="qrCodeDisabled" class="landing__qrcode-expired">
                        <div class="landing__form-error mb-3">Timeout reached. Please sign in again.</div>

                        <action-btn
                            class="ml-auto bdd-id--landing--signin-login-button"
                            type="save"
                            label="Return to sign in"
                            (click)="backToSignIn()">
                        </action-btn>
                    </div>
                </div>

                <div *ngIf="stage === 'TOTPSetup'" class="d-flex flex-column">
                    <div *ngIf="isTOTPSetup" class="landing__help-container">
                        <div class="landing__help-title">Your account has been successfully verified!</div>
                        <div class="landing__help-text"><b>Please wait</b> until the authenticator app has generated a <b>new time-based one-time password</b> then enter it below.
                        </div>
                        <div class="landing__help-title-important mt-3">DO NOT RE-ENTER THE SAME CODE AGAIN
                        </div>
                    </div>

                    <div *ngIf="!isTOTPSetup" class="landing__help-container">
                        <div class="landing__help-title">Enter your six-digit one time password (OTP) code</div>
                    </div>

                    <label>
                        <div class="custom-label">Time-based one-time password</div>
                        <input
                            class="input-field bdd-id--landing--totp-setup-totp-code-input"
                            [formControl]="totp"
                            (keydown.enter)="confirmSignInWithTOTP(totp.value)"/>
                    </label>

                    <action-btn
                        class="ml-auto bdd-id--landing--totp-setup-send-code-button"
                        type="save"
                        label="Submit"
                        (click)="confirmSignInWithTOTP(totp.value)">
                    </action-btn>
                </div>
            </div>

            <div *ngIf="(stage === 'forgotpassword' && forgotPasswordStage >= 2) || stage === 'resettingpassword'"
                 class="mx-4 mb-4 mb-lg-0 landing__password-requirements">
                <password-requirements-card class="bdd-id--landing--password-requirements-card">
                </password-requirements-card>
            </div>
        </div>
    </div>

    <div class="landing__signed-in bdd-id--landing--signed-in" *ngIf="stage === 'signedin'">
        <router-outlet></router-outlet>
    </div>

</div>
