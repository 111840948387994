<div class="multi-column-info">
    <div class="title-text">{{title}}</div>
    <div class="d-flex flex-row defaults justify-content-center">
        <div class="pie-wrapper d-none d-lg-block" [ngClass]="{'d-block': showPieChartOnMobile}">
            <app-info-pie
                class="pie"
                (hoverEvents)="handleHover($event)"
                [data]="data"
                [chartHeight]="70"
                [totalColour]="totalColour">
            </app-info-pie>
        </div>

        <div class="flex-fill d-flex flex-row scrollbar-nighthawk thin columned-info">
            <div *ngFor="let dataArray of columns" class="flex-fill my-auto info-col d-flex flex-row flex-lg-column justify-content-between"
                    [ngClass]="{'flex-column': showPieChartOnMobile}">
                <div *ngFor="let data of dataArray" class="d-flex flex-row align-items-center info-row"
                     [ngStyle]="hovered === data.name && {'color': data.color }">
                    <div class="fv-circle" [style.border-color]="data.color"
                         [style.background-color]="data.color"></div>
                    <div class="d-flex flex-fill align-items-end flex-nowrap">
                        <div class="info-name bdd-id--info-filter--info-name">{{data.name}}</div>
                        <div *ngIf="data.description" class="info-description text-nowrap bdd-id--info-filter--info-description">&nbsp;{{data.description}}</div>
                    </div>
                    <div class="align-self-center align-self-lg-end info-value bdd-id--info-filter--info-value" *ngIf="showValues">{{data.value}}</div>
                </div>
            </div>
        </div>
    </div>

    <mat-select class="filter-select"
                *ngIf="showSelector"
                #select
                [multiple]="multiple"
                [(value)]="selected"
                [placeholder]="placeholder"
                (selectionChange)="change($event)">
        <div class="select-all">
            <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}" (change)="selectAll()">
                Select All
            </mat-checkbox>
        </div>
        <mat-option *ngFor="let d of data" [value]="d.name">{{d.name}}</mat-option>
    </mat-select>
</div>
