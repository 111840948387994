import {ChargingStation, OptimisationSettings, Vehicle} from '../entities';
import {FacilityState} from './facility-state.model';
import {isValidInt} from '../../utils/number';
import {EvState} from './ev-state.model';
import {PluggedInOptions} from '../../../fleet-location/fleet-location-map-filter/fleet-location-map-filter.service';
import {highSoc, lowSoc, mediumSoc, unknownSoc} from '../../../facility-view/consts/text.consts';
import {highSocColour, lowSocColour, mediumSocColour, unknownSocColour} from '../../../facility-view/consts/colour.consts';
import {getSocType} from '../../../facility-view/utils';
import {FvData} from '../../../facility-view/models';
import {ChargingGroup} from '../entities/charging-group.model';
import {OptimisationUnit} from '../enums';

export interface FleetLocationState extends FacilityState {
  vehicles: Vehicle[];
  pluggedInStatus: { [key: number]: boolean };
  stateOfChargeInPercent: { [key: number]: number };
  stateOfChargeChartData;
  updatedAt: Date;
  csOptimisationUnit?: {[key: number]: OptimisationUnit};
}

export const getPluggedInStatus = (evState: EvState) => {
  if (evState?.isPluggedIn) {
    return PluggedInOptions.PluggedIn;
  }
  return PluggedInOptions.NotPluggedIn;
};

const calculatePluggedInVehicles = (
  facilityState: FleetLocationState,
) => {

  const plugInState = {
    [PluggedInOptions.PluggedIn]: {value: 0, color: '#4ab471'},
    [PluggedInOptions.NotPluggedIn]: {value: 0, color: '#999999'},
  };

  const accumulateStatuses = (evState: EvState) => {
    if (!evState) return;
    const statusType = getPluggedInStatus(evState);
    if (statusType) plugInState[statusType].value++;
  };

  const vehicleToState: { [key: number]: null | EvState } = facilityState.vehicles
    .reduce((map, veh) => {
      map[veh.evId] = null;
      return map;
    }, {});

  facilityState.evState.forEach(state => {
    if (vehicleToState[state?.evId] !== undefined) {
      vehicleToState[state.evId] = state;
    }
  });

  Object
    .values(vehicleToState)
    .forEach(state => accumulateStatuses(state));

  return Object.keys(plugInState).map(key => ({name: key, ...plugInState[key]}));
};

const calculateStateOfChargeChartData = (
  facilityState: FleetLocationState,
) => {
  const soc = {
    [highSoc]: {value: 0, color: highSocColour, description: '(≥66%)'},
    [mediumSoc]: {value: 0, color: mediumSocColour, description: '(33-66%)'},
    [lowSoc]: {value: 0, color: lowSocColour, description: '(≤33%)'},
    [unknownSoc]: {value: 0, color: unknownSocColour},
  };

  const accumulateStatuses = (fvData?: FvData) => {
    if (!fvData) return;
    const socType = getSocType(fvData);
    if (socType) soc[socType].value++;
  };

  Object
    .values(facilityState.vehicles)
    .forEach(vehicle => {
      const evState = facilityState.evState.find(x => x.evId === vehicle.evId);
      const fvData = new FvData({evState, vehicle});
      accumulateStatuses(fvData);
    });

  return Object.keys(soc).map(key => ({name: key, ...soc[key]}));
};

const calculatestateOfChargeInPercententage = (
  facilityState: FleetLocationState,
) => {
  const vehiclesHashMap = new Map(facilityState.vehicles.map(vehicle => [vehicle.evId, vehicle]));

  return facilityState.evState
    .reduce((results, evseState) => {
      const vehicle = vehiclesHashMap.get(evseState.evId);
      if (!vehicle) {
        return results;
      }

      return {
        ...results,
        [evseState.evId]: evseState.stateOfChargeInPercent,
      };
    }, {});
};

export const createFleetLocationState = (
  facilityState: FacilityState | FleetLocationState,
  vehicles: Vehicle[],
  chargingStations?: ChargingStation[],
  chargingGroups?: ChargingGroup[],
  facilityOptimisationSettings?: OptimisationSettings,
) => {
  const fleetLocationState: FleetLocationState = {
    ...facilityState,
    vehicles,
    pluggedInStatus: {},
    stateOfChargeInPercent: {},
    stateOfChargeChartData: {},
    updatedAt: new Date(),
  };

  if (!fleetLocationState.evState) {
    fleetLocationState.evState = [];
  }

  fleetLocationState.pluggedInStatus = calculatePluggedInVehicles(fleetLocationState);
  fleetLocationState.stateOfChargeInPercent = calculatestateOfChargeInPercententage(fleetLocationState);
  fleetLocationState.stateOfChargeChartData = calculateStateOfChargeChartData(fleetLocationState);

  fleetLocationState.csOptimisationUnit = {};

  if (chargingGroups?.length) {
    chargingStations.forEach(cs => {
      const chargingGroup = chargingGroups.find(x => x.chargingGroupId === cs.chargingGroupId);
      fleetLocationState.csOptimisationUnit[cs.chargingStationId] = chargingGroup.optimisationUnit === 'amps' ? OptimisationUnit.amps : OptimisationUnit.kW;
    });
  } else {
    chargingStations.forEach(cs => {
      fleetLocationState.csOptimisationUnit[cs.chargingStationId] = facilityOptimisationSettings.optimisationUnit  === 'amps' ? OptimisationUnit.amps : OptimisationUnit.kW;
    });
  }

  return fleetLocationState;
};

export const recalculateFleetLocationState = (
  facilityState: FleetLocationState,
) => ({
  ...facilityState,
  pluggedInStatus: calculatePluggedInVehicles(facilityState),
  stateOfChargeInPercent: calculatestateOfChargeInPercententage(facilityState),
  stateOfChargeChartData: calculateStateOfChargeChartData(facilityState),
  updatedAt: new Date(),
});
