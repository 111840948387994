import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {FacilitySelectRouterOutletComponent} from './facility-select-router-outlet.component';
import {FacilitySelectionModule} from '../facility-selection/facility-selection.module';
import {MatSelectModule} from '@angular/material/select';
import {MobileFacilitySelectorModule} from '../mobile-facility-selector/mobile-facility-selector.module';

@NgModule({
  declarations: [
    FacilitySelectRouterOutletComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FacilitySelectionModule,
    MatSelectModule,
    MobileFacilitySelectorModule,
  ],
  exports: [
    FacilitySelectRouterOutletComponent,
  ],
})
export class FacilitySelectRouterOutletModule {
}
