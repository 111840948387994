import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import mapboxgl from 'mapbox-gl';

import {environment} from '../../../environments/environment';
import {Facility} from '../models/entities';

@Injectable({
  providedIn: 'root',
})
export class MapHelperService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public getStaticMapUrl(
    coords: [number, number],
    width = 1000,
    height = 600,
    zoom = 18,
    retina = false,
    style = 'streets-v11',
  ) {
    const urlBase = `https://api.mapbox.com/styles/v1/mapbox/${style}/static/`;
    const location = `${coords[0]},${coords[1]}`;
    const dimensions = `${width}x${height}${retina === true ? '@2x' : ''}`;

    return `${urlBase}${location},${zoom},0.00,0.00/${dimensions}?access_token=${environment.mapbox.accessToken}`;
  }

  public addressToCoords(address: string): Observable<[number, number] | undefined> {
    const urlBase = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

    return this.http.get(`${urlBase}${address}.json?access_token=${environment.mapbox.accessToken}`)
      .pipe(
        map((res: any) => res?.features[0]?.center),
      );
  }

  public getAddressFromFacility({addressLine1, addressLine2, postcode}: Facility) {
    return [addressLine1, addressLine2, postcode]
      .filter(value => value)
      .join(' ');
  }

  public getIsochroneData(longitude: number, latitude: number, profile: string, meters: number) {
    const urlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';

    const params = {
      contours_meters: meters,
      polygons: true,
      access_token: environment.mapbox.accessToken,
    };

    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.http.get(`${urlBase}${profile}/${longitude},${latitude}?${queryString}`);
  }

  public getDrivableMeters(batteryCapacity: number, percentageRemaining: number, wattHrsPerMile: number) {
    const wattHrsPerKm = wattHrsPerMile / 1.6;
    const fractionRemaining = percentageRemaining / 100;
    return Math.min(Math.round((((batteryCapacity * fractionRemaining) * 1000) / wattHrsPerKm) * 1000), 100000);
  }

  public zoomToFitCoords(
    mapInstance: mapboxgl.Map,
    coords: [number, number][],
    padding = 50,
  ) {
    const bounds = new mapboxgl.LngLatBounds();

    coords.filter(x => x[0] && x[1]).forEach(item => bounds.extend(item));

    if (!bounds.isEmpty()) {
      mapInstance.fitBounds(bounds, {padding});
    }
  }

  public loadImagesIntoMap(mapObj: mapboxgl.Map, images: { [key: string]: string }, options: any = {}) {
    const promises = Object.keys(images)
      .map(key => new Promise((resolve, reject) => {
        mapObj.loadImage(images[key], (error, res) => {
          if (error) {
            return reject(error);
          }

          mapObj.addImage(key, res, options);
          resolve(true);
        });
      }));

    return Promise.all(promises);
  }
}
