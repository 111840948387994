<form class="table-filters-number-type__form bdd-id--table-filters-number-type--form" [formGroup]="optionsForm"
      (ngSubmit)="onSubmit()">
    <mat-radio-group formControlName="operator" class="table-filters-number-type__radio-group">
        <mat-radio-button
            class="table-filters-number-type__radio-button bdd-id--table-filters-number-type--greater-than-radio-button"
            [value]="TableFilterOperators.GreaterThan">
            is greater than

            <div class="table-filters-number-type__radio-button-content"
                 *ngIf="operator.value === TableFilterOperators.GreaterThan">
                <table-filters-input [type]="'number'" [name]="'valueGreaterThan'" [formGroup]="optionsForm"
                                     [unit]="config.unit" [unitsList]="config.unitsList"></table-filters-input>
            </div>
        </mat-radio-button>

        <mat-radio-button
            class="table-filters-number-type__radio-button bdd-id--table-filters-number-type--less-than-radio-button"
            [value]="TableFilterOperators.LessThan">
            is less than

            <div class="table-filters-number-type__radio-button-content"
                 *ngIf="operator.value === TableFilterOperators.LessThan">
                <table-filters-input [type]="'number'" [name]="'valueLessThan'" [formGroup]="optionsForm"
                                     [unit]="config.unit" [unitsList]="config.unitsList"></table-filters-input>
            </div>
        </mat-radio-button>

        <mat-radio-button
            class="table-filters-number-type__radio-button bdd-id--table-filters-number-type--equals-radio-button"
            [value]="TableFilterOperators.Equals">
            is equal too

            <div class="table-filters-number-type__radio-button-content"
                 *ngIf="operator.value === TableFilterOperators.Equals">
                <table-filters-input [type]="'number'" [name]="'valueEquals'" [formGroup]="optionsForm"
                                     [unit]="config.unit" [unitsList]="config.unitsList"></table-filters-input>
            </div>
        </mat-radio-button>
    </mat-radio-group>

    <div class="table-filters-number-type__actions bdd-id--table-filters-number-type--actions">
        <button
            type="button"
            class="bdd-id--table-filters-number-type--cancel-button cancel-btn cancel-btn--thin"
            (click)="cancel()"
        >
            <svg-icon type="cross-circled"></svg-icon>
            <span>Cancel</span>
        </button>

        <button
            class="bdd-id--table-filters-number-type--save-button save-btn save-btn--thin"
            type="submit"
            [disabled]="!optionsForm.valid"
        >
            <span>Apply Filter</span>
        </button>
    </div>
</form>
