<div class="fleet-location-vehicle-popup" *ngIf="properties">
  <div class="fleet-location-vehicle-popup__section">
    <div class="fleet-location-vehicle-popup__row">
      <div class="fleet-location-vehicle-popup__field mr-3">
        <strong>Vehicle</strong>
        <a href="#" (click)="navigateToVehiclePage($event)">{{ properties.evIdentifier }}</a>
      </div>
    </div>

    <div class="fleet-location-vehicle-popup__row">
      <div class="fleet-location-vehicle-popup__field mr-3">
        <strong>Make</strong>
        {{ properties.manufacturer | dashWhenNoValue }}
      </div>

      <div class="fleet-location-vehicle-popup__field">
        <strong>Model</strong>
        {{ properties.model }}
      </div>
    </div>

    <div class="fleet-location-vehicle-popup__row" *ngIf="properties.isCharging">
      <div class="fleet-location-vehicle-popup__field mr-3">
        <strong>Status</strong>
        Charging
      </div>

      <div class="fleet-location-vehicle-popup__field">
        <strong>Charging Station</strong>
        <a href="#" *ngIf="properties.isCharging"
           (click)="navigateToChargingStationPage($event)">{{getChargingStationName(properties.chargingStationId)}}
        </a>
        <span *ngIf="!properties.isCharging">-</span>
      </div>
    </div>

    <div class="fleet-location-vehicle-popup__row">
      <div class="fleet-location-vehicle-popup__field">
        <strong>State of Charge</strong>
        {{ properties.soc ? properties.soc + '%' : '-' }}
      </div>

      <div class="fleet-location-vehicle-popup__field">
        <strong>Mileage* </strong>
        {{ properties.estimatedMileage | dashWhenNoValue | customUnit: 'mi.'}}
      </div>
    </div>

    <div class="fleet-location-vehicle-popup__row fleet-location-vehicle-popup__row--no-margin" *ngIf="showIsochrone">
      <div class="fleet-location-vehicle-popup__field">
        <a (click)="addIsoChroneLayerToMap($event)"
           tooltip="Shows estimated drivable range given the vehicles current state of charge">
          <i class="fas fa-road"></i>
        </a>
      </div>
    </div>
  </div>
</div>
