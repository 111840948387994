import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'settings-options-toggle',
  templateUrl: './settings-options-toggle.component.html',
  styleUrls: ['./settings-options-toggle.component.scss'],
})
export class SettingsOptionsToggleComponent implements OnInit {
  @Input() public formKey: string;
  @Input() public formGroup: UntypedFormGroup;

  @Input() public label: string;
  @Input() public value: boolean;

  public formControl: UntypedFormControl;

  public ngOnInit() {
    this.formControl = this.createFormOptions();

    if (this.formKey) {
      this.formGroup.addControl(this.formKey, this.formControl);
    }
  }

  private createFormOptions() {
    return new UntypedFormControl(this.value || null);
  }
}
