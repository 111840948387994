export const environment = {
  production: false,
  environment_name: 'qa',
  login: {
    tenantInfo: 'https://qa.hevcos.cloud/login/tenant',
  },
  api: {
    fsas: {
      ws: 'wss://websocket.qa.hevcos.cloud/fsas',
      http: {
        uri: 'https://qa.hevcos.cloud/api/alb/fsas',
      },
    },
    scheduler: {
      http: {
        uri: 'https://qa.hevcos.cloud/api/alb/scheduler',
      },
    },
    lambda: {
      http: {
        proxy: 'https://qa.hevcos.cloud/api/lambda',
      },
    },
    am: {
      http: {
        proxy: 'https://qa.hevcos.cloud/api/alb/am',
      },
    },
    uam: {
      http: {
        proxy: 'https://qa.hevcos.cloud/api/alb/uam',
      },
    },
    fsm: {
      http: {
        proxy: 'https://qa.hevcos.cloud/api/alb/fsm',
      },
    },
    historyService: {
      graphql: 'https://qa.hevcos.cloud/api/alb/ehm/graphql',
    },
  },
  auth: {
    aws_project_region: 'eu-west-2',
    aws_cognito_region: 'eu-west-2',
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiaGV1LWlmZCIsImEiOiJja3JtODF3aHIwOTZ4Mm9ycXc0cW96NGdnIn0._YfmeyRsKEKRTh15kCrMhg',
  },
  assets: {
    images: 'https://dsg-ifd-qa-hevcos-portal-public-images.s3.eu-west-2.amazonaws.com',
  },
  launchDarklyClientId: '6668d1675a7cac0f5ddd2f1c',
};
