import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

import {Facility} from '../../models/entities';
import {FacilityItemState} from './facility-selection-item/facility-selection-item.component';

@Component({
  selector: 'facility-selection',
  templateUrl: './facility-selection.component.html',
  styleUrls: ['./facility-selection.component.scss'],
})
export class FacilitySelectionComponent implements OnChanges {
  @Input() facilities: Facility[] = [];
  @Input() facilityStates: { facilityId: number; state: 'ok' | 'warning' | 'critical' }[];
  @Input() selectedFacilityId?: number;
  @Input() defaultFacilityId?: number;
  @Input() isCollapsed = false;
  @Input() showDefaultFacility = false;

  @Output() selected = new EventEmitter<any>();
  @Output() handleDefaultFacilitySelect = new EventEmitter<any>();

  public states: { [facilityId: number]: FacilityItemState } = {};

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.facilityStates?.currentValue) {
      this.facilityStates.forEach(kv => {
        this.states[kv.facilityId] = kv.state;
      });
    }
  }

  public facilitySelected(facilityId: number) {
    this.selectedFacilityId = facilityId;
    this.selected.emit(facilityId);
  }

  public defaultFacilitySelected(facilityId: number) {
    this.handleDefaultFacilitySelect.emit(facilityId);
  }
}
