import {Evse} from './evse.model';
import {ConnectorType} from './connector-type.model';

export class ChargingStationAsset {
  chargingStationId: number;
  extChargingStationId: string;
  excludeFromOptimisation?: boolean;
  chargingGroupId: number;
  facilityId: number;
  chargerTypeId: number;
  chargerTypeName: string;
  name: string;
  manufacturer: string;
  maxPower: number;
  maxCurrent: number;
  model: string;
  firmwareVersion?: string;
  phase: string;
  v2g: boolean;
  manufacturerCsId?: string;
  location?: string;
  evSupplyEquipments: Evse[];
  connectorTypes: ConnectorType[];
  facilityZoneId?: number;
  longitude?: number;
  latitude?: number;
  updatedAt: Date | string;
  updatedBy: string;
  updatedByUserId?: number;
  powerType: 'AC' | 'DC';
}

export class ChargingStation extends ChargingStationAsset {

  constructor(cs: ChargingStationAsset) {
    super();
    Object.assign(this, cs);
  }

  get evsesChargeRates() {
    return this.evSupplyEquipments.map(({extEvseId, maxPower, maxCurrent}) => ({extEvseId, maxPower, maxCurrent}));
  }

  set evsesDefaultChargeRates(x) {
  }

  get evsesDefaultChargeRates() {
    return this.evSupplyEquipments
      .map(({extEvseId, defaultPower, defaultCurrent}) => ({extEvseId, defaultPower, defaultCurrent}));
  }

  set evsesChargeRates(x) {
  }

  get evsesChargingPriority() {
    return this.evSupplyEquipments
      .map(({extEvseId, chargingPriority}) => ({extEvseId, chargingPriority}));
  }

  set evsesChargingPriority(x) {
  }
}
