import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'error-message-banner',
  templateUrl: './error-message-banner.component.html',
  styleUrls: ['./error-message-banner.component.scss'],
})
export class ErrorMessageBannerComponent {
  @Input() message: string;
  @Output() messageChanged = new EventEmitter<string>();

  public close() {
    this.message = '';
    this.messageChanged.emit('');
  }
}
