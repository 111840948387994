import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';

import {CurrentSessionService} from './current-session.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationHelperService {
  constructor(
    private router: Router,
    private currentSessionService: CurrentSessionService,
  ) {
  }

  public navigateToTenantPath(routeItems: string | any[], extras?: NavigationExtras) {
    const tenantId = this.currentSessionService.getTenantId();
    if (!tenantId) {
      return;
    }

    const commands = typeof routeItems === 'string'
      ? routeItems.split('/')
      : routeItems;

    return this.router.navigate([tenantId, ...commands], extras);
  }

  public searchForParamInAllChildComponents(route: ActivatedRoute, paramName: string): string | null {
    const child = route.firstChild;
    if (!child) {
      return null; // if the given ActiveRoute doesnt have a child ActiveRoute, return null
    }

    const param = child.snapshot.params[paramName]; // otherwise, search for the param in the child ActiveRoute params
    if (param) {
      return param; // if found, return the param value
    }

    return this.searchForParamInAllChildComponents(child, paramName); // otherwise continue the within that child ActiveRoute
  }
}
