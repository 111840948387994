import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FullScreenDirective} from './full-screen.directive';



@NgModule({
  declarations: [
    FullScreenDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FullScreenDirective,
  ],
})
export class FullScreenModule { }
