export const highSoc = 'High';
export const mediumSoc = 'Medium';
export const lowSoc = 'Low';
export const unknownSoc = 'Unknown';

export const pluggedIn = 'Plugged In';
export const pluggedOut = 'Plugged Out';
export const noConnection = 'No connection';

export const occupied = 'Occupied';
export const unoccupied = 'Unoccupied';
export const unavailable = 'Unavailable';
export const blocked = 'Blocked';

export const online = 'Online';
export const offline = 'Offline';
