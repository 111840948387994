<div [hidden]="!initialized" class="evses-status-table__main bdd-id--evses-status-table--main">
  <div class="evses-status-table__table-panel bdd-id--evses-status-table--table-panel">
    <div class="d-flex flex-column justify-content-between">
      <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
        <evses-status-filters class="bdd-id--evses-status-table--evses-status-filters order-1 order-md-0"
          (filtered)="onChangeEvseStatusFilter($event)">
        </evses-status-filters>

        <div class="d-flex flex-row align-items-center">
          <div class="charging-group-select dropdown-wrapper">
            <div class="d-flex flex-row align-items-center">
              <div class="select-wrapper bdd-id--evse-status-table--evse-status-selector mr-3">
                <mat-select
                  class="input-style full-size filter-select input-style bdd-id--facility-dashboard--evse-status-select"
                  [formControl]="selectedEvseStatuses"
                  (openedChange)="handleSelectOpenChanged($event, evseStatusFilterInput, evseStatusTextFilterControl)"
                  (selectionChange)="evseStatusChanged()" placeholder="EVSE Status" multiple>
                  <div class="p-2 position-relative">
                    <input #evseStatusFilterInput class="full-size" type="text" [formControl]="evseStatusTextFilterControl" />
                    <button *ngIf="evseStatusFilterInput.value.length > 0" class="btn bg-transparent clear-btn"
                      (click)="evseStatusFilterInput.value = ''">
                      <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="evses-status-table--charging-group-selector--select-all">
                    <mat-checkbox [(ngModel)]="allEvseStatusesSelected" [ngModelOptions]="{standalone: true}" (change)="selectAllEvseStatuses()">
                      Select All
                    </mat-checkbox>
                  </div>
    
                  <mat-option *ngFor="let es of evseStatuses" [value]="es.id"
                    [class.hide-option]="evseStatusFilterInput.value !== '' && es.name.toLowerCase().indexOf(evseStatusFilterInput.value.toLowerCase()) === -1">
                    {{ es.name }}
                  </mat-option>
                </mat-select>
              </div>
              <div class="select-wrapper bdd-id--evse-status-table--charging-group-selector" *ngIf="chargingGroups?.length > 0">
                <mat-select
                  class="input-style full-size filter-select input-style bdd-id--facility-dashboard--charging-group-select"
                  [formControl]="selectedChargingGroups"
                  (openedChange)="handleSelectOpenChanged($event, cgFilterInput, cgTextFilterControl)"
                  (selectionChange)="cgChanged()" placeholder="Charging Group" multiple>
                  <div class="p-2 position-relative">
                    <input #cgFilterInput class="full-size" type="text" [formControl]="cgTextFilterControl" />
                    <button *ngIf="cgFilterInput.value.length > 0" class="btn bg-transparent clear-btn"
                      (click)="cgFilterInput.value = ''">
                      <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="evses-status-table--charging-group-selector--select-all">
                    <mat-checkbox [(ngModel)]="allCGSelected" [ngModelOptions]="{standalone: true}" (change)="selectAllChargingGroups()" class="selectAllCg">
                      Select All
                    </mat-checkbox>
                  </div>
    
                  <mat-option *ngFor="let cg of chargingGroups" [value]="cg.chargingGroupId"
                    [class.hide-option]="cgFilterInput.value !== '' && cg.name.toLowerCase().indexOf(cgFilterInput.value.toLowerCase()) === -1">
                    {{ cg.name }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            
          </div>
          <div class="d-flex justify-content-between align-items-center order-0 order-md-2 mt-1 search-wrapper">
            <table-search class="bdd-id--evses-status-table--table-search mr-3 ml-3" [inlineStyle]="true"
              (searched)="onSearched($event)">
            </table-search>
  
            <settings-icon class="d-none d-md-block" (click)="openSettingsDialog()"></settings-icon>
          </div>
        </div>
      </div>

      <div class="evses-status-table__table-filters d-none d-md-flex">
        <table-filters class="bdd-id--evses-status-table--table-filters" [tableFilterConfig]="tableFilterConfig"
          [filters]="evseTableFilters" (updatedFilters)="onChangeEvseTableFilters($event)"></table-filters>
      </div>

      <div *ngIf="dataSource.filteredData.length === 0 && initialized; then renderNoDataMessage else renderDataTable"></div>

      <ng-template #renderNoDataMessage>
        <div *ngIf="dataSource.filteredData.length === 0"
          class="no-data-row d-flex justify-content-center align-items-center bdd-id--evses-status-table--no-data-row">
          No EVSEs to display
        </div>
      </ng-template>

      <ng-template #renderDataTable>
        <table-wrapper [hidden]="!initialized" [dataSource]="dataSource" [tableColumns]="tableColumns" [pageSize]="pageSize"
          [isMobile]="isMobileSize" isCondensed="true" class="bdd-id--evses-status-table--table-wrapper" #table>
          <ng-container matColumnDef="customRow">
            <mat-cell *matCellDef="let element"
              [ngClass]="{'isPluggingIn': element.isAnimated && element.isPluggedIn, 'isPluggingOut': element.isAnimated && !element.isPluggedIn}">
              <evses-status-table-row class="bdd-id--evses-status-table--evses-status-table-row"
                [tableColumns]="tableColumns" [evseView]="element">
              </evses-status-table-row>
            </mat-cell>
          </ng-container>
        </table-wrapper>
      </ng-template>
    </div>
  </div>
</div>