import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {EvseState, EvState, FacilityState} from '../models/entity-states';
import {handleError} from '../utils/errorHandling';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FacilityStateService {
  private readonly url = environment.api.fsas.http.uri;

  constructor(
    private http: HttpClient,
  ) {
  }

  public getFacilityStateById(facilityId: number, includeEvStates = false) {
    const url = `${this.url}/facilities/${facilityId}` + (includeEvStates ? '?evStates=true' : '');

    return this.http.get<FacilityState>(url)
      .pipe(
        catchError(() => of(null)),
        map((facilityState: FacilityState) => {
          if (!facilityState) {
            return {evsesState: []} as FacilityState;
          }

          // Copy SOC from evseState to stateOfChargeInPercent to use it if we don't have evState
          facilityState.evsesState.forEach(evseState => evseState.stateOfChargeInPercent = evseState.stateOfCharge);

          if (includeEvStates && facilityState.evState?.length > 0) {
            facilityState.evsesState.forEach(evseState => {
              const evState = facilityState.evState.find(x => x.evseId === evseState.evseId && x.sessionId === evseState.sessionId);

              if (evState) {
                evseState.stateOfChargeInPercent = evState.stateOfChargeInPercent;
                evseState.stateOfChargeType = evState.stateOfChargeType;
              }
            });
          }

          facilityState.evsesState.forEach(evseState => {
            evseState.timestamp = new Date(evseState.timestamp);
          });

          return facilityState;
        }),
      );
  }

  public getEvseStateByFacilityIdAndEvseId(facilityId: number, evseId: number): Observable<EvseState | HttpErrorResponse | undefined> {
    const url = `${this.url}/facilities/${facilityId}/evses/${evseId}`;

    return this.http.get<EvseState>(url)
      .pipe(
        map((evseState: EvseState) => {
          if (!evseState) {
            return;
          }

          evseState.timestamp = new Date(evseState.timestamp);

          return evseState;
        }),
        catchError(handleError<EvseState>('getEvseStateByFacilityIdAndEvseId')),
      );
  }

  public getEvStateByFacilityIdAndEvId(facilityId: number, evId: number): Observable<EvState | HttpErrorResponse | undefined> {
    const url = `${this.url}/facilities/${facilityId}/electric-vehicles/${evId}`;

    return this.http.get<EvState>(url)
      .pipe(
        map((evState: EvState) => {
          if (!evState) {
            return;
          }

          evState.timestamp = new Date(evState.timestamp);

          return evState;
        }),
        catchError(handleError<EvState>('getEvStateByFacilityIdAndEvseId')),
      );
  }
}
