import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {MatSelectModule} from '@angular/material/select';
import {CustomUnitPipeModule} from '../../../shared/pipes/custom-unit/custom-unit-pipe.module';
import {ChargingStationPopupComponent} from './charging-station-popup.component';
import {TooltipsModule} from '../../../shared/components/tooltips/tooltips.module';
import {NoValueDashPipeModule} from '../../../shared/pipes/no-value-dash/no-value-dash-pipe.module';
import {MatIconModule} from '@angular/material/icon';
import {SvgIconModule} from '../../../shared/components/svg-icon/svg-icon.module';

@NgModule({
  declarations: [
    ChargingStationPopupComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    MatIconModule,
    CustomUnitPipeModule,
    TooltipsModule,
    NoValueDashPipeModule,
    SvgIconModule,
  ],
  exports: [
    ChargingStationPopupComponent,
  ],
})
export class ChargingStationPopupModule {
}
