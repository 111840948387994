import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {catchError, filter, map, takeUntil, tap} from 'rxjs/operators';
import {Auth} from '@aws-amplify/auth';

import {AllDataStreamType, DataDistributor} from '../../services/data-distributor.service';
import {AssetManagerService} from '../../../shared/services/asset-manager.service';
import {ChargingStation, Facility} from '../../../shared/models/entities';
import {FacilityStateService} from '../../../shared/services/facility-state.service';
import {
  calculateMeta,
  convertToExpandedFacilityState,
  EvseState,
  ExpandedFacilityState,
  updateCalculatedMetaHistory,
} from '../../../shared/models/entity-states';
import {isEvseState, isFacilityHeadroom} from '../../../shared/utils/typeChecks';
import {FacilityConnectionHandlerService} from '../../services/facility-connection-handler.service';
import {ChargingGroup} from '../../../shared/models/entities/charging-group.model';
import {UntypedFormControl} from '@angular/forms';
import {OptimisationUnit} from '../../../shared/models/enums';
import {FacilityViewChargingStation, FvData} from '../../../facility-view/models';
import {constructOfflineData, isCsOffline} from '../../../facility-view/utils/offline-status.util';
import {constructEvseNotificationStateData} from '../../../facility-view/utils';
import {VehicleGroup} from '../../../shared/models/entities/vehicle-group.model';

@Component({
  selector: 'facility-dashboard',
  templateUrl: './facility-dashboard.component.html',
  styleUrls: ['./facility-dashboard.component.scss'],
})
export class FacilityDashboardComponent implements OnInit, OnDestroy {
  selectedFacility: Facility;
  facilities: Facility[] = [];
  vehicleGroups: VehicleGroup[];
  chargingGroups: ChargingGroup[];
  chargingStations: ChargingStation[];
  csList: FacilityViewChargingStation[] = [];
  selectedChargingGroup: ChargingGroup;
  facilityStateCache: Map<number, ExpandedFacilityState> = new Map();
  selectedFacilityState: ExpandedFacilityState = undefined;
  facilityStateLoaded = false;
  optimisationUnit: OptimisationUnit;

  public offlineData = [];
  public filterableCsData = [];

  public cgControl = new UntypedFormControl();
  public cgTextFilterControl = new UntypedFormControl();

  private token: string;

  private destroyed$ = new Subject<void>();

  constructor(
    private assetService: AssetManagerService,
    private stateService: FacilityStateService,
    private dataDistributor: DataDistributor,
    private route: ActivatedRoute,
    private facilityConnectionHandler: FacilityConnectionHandlerService,
  ) {
  }

  public async ngOnInit(): Promise<void> {
    const userData = await Auth.currentAuthenticatedUser();
    this.token = userData.signInUserSession.idToken.jwtToken;

    this.facilityStateLoaded = false;
    this.initialiseFacilities();
    this.initialiseDataHandler();

    this.destroyed$.subscribe(() => {
      this.facilityConnectionHandler.closeSocket();
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  private initialiseFacilities() {
    this.assetService
      .getAllFacilities()
      .pipe(catchError(() => of([])))
      .subscribe(facilities => {
        this.facilities = facilities;

        if (this.facilities?.length > 0) {
          this.selectFacility(Number(this.route.parent.snapshot.paramMap.get('facilityId')));

          this.route.parent.paramMap
            .pipe(takeUntil(this.destroyed$))
            .subscribe(params => {
              const facilityId = Number(params.get('facilityId'));
              if (facilityId !== this.selectedFacility?.facilityId) {
                this.selectFacility(facilityId);
              }
            });
        } else {
          this.facilityConnectionHandler.closeSocket(); // guarding for an open socket
        }
      });
  }

  private initialiseDataHandler() {
    this.dataDistributor.allData()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({name, data}: AllDataStreamType) => {
        const facilityId = data.facilityId; // as there is a chance that when changing a selected Facility, the socket still
        // receives facilityHeadroom messages that belong to the previously selected Facility

        let shouldUpdateHeadroom = false;

        const currentExpandedFacilityState = this.facilityStateCache.get(facilityId);
        const newExpandedFacilityState = Object.assign({}, currentExpandedFacilityState);

        if (name === 'facilityHeadroomChanged' && isFacilityHeadroom(data)) {
          newExpandedFacilityState.facilityHeadroom = Object.assign({}, data);

          shouldUpdateHeadroom = true;
        } else if (name === 'evseChanged' && isEvseState(data)) {
          newExpandedFacilityState
            .evsesState
            ?.splice(newExpandedFacilityState.evsesState.findIndex(e => e.evseId === data.evseId), 1, data);

          this.updateCsList(data);
        }

        const updatedCalculatedMeta = calculateMeta(newExpandedFacilityState, this.chargingGroups);

        newExpandedFacilityState.calculatedMeta = updatedCalculatedMeta;

        if (shouldUpdateHeadroom) {
          updateCalculatedMetaHistory(newExpandedFacilityState, updatedCalculatedMeta);
        }

        if (this.selectedFacility.facilityId === facilityId) {
          this.selectedFacilityState = newExpandedFacilityState;
        }

        this.facilityStateCache.set(facilityId, newExpandedFacilityState);
      });
  }

  private selectFacility(facilityId: number) {
    this.facilityStateLoaded = false;
    this.chargingGroups = null;
    this.vehicleGroups = null;
    this.selectedChargingGroup = null;
    this.optimisationUnit = null;
    this.selectedFacility = Object.assign({}, this.facilities.find(f => f.facilityId === facilityId));

    const chargingStationsObs = this.assetService
      .getAllChargingStationsByFacilityId(facilityId)
      .pipe(
        catchError(() => of([])),
      );

    forkJoin({
      vehicleGroups:  this.assetService.getVehicleGroupsByFacilityId(facilityId),
      chargingGroups:  this.assetService.getChargingGroupsByFacilityId(facilityId),
      chargingStations: chargingStationsObs,
      facilityOptimisationSettings: this.assetService.getFacilityOptimisationSettingsByFacilityId(facilityId),
    })
    .subscribe(({ vehicleGroups, chargingGroups, chargingStations, facilityOptimisationSettings }) => {
        this.vehicleGroups = vehicleGroups;
        this.chargingGroups = chargingGroups;
        this.chargingStations = chargingStations;

        this.optimisationUnit = facilityOptimisationSettings?.optimisationUnit == 'amps' ? OptimisationUnit.amps : OptimisationUnit.kW;

        this.populateFacilityState(facilityId).subscribe(expandedFacilityState => {
          this.selectedFacilityState = Object.assign({}, expandedFacilityState);
          this.facilityStateLoaded = true;
        });

        this.facilityConnectionHandler.connectToSocket(facilityId, this.token);
    });
  }

  private populateFacilityState(facilityId: number): Observable<ExpandedFacilityState> {
    return this.stateService
      .getFacilityStateById(facilityId, true)
      .pipe(
        tap(facilityState => {
          if (facilityState?.evsesState?.length === 0) {
            this.createCsList([]);
            this.facilityStateLoaded = true;
          }
        }),
        map(facilityState => {
          const expandedFacilityState: ExpandedFacilityState = convertToExpandedFacilityState(facilityState, this.chargingGroups);

          this.facilityStateCache.set(facilityId, expandedFacilityState);
          this.createCsList(facilityState.evsesState);

          return expandedFacilityState;
        }),
      );
  }

  private createCsList(evsesState: EvseState[]) {
    this.csList = [];
    this.chargingStations
      .forEach(cs => {
        let chargingGroup;
        if (cs.chargingGroupId) {
          chargingGroup = this.chargingGroups.find(x => x.chargingGroupId === cs.chargingGroupId);
        }
        const optimisationUnit = chargingGroup?.optimisationUnit || this.optimisationUnit;

        const fvcs = {
          chargingStation: cs,
          evses: cs.evSupplyEquipments.map(x => x ? new FvData({evse: x, cs}) : undefined),
          optimisationUnit: optimisationUnit === 'amps' ? OptimisationUnit.amps : OptimisationUnit.kW,
        };
        this.csList.push(fvcs);
      });

    (evsesState || [])
      .forEach(evseState => {
        const fvcs = this.csList.find(x => x.chargingStation.chargingStationId === evseState.chargingStationId);
        if (!fvcs) return;
        const chargingStation = fvcs.chargingStation;
        const evseIndex = chargingStation.evSupplyEquipments.findIndex(evse => evse.evseId === evseState.evseId);
        const fvData = fvcs.evses[evseIndex];

        if (!fvData) return console.debug(`Could not match an evseState: ${evseState.evseId} to evse's within a charging station ${chargingStation.chargingStationId}`);

        fvData.set('evseState', evseState);
      });

      this.constructInfoAndFilters();
  }

  private updateCsList(evseState: EvseState) {
    const fvcs = this.csList.find(x => x.chargingStation.chargingStationId === evseState.chargingStationId);

    if (!fvcs) return console.debug('received evseState for a non-existent charging station: ', evseState.chargingStationId);

    const evseIndex = fvcs.evses.findIndex(x => x.evse.evseId === evseState.evseId);
    const fvData = fvcs[evseIndex];

    if (!fvData) return console.debug('No present model for this evseState', evseState);

    fvData.set('evseState', evseState);
    this.constructInfoAndFilters();
  }

  private constructInfoAndFilters() {
    const filteredCsList = this.csList.filter(x => !isCsOffline(x));
    const statusData = constructEvseNotificationStateData(filteredCsList).filter(x => !!x.value);
    this.addItemToAlignSecondColumnOfPieChartToTop(statusData);
    this.filterableCsData = statusData;
    this.offlineData = constructOfflineData(this.csList);
  }

  private addItemToAlignSecondColumnOfPieChartToTop(statusData: any) {
    if (statusData.length > 2 && statusData.length % 2 === 1) {
      statusData.push({
        name: '',
        value: null,
        color: '#00000000'
      });
    }
  }

  public handleSelectOpenChanged(value: boolean, elementRef: HTMLElement, control: UntypedFormControl) {
    if (value === true && !control.value) {
      elementRef.focus();
    }
  }
}
