import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatColumnDef, MatHeaderRowDef, MatNoDataRow, MatRowDef, MatTable, MatTableDataSource} from '@angular/material/table';

import {TableColumn} from '../table-column.model';

@Component({
  selector: 'table-wrapper',
  templateUrl: 'table-wrapper.component.html',
  styleUrls: ['./table-wrapper.component.scss'],
})
export class TableWrapperComponent<T> implements AfterContentInit, OnChanges {
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ContentChild(MatNoDataRow) noDataRow: MatNoDataRow;

  @ViewChild(MatTable, {static: true}) table: MatTable<T>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input() isCondensed = false;
  @Input() showPaginator = true;
  @Input() pageSize = 10;
  @Input() dataSource: MatTableDataSource<T>;
  @Input() tableColumns: TableColumn[];
  @Input() isMobile: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  public ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
    this.table.setNoDataRow(this.noDataRow);

    this.changeDetectorRef.detectChanges();

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSource && changes.dataSource.previousValue !== changes.dataSource.currentValue && this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
  }

  public renderRows() {
    this.table.renderRows();
  }

  public onSorted(column: string) {
    this.sort.sort(({id: column, start: 'asc'}) as MatSortable);
    this.dataSource.sort = this.sort;
  }

  public onChangedPageNo(newPageNo: number) {
    if (!this.paginator) {
      return;
    }

    this.paginator.pageIndex = newPageNo - 1;
    this.paginator.page.next({
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length,
    });
  }
}
