import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
})
export class TablePaginatorComponent implements OnInit, OnChanges {
  @Input() pageSize: number;
  @Input() itemsCount: number;
  @Input() matPaginator: MatPaginator;
  @Output() changedPageNo = new EventEmitter<number>();

  firstPageNo: number;
  chosenPageNo: number;
  maxPageCount: number;

  ngOnInit(): void {
    this.firstPageNo = 1;
    this.chosenPageNo = 1;
  }

  public ngOnChanges(changes: { [key: string]: SimpleChange }) {

    // refresh when
    // 1/ and new table of data gets set up and the new paginator for that table is ready, OR
    // 2/ the (filtered) number of items for the given table changes
    const paginatorCheck = changes.matPaginator && !changes.matPaginator.previousValue && changes.matPaginator.currentValue;
    if (
      (paginatorCheck && this.itemsCount && this.pageSize) || (this.matPaginator && (changes.itemsCount || changes.pageSize))
    ) {
      // count the number of pages that will be needed to display the given number of items, round up the number of pages to at least 1
      this.maxPageCount = Math.max(Math.ceil(this.itemsCount / this.pageSize), 1);

      // set the first
      this.firstPageNo = Math.max(Math.min(this.firstPageNo, this.maxPageCount - 4), 1);

      this.selectPage(Math.max(Math.min(this.chosenPageNo, this.maxPageCount), 1));
    }
  }

  public selectPage(newPageNo) {
    this.chosenPageNo = newPageNo;
    this.changedPageNo.emit(this.chosenPageNo);
  }

  public moveForward() {
    this.firstPageNo = Math.min(this.firstPageNo + 1, this.maxPageCount);
    this.selectPage(Math.min(this.chosenPageNo + 1, this.maxPageCount));
  }

  public moveBack() {
    this.firstPageNo = Math.max(this.firstPageNo - 1, 1);
    this.selectPage(Math.max(this.chosenPageNo - 1, 1));
  }
}
