<div *ngIf="fvChargingStation" class="cs-info-popup">
  <div class="d-flex flex-column align-items-center" [ngClass]="{'align-items-center': fvChargingStation.evses?.length > 1}">
    <div class="charging-station-data">
      <div>
        <span class="field">Charging Station Name: </span>
        <span class="value">
          <a *ngIf="showAssetsLinks; else csName" href="#" (click)="navigateToChargingStationPage($event)">
            {{fvChargingStation.chargingStation.name}}
          </a>
          <ng-template #csName>
            {{fvChargingStation.chargingStation.name}}
          </ng-template>
        </span>
      </div>
      <div *ngIf="isCsOffline(fvChargingStation)">
        <span class="field">Offline: </span>
        <span class="value">A connection was not established with this charging station</span>
      </div>
      <div>
        <span class="field">Last Updated: </span>
        <span class="value">{{lastUpdated}}</span>
      </div>
    </div>
  </div>

  <div class="evse-list-wrapper">
    <div *ngFor="let fvData of evses" class="mt-2 w-100">
      <div>
        <span class="field">EVSE ID: </span>
        <span class="value">{{fvData.evse.extEvseId}}
          <i class="fa-solid fa-arrow-up priority-icon" *ngIf="fvData.evse.chargingPriority === 1"></i>
          <div class="charge-box {{fvData.state}}" [ngClass]="{'offline': isCsOffline(fvChargingStation)}">
            <svg-icon type="plug" class=" plug-icon"></svg-icon>
          </div>
        </span>
      </div>
      <div class="data-row" *ngIf="fvData?.evseState?.isPluggedIn && (fvData?.evseState?.power === 0 || fvData?.evseState?.power)">
        <span class="field">Power: </span>
        <span class="value">{{fvData?.evseState?.power | number: '1.0-2'| customUnit: 'kW'| dashWhenNoValue}}</span>
      </div>
      <div class="data-row" *ngIf="fvData?.evseState?.isPluggedIn && (fvData?.evseState?.current === 0 || fvData?.evseState?.current)">
        <span class="field">Current: </span>
        <span class="value">{{fvData?.evseState?.current | number: '1.0-2'| customUnit: 'A'| dashWhenNoValue}}</span>
      </div>
      <div class="data-row" *ngIf="fvData?.evseState?.isPluggedIn && (fvData?.evseState?.energy || fvData?.evseState?.energy===0)">
        <span class="field">Energy: </span>
        <span class="value">
          <span
            *ngIf="(fvData?.evseState?.energy || fvData?.evseState?.energy===0);
            else naSoc">{{fvData?.evseState?.energy | number: '1.0-2' | customUnit: 'kWh'}}</span>
        </span>
      </div>
      <div class="data-row" *ngIf="fvData?.evseState?.isPluggedIn">
        <span class="field">SoC: </span>
        <span class="value">
          <span
            *ngIf="(fvData?.meta?.stateOfChargeInPercent || fvData?.meta?.stateOfChargeInPercent===0);
            else naSoc">
              {{fvData?.meta.stateOfChargeInPercent | number: '1.0-2' | customUnit: '%'}}

              <div class="d-inline-block"
                [class.unknown]="!fvData || fvData?.evseState?.stateOfChargeType === 'Unknown'"
                [class.low]="fvData?.meta?.stateOfChargeInPercent <= 33"
                [class.medium]="fvData?.meta?.stateOfChargeInPercent > 33 && fvData?.meta?.stateOfChargeInPercent < 66"
                [class.high]="fvData?.meta?.stateOfChargeInPercent >= 66">
                <div class="soc-bar">
                  <div class="soc-percentage" [style.width.%]="fvData?.meta?.stateOfChargeInPercent"></div>
                </div>
              </div>
          </span>
        </span>
      </div>
      <div class="data-row" *ngIf="fvData?.evseState?.isPluggedIn && (fvData?.evseState.setpoint || fvData?.evseState.setpoint===0)">
        <span class="field">Setpoint: </span>
        <span class="value">{{fvData?.evseState.setpoint | number: '1.0-2' | customUnit: fvChargingStation.optimisationUnit}}</span>
      </div>
      <div *ngIf="fvData?.evseState?.isPluggedIn">
        <div class="data-row">
          <span class="field">Vehicle: </span>
          <span class="value">
            <a *ngIf="showAssetsLinks && fvData?.vehicle?.evIdentifier; else ev" href="#" 
            (click)="navigateToVehiclePage($event, fvData?.vehicle?.evId)">
              {{ fvData?.vehicle?.evIdentifier }}
            </a>
            <ng-template #ev>{{fvData?.vehicle?.evIdentifier || 'Unregistered EV'}}</ng-template>
            <span *ngIf="fvData?.meta.isDuplicate">†</span>
            <i class="fa-solid fa-arrow-up priority-icon" *ngIf="fvData?.vehicle?.chargingPriority === 1"></i>
          </span>
        </div>
        <div class="data-row" *ngIf="fvData?.meta?.estimatedMileage">
          <span class="field">Mileage*: </span>
          <span class="value">{{fvData?.meta?.estimatedMileage | number: '1.0-2' | customUnit: 'mi.'}}</span>
        </div>
        <div class="data-row" *ngIf="fvData?.meta?.estimatedTimeToFullCharge">
          <span class="field">Time to 100%*: </span>
          <span class="value">{{fvData?.meta.estimatedTimeToFullCharge}}</span>
        </div>
      </div>
    </div>
  </div>
  

  <div *ngIf="hasDuplicates">
    † - Vehicle appears twice due to missing EVSE data
  </div>
</div>

<ng-template #naSoc>N/A</ng-template>
