export interface Color {
  r: number,
  g: number,
  b: number
}

/**
 * Calculates what colour would appear on a linear gradient between the two given colours at the given position (0-1)
 * @param color1 The first colour
 * @param color2 The Second colour
 * @param position Must be a real number between 0 and 1 representing the position of the colour (from left to right) that is returned
 * @return A Color object that would appear on that position or undefined if called erroneously
 */
export function calculateColorOnLinearGradient(color1: Color, color2: Color, position: number): Color | undefined {
  if (position < 0 || position > 1) return undefined;
  const r = color1.r + position * (color2.r - color1.r);
  const g = color1.g + position * (color2.g - color1.g);
  const b = color1.b + position * (color2.b - color1.b);
  return {r, g, b};
}

export function colorToStringRgb({r, g, b}: Color) {
  return `rgb(${r}, ${g}, ${b})`;
}

// https://gka.github.io/palettes/#/18|d|19439c,ffe068,cfcfcf|95a800,ff005e,17439b|1|1
export const colourPalette = [
  '#00bbf9', '#f94144', '#ffbe0b',
  '#f15bb5', '#fb5607', '#8338ec',
  '#00f5d4', '#e76f51', '#264653',
  '#577590', '#9b5de5', '#e9c46a',
  '#43aa8b', '#f9844a', '#277da1',
  '#f9c74f', '#ff006e', '#3a86ff',
  '#fee440', '#2a9d8f', '#f8961e',
  '#90be6d', '#f3722c', '#4d908e',
  '#f4a261',
];

export const getNextColour = (existingColours: string[], colourId = 0) => {
  if (!existingColours?.length) {
    return getColour(colourId);
  }

  // Pick the first available colour
  const availableColours = colourPalette.filter(colour => !existingColours.includes(colour));
  if (availableColours.length) {
    return availableColours[0];
  }

  // Get the last colour and try to return the next in the palette
  const lastColour = [...existingColours].reverse().find(colour => colourPalette.includes(colour));
  if (lastColour) {
    const lastColourIndex = colourPalette.findIndex(colour => colour === lastColour);

    return getColour(lastColourIndex + 1);
  }

  return getColour(colourId);
};

export const getColour = (id: number) => colourPalette[id % colourPalette.length];
