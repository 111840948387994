import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {NavigationHelperService} from '../../../shared/services/navigation-helper.service';
import {FeatureFlagService} from '../../../shared/services/feature-flag.service';

export type TabName =
  'home'
  | 'fleet-location'
  | 'facility-view'
  | 'facilities'
  | 'chargers'
  | 'vehicles'
  | 'sessions'
  | 'configurations'
  | 'schedules'
  | 'users'
  | 'analytics'
  | 'tenants'
  | 'profile'
  | 'logout';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() signOut = new EventEmitter<string>();
  @Input() signedIn = false;

  public selectedTab: string;
  public selectedTabDisplayName: string;
  public isMenuCollapsed = true;

  private selectedFacility: string;
  private sendSetpointToEdrvFlagValue = true;
  private listOfTabsToShowBanner = ['home', 'fleet-location', 'facility-view', 'sessions', 'analytics'];

  public destroy$ = new Subject<void>();

  // map used to determine which tab should be selected on the startup
  childUrlSegmentToTabNameMap: Map<string, TabName> = new Map([
    ['dashboard', 'home'],
    ['fleet-location', 'fleet-location'],
    ['facility-view', 'facility-view'],
    ['facilities', 'facilities'],
    ['chargers', 'chargers'],
    ['vehicles', 'vehicles'],
    ['sessions', 'sessions'],
    ['configurations', 'configurations'],
    ['schedules', 'schedules'],
    ['users', 'users'],
    ['analytics', 'analytics'],
    ['profile', 'profile'],
  ]);

  // map used to determine which route the user should be redirected when clicking on each tab
  tabNameToRedirectionUrlMap: Map<TabName, { path: string, redirectToFacility: boolean }> = new Map([
    ['home', {path: 'dashboard', redirectToFacility: true}],
    ['fleet-location', {path: 'fleet-location', redirectToFacility: true}],
    ['facility-view', {path: 'facility-view', redirectToFacility: true}],
    ['facilities', {path: 'facilities', redirectToFacility: false}],
    ['chargers', {path: 'chargers/facility-charging-stations', redirectToFacility: true}],
    ['vehicles', {path: 'vehicles/facility-vehicles', redirectToFacility: true}],
    ['sessions', {path: 'sessions', redirectToFacility: true}],
    ['configurations', {path: 'configurations/charging-groups', redirectToFacility: true}],
    ['schedules', {path: 'schedules/fleet-scheduler', redirectToFacility: true}],
    ['users', {path: 'users', redirectToFacility: false}],
    ['analytics', {path: 'analytics', redirectToFacility: true}],
    ['profile', {path: 'profile', redirectToFacility: false}],
  ]);

  // map used to determine which tab should be selected on the startup
  tabNameToTabDisplayNameMap: Map<string, string> = new Map([
    ['home', 'Dashboard'],
    ['fleet-location', 'Fleet Location'],
    ['facility-view', 'Facility View'],
    ['facilities', 'Facilities'],
    ['chargers', 'Chargers'],
    ['vehicles', 'Vehicles'],
    ['sessions', 'Sessions'],
    ['configurations', 'Configurations'],
    ['schedules', 'Schedules'],
    ['users', 'Users'],
    ['analytics', 'Analytics'],
    ['profile', 'Profile'],
    ['logout', 'Logout'],
  ]);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationHelper: NavigationHelperService,
    private featureFlagService: FeatureFlagService,
  ) {
  }

  public ngOnInit() {
    this.updateSelectedTab();
    this.checkSendSetpointToEdrvFlagValue();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateSelectedTab();
        this.checkSendSetpointToEdrvFlagValue();
      });

  }

  private checkSendSetpointToEdrvFlagValue() {
    let route = this.route.root;
    
    while (route.firstChild) {
      route = route.firstChild;
    }

    const facilityId = route.snapshot.paramMap.get('facilityId');

    if (facilityId && facilityId !== this.selectedFacility) {
      this.featureFlagService.changeContext({facility_id: facilityId}).then(() => {
        this.featureFlagService.getFlag('send-setpoint-to-edrv').subscribe(flagValue => {
          this.sendSetpointToEdrvFlagValue = flagValue;
        });
      });
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public selectTab(tabName: TabName) {
    if (tabName === 'logout') {
      this.signOut.emit('signOut');
      return;
    }

    const facilityId = this.navigationHelper.searchForParamInAllChildComponents(this.route, 'facilityId');
    const redirectionDetails = this.tabNameToRedirectionUrlMap.get(tabName);
    const redirectionPath = (facilityId && redirectionDetails.redirectToFacility)
      ? redirectionDetails.path + '/' + facilityId
      : redirectionDetails.path;

    this.router.navigate([redirectionPath], {relativeTo: this.route})
      .then(() =>  {
        this.selectedTab = tabName;
        this.selectedTabDisplayName = this.tabNameToTabDisplayNameMap.get(tabName);
        this.isMenuCollapsed = true;
      });
  }

  public updateSelectedTab() {
    const childUrlSnapshot = this.route.firstChild.snapshot.url[0]?.path;
    this.selectedTab = childUrlSnapshot ? this.childUrlSegmentToTabNameMap.get(childUrlSnapshot) : 'home';
    this.selectedTabDisplayName = this.tabNameToTabDisplayNameMap.get(this.selectedTab);
  }

  public selectDropdownItem(event: MouseEvent, dropdownName: string) {
    event.stopPropagation();

    if (dropdownName === 'fleet-location') {
      const facilityId = this.navigationHelper.searchForParamInAllChildComponents(this.route, 'facilityId');

      const path = facilityId
        ? `dashboard/${facilityId}/fleet-location`
        : 'dashboard/fleet-location';

      this.navigationHelper.navigateToTenantPath(path);
    }
  }

  public get showBModeBanner(): boolean {
    return !this.sendSetpointToEdrvFlagValue && this.listOfTabsToShowBanner.includes(this.selectedTab);
  }
}
