import {EvState} from '../../shared/models/entity-states';
import {Vehicle} from '../../shared/models/entities';
import {FacilityViewChargingStation, FvData} from '../models';
import {highSoc, lowSoc, mediumSoc, unknownSoc} from '../consts/text.consts';
import {highSocColour, lowSocColour, mediumSocColour, unknownSocColour} from '../consts/colour.consts';

type WithSoc = { stateOfChargeInPercent?: number; stateOfChargeType?: string }

export function isHighSoc({stateOfChargeInPercent}: WithSoc) {
  return !Number.isNaN(stateOfChargeInPercent) && stateOfChargeInPercent >= 66;
}

export function isMedSoc({stateOfChargeInPercent}: WithSoc) {
  return !Number.isNaN(stateOfChargeInPercent) && (stateOfChargeInPercent > 33 && stateOfChargeInPercent < 66);
}

export function isLowSoc({stateOfChargeInPercent}: WithSoc) {
  return !Number.isNaN(stateOfChargeInPercent) && stateOfChargeInPercent <= 33;
}

export type SocType = typeof highSoc | typeof mediumSoc | typeof lowSoc | typeof unknownSoc;

export function getSocType(fvData: FvData): undefined | SocType {
  if (!fvData) return;
  if (fvData.evState && fvData.evState?.stateOfChargeType === 'Unknown') return unknownSoc;
  else if (isHighSoc(fvData.meta)) return highSoc;
  else if (isMedSoc(fvData.meta)) return mediumSoc;
  else if (isLowSoc(fvData.meta)) return lowSoc;
}

export function toPerc(vehicle: Vehicle, state?: EvState): number | undefined {
  if (!vehicle || isNaN(state?.stateOfCharge)) return;

  return Math.round((state.stateOfCharge / vehicle.batteryCapacity) * 10000) / 100;
}

export function constructSocData(//return type
  csList: FacilityViewChargingStation[] | { [key: number]: FacilityViewChargingStation },
  vehicleList?: FvData[],
  outOfZone?: FvData[],
  asMap?: boolean, //make obj params
) {
  const soc = {
    [highSoc]: {value: 0, color: highSocColour, description: '(≥66%)'},
    [mediumSoc]: {value: 0, color: mediumSocColour, description: '(33-66%)'},
    [lowSoc]: {value: 0, color: lowSocColour, description: '(≤33%)'},
    [unknownSoc]: {value: 0, color: unknownSocColour},
  };

  const accumulateStatuses = (fvData?: FvData) => {
    if (!fvData) return;
    const socType = getSocType(fvData);
    if (socType) soc[socType].value++;
  };

  if (csList) {
    Object
      .values(csList)
      .forEach(fvcs => {
        fvcs.evses.forEach(x => {
          accumulateStatuses(x)
        });
      });
  }

  vehicleList?.forEach(fvData => accumulateStatuses(fvData));
  outOfZone?.forEach(fvData => accumulateStatuses(fvData));

  if (asMap) return soc;
  else return Object.keys(soc).map(key => ({name: key, ...soc[key]})) as any; //fix any
}
