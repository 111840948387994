import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SvgIconComponent implements OnInit {
  @Input() type = '';
  @HostBinding('class') class = '';

  public link = '';

  public ngOnInit() {
    this.link = `/assets/svg-sprites/svg-sprites.svg#${this.type}`;
    this.class = `icon-${this.type} svg-${this.type}-dims`;
  }
}
