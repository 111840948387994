import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';

import {TableHeaderComponent} from './table-header/table-header.component';
import {TablePaginatorComponent} from './table-paginator/table-paginator.component';
import {TableSearchComponent} from './table-search/table-search.component';
import {TableHeaderCellComponent} from './table-header/table-header-cell/table-header-cell.component';
import {TableWrapperComponent} from './table-wrapper/table-wrapper.component';
import {TooltipsModule} from '../tooltips/tooltips.module';
import {SvgIconModule} from '../svg-icon/svg-icon.module';

@NgModule({
  declarations: [
    TableHeaderComponent,
    TableHeaderCellComponent,
    TablePaginatorComponent,
    TableSearchComponent,
    TableWrapperComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    TooltipsModule,
    SvgIconModule,
  ],
  exports: [
    TableHeaderComponent,
    TableHeaderCellComponent,
    TablePaginatorComponent,
    TableSearchComponent,
    TableWrapperComponent,
  ],
})
export class TableAddonsModule {
}
