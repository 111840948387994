import {FacilityHeadroom} from './facility-headroom.model';
import {EvseState} from './evse-state.model';
import {EvState} from './ev-state.model';
import {roundToTwoDecimalPlaces} from '../../utils/number';
import {OptimisationUnit} from '../enums';
import {ChargingGroup} from '../entities/charging-group.model';

export interface FacilityState {
  facilityHeadroom: FacilityHeadroom;
  evsesState: EvseState[];
  evState?: EvState[];
}

export interface ExpandedFacilityState extends FacilityState {
  calculatedMeta: FacilityCalculatedMeta[];
  calculatedMetaHistory: FacilityCalculatedMeta[][];
}

export interface FacilityCalculatedMeta {
  updatedAt: Date;
  facilityLoad: number;
  evLoad: number;
  totalBuffer: number;
  bufferUsed: number;
  bufferRemaining: number;
  spare: number;
  maxCapacity: number;
  chargingGroupId?: number;
  chargingGroupName?: string;
  unit?: OptimisationUnit;
}

export function calculateMetaForOneChargingGroup(facilityState: FacilityState | ExpandedFacilityState, chargingGroupId: number = -1, chargingGroupName?: string): FacilityCalculatedMeta {
  let calculatedMeta: FacilityCalculatedMeta;

  if (facilityState.facilityHeadroom && facilityState.evsesState) {
    const groupUnit = facilityState?.facilityHeadroom?.groups?.find(x => x.chargingGroupId === chargingGroupId)?.optimisationUnit;
    const unit = groupUnit === 'amps' ? OptimisationUnit.amps : OptimisationUnit.kW;
    const facilityLoad = roundToTwoDecimalPlaces(
      facilityState?.facilityHeadroom?.groups?.find(x => x.chargingGroupId === chargingGroupId)?.facilityLoad || 0,
    );
    const maxCapacity = facilityState?.facilityHeadroom?.groups?.find(x => x.chargingGroupId === chargingGroupId)?.maxCapacity || 0;

    const calculatePower = (evsesState: EvseState[]) =>
      evsesState?.filter(x => x.chargingGroupId === chargingGroupId).reduce((previousValue, currentValue) => previousValue + (currentValue.isPluggedIn ? currentValue.power : 0), 0);

      const calculateCurrent = (evsesState: EvseState[]) =>
      evsesState?.filter(x => x.chargingGroupId === chargingGroupId).reduce((previousValue, currentValue) => previousValue + (currentValue.isPluggedIn ? currentValue.current : 0), 0);

    const evLoad = facilityState?.evsesState?.length > 0
      ? roundToTwoDecimalPlaces(unit == OptimisationUnit.amps ? calculateCurrent(facilityState?.evsesState) : calculatePower(facilityState?.evsesState))
      : 0;

    const totalBuffer = facilityState?.facilityHeadroom?.groups?.find(x => x.chargingGroupId === chargingGroupId)?.headroomBuffer || 0;

    let spare = roundToTwoDecimalPlaces(
      (maxCapacity - totalBuffer) - (evLoad + facilityLoad),
    );

    let bufferRemaining = totalBuffer;
    let bufferUsed = 0;

    if (spare <= 0) {
      bufferUsed = roundToTwoDecimalPlaces(Math.abs(spare));
      bufferRemaining = roundToTwoDecimalPlaces(totalBuffer - bufferUsed);
      spare = 0;
    }

    const updatedAt = new Date(Date.now());
    calculatedMeta = {updatedAt, facilityLoad, evLoad, totalBuffer, bufferUsed, bufferRemaining, spare, maxCapacity, chargingGroupId, unit, chargingGroupName};
  }

  return calculatedMeta;
}

export function calculateMeta(facilityState: FacilityState | ExpandedFacilityState, chargingGroups: ChargingGroup[]): FacilityCalculatedMeta[] {
  let calculatedMeta: FacilityCalculatedMeta[] = [];

  facilityState.facilityHeadroom?.groups.forEach(headroomGroup => {
    const cgName = chargingGroups.find(x => x.chargingGroupId == headroomGroup.chargingGroupId)?.name;
    calculatedMeta.push(calculateMetaForOneChargingGroup(facilityState, headroomGroup.chargingGroupId, cgName));
  })

  return calculatedMeta;
}

export function convertToExpandedFacilityState(facilityState: FacilityState | ExpandedFacilityState, chargingGroups: ChargingGroup[]): ExpandedFacilityState {
  const newFacilityState = {...facilityState};

  const calculatedMeta = calculateMeta(newFacilityState, chargingGroups);

  return {
    ...newFacilityState,
    calculatedMeta: calculatedMeta,
    calculatedMetaHistory: [calculatedMeta],
  };
}

export function updateCalculatedMetaHistory(facilityState: ExpandedFacilityState, calculatedMeta: FacilityCalculatedMeta[]) {
  const calculatedMetaHistory = facilityState.calculatedMetaHistory
    ? [...facilityState.calculatedMetaHistory, calculatedMeta]
    : [calculatedMeta];

  const filteredMetaHistory = calculatedMetaHistory.filter(item => {
    if (!item) {
      return false;
    }

    const oneHour = 60 * 60 * 1000;
    const now = (new Date()).valueOf();
    const updatedAt = (new Date(item[0].updatedAt)).valueOf();

    return (now - updatedAt) < oneHour;
  });

  facilityState.calculatedMetaHistory = filteredMetaHistory;
}
