<div
    class="facility-selection-item d-flex flex-row bdd-id--facility-selection-item"
    [class.facility-selection-item--collapsed]="isCollapsed"
    (click)="selectFacilityClick()"
    (mouseenter)="onMouseEnter($event)"
    (mouseleave)="onMouseLeave()"
>
    <span *ngIf="isCollapsed" class="facility-selection-item__collapsed-icon" [class.selected]="isSelected">
        <i class="material-icons" *ngIf="isDefaultFacility">home</i>
        <i class="material-icons-outlined" *ngIf="!isDefaultFacility">home</i>
    </span>

    <div class="facility-selection-item__container" [class.selected]="isSelected"
         [style.top.px]="isCollapsed && offsetY ? offsetY : 0">
        <div class="facility-selection-item__indicator {{state}}"></div>

        <div class="facility-selection-item__content">
            <div class="facility-selection-item__text my-auto" [tooltipTitle]="facility.name">
                {{ facility.name }}
            </div>

            <span
                *ngIf="showDefaultFacility"
                class="facility-selection-item__default-facility-option"
                [class.selected]="isDefaultFacility"
                (click)="selectDefaultFacility($event)"
                [tooltip]="!isDefaultFacility && defaultFacilityTooltip"
            >
                <i class="material-icons" *ngIf="isDefaultFacility">home</i>
                <i class="material-icons-outlined" *ngIf="!isDefaultFacility">home</i>
            </span>
        </div>
    </div>
</div>
