import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import mapboxgl, {GeoJSONSource, Marker, Popup} from 'mapbox-gl';
import {FeatureCollection as GeoJSONFeatureCollection} from 'geojson';
import {EMPTY, of, Subject} from 'rxjs';
import {pairwise, startWith, switchMap, takeUntil} from 'rxjs/operators';

import {FleetLocationState} from '../../shared/models/entity-states';
import {ChargingStation, Facility, FacilityZone} from '../../shared/models/entities';
import {MapHelperService} from '../../shared/services/map-helper.service';
import {createMapboxPopup, FleetLocationMapService} from './fleet-location-map.service';
import {FleetLocationVehiclePopupComponent, IsochroneData} from '../../shared/components/fleet-location-vehicle-popup/fleet-location-vehicle-popup.component';
import {FleetLocationMapFilterService} from '../fleet-location-map-filter/fleet-location-map-filter.service';
import {
  addChargingStationLayers,
  addChargingStationSourcesToMap,
  addEvseLayers,
  addEvseSourcesToMap,
  addFacilityMarker,
  addTransitionTextMarker,
  addVehiclesLayer,
  addVehicleSourcesToMap,
  clearFacilityZoneLayers,
  createMapObject,
  drawFacilityZone,
} from '../../shared/components/facility-map/facility-map.helpers';
import {NavigationHelperService} from '../../shared/services/navigation-helper.service';
import {FacilityViewChargingStation, FvData} from '../../facility-view/models';
import {ChargingStationPopupComponent} from '../../facility-view/components/charging-station-popup/charging-station-popup.component';

const vehiclesLabelsLayerId = 'vehicle_labels_layer';
const vehicleIconsLayerId = 'vehicle_icons_layer';
const csLayerId = 'cs_layer';
const evseLayerId = 'evse_layer';
const defaultMapStyle = 'mapbox://styles/mapbox/streets-v11';
const satelliteMapStyle = 'mapbox://styles/mapbox/satellite-streets-v11';

@Component({
  selector: 'fleet-location-map',
  templateUrl: './fleet-location-map.component.html',
  styleUrls: ['./fleet-location-map.component.scss'],
})
export class FleetLocationMapComponent implements OnInit, OnDestroy, OnChanges {
  @Input() facility: Facility;
  @Input() chargingStations: ChargingStation[];
  @Input() facilityZones: FacilityZone[];
  @Input() state: FleetLocationState;

  @ViewChild(FleetLocationVehiclePopupComponent) popupContainer: FleetLocationVehiclePopupComponent;
  @ViewChild(ChargingStationPopupComponent) csPopup: ChargingStationPopupComponent;

  public popupProperties: { [key: string]: any };
  public popupCoords: [number, number];

  private mapIsReady = false;
  private map: mapboxgl.Map;
  public csPopupData: FacilityViewChargingStation;
  public issatelliteView = false;
  public isFirstVehicalsZoom = true;

  private markers: { [key: number]: Marker } = {};
  private markersOnScreen: { [key: number]: Marker } = {};
  private facilityMarker: Marker;
  private transitionTextMarker: Marker;
  private facilityCoords: [number, number];
  private facilityAddress: string;
  private mapDataVehicleCache: GeoJSONFeatureCollection;
  private mapDataChargingStationCache: GeoJSONFeatureCollection;
  private currentVehiclePopup: Popup;
  private currentCSPopup: Popup;
  public evsePopupIndex: number;
  private readonly facilityZonesZoomLevel = 16;
  private readonly goToFacilityViewZoomLevel = 20;

  private destroyed$ = new Subject<void>();

  constructor(
    private mapHelper: MapHelperService,
    private fleetLocationMapService: FleetLocationMapService,
    private fleetLocationMapFilterService: FleetLocationMapFilterService,
    private changeDetectorRef: ChangeDetectorRef,
    private navigationHelper: NavigationHelperService,
  ) {
  }

  public ngOnInit() {
    this.fleetLocationMapService.popupDataSubject()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(values => {
        this.addIsoChroneDataToMap(values);
      });

    this.fleetLocationMapFilterService.updateMapFiltersSubject()
      .pipe(
        startWith(null as any), // Fixs incorrect deprecation message
        pairwise(),
        switchMap(([a, b]) => JSON.stringify(a) !== JSON.stringify(b)
          ? of(b)
          : EMPTY,
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe(filtersData => {
        this.closeVehiclePopup();
        this.closeCSPopup();
      });

    this.fleetLocationMapFilterService.mapFiltersActionSubject()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(values => {
        if (!this.map || !this.mapDataVehicleCache) {
          return;
        }

        if (values.type === 'select-facility') {
          if (!this.facilityCoords) {
            return;
          }

          if (this.mapDataVehicleCache?.features?.length) {
            this.initialZoom(this.mapDataVehicleCache, this.facilityCoords);
          } else {
            this.map.flyTo({center: this.facilityCoords});
          }
        }

        if (values.type === 'select-vehicle') {
          const feature = this.fleetLocationMapService.findFeatureByEvId(this.mapDataVehicleCache, values.evId);
          if (!feature) {
            return;
          }

          const coordinates = (feature.geometry as any).coordinates;
          if (coordinates) {
            const onCloseCallback = () => {
              this.fleetLocationMapFilterService.clearVehicle();
            };

            this.createVehiclePopup(coordinates, feature.properties, onCloseCallback);
            this.map.flyTo({center: coordinates, zoom: 18});
          }
        }
      });
  }

  public ngOnDestroy() {
    this.removeMapInstance();

    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.facility?.currentValue !== changes.facility?.previousValue && this.facility) {
      this.showFacilityMap();
    }

    if (changes.state?.currentValue !== changes.state?.previousValue && this.state) {
      this.updateMapVehicles();
      this.updateMapCharingStations();
    }

    if (changes.chargingStations?.currentValue !== changes.chargingStations?.previousValue && this.chargingStations) {
      this.updateMapCharingStations();
    }

    if (changes.facilityZones?.currentValue !== changes.facilityZones?.previousValue && this.facilityZones) {
      this.updateFacilityZones();
    }
  }

  private removeMapInstance() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }

    this.mapIsReady = false;
    this.markers = {};
    this.markersOnScreen = {};
  }

  private showFacilityMap() {
    this.removeMapInstance();

    this.facilityAddress = this.mapHelper.getAddressFromFacility(this.facility);
    if (!this.facilityAddress) {
      return;
    }

    this.mapHelper.addressToCoords(this.facilityAddress)
      .subscribe(coords => {
        this.facilityCoords = coords;

        if (!coords) {
          return;
        }

        this.createMapInstance(coords);
      });
  }

  private createMapInstance(coords: [number, number]) {
    this.removeMapInstance();

    this.mapIsReady = false;

    this.map = createMapObject(coords, 13);

    this.map.on('load', async () => {
      await this.loadVehicleImages();

      this.mapIsReady = true;

      this.addAllLayers();

      this.map.on('style.load', async () => {
        await this.loadVehicleImages();
        this.addAllLayers();
      });
    });

    this.map.on('zoom', () => {
      const currentZoomLevel = this.map.getZoom();

      if (currentZoomLevel <= this.facilityZonesZoomLevel && !this.facilityMarker) {
        this.addFacilityMarker();
      }

      if (currentZoomLevel > this.facilityZonesZoomLevel && this.facilityZones?.length) {
        this.removeFacilityMarker();
        this.closeCSPopup();
      }
    });
  }

  private addAllLayers() {
    this.addFacilityMarker();
    this.setupTransitionToFacilityViewLayer();

    if (this.state) {
      this.updateMapVehicles();
    }

    if (this.chargingStations) {
      this.updateMapCharingStations();
    }

    if (this.facilityZones) {
      this.updateFacilityZones();
    }
  }

  public satelliteViewToggle() {
    this.issatelliteView = !this.issatelliteView;

    if (this.issatelliteView) {
      this.map.setStyle(satelliteMapStyle);
    } else {
      this.map.setStyle(defaultMapStyle);
    }

    this.map.once('styledata', () => {
      this.updateMapVehicles();
      this.updateMapCharingStations();
    });
  }

  private setupTransitionToFacilityViewLayer() {
    this.map.addLayer({
      id: 'transition-layer',
      type: 'background',
      minzoom: this.goToFacilityViewZoomLevel,
      paint: {
        'background-color': '#000000',
        'background-opacity': 0.25,
      },
    });

    this.map.on('zoom', () => {
      const currentZoomLevel = this.map.getZoom();

      if (currentZoomLevel >= this.goToFacilityViewZoomLevel && !this.transitionTextMarker) {
        this.addTransitionTextMarker();
        this.map.getCanvas().style.cursor = 'pointer';
      } else if (currentZoomLevel < this.goToFacilityViewZoomLevel && this.transitionTextMarker) {
        this.removeTransitionTextMarker();
        this.map.getCanvas().style.cursor = '';
      } else if (currentZoomLevel >= this.goToFacilityViewZoomLevel && this.transitionTextMarker) {
        this.redrawTransitionTextMarker();
      }
    });

    this.map.on('move', () => {
      const currentZoomLevel = this.map.getZoom();

      if (currentZoomLevel >= this.goToFacilityViewZoomLevel && this.transitionTextMarker) {
        this.redrawTransitionTextMarker();
      }
    });

    this.map.on('click', () => {
      if (this.map.getZoom() >= this.goToFacilityViewZoomLevel) {
        this.navigationHelper.navigateToTenantPath(`facility-view/${this.facility.facilityId}`);
      }
    });
  }

  private redrawTransitionTextMarker() {
    this.removeTransitionTextMarker();
    this.addTransitionTextMarker();
  }

  private addTransitionTextMarker() {
    if (this.transitionTextMarker) {
      return;
    }

    this.transitionTextMarker = addTransitionTextMarker(this.map, this.map.getCenter());
  }

  private removeTransitionTextMarker() {
    if (this.transitionTextMarker) {
      this.transitionTextMarker.remove();
      this.transitionTextMarker = null;
    }
  }

  private addFacilityMarker() {
    if (!this.facilityCoords || this.facilityMarker) {
      return;
    }

    this.facilityMarker = addFacilityMarker(
      this.map,
      this.facilityCoords,
      this.facility.name || this.facilityAddress,
    );
  }

  private removeFacilityMarker() {
    if (this.facilityMarker) {
      this.facilityMarker.remove();
      this.facilityMarker = null;
    }
  }

  private updateMapVehicles() {
    if (!this.map || !this.state || !this.mapIsReady) {
      return;
    }

    this.mapDataVehicleCache = this.fleetLocationMapService
      .createVehiclesGeoJSONFeatures(this.state);

    const vehiclesSource = this.map.getSource('vehicles') as GeoJSONSource;
    if (vehiclesSource) {
      vehiclesSource.setData(this.mapDataVehicleCache);

      this.updateCurrentPopupLocation();
    } else {
      addVehicleSourcesToMap(this.mapDataVehicleCache, this.map);
      addVehiclesLayer(this.map, vehiclesLabelsLayerId, vehicleIconsLayerId);

      this.setupVehicleHandlers();

      if (this.isFirstVehicalsZoom) {
        this.initialZoom(this.mapDataVehicleCache, this.facilityCoords);
        this.isFirstVehicalsZoom = false;
      }
    }
  }

  private updateMapCharingStations() {
    if (!this.map || !this.chargingStations || !this.mapIsReady) {
      return;
    }

    const csFvArray = this.chargingStations.map(cs => {

      cs.evSupplyEquipments.forEach((evse, index) => {
        if (evse.latitude == null || evse.longitude == null) {
          evse.longitude = cs.longitude + 0.000015 * index;
          evse.latitude = cs.latitude;
        }
      });

      const fvcs = {
        evses: cs.evSupplyEquipments.map(evse => evse ? new FvData({ evse: evse, cs, evseState: this.state?.evsesState?.find(x => x.evseId === evse.evseId) }) : undefined),
        chargingStation: cs,
      };
      return fvcs;
    });

    this.mapDataChargingStationCache = this.fleetLocationMapService.createEvseGeoJSONFeatures(csFvArray);
    const evseSource = this.map.getSource('evses') as GeoJSONSource;

    if (evseSource) {
      evseSource.setData(this.mapDataChargingStationCache);
    } else {
      addEvseSourcesToMap(this.mapDataChargingStationCache, this.map);
      addEvseLayers(this.map, evseLayerId, this.facilityZonesZoomLevel, vehicleIconsLayerId);
      this.setupCSHandlers();
    }
  }

  private updateFacilityZones() {
    if (!this.map || !this.facilityZones || !this.mapIsReady) {
      return;
    }

    const facilityZoneIdPrefix = 'facility-zone-id';

    clearFacilityZoneLayers(this.map, facilityZoneIdPrefix);

    this.facilityZones.forEach((facilityZone, idx) => {
      drawFacilityZone(this.map, facilityZone, facilityZoneIdPrefix, idx, this.facilityZonesZoomLevel, true, evseLayerId);
    });
  }

  private setupVehicleHandlers() {
    this.map.on('render', () => {
      if (!this.map.getSource('vehicles') || !this.map.isSourceLoaded('vehicles')) {
        return;
      }

      this.updateMarkers();
    });

    const getFeatureCoords = (evId: number) =>
      this.fleetLocationMapService.findFeatureCoordsByEvId(this.mapDataVehicleCache, evId);

    this.map.on('click', vehicleIconsLayerId, (e) => {
      const properties = {...e.features[0].properties};

      const coordinates = getFeatureCoords(properties.id);
      if (coordinates) {
        this.createVehiclePopup(coordinates, properties);
      }
    });

    this.map.on('click', evseLayerId, (e: any) => {
      const properties = {...e.features[0].properties};

      const coordinates = e.features[0].geometry.coordinates;
      if (coordinates) {
        this.createCSPopup(coordinates, properties);
      }
    });

    this.map.on('dblclick', vehicleIconsLayerId, (e) => {
      const properties = {...e.features[0].properties};

      const coordinates = getFeatureCoords(properties.id);
      if (coordinates) {
        e.preventDefault();

        /*
         e.preventDefault() stops default dblclick zoom handler but it also stops our flyTo.
         There is a stopPropergation but it exists on e.originalEvent but does not have the desired effect here.
         */
        setTimeout(() => {
          this.map.flyTo({center: coordinates, zoom: 18});
        });
      }
    });

    this.map.on('mouseenter', vehicleIconsLayerId, () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', vehicleIconsLayerId, () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  private setupCSHandlers() {
    let wasOpenedByClick = false;
    let lastOpenedByClickId;

    this.map.on('click', evseLayerId, (e: any) => {
      const properties = {...e.features[0].properties};
      wasOpenedByClick = true;
      lastOpenedByClickId = properties.evseId;

      const coordinates = e.features[0].geometry.coordinates;
      if (coordinates) {
        this.createCSPopup(coordinates, properties);
      }
    });

    this.map.on('mouseenter', evseLayerId, (e: any) => {
      this.map.getCanvas().style.cursor = 'pointer';
      const properties = {...e.features[0].properties};

      if (lastOpenedByClickId === properties.evseId) {
        wasOpenedByClick = true;
      } else {
        lastOpenedByClickId = null;
      }

      const coordinates = e.features[0].geometry.coordinates;
      if (coordinates) {
        this.createCSPopup(coordinates, properties);
      }
    });

    this.map.on('mouseleave', evseLayerId, () => {
      this.map.getCanvas().style.cursor = '';

      if (wasOpenedByClick) {
        wasOpenedByClick = false;
      } else {
        this.currentCSPopup.remove();
      }
    });
  }

  private createVehiclePopup(coordinates: [number, number], properties, onCloseCallback?: () => void) {
    this.closeVehiclePopup();

    this.popupProperties = properties;
    this.popupCoords = coordinates;

    this.changeDetectorRef.detectChanges();

    this.currentVehiclePopup = createMapboxPopup(
      this.map,
      this.popupContainer.element.nativeElement as HTMLElement,
      coordinates,
      onCloseCallback,
    );
  }

  private createCSPopup(coordinates: [number, number], properties: any) {
    this.closeCSPopup();

    const cs = this.chargingStations.find(x => x.chargingStationId === properties.csId);
    const fvCs = this.constructFvChargingStation(cs);
    const evseIndex = fvCs.evses.findIndex(x => x.evse.evseId === properties.evseId);
    this.evsePopupIndex = evseIndex;
    this.csPopupData = fvCs;
    this.changeDetectorRef.detectChanges();

    this.currentCSPopup = new mapboxgl.Popup({
      maxWidth: '450',
      className: 'cs-popup',
      closeButton: false,
      closeOnClick: true,
    }).setLngLat(coordinates)
      .setDOMContent(this.csPopup.element.nativeElement as HTMLElement)
      .addTo(this.map);
  }

  private constructFvChargingStation(cs: ChargingStation) {
    const evses = [];
    cs.evSupplyEquipments.forEach(evse => {
      const evseState = this.state.evsesState.find(x => x.evseId === evse.evseId);
      const evState = this.state.evState.find(x => x.evseId === evseState?.evseId);
      const vehicle = this.state.vehicles.find(x => x.evId === evseState?.evId);
      const fvData = new FvData({cs, evse, evseState: evseState, vehicle: vehicle, evState: evState});
      evses.push(fvData);
    });

    return {chargingStation: cs, evses: evses, optimisationUnit: this.state.csOptimisationUnit[cs.chargingStationId]};
  }

  private closeVehiclePopup() {
    if (this.currentVehiclePopup) {
      this.currentVehiclePopup.remove();
    }
  }

  private closeCSPopup() {
    if (this.currentCSPopup) {
      this.currentCSPopup.remove();
    }
  }

  private updateCurrentPopupLocation() {
    if (this.currentVehiclePopup && this.popupProperties?.id) {
      const coordinates = this.fleetLocationMapService.findFeatureCoordsByEvId(this.mapDataVehicleCache, this.popupProperties.id);
      if (coordinates) {
        this.currentVehiclePopup.setLngLat(coordinates);
      }
    }
  }

  private addVehicleSourcesToMap(data: GeoJSONFeatureCollection) {
    this.map.addSource('vehicles', {
      type: 'geojson',
      data,
      cluster: true,
      clusterRadius: 40,
      clusterMinPoints: 2,
      clusterProperties: {
        red: ['+', ['case', ['<=', ['get', 'soc'], 33], 1, 0]],
        amber: ['+', ['case', ['all', ['>=', ['get', 'soc'], 34], ['<', ['get', 'soc'], 66]], 1, 0]],
        green: ['+', ['case', ['>=', ['get', 'soc'], 66], 1, 0]],
      },
    });

    this.map.addSource('iso', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }

  private updateMarkers() {
    const newMarkers = {};
    const features = this.map.querySourceFeatures('vehicles');

    for (const feature of features) {
      const coords = (feature.geometry as any).coordinates;
      const props = feature.properties;
      if (!props.cluster) {
        continue;
      }

      const id = props.cluster_id;
      const el = this.fleetLocationMapService.createDonutChart(props);

      let marker = this.markers[id];
      if (!marker) {
        marker = new mapboxgl.Marker({element: el})
          .setLngLat(coords)
          .addTo(this.map);

        this.markers[id] = marker;
      } else {
        marker.getElement().innerHTML = el.innerHTML;
      }

      newMarkers[id] = marker;

      if (!this.markersOnScreen[id]) {
        marker.addTo(this.map);
      }
    }

    for (const id in this.markersOnScreen) {
      if (!newMarkers[id]) {
        this.markersOnScreen[id].remove();
      }
    }

    this.markersOnScreen = newMarkers;
  }

  private addIsoChroneDataToMap({coords, properties}: IsochroneData) {
    const vehicle = this.state.vehicles.find(item => item.evId === properties.id);
    if (!vehicle) {
      return;
    }

    const meters = this.mapHelper.getDrivableMeters(vehicle.batteryCapacity, properties.soc, vehicle.vehicleEfficiency);

    this.mapHelper.getIsochroneData(coords[0], coords[1], 'driving', meters)
      .subscribe((data: any) => {
        if (!data.features.length) {
          return;
        }

        if (!this.map.getLayer('iso_layer')) {
          this.map.addLayer({
            id: 'iso_layer',
            type: 'fill',
            source: 'iso',
            layout: {},
            paint: {
              'fill-color': '#5a3fc0',
              'fill-opacity': 0.3,
            },
          }, vehicleIconsLayerId);
        }

        (this.map.getSource('iso') as GeoJSONSource).setData(data);

        if (data.features[0].geometry?.coordinates[0]) {
          this.mapHelper.zoomToFitCoords(this.map, data.features[0].geometry.coordinates[0]);
        }
      });
  }

  private loadVehicleImages() {
    return this.mapHelper.loadImagesIntoMap(this.map, {
      'vehicle-green': '/assets/images/map/vehicle-green.png',
      'vehicle-amber': '/assets/images/map/vehicle-amber.png',
      'vehicle-red': '/assets/images/map/vehicle-red.png',
      'vehicle-green-charging': '/assets/images/map/vehicle-green-charging.png',
      'vehicle-amber-charging': '/assets/images/map/vehicle-amber-charging.png',
      'vehicle-red-charging': '/assets/images/map/vehicle-red-charging.png',
      'label-box': '/assets/images/map/label-box.png',
      'charger': '/assets/images/map/charger-circle.png',
      'double-blocked-blocked': 'assets/images/map/double-blocked-blocked.png',
      'double-blocked-occupied': 'assets/images/map/double-blocked-occupied.png',
      'double-blocked-unavailable': 'assets/images/map/double-blocked-unavailable.png',
      'double-blocked-unoccupied': 'assets/images/map/double-blocked-unoccupied.png',
      'double-occupied-blocked': 'assets/images/map/double-occupied-blocked.png',
      'double-occupied-occupied': 'assets/images/map/double-occupied-occupied.png',
      'double-occupied-unavailable': 'assets/images/map/double-occupied-unavailable.png',
      'double-occupied-unoccupied': 'assets/images/map/double-occupied-unoccupied.png',
      'double-unavailable-blocked': 'assets/images/map/double-unavailable-blocked.png',
      'double-unavailable-occupied': 'assets/images/map/double-unavailable-occupied.png',
      'double-unavailable-unavailable': 'assets/images/map/double-unavailable-unavailable.png',
      'double-unavailable-unoccupied': 'assets/images/map/double-unavailable-unoccupied.png',
      'double-unoccupied-blocked': 'assets/images/map/double-unoccupied-blocked.png',
      'double-unoccupied-occupied': 'assets/images/map/double-unoccupied-occupied.png',
      'double-unoccupied-unavailable': 'assets/images/map/double-unoccupied-unavailable.png',
      'double-unoccupied-unoccupied': 'assets/images/map/double-unoccupied-unoccupied.png',
      'single-blocked': 'assets/images/map/single-blocked.png',
      'single-occupied': 'assets/images/map/single-occupied.png',
      'single-unavailable': 'assets/images/map/single-unavailable.png',
      'single-unoccupied': 'assets/images/map/single-unoccupied.png',
      'offline': 'assets/images/map/offline-border.png',
    }, {pixelRatio: 1});
  }

  private initialZoom(data: GeoJSONFeatureCollection, [longitude, latitude]: [number, number]) {
    if (!data.features.length) {
      return;
    }

    const coords: number[] = data.features
      .map((feature: any) => feature.geometry.coordinates);

      const validCoords = coords.filter(x => x[0] != null && x[1] != null);

      const getMaxDistance = (coordinate, index) => {
          const distances = validCoords.map(item => Math.abs(coordinate - item[index]));
          const maxDistance = Math.max(...distances);
          return isFinite(maxDistance) ? maxDistance : 0.01;
      };
      
      const maxLngDistance = getMaxDistance(longitude, 0);
      const maxLatDistance = getMaxDistance(latitude, 1);

    this.mapHelper.zoomToFitCoords(this.map, [
      [Math.max(longitude - maxLngDistance, -180), Math.max(latitude - maxLatDistance, -90)],
      [Math.min(longitude + maxLngDistance, 180), Math.min(latitude + maxLatDistance, 90)],
    ]);
  }
}
