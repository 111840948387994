<div class="main bdd-id--facility-selection--main" [class.main--collapsed]="isCollapsed">
    <div class="content">
        <div *ngFor="let facility of facilities" class="facility-item">
            <facility-selection-item
                class="bdd-id--facility-selection--facility-selection-item"
                [facility]="facility"
                [state]="states[facility.facilityId]"
                (handleFacilitySelect)="facilitySelected($event)"
                [showDefaultFacility]="showDefaultFacility"
                [isDefaultFacility]="defaultFacilityId === facility.facilityId"
                (handleDefaultFacilitySelect)="defaultFacilitySelected($event)"
                [isSelected]="selectedFacilityId === facility.facilityId"
                [isCollapsed]="isCollapsed"
                [attr.data-bdd-value]="facility.facilityId">
            </facility-selection-item>
        </div>
    </div>
</div>
