import {Component, Input} from '@angular/core';
import moment from 'moment';
import {OptimisationUnit} from '../../../shared/models/enums';

import {FacilityCalculatedMeta} from '../../../shared/models/entity-states';

@Component({
  selector: 'facility-live-capacity-graph',
  templateUrl: './facility-live-capacity-graph.component.html',
  styleUrls: ['./facility-live-capacity-graph.component.scss'],
})
export class FacilityLiveCapacityGraphComponent {
  public chartOptions: any;
  public unit: OptimisationUnit;
  public maxCapacity: number;

  @Input() set optimisationUnit(unit: OptimisationUnit) {
    this.unit = unit;
    if (this.chartOptions) {
      this.chartOptions.yAxis.name = `Max capacity: ${this.maxCapacity}${this.unit}`;
    } 
  }
  
  @Input()
  public set calculatedMetaHistory(calculatedMetaHistory: FacilityCalculatedMeta[]) {
    if (!calculatedMetaHistory || !calculatedMetaHistory.length) {
      return;
    }

    if (!this.chartOptions) {
      this.createChartOptions();
    }

    this.updateChartSeriesData(this.chartOptions, calculatedMetaHistory);
  }

  private createChartOptions() {
    const chartOptions = {
      color: ['#959595', '#f3ae4e', '#4ab471', '#eee', '#ddd'],
      toolbox: {
        show: false,
      },
      grid: {
        top: 40,
        bottom: 10,
        left: 110,
        right: 40,
        containLabel: true,
      },
      animation: false,
      title: {
        text: 'Live Capacity',
        textStyle: {
          color: '#fff',
          fontSize: 18,
          fontWeight: 200,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
        order: 'seriesDesc',
        valueFormatter: (value: string) => `${Math.round(+value * 100) / 100}${this.unit}`,
      },
      legend: {
        left: 'left',
        top: 'middle',
        orient: 'vertical',
        padding: [0, 10, 0, 0],
        data: ['Buffer', 'Spare', 'EV Load', 'Facility Load'],
        textStyle: {
          color: '#fff',
        },
      },
      xAxis: [
        {
          type: 'time',
          boundaryGap: false,
          axisLabel: {
            formatter: (value: string) => {
              const data = this.chartOptions.series[0].data;
              const timeDiff = moment(data[data.length - 1][0]).diff(data[0][0]);
              return timeDiff < 330 * 1000 ? moment(value).format('HH:mm:ss') : moment(value).format('HH:mm');
            },
            color: '#fff',
            fontSize: 11,
          },
          interval: 1200 * 1000,
          min: (value: { min: number }) => value.min - 1000, // 1 Second - if not is set it shows a day
        },
      ],
      yAxis: {
        type: 'value',
        nameLocation: 'middle',
        position: 'right',
        nameGap: 18,
        nameTextStyle: {
          color: '#ddd',
          fontSize: 11,
        },
        splitNumber: 1000000, // to hide numbers but still show the name of axis
      },
      series: [
        {
          name: 'Facility Load',
          type: 'line',
          stack: 'Total',
          smooth: true,
          symbol: 'none',
          areaStyle: {
            opacity: 1,
          },
          lineStyle: {
            opacity: 0,
          },
          emphasis: {
            focus: 'series',
          },
          data: [],
        },
        {
          name: 'EV Load',
          type: 'line',
          stack: 'Total',
          smooth: true,
          symbol: 'none',
          areaStyle: {
            opacity: 1,
          },
          lineStyle: {
            opacity: 0,
          },
          emphasis: {
            focus: 'series',
          },
          data: [],
        },
        {
          name: 'Spare',
          type: 'line',
          stack: 'Total',
          smooth: true,
          symbol: 'none',
          lineStyle: {
            opacity: 0,
          },
          areaStyle: {
            opacity: 1,
          },
          emphasis: {
            focus: 'series',
          },
          data: [],
        },

        {
          name: 'Buffer',
          type: 'line',
          stack: 'buffer',
          symbol: 'none',
          lineStyle: {
            width: 2,
            type: 'dashed',
            color: '#f0f0f0',
            opacity: 1,
          },
          data: [],
          tooltip: {
            show: false,
          },
        },
        {
          name: 'Buffer',
          type: 'line',
          stack: 'buffer-tooltip',
          symbol: 'none',
          lineStyle: {
            opacity: 0,
            type: 'dashed',
          },
          data: [],
        },
      ],
    };

    this.chartOptions = chartOptions;
  }

  private updateChartSeriesData(chartOptions: any, calculatedMetaHistory?: FacilityCalculatedMeta[]) {
    if (!chartOptions || !calculatedMetaHistory) {
      return;
    }

    const chartData = this.convertDataToChartFormat(calculatedMetaHistory);

    ['facilityLoad', 'evLoad', 'spare', 'buffer', 'bufferTooltip'].forEach((key, i) => {
      chartOptions.series[i].data = chartData[key];
    });

    this.maxCapacity = calculatedMetaHistory[0].maxCapacity;
    chartOptions.yAxis.name = `Max capacity: ${this.maxCapacity}${this.unit}`;

    this.chartOptions = {...chartOptions};
  }

  private convertDataToChartFormat(calculatedMetaHistory: FacilityCalculatedMeta[]) {
    return (calculatedMetaHistory || [])
      .reduce((results, meta) => {
        if (!meta) {
          return results;
        }

        const updatedAt = new Date(meta.updatedAt).toJSON();

        results.facilityLoad.push([updatedAt, meta.facilityLoad]);
        results.evLoad.push([updatedAt, meta.evLoad]);
        results.spare.push([updatedAt, meta.spare]);
        results.buffer.push([updatedAt, meta.maxCapacity - meta.totalBuffer]);
        results.bufferTooltip.push([updatedAt, meta.maxCapacity - meta.facilityLoad - meta.evLoad - meta.spare]);

        return results;
      }, {
        facilityLoad: [],
        evLoad: [],
        spare: [],
        buffer: [],
        bufferTooltip: [],
      });
  }
}
