<button
    [type]="buttonType"
    class="btn-background-{{ size }} btn-background-{{ type }} bdd-id--action-btn--main"
    [disabled]="disabled"
>
    <svg-icon *ngIf="iconType" [type]="iconType"></svg-icon>
    <img *ngIf="!iconType" class="btn-icon-{{ size }}-{{ type }}-svg" alt="{{ type }}"/>

    <span>{{ label }}</span>
</button>
