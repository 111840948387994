<ng-container *ngIf="parsedTags">
    <i
        class="fas fa-tags bdd-id--asset-tags-icon--icon"
        [tooltip]="tooltipHtml"
        [tooltipOptions]="{allowHTML: true}"
    ></i>

    <div #tooltipData style="display: none">
        <asset-tags-view [tags]="parsedTags"></asset-tags-view>
    </div>
</ng-container>
