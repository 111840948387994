import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'table-filters-input',
  templateUrl: './table-filters-input.component.html',
  styleUrls: ['./table-filters-input.component.scss'],
})
export class TableFiltersInputComponent {
  @Input() type: 'text' | 'number' = 'text';
  @Input() formGroup: UntypedFormGroup;
  @Input() name: string;
  @Input() unit: string;
  @Input() unitsList: string[];
}
