import {Injectable} from '@angular/core';

import {TableFilter, TableFilterData, TableFilterOperators} from './table-filters.model';

const getProperty = (object: any, path: string) => path.split('.')
  .reduce((obj, key) => obj && obj[key], object);

@Injectable()
export class TableFiltersService {
  public getLabel(operator: TableFilterOperators, fieldName: string, value: string | number, unit = '') {
    const labelMap = {
      [TableFilterOperators.Contains]: 'contains',
      [TableFilterOperators.DoesNotContain]: 'does not contain',
      [TableFilterOperators.Equals]: 'is equal to',
      [TableFilterOperators.GreaterThan]: 'is greater than',
      [TableFilterOperators.LessThan]: 'is less than',
    };

    if (!labelMap[operator]) {
      return '';
    }

    return [fieldName, labelMap[operator], `"${value}${unit}"`].join(' ');
  }

  public checkFilters(obj: object, filters: TableFilter[]) {
    const filtersByField: { [key: string]: TableFilterData[] } = filters.reduce((results, {data: filterData}) => {
      if (!results[filterData.field]) {
        results[filterData.field] = [];
      }

      results[filterData.field].push(filterData);

      return results;
    }, {});

    for (const fieldFilters of Object.values(filtersByField)) {
      const hasValidFilter = fieldFilters.some(filter => this.processFilter(obj, filter));
      if (!hasValidFilter) {
        return false;
      }
    }

    return true;
  }

  private processFilter(obj: object, {field, operator, value}: TableFilterData) {
    const currentValue = getProperty(obj, field);
    if (!currentValue) {
      return false;
    }

    if (operator === TableFilterOperators.Equals && !this.checkFilterEquals(currentValue, value)) {
      return false;
    }

    if (typeof value === 'string') {
      if (operator === TableFilterOperators.Contains && !this.checkFilterContains(currentValue, value)) {
        return false;
      }

      if (operator === TableFilterOperators.DoesNotContain && this.checkFilterContains(currentValue, value)) {
        return false;
      }
    }

    if (typeof value === 'number') {
      if (operator === TableFilterOperators.GreaterThan && !this.checkFilterGreaterThan(currentValue, value)) {
        return false;
      }

      if (operator === TableFilterOperators.LessThan && !this.checkFilterLessThan(currentValue, value)) {
        return false;
      }
    }

    return true;
  }

  private checkFilterContains(currentValue: string, valueToCheck: string) {
    return String(currentValue).toLowerCase().includes(valueToCheck.toLowerCase());
  }

  private checkFilterEquals(currentValue: string | number, valueToCheck: string | number) {
    if (typeof currentValue === 'number') {
      return currentValue === valueToCheck;
    }

    return String(currentValue).toLowerCase() === String(valueToCheck).toLowerCase();
  }

  private checkFilterGreaterThan(currentValue: number, valueToCheck: number) {
    return currentValue > valueToCheck;
  }

  private checkFilterLessThan(currentValue: number, valueToCheck: number) {
    return currentValue < valueToCheck;
  }
}
