<ng-container *ngIf="notification">
    <span
        class="cs-notification-icon"
        [tooltip]="tooltipHtml"
        [tooltipOptions]="{allowHTML: true, maxWidth: 500}"
        [ngSwitch]="notification.status"
    >
        <i *ngSwitchCase="ChargePointStatus.Available" class="material-icons-outlined cs-notification-icon--available">info</i>
        <i *ngSwitchCase="ChargePointStatus.Preparing" class="material-icons-outlined cs-notification-icon--preparing">info</i>
        <i *ngSwitchCase="ChargePointStatus.Charging" class="material-icons-outlined cs-notification-icon--charging">info</i>
        <i *ngSwitchCase="ChargePointStatus.SuspendedEVSE"
           class="material-icons-outlined cs-notification-icon--suspended-evse">report_problem</i>
        <svg-icon *ngSwitchCase="ChargePointStatus.SuspendedEV" type="charging-finished" class="cs-notification-icon--suspended-ev"></svg-icon>
        <i *ngSwitchCase="ChargePointStatus.Finishing" class="material-icons-outlined cs-notification-icon--finishing">info</i>
        <i *ngSwitchCase="ChargePointStatus.Reserved" class="material-icons-outlined cs-notification-icon--reserved">report_problem</i>
        <svg-icon *ngSwitchCase="ChargePointStatus.Unavailable" type="cross-circled"
                  class="cs-notification-icon--unavailable"></svg-icon>
        <svg-icon *ngSwitchCase="ChargePointStatus.Faulted" type="cross-circled"
                  class="cs-notification-icon--faulted"></svg-icon>
    </span>

    <div #csNotificationPopup style="display: none">
        <cs-notification-popup [name]="name" [notification]="notification"></cs-notification-popup>
    </div>
</ng-container>
