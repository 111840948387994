import distance from '@turf/distance';

import {EvseState, EvState} from '../../shared/models/entity-states';
import {FacilityViewChargingStation, FvData, FvDataState} from '../models';
import {ChargingStation} from '../../shared/models/entities';

const metresToBlock = 10;

interface Positioned {
  longitude?: number,
  latitude?: number
}

export const hasPosition = (positioned: Positioned) => !isNaN(parseFloat(positioned.longitude + '')) && !isNaN(parseFloat(positioned.latitude + ''));

export const isEligibleToBlock = (vehicle: EvState) => !!(hasPosition(vehicle) && !vehicle.isPluggedIn && vehicle.facilityZoneId);

export enum BlockingResultState {
  BLOCKING,
  IN_ZONE,
  NEITHER
}

export interface VehicleBlockingResult {
  result: BlockingResultState;
  blocking?: FvData;
}

export type HasEv = { evId: number }

function isBlocking(fvData: FvData, evState: EvState) {
  return fvData?.evseState?.evId === evState.evId
    && fvData?.evseState?.evseId === evState.evseId
    && !fvData.evseState.isPluggedIn && fvData.state !== FvDataState.BLOCKED;
}

export function isWithinBlockingDistance(cs: ChargingStation, ev: EvState) {
  if (!cs || !ev || !hasPosition(cs) || !hasPosition(ev)) return false;
  const distanceFromCs = distance([cs.longitude, cs.latitude], [ev.longitude, ev.latitude], {units: 'meters'});
  return distanceFromCs <= metresToBlock;
}

export function findEvseBlockedByVehicle(vehicle: EvState, fvChargingStations: FacilityViewChargingStation[]): VehicleBlockingResult {
  let result = BlockingResultState.NEITHER;
  if (!hasPosition(vehicle)) return {result};
  if (!isNaN(vehicle.facilityZoneId)) result = BlockingResultState.IN_ZONE;

  let blockedEvseKey;

  const blockedCs = fvChargingStations.find(fvcs => {
    if (isWithinBlockingDistance(fvcs.chargingStation, vehicle)) {
      blockedEvseKey = fvcs.evses.findIndex(x => isBlocking(x, vehicle));
      return blockedEvseKey >= 0;
    }
    return false;
  });

  if (blockedCs) result = BlockingResultState.BLOCKING;

  return {result, blocking: blockedCs ? blockedCs.evses[blockedEvseKey] : undefined};
}

export function hasCorrectPluginStatus(evState: EvState, evseStates: EvseState[]): boolean {
  if (!evState.isPluggedIn) return true;

  const evseState = evseStates.find(x => x.evseId === evState.evseId && x.evId === evState.evId);
  if (!evseState) return false;
  
  return true;
}
