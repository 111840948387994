import {Component, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import moment from 'moment';
import {NavigationHelperService} from '../../../shared/services/navigation-helper.service';

import {FacilityViewChargingStation, FvDataState} from '../../models';
import {EvseStatus, getEvseStatus} from '../../utils';
import {isCsOffline} from '../../utils/offline-status.util';

@Component({
  selector: 'fv-charging-station-popup',
  templateUrl: './charging-station-popup.component.html',
  styleUrls: ['./charging-station-popup.component.scss'],
})
export class ChargingStationPopupComponent implements OnChanges {
  @Input() public fvChargingStation: FacilityViewChargingStation;
  @Input() public showAssetsLinks = false;
  @Input() public evseIndex: number; //Use to show popup for socket instead of the CS

  public lastUpdated: string;

  constructor(public element: ElementRef,
              private navigationHelper: NavigationHelperService,) {}

  states = FvDataState;
  public isCsOffline = isCsOffline;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.fvChargingStation && changes.fvChargingStation?.previousValue !== changes.fvChargingStation?.currentValue) {
      this.calcStatuses();
    }
  }

  get evses() {
    if (this.evseIndex !== null && this.evseIndex !== undefined) {
      return [this.fvChargingStation.evses[this.evseIndex]];
    }
    return this.fvChargingStation.evses;
  }

  get hasDuplicates() {
    return this.evses.map(x => x.meta?.isDuplicate).some(x => x);
  }

  public calcStatuses() {
    const updatedDates = [
      this.fvChargingStation.chargingStation.updatedAt,
      ...this.fvChargingStation.evses.map(x => x.evseState?.timestamp),
    ];
    const latestUpdate = moment.max(updatedDates.filter(d => d !== undefined).map(d => moment(d)));
    this.lastUpdated = latestUpdate.fromNow();
  }

  public navigateToChargingStationPage(event: MouseEvent) {
    event.preventDefault();
    this.navigationHelper.navigateToTenantPath(`chargers/facility-charging-stations/${this.fvChargingStation.chargingStation.facilityId}/${this.fvChargingStation.chargingStation.chargingStationId}/view`);
  }

  public navigateToVehiclePage(event: MouseEvent, evId) {
    event.preventDefault();
    this.navigationHelper.navigateToTenantPath(`vehicles/facility-vehicles/${this.fvChargingStation.chargingStation.facilityId}/${evId}/view`);
  }
}
