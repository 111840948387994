import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxEchartsModule} from 'ngx-echarts';

import {FacilityLiveCapacityGraphComponent} from './facility-live-capacity-graph.component';

@NgModule({
  declarations: [
    FacilityLiveCapacityGraphComponent,
  ],
  imports: [
    CommonModule,
    NgxEchartsModule,
  ],
  exports: [
    FacilityLiveCapacityGraphComponent,
  ],
})
export class FacilityLiveCapacityGraphModule {
}
