import {Component} from '@angular/core';

import {Tab} from '../../../shared/components/tab-switch-header/tab-switch-header.component';

@Component({
  selector: 'vehicles-outlet',
  templateUrl: './vehicles-outlet.component.html',
  styleUrls: ['./vehicles-outlet.component.scss'],
})
export class VehiclesOutletComponent {
  public readonly tabConfig: Tab[] = [
    {label: 'Vehicles', value: 'facility-vehicles', permission: 'page/vehicles/facility-vehicles'},
    {label: 'Vehicle Types', value: 'vehicle-types', permission: 'page/vehicles/vehicle-types'},
  ];
}
