<div class="table-wrapper">
    <table
        mat-table matSort
        [dataSource]="dataSource"
        [ngClass]="{'table-wrapper-default': !isCondensed, 'table-wrapper-condensed': isCondensed}"
    >
        <ng-container matColumnDef="defaultHeader" *ngIf="tableColumns">
            <mat-header-cell *matHeaderCellDef>
                <table-header
                    class="bdd-id--table-wrapper--table-header"
                    [columns]="tableColumns"
                    (sorted)="onSorted($event)"
                    [isCondensed]="isCondensed"
                    [isMobile]="isMobile"
                >
                </table-header>
            </mat-header-cell>

            <mat-header-row class="header-row" *matHeaderRowDef="['defaultHeader']"></mat-header-row>
        </ng-container>

        <ng-content></ng-content>

        <mat-row class="item-row" *matRowDef="let row; columns: ['customRow'];"></mat-row>
    </table>

    <ng-container *ngIf="showPaginator">
        <mat-paginator [hidden]="true" [pageSize]="pageSize"></mat-paginator>
        <table-paginator
            class="bdd-id--table-wrapper--table-paginator"
            [itemsCount]="dataSource.filteredData.length"
            [pageSize]="pageSize"
            [matPaginator]="dataSource.paginator"
            (changedPageNo)="onChangedPageNo($event)">
        </table-paginator>
    </ng-container>
    <div>
