import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {catchError, map, takeUntil, tap} from 'rxjs/operators';
import {Auth} from '@aws-amplify/auth';
import clone from 'ramda/src/clone';

import {AllDataStreamType, DataDistributor} from '../home/services/data-distributor.service';
import {AssetManagerService} from '../shared/services/asset-manager.service';
import {FacilityStateService} from '../shared/services/facility-state.service';
import {ChargingStation, Facility, FacilityZone, Vehicle} from '../shared/models/entities';
import {
  createFleetLocationState,
  EvState,
  FleetLocationState,
  getPluggedInStatus,
  recalculateFleetLocationState,
} from '../shared/models/entity-states';
import {isEvseState, isEvState} from '../shared/utils/typeChecks';
import {FacilityConnectionHandlerService} from '../home/services/facility-connection-handler.service';
import {FvData} from '../facility-view/models';
import {getSocType} from '../facility-view/utils';

@Component({
  selector: 'fleet-location',
  templateUrl: './fleet-location.component.html',
  styleUrls: ['./fleet-location.component.scss'],
})
export class FleetLocationComponent implements OnInit, OnDestroy {

  @Output() facilityChanged = new EventEmitter<number>();

  public selectedFacilityState: FleetLocationState;
  public filteredFacilityState: FleetLocationState;
  public facilityStateLoaded = false;
  public selectedFacility: Facility;
  public chargingStations: ChargingStation[];
  public facilityZones: FacilityZone[];

  public plugInFilterState = [];
  public stateOfChargeFilterState = [];

  private token: string;
  private facilityStateCache: Map<number, FleetLocationState> = new Map();
  public facilities: Facility[] = [];

  private destroyed$ = new Subject<void>();

  constructor(
    private assetService: AssetManagerService,
    private stateService: FacilityStateService,
    private dataDistributor: DataDistributor,
    private route: ActivatedRoute,
    private facilityConnectionHandler: FacilityConnectionHandlerService,
  ) {
  }

  public async ngOnInit() {
    const userData = await Auth.currentAuthenticatedUser();
    this.token = userData.signInUserSession.idToken.jwtToken;

    this.initialiseFacilitySelection();
    this.initialiseDataHandler();

    this.facilityStateLoaded = false;

    this.destroyed$.subscribe(() => {
      this.facilityConnectionHandler.closeSocket();
    });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  private initialiseFacilitySelection() {
    this.assetService
      .getAllFacilities()
      .pipe(catchError(() => of([])))
      .subscribe(facilities => {
        this.facilities = facilities;

        if (this.facilities?.length > 0) {
          const facilityId = parseInt(this.route.snapshot.paramMap.get('facilityId'), 10);
          if (facilityId) {
            this.selectFacility(facilityId);
          }

          this.route.paramMap
            .pipe(takeUntil(this.destroyed$))
            .subscribe(params => {
              const newFacilityId = parseInt(params.get('facilityId'), 10);
              this.selectFacility(newFacilityId);
            });
        } else {
          this.facilityConnectionHandler.closeSocket(); // guarding for an open socket
        }
      });
  }

  private initialiseDataHandler() {
    this.dataDistributor.allData()
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(({name, data}: AllDataStreamType) => {
        const facilityId = data.facilityId;
        const newFleetLocationState = clone(this.facilityStateCache.get(facilityId));

        if (name === 'evseChanged' && isEvseState(data)) {
          const evseIndex = newFleetLocationState.evsesState.findIndex(e => e.evseId === data.evseId);
          if (evseIndex !== -1) {
            newFleetLocationState.evsesState.splice(evseIndex, 1, data);
          } else {
            newFleetLocationState.evsesState.push(data);
          }
        } else if (name === 'evChanged' && isEvState(data)) {
          const evIndex = newFleetLocationState.evState.findIndex(e => e.evId === data.evId);
          if (evIndex !== -1) {
            newFleetLocationState.evState.splice(evIndex, 1, data);
          } else {
            newFleetLocationState.evState.push(data);
          }
        }

        const updatedFleetLocationState = recalculateFleetLocationState(newFleetLocationState);

        if (this.selectedFacility?.facilityId === facilityId) {
          this.selectedFacilityState = updatedFleetLocationState;
          this.filterData();
        }

        this.facilityStateCache.set(facilityId, updatedFleetLocationState);
      });
  }

  private selectFacility(facilityId: number) {
    if (facilityId === this.selectedFacility?.facilityId) {
      return;
    }

    this.facilityStateLoaded = false;
    this.selectedFacility = Object.assign({}, this.facilities.find(f => f.facilityId === facilityId));

    this.assetService
      .getAllChargingStationsByFacilityId(facilityId)
      .pipe(catchError(() => of([])))
      .subscribe(chargingStations => {
        this.chargingStations = chargingStations;

        if (chargingStations.length > 0) {
          this.facilityConnectionHandler.connectToSocket(facilityId, this.token);
        }
      });

    this.assetService
      .getFacilityZonesByFacilityId(facilityId)
      .pipe(catchError(() => of([])))
      .subscribe(facilityZones => {
        this.facilityZones = facilityZones;
      });

    this.populateFacilityState(facilityId)
      .subscribe(value => {
        this.selectedFacilityState = value;
        this.filterData();
        this.facilityStateLoaded = true;
      });
  }

  private populateFacilityState(facilityId: number) {
    const vehicles$: Observable<Vehicle[]> = this.assetService.getElectricVehiclesByFacilityId(facilityId)
      .pipe(catchError(() => of([])));

    const facilityState$ = this.stateService.getFacilityStateById(facilityId, true)
      .pipe(
        tap(facilityState => {
          if (facilityState?.evsesState?.length === 0) {
            this.facilityStateLoaded = true;
          }
        }),
      );
    
    const chargingStations$ = this.assetService.getAllChargingStationsByFacilityId(facilityId);
    const chargingGroups$ = this.assetService.getChargingGroupsByFacilityId(facilityId);
    const facilityOptimisationSettings$ = this.assetService.getFacilityOptimisationSettingsByFacilityId(facilityId);

    return forkJoin([facilityState$, vehicles$, chargingStations$, chargingGroups$, facilityOptimisationSettings$])
      .pipe(
        map(([facilityStateO, vehicles, chargingStations, chargingGroups, facilityOptimisationSettings]) => {
          const facilityState = clone(facilityStateO);
          if (facilityState.evState === undefined) {
            facilityState.evState = [];
          }
          const vehiclesWithState = new Set(facilityState.evState?.map(evState => evState.evId));
          vehicles.forEach((veh: Vehicle) => {
            if (vehiclesWithState.has(veh.evId)) return;
            facilityState.evState.push({
              evId: veh.evId,
              stateOfChargeType: 'Unknown',
              stateOfCharge: 0,
              facilityId: veh.facilityId,
              timestamp: new Date(),
              isPluggedIn: false,
            });
          });

          const fleetLocationState = createFleetLocationState(facilityState, vehicles, chargingStations, chargingGroups, facilityOptimisationSettings);

          this.facilityStateCache.set(facilityId, fleetLocationState);

          return fleetLocationState;
        }),
      );
  }

  public filterData() {
    const plugIn = new Set(this.plugInFilterState);
    const socStatus = new Set(this.stateOfChargeFilterState);

    const isInCharge = (fvData: FvData) => socStatus.size === 0 || (socStatus.has(getSocType(fvData)));
    const isPlugInStatus = (state: EvState) => plugIn.size === 0 || plugIn.has(getPluggedInStatus(state));

    const newFilteredState = clone(this.selectedFacilityState);
    newFilteredState.vehicles = this.selectedFacilityState.vehicles.filter(vehicle => {
      const evState = newFilteredState.evState.find(x => x.evId === vehicle.evId);
      const fvData = new FvData({evState, vehicle});
      return isPlugInStatus(evState) && isInCharge(fvData);
    });
    this.filteredFacilityState = recalculateFleetLocationState(newFilteredState);
  }

  public filterChanged(filters) {
    this.plugInFilterState = filters.pluggedIn || [];
    this.stateOfChargeFilterState = filters.stateOfCharge || [];
    this.filterData();
  }
}
