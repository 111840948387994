import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  transform(text: any): string {
    return this.convertToSentenceCase(text);
  }

  private convertToSentenceCase(text: string) {
    if (!text || text === '') {
      return text;
    }

    // Source: https://stackoverflow.com/a/61389248/1022873
    return text.replace(/([A-Z][a-z]+)/g, ' $1')
      .replace(/([A-Z]{2,})/g, ' $1')
      .replace(/\s{2,}/g, ' ')
      .trim();
  }
}
