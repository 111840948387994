<div class="fleet-location__main bdd-id--fleet-location--main">
    <div class="fleet-location__overview">
        <fleet-location-overview
            class="bdd-id--fleet-location--fleet-location-overview"
            [stateOfChargeChartData]="filteredFacilityState?.stateOfChargeChartData"
            [pluggedInStatus]="filteredFacilityState?.pluggedInStatus"
            [facilityState]="filteredFacilityState"
            [facilities]="facilities"
            [selectedFacility]="selectedFacility"
            (facilityChanged)="facilityChanged.emit($event)"
            (filterChanged)="filterChanged($event)">
        </fleet-location-overview>
    </div>

    <div class="fleet-location__map">
        <fleet-location-map
            *ngIf="facilityStateLoaded"
            class="bdd-id--fleet-location--fleet-location-map"
            [facility]="selectedFacility"
            [state]="filteredFacilityState"
            [chargingStations]="chargingStations"
            [facilityZones]="facilityZones">
        </fleet-location-map>
    </div>
</div>
