import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';

import {SettingsIconComponent} from './components/settings-icon/settings-icon.component';
import {SettingsOptionsTableColumnsComponent} from './components/settings-options-table-columns/settings-options-table-columns.component';
import {SettingsOptionsDropdownComponent} from './components/settings-options-dropdown/settings-options-dropdown.component';
import {SettingsOptionsToggleComponent} from './components/settings-options-toggle/settings-options-toggle.component';
import {SettingsService} from './settings.service';

@NgModule({
  declarations: [
    SettingsIconComponent,
    SettingsOptionsTableColumnsComponent,
    SettingsOptionsDropdownComponent,
    SettingsOptionsToggleComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
  ],
  exports: [
    SettingsIconComponent,
    SettingsOptionsTableColumnsComponent,
    SettingsOptionsDropdownComponent,
    SettingsOptionsToggleComponent,
  ],
  providers: [
    SettingsService,
  ],
})
export class SettingsModule {
}
