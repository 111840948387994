import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {EvseState, EvState, FacilityHeadroom} from '../../shared/models/entity-states';

export type SupportedStreamTypes = EvState | EvseState | FacilityHeadroom & { facilityName: string };

export interface AllDataStreamType {
  name: string;
  data: SupportedStreamTypes;
}

@Injectable()
export class DataDistributor {
  private allData$ = new Subject<AllDataStreamType>();
  private evseData$ = new Subject<EvseState>();
  private headroomData$ = new Subject<FacilityHeadroom>();
  private evData$ = new Subject<EvState>();

  private mapping = {
    evseChanged: this.evseData$,
    facilityHeadroomChanged: this.headroomData$,
    evChanged: this.evData$,
  };

  public emit(data: any) {
    this.allData$.next(data);

    try {
      if (!data.name) throw new Error('no name');
      if (!this.mapping[data.name]) throw new Error('no stream');

      const stream: Subject<any> = this.mapping[data.name];
      stream.next(data.data);
    } catch (err) {
      console.warn('Data received but,', err);
      return;
    }
  }

  public allData(): Observable<AllDataStreamType> {
    return this.allData$;
  }

  public evseState(): Observable<EvseState> {
    return this.evseData$;
  }

  public evState(): Observable<EvState> {
    return this.evData$;
  }

  public facilityHeadroom(): Observable<FacilityHeadroom> {
    return this.headroomData$;
  }
}
