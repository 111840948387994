<div class="design-bar bdd-id--design-bar--main" [ngStyle]="{'height.px': height, 'max-width.px': maxWidth}">
    <div class="design-bar__bar-wrapper">
        <div *ngFor="let bar of bars" [ngStyle]="{'width.%': bar.percentage}">
            <div class="design-bar__label-top">
                <div *ngIf="bar.labelStyle?.alignVertical === 'top'"
                     [ngClass]="{'right-align': bar.labelStyle?.alignHorizontal === 'right'}"
                     [innerHTML]="bar.labelHtml | sanitiseHtml">
                </div>
            </div>

            <div class="design-bar__bar-body"
                 [ngStyle]="{'background': bar.itemStyle.color, 'margin-right.px': bar.itemStyle.removeGap ? 0 : 1}">
                <div *ngIf="bar.itemStyle.hatched" class="hatched-bar"></div>
            </div>

            <div class="design-bar__label-bottom">
                <div *ngIf="bar.labelStyle?.alignVertical !== 'top'"
                     [ngClass]="{'right-align': bar.labelStyle?.alignHorizontal === 'right'}"
                     [innerHTML]="bar.labelHtml | sanitiseHtml">
                </div>
            </div>
        </div>
    </div>
</div>
