<div class="dashboard-selector-router-outlet__main bdd-id--dashboard-selector-router-outlet--main d-none d-lg-block">
    <div class="dashboard-selector-router-outlet__options">
        <div class="dashboard-selector-router-outlet__tabs">
            <ng-container *ngFor="let tab of tabs">
                <div
                    class="dashboard-selector-router-outlet__tab-item bdd-id--dashboard-selector-router-outlet--tab-item"
                    [ngClass]="{'selected': selectedTabValue === tab.value}"
                    (click)="select(tab.value)"
                    [attr.data-bdd-value]="tab.value"
                    *authPermission="tab.permission">
                    <div class="dashboard-selector-router-outlet__tab-item-label">
                        {{ tab.label }}
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="flex-fill"></div>

        <div class="dashboard-selector-router-outlet__edit-facility" *ngIf="facilityId">
            <action-btn
                class="bdd-id--dashboard-selector-router-outlet--edit-facility-button"
                type="edit-white"
                size="small"
                label="Edit facility"
                (click)="edit()"
                *authPermission="'component/facilities/edit-facility-btn'">
            </action-btn>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
