<form class="table-filters-string-type__form bdd-id--table-filters-string-type--form" [formGroup]="optionsForm"
      (ngSubmit)="onSubmit()">
    <mat-radio-group formControlName="operator" class="table-filters-string-type__radio-group">
        <mat-radio-button
            class="table-filters-string-type__radio-button bdd-id--table-filters-string-type--contains-radio-button"
            [value]="TableFilterOperators.Contains">
            contains

            <div class="table-filters-string-type__radio-button-content"
                 *ngIf="operator.value === TableFilterOperators.Contains">
                <table-filters-input [name]="'valueContains'" [formGroup]="optionsForm"
                                     [unit]="config.unit"></table-filters-input>
            </div>
        </mat-radio-button>

        <mat-radio-button
            class="table-filters-string-type__radio-button bdd-id--table-filters-string-type--does-not-contain-radio-button"
            [value]="TableFilterOperators.DoesNotContain">
            does not contain

            <div class="table-filters-string-type__radio-button-content"
                 *ngIf="operator.value === TableFilterOperators.DoesNotContain">
                <table-filters-input [name]="'valueDoesNotContain'" [formGroup]="optionsForm"
                                     [unit]="config.unit"></table-filters-input>
            </div>
        </mat-radio-button>

        <mat-radio-button *ngIf="!disableEquals"
                          class="table-filters-string-type__radio-button bdd-id--table-filters-string-type--equals-radio-button"
                          [value]="TableFilterOperators.Equals">
            is equal to

            <div class="table-filters-string-type__radio-button-content"
                 *ngIf="operator.value === TableFilterOperators.Equals">
                <table-filters-input [name]="'valueEquals'" [formGroup]="optionsForm"
                                     [unit]="config.unit"></table-filters-input>
            </div>
        </mat-radio-button>
    </mat-radio-group>

    <div class="table-filters-string-type__actions bdd-id--table-filters-string-type--actions">
        <button
            type="button"
            class="bdd-id--table-filters-string-type--cancel-button cancel-btn cancel-btn--thin"
            (click)="cancel()"
        >
            <svg-icon type="cross-circled"></svg-icon>
            <span>Cancel</span>
        </button>

        <button
            class="bdd-id--table-filters-string-type--save-button save-btn save-btn--thin"
            type="submit"
            [disabled]="!optionsForm.valid"
        >
            <span>Apply Filter</span>
        </button>
    </div>
</form>
