import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Facility} from '../../../models/entities';

export type FacilityItemState = 'ok' | 'warning' | 'critical'

@Component({
  selector: 'facility-selection-item',
  templateUrl: './facility-selection-item.component.html',
  styleUrls: ['./facility-selection-item.component.scss'],
})
export class FacilitySelectionItemComponent {
  @Input() state: FacilityItemState;
  @Input() facility: Facility;
  @Input() showDefaultFacility = false;
  @Input() isDefaultFacility = false;
  @Input() isCollapsed = false;
  @Input() isSelected = false;

  @Output() handleFacilitySelect: EventEmitter<number> = new EventEmitter<number>();
  @Output() handleDefaultFacilitySelect: EventEmitter<number> = new EventEmitter<number>();

  public offsetY: number;

  public readonly defaultFacilityTooltip = 'Set the default facility';

  public selectFacilityClick() {
    if (!this.isSelected) {
      this.handleFacilitySelect.emit(this.facility.facilityId);
    }
  }

  public selectDefaultFacility(event: MouseEvent) {
    event.stopPropagation();

    if (!this.isDefaultFacility) {
      this.handleDefaultFacilitySelect.emit(this.facility.facilityId);
    }
  }

  public onMouseEnter(event: MouseEvent) {
    this.offsetY = (event.target as HTMLElement).getBoundingClientRect().top;
  }

  public onMouseLeave() {
    this.offsetY = 0;
  }
}
