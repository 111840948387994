<div
    *ngIf="tagValues"
    class="asset-tags-view__container bdd-id--asset-tags-view--container"
>
    <ng-container *ngIf="type === 'tooltip'">
        <div class="asset-tags-view__key-column">
            <span class="label">Tags</span>
        </div>

        <div
            class="asset-tags-view__row bdd-id--asset-tags-view--row"
            *ngFor="let tag of tagValues | keyvalue"
        >
            <div class="asset-tags-view__key-column bdd-id--asset-tags-view--key-column">
                <span class="label">{{ tag.key }}</span>
            </div>

            <div class="asset-tags-view__value-column bdd-id--asset-tags-view--value-column">
                {{ tag.value }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="type === 'details'">
        <div
            *ngFor="let tag of tagValues | keyvalue"
            class="asset-tags-view__row bdd-id--asset-tags-view--row"
        >
            <span class="label mr-1">{{ tag.key }}:</span> {{ tag.value }}
        </div>
    </ng-container>

</div>
