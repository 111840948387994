import {BrowserStorageCache} from '@aws-amplify/cache';
import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {CurrentSessionService} from '../../shared/services/current-session.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private currentSession: CurrentSessionService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const currentUser = BrowserStorageCache.getItem('currentUserIdToken');
    if (currentUser?.jwtToken) {
      const {'custom:userId': userId} = currentUser.payload;

      const tenantId = this.currentSession.getTenantId();

      request = request.clone({
        setHeaders: {
          Authorization: currentUser.jwtToken,
          'x-user-id': userId,
          'x-tenant-id': tenantId,
        },
      });
    }

    return next.handle(request);
  }
}
