export enum ErrorType {
  MFA_SETUP = 'There was a problem setting up your authentication device',
  PasswordReset = '`There was a problem setting your password, please try again',
  MFA_SIGNIN = 'There was a problem signing in with your multi-factor authentication device.'
}

export function errorMessageAdaptor(err: string | ErrorType): string {
  if (err.toLowerCase().includes('incorrect username or password')) {
    return 'Your password is incorrect or the account doesn\'t exist';
  } else {
    return 'There was a problem signing in';
  }
}
