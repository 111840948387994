import {Component} from '@angular/core';

import {Tab} from '../../../shared/components/tab-switch-header/tab-switch-header.component';

@Component({
  selector: 'chargers-outlet',
  templateUrl: './chargers-outlet.component.html',
  styleUrls: ['./chargers-outlet.component.scss'],
})
export class ChargersOutletComponent {
  public readonly tabConfig: Tab[] = [
    {label: 'Charging Stations', value: 'facility-charging-stations', permission: 'page/chargers/facility-charging-stations'},
    {label: 'Charger Types', value: 'charger-types', permission: 'page/chargers/charger-types'},
  ];
}
