import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ActionBtnComponent} from './action-btn.component';
import {SvgIconModule} from '../svg-icon/svg-icon.module';

@NgModule({
  declarations: [
    ActionBtnComponent,
  ],
  imports: [
    CommonModule,
    SvgIconModule,
  ],
  exports: [
    ActionBtnComponent,
  ],
})
export class ActionBtnModule {
}
