<div class="facility-overview-graph__main bdd-id--facility-overview-graph--main">
    <div class="facility-overview-graph__max-capacity">
        <h1 class="title mt-2 mt-md-0">
            <span class="collapser d-md-none"
                 (click)="collapsed = !collapsed">
                    <span *ngIf="!collapsed; else collapsedIcon">
                        <mat-icon>expand_less</mat-icon>
                    </span>
                <ng-template #collapsedIcon>
                        <span class="material-symbols-outlined">
                            <mat-icon>expand_more</mat-icon>
                        </span>
                </ng-template>
            </span>
            Max Capacity:
            <span class="value">{{facilityState?.calculatedMeta[0]?.maxCapacity | number:'1.2-2'}}<span class="smaller">{{optimisationUnit}}</span></span>
        </h1>
        <div class="box" [class.collapsed]="collapsed"></div>
    </div>

    <div class="facility-overview-graph__container collapsible" [class.collapsed]="collapsed">
        <div *ngIf="facilityStateLoaded; then loaded else loading"></div>

        <ng-template #loaded>
            <div *ngIf="config; then drawGraph else drawNoDataMessage"></div>

            <ng-template #drawGraph>
                <design-bar class="bdd-id--facility-overview-graph--design-bar d-none d-md-block" [config]="config"></design-bar>

                <div class="bdd-id--facility-overview-graph--design-bar--mobile d-md-none row mt-2 mb-2">
                  <div class="col d-flex">
                    <div class="legend-color-block facility-load-legend"></div>
                    <div class="legend-value-block">
                      <span class="legend-title">Facility Load:</span>
                      <span class="legend-value">{{facilityState.calculatedMeta[0].facilityLoad | number:'1.2-2'}}{{optimisationUnit}}</span>
                    </div>
                  </div>
                  <div class="col d-flex">
                    <div class="legend-color-block ev-load-legend"></div>
                    <div class="legend-value-block">
                      <span class="legend-title">EV Load:</span>
                      <span class="legend-value">{{facilityState.calculatedMeta[0].evLoad | number:'1.2-2'}}{{optimisationUnit}}</span>
                    </div>
                  </div>
                  <div class="w-100 mb-2"></div>
                  <div class="col d-flex">
                    <div class="legend-color-block spare-legend"></div>
                    <div class="legend-value-block">
                      <span class="legend-title">Spare:</span>
                      <span class="legend-value">{{facilityState.calculatedMeta[0].spare | number:'1.2-2'}}{{optimisationUnit}}</span>
                    </div>
                  </div>
                  <div class="col d-flex">
                    <div class="legend-color-block buffer-legend"></div>
                    <div class="legend-value-block">
                      <span class="legend-title">Buffer:</span>
                      <span class="legend-value">{{facilityState.calculatedMeta[0].bufferRemaining | number:'1.2-2'}}{{optimisationUnit}}</span>
                    </div>
                  </div>
                </div>
            </ng-template>

            <ng-template #drawNoDataMessage>
                <div class="no-data-row bdd-id--facility-overview-graph--no-data-row">
                    Insufficient data to draw the Live Load graph.
                </div>
            </ng-template>
        </ng-template>

        <ng-template #loading>
            <div class="facility-overview-graph__loading">
                <mat-spinner [diameter]="75"></mat-spinner>
            </div>
        </ng-template>
    </div>
</div>
