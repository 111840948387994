import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HeaderComponent} from './header.component';
import {AuthPermissionModule} from '../../../shared/directives/auth-permission/auth-permission.module';
import {SvgIconModule} from '../../../shared/components/svg-icon/svg-icon.module';

@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    AuthPermissionModule,
    SvgIconModule,
  ],
  exports: [
    HeaderComponent,
  ],
})
export class HeaderModule {

}
