import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthPermissionDirective} from './auth-permission.directive';

@NgModule({
  declarations: [
    AuthPermissionDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AuthPermissionDirective,
  ],
})
export class AuthPermissionModule {
}
