import {NgModule} from '@angular/core';

import {PasswordRequirementsCardComponent} from './password-requirements-card.component';

@NgModule({
  declarations: [
    PasswordRequirementsCardComponent,
  ],
  exports: [
    PasswordRequirementsCardComponent,
  ],
})
export class PasswordRequirementsModule {
}
