<div *ngIf="facilityState; then loaded else loading"></div>

<ng-template #loaded>
  <div class="fleet-location-overview bdd-id--fleet-location-overview d-flex flex-row">
    <div class="fleet-location-overview__zone-area d-flex flex-column justify-content-between">
      <div>
        <label class="fac-label">Facility</label>
        <mat-select class="input-style mb-auto bdd-id--fleet-location--facility-select"
                    placeholder="Facility"
                    [(value)]="selectedFacilityId"
                    (selectionChange)="facilityChanged.emit($event.value)">
            <mat-option *ngFor="let f of facilities" [value]="f.facilityId">{{f.name}}</mat-option>
        </mat-select>
      </div>
      <div>
        <mat-select class="full-size filter-select input-style bdd-id--fleet-location-overview--vehicle-select"
                    [formControl]="selectedVehicleControl"
                    (openedChange)="handleSelectOpenChanged($event)"
                    (selectionChange)="vehicleSelected($event)"
                    placeholder="Focus on Vehicle">
          <div class="p-2 position-relative">
            <input #filterInput class="full-size" type="text" [formControl]="textFilterControl"/>
            <button *ngIf="filterInput.value.length > 0" class="btn bg-transparent clear-btn" (click)="filterInput.value = ''">
              <i class="fa-solid fa-xmark" aria-hidden="true"></i>
            </button>
          </div>

          <mat-option
            *ngFor="let vehicle of vehicles"
            [disabled]="!vehicle.hasValidCoordinates"
            [value]="vehicle"
            [class.fleet-location-overview__hide-option]="filterInput.value !== '' && vehicle.evIdentifier.toLowerCase().indexOf(filterInput.value.toLowerCase()) === -1">
            {{ vehicle.evIdentifier }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <facility-view-info-filter class="flex-fill" #socSelector
                               [title]="'Vehicles State of Charge'"
                               [data]="stateOfChargeChartData"
                               [cols]="2"
                               [multiple]="true"
                               [placeholder]="'Any State of Charge'"
                               (filterChanges)="filterChange('soc', $event)">
    </facility-view-info-filter>
    <facility-view-info-filter class="flex-fill" #statusSelector
                               [title]="'Plug In Status'"
                               [data]="pluggedInStatus"
                               [cols]="2"
                               [multiple]="true"
                               [placeholder]="'Any Status'"
                               (filterChanges)="filterChange('plugInStatus', $event)">
    </facility-view-info-filter>
    <div class="d-flex justify-content-end align-items-end fleet-location-overview__select-facility-clear-all-block">
      <div class="mr-2">
        <button mat-flat-button class="bdd-id--fleet-location-overview--select-facility-btn select-facility-btn" (click)="selectFacility()">
          <img src="/assets/images/map/facilities.svg" alt="Select Facility"/>
        </button>
      </div>
      <div class="d-flex flex-row justify-content-start mt-auto">
        <button mat-flat-button (click)="clearAllFilters()">Clear All</button>
      </div>
    </div>
</div>
</ng-template>

<ng-template #loading>
    <div class="fleet-location-overview__loading-spinner bdd-id--fleet-location-overview--loading-spinner">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
