import {Component, Input, OnInit} from '@angular/core';
import {AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import {TableColumn} from '../../../table-addons/table-column.model';
import {tableColumnsValidator} from './settings-options-table-columns.validator';

@Component({
  selector: 'settings-options-table-columns',
  templateUrl: './settings-options-table-columns.component.html',
  styleUrls: ['./settings-options-table-columns.component.scss'],
})
export class SettingsOptionsTableColumnsComponent implements OnInit {
  @Input() public formKey: string;
  @Input() public formGroup: UntypedFormGroup;

  @Input() public label: string;
  @Input() public tableColumnsSelected: string[];
  @Input() public tableColumns: TableColumn[] = [];
  @Input() public minRequired: number;
  @Input() public maxLimit: number;

  public tableColumnsGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  public ngOnInit() {
    this.tableColumnsGroup = this.createFormOptions();

    if (this.formKey) {
      this.formGroup.addControl(this.formKey, this.tableColumnsGroup);
    }
  }

  private createFormOptions() {
    const options: AbstractControlOptions = {
      validators: tableColumnsValidator(this.minRequired, this.maxLimit),
    };

    const tableColumnsToDisplay = this.tableColumns.filter(column => !column.isMandatory);
    const tableColumnsGroup = this.formBuilder.group({}, options);

    for (const column of tableColumnsToDisplay) {
      const valueToUse = Array.isArray(this.tableColumnsSelected) && this.tableColumnsSelected.includes(column.id)
        ? true
        : false;

      const control = this.formBuilder.control(valueToUse);

      tableColumnsGroup.addControl(column.id, control);
    }

    return tableColumnsGroup;
  }
}
