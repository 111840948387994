import {Component, Host, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {TableFiltersComponent} from '../table-filters.component';
import {TableFilter, TableFilterConfig, TableFilterOperators} from '../table-filters.model';
import {TableFiltersService} from '../table-filters.service';

interface FormValues {
  operator: TableFilterOperators;
  valueGreaterThan: string;
  valueLessThan: string;
  valueEquals: string;
  unit?: string;
}

@Component({
  selector: 'table-filters-number-type',
  templateUrl: './table-filters-number-type.component.html',
  styleUrls: ['./table-filters-number-type.component.scss'],
})
export class TableFiltersNumberTypeComponent implements OnInit, OnDestroy {
  @Input() config: TableFilterConfig;

  public optionsForm: UntypedFormGroup;
  public readonly TableFilterOperators = TableFilterOperators;

  private destroyed$ = new Subject<void>();

  constructor(
    @Host() private parent: TableFiltersComponent,
    private formBuilder: UntypedFormBuilder,
    private tableFiltersService: TableFiltersService,
  ) {
  }

  get operator() {
    return this.optionsForm.get('operator');
  }

  public ngOnInit() {
    this.optionsForm = this.createOptionsForm();

    this.optionsForm.get('operator').valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        const fieldMap = {
          [TableFilterOperators.GreaterThan]: 'valueGreaterThan',
          [TableFilterOperators.LessThan]: 'valueLessThan',
          [TableFilterOperators.Equals]: 'valueEquals',
        };

        Object.values(fieldMap)
          .forEach(fieldName => {
            const control = this.optionsForm.get(fieldName);

            if (fieldName === fieldMap[value]) {
              control.setValidators([Validators.required]);
            } else {
              control.clearValidators();
            }

            control.updateValueAndValidity();
          });
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onSubmit() {
    if (!this.optionsForm.valid) {
      this.optionsForm.markAllAsTouched();
      return;
    }

    const filter = this.buildFilterObject(this.optionsForm.value);
    if (!filter) {
      return;
    }

    this.parent.addFilter(filter);
  }

  public cancel() {
    this.parent.deselectOption();
  }

  private createOptionsForm() {
    return this.formBuilder.group({
      operator: [TableFilterOperators.GreaterThan],
      valueGreaterThan: ['', [Validators.required]],
      valueLessThan: [''],
      valueEquals: [''],
      unit: [this.config.unit || this.config.unitsList?.[0] || ''],
    });
  }

  private buildFilterObject(formValues: FormValues): TableFilter {
    const getValue = () => {
      if (formValues.operator === TableFilterOperators.GreaterThan) {
        return formValues.valueGreaterThan;
      }

      if (formValues.operator === TableFilterOperators.LessThan) {
        return formValues.valueLessThan;
      }

      if (formValues.operator === TableFilterOperators.Equals) {
        return formValues.valueEquals;
      }
    };

    const value = getValue();
    if (!value) {
      return;
    }

    const label = this.tableFiltersService.getLabel(formValues.operator, this.config.label, value, formValues.unit);

    return {
      label,
      data: {
        field: this.config.field,
        operator: formValues.operator,
        value: Number(value), // Value from the form will be a string
        unit: formValues.unit,
      },
    };
  }
}
