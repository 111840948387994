import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {fromEvent, of, Subject} from 'rxjs';
import {auditTime, catchError, filter, map, takeUntil} from 'rxjs/operators';

import {EvseStatus, EvseView} from '../../models';
import {ChargePointStatus, EvseState, EvState} from '../../../shared/models/entity-states';
import {DataDistributor} from '../../services/data-distributor.service';
import {Facility} from '../../../shared/models/entities';
import {EvseStatusFilters} from './evses-status-filters/evses-status-filters.component';
import {EventType, EvseStateService} from '../../services/evse-state.service';
import {TableColumn} from '../../../shared/components/table-addons/table-column.model';
import {EvsesStatusTableSettingsService} from './evses-status-table-settings/evses-status-table-settings.service';
import {TableFilter, TableFilterConfig} from '../../../shared/components/table-filters/table-filters.model';
import {TableFiltersService} from '../../../shared/components/table-filters/table-filters.service';
import {createTableSearchArray} from '../../../shared/components/asset-tags/asset-tags.utils';
import {TableWrapperComponent} from '../../../shared/components/table-addons/table-wrapper/table-wrapper.component';
import {UntypedFormControl} from '@angular/forms';
import {ChargingGroup} from '../../../shared/models/entities/charging-group.model';
import {VehicleGroup} from '../../../shared/models/entities/vehicle-group.model';

const getProperty = (object: any, path: string, unit?: string) => {
  const value = path.split('.')
    .reduce((obj, key) => obj && obj[key], object);

  return value && unit ? value + unit : value;
};

const evseStatusTableChargingGroupFilterPrefix = 'evse-status-table-charging-group-filter';
const evseStatusTableEvseStatusFilterPrefix = 'evse-status-table-evse-status-filter';

@Component({
  selector: 'evses-status-table',
  templateUrl: './evses-status-table.component.html',
  styleUrls: ['./evses-status-table.component.scss'],
})

export class EvsesStatusTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() facility: Facility;
  @Input() chargingGroups: ChargingGroup[];
  @Input() vehicleGroups: VehicleGroup[];

  @ViewChild('table') table: TableWrapperComponent<EvseView>;

  public initialized = false;
  public currentSearchTerm = '';
  public pageSize: number;
  public evseStatuses = this.getEvseStatuses();
  public allCGSelected = false;
  public allEvseStatusesSelected = false;

  public dataSource: MatTableDataSource<EvseView> = new MatTableDataSource([]);
  public tableColumns: TableColumn[] = [];

  public cgTextFilterControl = new UntypedFormControl();
  public evseStatusTextFilterControl = new UntypedFormControl();
  public selectedChargingGroups = new UntypedFormControl([]);
  public selectedEvseStatuses = new UntypedFormControl([]);

  public evseTableFilters: TableFilter[];
  public tableFilterConfig: TableFilterConfig[] = [
    {label: 'Vehicle Reg', field: 'regNo', type: 'string'},
    {label: 'Tags', field: 'vehicle.tags', type: 'string', disableEquals: true},
    {label: 'Setpoint', field: 'setpoint', type: 'number', unitsList:['kW', 'A']},
    {label: 'SoC', field: 'stateOfChargeInPercent', type: 'number', unit: '%'},
    {label: 'Location', field: 'location', type: 'string'},
    {label: 'Charger Zone', field: 'zone', type: 'string'},
  ];
  public isMobileSize = false;

  private evsesView: EvseView[];
  private allTableColumns: TableColumn[] = [
    {label: 'Charger', id: 'chargingStation.name', widthFlex: 1.2, isMandatory: true, isShownMobile: true},
    {label: 'EVSE', id: 'extEvseId', widthFlex: 0.7, isMandatory: true, isShownMobile: true},
    {label: 'Charging Group', id: 'chargingGroup.name', widthFlex: 1.2, isMandatory: false},
    {label: 'Session Id', id: 'sessionId', widthFlex: 0.6, isMandatory: false},
    {label: 'Session Start Time', id: 'sessionStart', widthFlex: 1.2, isMandatory: false},
    {label: 'Vehicle', id: 'vehicle.evIdentifier', widthFlex: 1, isShownMobile: true},
    {label: 'Vehicle Group', id: 'vehicleGroup.name', widthFlex: 1.2, isMandatory: false},
    {label: 'Power', id: 'power', widthFlex: 1.1, isMandatory: false, isShownMobile: true, alignRight: true},
    {label: 'Current', id: 'current', widthFlex: 1.1, isMandatory: false, isShownMobile: false, alignRight: true},
    {label: 'Energy', id: 'energy', widthFlex: 1.1, isMandatory: false, isShownMobile: false, alignRight: true},
    {label: 'SoC', id: 'stateOfChargeInPercent', widthFlex: 0.7, alignRight: true},
    {label: 'SetPoint', id: 'setpoint', widthFlex: 1.1, alignRight: true},
    {label: 'Notification', id: 'status', widthFlex: 1.1, isShownMobile: true, mobileWidthFlex: 0.5},
    {label: 'Status', id: 'fvEvseStatus', widthFlex: 1.4},
    {label: 'Updated', id: 'timestamp', widthFlex: 1.3, isShownMobile: true, mobileWidthFlex: 0.8},
    {label: 'Charger Location', id: 'location', widthFlex: 1.1},
    {label: 'Charger Zone', id: 'zone', widthFlex: 1.1},
    {label: 'Charger Max Power', id: 'chargingStation.maxPower', widthFlex: 1.2, alignRight: true},
    {label: 'EVSE Max Power', id: 'maxPower', widthFlex: 1.2, alignRight: true},
    {label: 'EVSE Default Power', id: 'defaultPower', widthFlex: 1.2, alignRight: true},
    {label: 'Charger Manufacturer', id: 'chargingStation.manufacturer', widthFlex: 1},
    {label: 'Charger Model', id: 'chargingStation.model', widthFlex: 1},
    {label: 'Charger CSMS Ref.', id: 'chargingStation.extChargingStationId', widthFlex: 1},
    {label: 'Charger Firmware Version', id: 'chargingStation.firmwareVersion', widthFlex: 1},
    {label: 'Charger Phase', id: 'chargingStation.phase', widthFlex: 1},
    {label: 'Charger Power Type', id: 'chargingStation.powerType', widthFlex: 1},
    {label: 'Charger Connector Types', id: 'chargingStation.connectorTypes', widthFlex: 1},
    {label: 'Charger Max Current', id: 'chargingStation.maxCurrent', widthFlex: 1.1, isMandatory: false, isShownMobile: false, alignRight: true},
    {label: 'EVSE Max Current', id: 'maxCurrent', widthFlex: 1.1, isMandatory: false, isShownMobile: false, alignRight: true},
    {label: 'EVSE Default Current', id: 'defaultCurrent', widthFlex: 1.1, isMandatory: false, isShownMobile: false, alignRight: true},
    {label: 'Vehicle Reg', id: 'regNo', widthFlex: 1.3, isMandatory: false, isShownMobile: false},
    {label: 'Vehicle Manufacturer', id: 'vehicle.manufacturer', widthFlex: 1},
    {label: 'Vehicle Model', id: 'vehicle.model', widthFlex: 1},
    {label: 'Vehicle Authentication details', id: 'vehicle.authenticationId', widthFlex: 1},
    {label: 'Vehicle Year of Manufacture', id: 'vehicle.year', widthFlex: 1},
    {label: 'Vehicle Month of Manufacture', id: 'vehicle.month', widthFlex: 1},
    {label: 'Vehicle Internal Name', id: 'vehicle.name', widthFlex: 1},
    {label: 'Vehicle AC Charging Phase', id: 'vehicle.acChargingPhase', widthFlex: 1},
    {label: 'Vehicle Battery Capacity', id: 'vehicle.batteryCapacity', widthFlex: 1, alignRight: true},
    {label: 'Vehicle Authentication ID', id: 'vehicle.idToken', widthFlex: 1},
    {label: 'Vehicle Max Power', id: 'vehicle.maxPower', widthFlex: 1, alignRight: true},
    {label: 'Vehicle Max Current', id: 'vehicle.maxCurrent', widthFlex: 1, alignRight: true},
    {label: 'Vehicle Min Power', id: 'vehicle.minPower', widthFlex: 1, alignRight: true},
    {label: 'Vehicle Min Current', id: 'vehicle.minCurrent', widthFlex: 1, alignRight: true},
    {label: 'Vehicle Max State of Charge', id: 'vehicle.maxSoc', widthFlex: 1, alignRight: true},
    {label: 'Vehicle Telematics Provider', id: 'vehicle.telematicsProviderName', widthFlex: 1},
    {label: 'Tags', id: 'vehicle.tags', widthFlex: 0.7},
  ];

  private evseStatusFilters: EvseStatusFilters;
  private destroyed$ = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private evseStateService: EvseStateService,
    private dataDistributor: DataDistributor,
    private settingsService: EvsesStatusTableSettingsService,
    private tableFiltersService: TableFiltersService,
  ) {
  }

  public ngOnInit() {
    const isMobile = () => document.body.offsetWidth < 992;
    this.isMobileSize = isMobile();

    this.updateConfigValues();

    const screenChange = fromEvent(window, 'resize')
      .pipe(
        auditTime(100),
        map(isMobile),
        filter(isMob => isMob !== this.isMobileSize),
      );

    screenChange.subscribe(isMobileSize => {
      this.isMobileSize = isMobileSize;
      this.updateConfigValues();
    });

    this.settingsService.getUpdateSubject()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.updateConfigValues();
      });

    this.evseStateService.eventEmitter
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: EventType) => {
        if (event === 'filterAndRefresh') {
          this.filterDataSource();
        }
      });

    this.dataDistributor
      .evState()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((evState: EvState) => {
        if (!this.evsesView || evState.facilityId !== this.facility.facilityId || !evState.evseId) {
          return;
        }
        const evseView: EvseView = this.evsesView.find(evseViewItem => evseViewItem.evseId === evState.evseId);
        if (evseView) {
          const evseState: EvseState = {
            chargingStationId: evseView.chargingStationId,
            evseId: evseView.evseId,
            facilityId: evseView.facilityId,
            isPluggedIn: evseView.isPluggedIn,
            timestamp: evState.timestamp,
            stateOfCharge: evState.stateOfCharge,
            stateOfChargeInPercent: evState.stateOfChargeInPercent,
            stateOfChargeType: evState.stateOfChargeType,
            evId: evState.evId,
            evseStatusNotification: evseView.evseStatusNotification,
            power: evseView.power,
            current: evseView.current,
            energy: evseView.energy,
            setpoint: evseView.setpoint,
          };

          this.evseStateService.updateEvseViewWithNewState(evseView, evseState, false, this.vehicleGroups);

          // Make sure the rows components are updated and then announce that the evseState was updated.
          this.changeDetectorRef.detectChanges();
          this.evseStateService.evseStateUpdated$.next(evseState);
        }

      });


    this.dataDistributor
      .evseState()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((evseState: EvseState) => {
        if (!this.evsesView || evseState.facilityId !== this.facility.facilityId) {
          return;
        }

        // Keep SOC for unregistered EV
        if (!evseState.evId) {
          evseState.stateOfChargeInPercent = evseState.stateOfCharge;
        }
        
        delete evseState.stateOfChargeType;
        delete evseState.stateOfCharge;

        const evseView: EvseView = this.evsesView.find(evseViewItem => evseViewItem.evseId === evseState.evseId);
        if (evseView) {
          this.evseStateService.updateEvseViewWithNewState(evseView, evseState, false, this.vehicleGroups);

          // Make sure the rows components are updated and then announce that the evseState was updated.
          this.changeDetectorRef.detectChanges();
          this.evseStateService.evseStateUpdated$.next(evseState);
          this.table.renderRows();
        }
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    // run on changes only if the Facility is loaded for the first time, or it changes to a facility with another id
    if (this.facility && (!changes.facility?.previousValue ||
      (changes.facility?.previousValue as Facility).facilityId !== (changes.facility?.currentValue as Facility).facilityId)) {
      this.evseStateService
        .getEvsesViewByFacilityId(this.facility.facilityId)
        .pipe(catchError(() => of([])))
        .subscribe(evsesView => {
          this.evsesView = evsesView;
          this.allCGSelected = false;
          this.allEvseStatusesSelected = false;
          this.selectedEvseStatuses.setValue([]);
          this.updateConfigValues();

          this.init();
        });
    }

    if (changes.chargingGroups?.currentValue) {
      this.selectedChargingGroups.setValue([]);

      const chargingGroupSelection = sessionStorage.getItem(evseStatusTableChargingGroupFilterPrefix + '-' + this.facility.facilityId);

      if (chargingGroupSelection) {
        const cgIds = this.chargingGroups.map(x => x.chargingGroupId);
        // Check if previously selected charging groups still exist
        const existingSelectedChargingGroups = JSON.parse(chargingGroupSelection).filter(x => cgIds.includes(x));
        this.selectedChargingGroups.setValue(existingSelectedChargingGroups);
      }

      this.filterDataSource();
    }
  }

  public onSearched(searchTerm: string) {
    this.currentSearchTerm = searchTerm;

    this.filterDataSource();
  }

  public onChangeEvseStatusFilter(filters: EvseStatusFilters) {
    this.evseStatusFilters = filters;

    this.filterDataSource();
  }

  public onChangeEvseTableFilters(filters: TableFilter[]) {
    this.evseTableFilters = filters;

    this.filterDataSource();
  }

  public openSettingsDialog() {
    let allTableColumns = this.hideOrShowColumns(this.allTableColumns);
    this.settingsService.openSettingsDialog(allTableColumns);
  }

  private hideOrShowColumns(tableColumns: TableColumn[]) {
    let resultColumns = tableColumns.slice();
    if (resultColumns.map(x => x.id).includes('chargingGroup.name')) {
      const chargingGroupName = resultColumns.find(x => x.id == 'chargingGroup.name');
      if (!this.evsesView?.some(x => x.chargingGroup)) {
        chargingGroupName.isHidden = true;
      } else {
        chargingGroupName.isHidden = false;
      }
    }
    
    return resultColumns;
  }

  private init() {
    this.setupTableDataSource();
    this.preselectSavedEvseStatus();

    this.initialized = true;
  }

  private preselectSavedEvseStatus() {
    const evseStatusesSelection = sessionStorage.getItem(evseStatusTableEvseStatusFilterPrefix + '-' + this.facility.facilityId);

    if (evseStatusesSelection) {
      this.selectedEvseStatuses.setValue(JSON.parse(evseStatusesSelection));
    }

    this.filterDataSource();
  }

  private getTableColumns() {
    const tableColumnsToDisplay = this.settingsService.getTableColumns();

    if (this.isMobileSize) {
      return this.allTableColumns.filter(column => {
        return column.isShownMobile;
      });
    }

    return this.allTableColumns.filter(column => {
      if (column.isMandatory) {
        return true;
      }

      if (!tableColumnsToDisplay.includes(column.id)) {
        return false;
      }

      return true;
    });
  }

  private setupTableDataSource() {
    this.dataSource.data = this.evsesView;

    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      filter = filter.trim().toLowerCase();

      const allValues = this.tableColumns.reduce((results, column) => {
        if (column.id === 'timestamp') {
          return results;
        }

        if (column.id === 'chargingStation.connectorTypes') {
          const connectorTypes = (data.chargingStation.connectorTypes || [])
            .map(obj => obj.connectorTypeName)
            .join(', ');

          return results.concat(connectorTypes);
        }

        if (column.id === 'vehicle.authenticationId') {
          if (data.vehicle?.idTokenType && data.vehicle?.idToken) {
            return results.concat([data.vehicle?.idTokenType && data.vehicle?.idToken]);
          }

          return results;
        }

        if (column.id === 'vehicle.tags') {
          return results.concat(createTableSearchArray(data.vehicle?.tags));
        }

        const applyValueIfFound = (object: any, key: string, unit?: string) => {
          const valueFound = getProperty(object, key, unit);
          if (valueFound) {
            results.push(valueFound);
          }

          return results;
        };

        if (['maxPower', 'power', 'setpoint', 'vehicle.maxPower', 'vehicle.minPower'].includes(column.id)) {
          return applyValueIfFound(data, column.id, 'kW');
        }

        if (['vehicle.batteryCapacity', 'energy'].includes(column.id)) {
          return applyValueIfFound(data, column.id, 'kWh');
        }

        return applyValueIfFound(data, column.id);
      }, ['*']);

      const searchString = allValues
        .join('')
        .trim()
        .toLowerCase();

      return this.checkAdditionalFilters(data) && searchString.indexOf(filter) !== -1;
    };

    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'chargingStation.connectorTypes') {
        return (item.chargingStation.connectorTypes || [])
          .map(obj => obj.connectorTypeName)
          .join(', ');
      }

      if (property === 'vehicle.authenticationId') {
        return item.vehicle?.idTokenType;
      }

      if (property === 'timestamp') {
        return new Date(item.timestamp).valueOf();
      }

      return getProperty(item, property);
    };

    // re-run the filter with the current search term and selected evse status
    this.filterDataSource();
  }

  private filterDataSource() {
    this.dataSource.filter = this.currentSearchTerm ? this.currentSearchTerm : '*';
  }

  private checkAdditionalFilters(evseData: EvseView) {
    if (this.selectedChargingGroups.value?.length > 0) {
      const selectedCGId = this.selectedChargingGroups.value;

      if (!selectedCGId.includes(evseData.chargingGroup?.chargingGroupId)) {
        return false;
      }
    }

    if (this.selectedEvseStatuses.value?.length > 0) {
      const selectedEvseStatusesId = this.selectedEvseStatuses.value;

      if (!selectedEvseStatusesId.includes(evseData.evseStatusNotification?.status) 
      && !(!evseData.evseStatusNotification?.status && selectedEvseStatusesId.includes(ChargePointStatus.Unknown))) {
        return false;
      }
    }

    if (this.evseTableFilters && this.evseTableFilters.length) {
      const setpointFilters = this.evseTableFilters.filter(x => x.data.field === 'setpoint')?.map(x => x.data);

      for (const setpointFilter of setpointFilters) {
        if (setpointFilter && evseData.optimisationUnit !== setpointFilter.unit) {
          return false;
        }
      }

      const isFilterMatch = this.tableFiltersService.checkFilters(evseData, this.evseTableFilters);
      if (!isFilterMatch) {
        return false;
      }
    }
    
    if (!this.evseStatusFilters?.evseStatus?.length && !this.evseStatusFilters?.offlineStatus) {
      return true;
    }

    if (this.evseStatusFilters?.offlineStatus && ((evseData.online != null && evseData.online) || evseData.online == null)) {
      return false;
    }

    if (this.evseStatusFilters?.evseStatus?.length === 0) {
      return true;
    }

    const evseStatusFilters = this.evseStatusFilters?.evseStatus;

    const isUnavailableSelected = evseStatusFilters.includes(EvseStatus.Unavailable);
    const unavailableStatuses = [
      ChargePointStatus.Faulted,
      ChargePointStatus.Unavailable,
      ChargePointStatus.Reserved,
    ];

    if (isUnavailableSelected && evseData.evseStatusNotification?.status && unavailableStatuses.includes(evseData.evseStatusNotification.status)) {
      return true;
    }

    if (!isUnavailableSelected && evseData.evseStatusNotification?.status && unavailableStatuses.includes(evseData.evseStatusNotification.status)) {
      return false;
    }

    return evseStatusFilters
      .filter(item => item !== EvseStatus.Unavailable)
      .includes(evseData.evseStatus as EvseStatus);
  }

  private updateConfigValues() {
    if (this.evsesView?.length) {
      this.updateSetpointFilterConfig();
    }
    
    this.tableColumns = this.hideOrShowColumns(this.getTableColumns());
    this.pageSize = this.settingsService.getPageSize();
  }

  private updateSetpointFilterConfig() {
    const setpointConfig = this.tableFilterConfig.find(x => x.field === 'setpoint');

    const unitsSet = new Set<string>();
    this.evsesView?.forEach(element => {
      unitsSet.add(element.optimisationUnit);
    });

    if (unitsSet.size == 1) {
      setpointConfig.unit = Array.from(unitsSet)[0];
      delete setpointConfig.unitsList;
    } else {
      setpointConfig.unitsList = Array.from(unitsSet);
      delete setpointConfig.unit;
    }
  }

  public evseStatusChanged() {
    if (this.allEvseStatusesSelected && this.selectedEvseStatuses.value.length < this.evseStatuses.length) {
      this.allEvseStatusesSelected = false;
    }
    this.filterDataSource();
    sessionStorage.setItem(evseStatusTableEvseStatusFilterPrefix + '-' + this.facility.facilityId, JSON.stringify(this.selectedEvseStatuses.value));
  }

  public cgChanged() {
    if (this.allCGSelected && this.selectedChargingGroups.value.length < this.chargingGroups.length) {
      this.allCGSelected = false;
    }
    this.filterDataSource();
    sessionStorage.setItem(evseStatusTableChargingGroupFilterPrefix + '-' + this.facility.facilityId, JSON.stringify(this.selectedChargingGroups.value));
  }

  public handleSelectOpenChanged(value: boolean, elementRef: HTMLElement, control: UntypedFormControl) {
    if (value === true && !control.value) {
      elementRef.focus();
    }
  }

  selectAllEvseStatuses() {
    if (this.allEvseStatusesSelected) {
      this.selectedEvseStatuses.setValue(this.evseStatuses.map(x => x.id));
      sessionStorage.setItem(evseStatusTableEvseStatusFilterPrefix + '-' + this.facility.facilityId, JSON.stringify(this.selectedEvseStatuses.value));
    } else {
      this.selectedEvseStatuses.setValue([]);
      sessionStorage.removeItem(evseStatusTableEvseStatusFilterPrefix + '-' + this.facility.facilityId);
    }

    this.filterDataSource();
  }

  selectAllChargingGroups() {
    if (this.allCGSelected) {
      this.selectedChargingGroups.setValue(this.chargingGroups.map(x => x.chargingGroupId));
      sessionStorage.setItem(evseStatusTableChargingGroupFilterPrefix + '-' + this.facility.facilityId, JSON.stringify(this.selectedChargingGroups.value));
    } else {
      this.selectedChargingGroups.setValue([]);
      sessionStorage.removeItem(evseStatusTableChargingGroupFilterPrefix + '-' + this.facility.facilityId);
    }

    this.filterDataSource();
  }

  getEvseStatuses() {
    return Object.keys(ChargePointStatus)
    .map(key => ({ id: key, name: ChargePointStatus[key] }))
  }
}
