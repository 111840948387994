import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {fromEvent, interval, Subject} from 'rxjs';
import {auditTime, filter, map, takeUntil} from 'rxjs/operators';

import {EvseStatus, EvseView} from '../../../models';
import {NavigationHelperService} from '../../../../shared/services/navigation-helper.service';
import {TableColumn} from '../../../../shared/components/table-addons/table-column.model';
import {ConnectorType} from '../../../../shared/models/entities';
import {EvseState} from '../../../../shared/models/entity-states';
import {EvseStateService} from '../../../services/evse-state.service';

@Component({
  selector: 'evses-status-table-row',
  templateUrl: './evses-status-table-row.component.html',
  styleUrls: ['./evses-status-table-row.component.scss'],
})
export class EvsesStatusTableRowComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public evseView: EvseView;
  @Input() public tableColumns: TableColumn[];

  @ViewChild('stateOfChargeBarElement') stateOfChargeBarElement: ElementRef;
  public isMobileSize: boolean;

  @HostListener('window:resize')
  public onResize() {
    this.updateSoCBarWidth();
  }

  public EvseStatus = EvseStatus;
  public stateOfChargeBarWidth: number;
  public latestTimestamp: Date | string;
  public forceTimeUpdate: number;

  private destroyed$ = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private navigationHelper: NavigationHelperService,
    private evseStateService: EvseStateService,
  ) {
  }

  public ngOnInit() {
    const isMobile = () => document.body.offsetWidth < 992;
    this.isMobileSize = isMobile();


    const screenChange = fromEvent(window, 'resize')
      .pipe(
        auditTime(100),
        map(isMobile),
        filter(isMob => isMob !== this.isMobileSize),
      );

    this.evseStateService.evseStateUpdated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((evseState: EvseState) => {
        if (this.evseView.evseId === evseState.evseId) {
          this.latestTimestamp = this.getLatestTimestamp();
        }
      });

    this.latestTimestamp = this.getLatestTimestamp();

    interval(60 * 1000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.forceTimeUpdate = Math.random();
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  public ngAfterViewInit() {
    this.updateSoCBarWidth();
  }

  public navigateToChargingStationPage() {
    const {facilityId, chargingStationId} = this.evseView;
    if (!facilityId || !chargingStationId) {
      return;
    }

    this.navigationHelper.navigateToTenantPath(`chargers/facility-charging-stations/${facilityId}/${chargingStationId}/view`);
  }

  public navigateToVehiclePage() {
    const {facilityId, evId} = this.evseView;
    if (!facilityId || !evId) {
      return;
    }

    this.navigationHelper.navigateToTenantPath(`vehicles/facility-vehicles/${facilityId}/${evId}/view`);
  }

  public navigateToSessionPage() {
    const {facilityId, sessionId} = this.evseView;
    if (!facilityId || !sessionId) {
      return;
    }

    this.navigationHelper.navigateToTenantPath(`sessions/${facilityId}`, {queryParams: {sessionId}});
  }

  public formatConnectorTypes(connectorTypes?: ConnectorType[]) {
    return (connectorTypes || [])
      .map(item => item.connectorTypeName)
      .join(', ');
  }

  private getLatestTimestamp() {
    if (!this.evseView) {
      return null;
    }

    const notificationTimestamp = this.evseView.evseStatusNotification?.timestamp;
    return notificationTimestamp && new Date(notificationTimestamp) > new Date(this.evseView.timestamp)
      ? notificationTimestamp
      : this.evseView.timestamp;
  }

  private updateSoCBarWidth() {
    if (this.stateOfChargeBarElement) {
      this.stateOfChargeBarWidth = this.stateOfChargeBarElement.nativeElement.offsetWidth;

      this.changeDetectorRef.detectChanges();
    }
  }
}
