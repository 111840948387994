import {Component} from '@angular/core';

import {Tab} from '../../../shared/components/tab-switch-header/tab-switch-header.component';

@Component({
  selector: 'configurations-outlet',
  templateUrl: './configurations-outlet.component.html',
  styleUrls: ['./configurations-outlet.component.scss'],
})
export class ConfigurationsOutletComponent {
  public readonly tabConfig: Tab[] = [
    {label: 'Charging Groups', value: 'charging-groups', permission: 'page/configurations/charging-groups', redirectToFacility: true},
    {label: 'Vehicle Groups', value: 'vehicle-groups', permission: 'page/configurations/vehicle-groups', redirectToFacility: true},
  ];
}
