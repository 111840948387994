import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {RouterHelperService} from '../../../shared/services/router-helper.service';
import {CurrentSessionService} from '../../../shared/services/current-session.service';
import {UserPreferencesService} from '../../../shared/services/user-preferences.service';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  public statusCode = 404;
  public buttonText = 'Back';

  public showNavigationButton = false;
  public previousPageUrl: string;

  urlSegmentToPageNameMap: Map<string, string> = new Map([
    ['dashboard', 'Dashboard'],
    ['facility-view', 'Facility View'],
  ]);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routerHelperService: RouterHelperService,
    private currentSessionService: CurrentSessionService,
    private userPreferencesService: UserPreferencesService,
  ) {
  }

  public async ngOnInit() {
    if (this.route.snapshot.params?.status) {
      this.statusCode = this.route.snapshot.params.status;
    }

    const previousPageUrl = this.routerHelperService.getPreviousUrl();
    if (previousPageUrl && previousPageUrl !== '/') {
      this.showNavigationButton = true;
      this.previousPageUrl = previousPageUrl;
    } else if (this.router.url !== '/' && this.currentSessionService.getTenantId()) {
      this.showNavigationButton = true;

      if (this.currentSessionService.getUserId()) {
        const preferences = await this.userPreferencesService.getUserPreferences();
        const homepagePath = preferences['homepage'];

        if (homepagePath) {
          const pageName = this.urlSegmentToPageNameMap.get(homepagePath);
          this.buttonText = pageName? 'Go to ' + pageName : 'Go to Dashboard';
        } else {
          this.buttonText = 'Go to Dashboard';
        }
      } else {
        this.buttonText = 'Go to Login';
      }
    }
  }

  public navigateAway() {
    if (this.previousPageUrl) {
      return this.router.navigateByUrl(this.previousPageUrl);
    }

    return this.router.navigate(['/', this.currentSessionService.getTenantId()]);
  }
}
