export const pick = (object: object, propNames: string[]) => propNames.reduce((newObject, key) => {
  if (object.hasOwnProperty(key)) {
    newObject[key] = object[key];
  }

  return newObject;
}, {});

/*
 Returns an array containing any nested objects from an object traversal.

 This array can then be filtered as needed i.e. arr.filter(item => item.myKey);
 */
export const flattenObject = (obj: object, initialValue = []) => Object.keys(obj)
  .reduce((results, key) => {
    if (obj[key] && typeof (obj[key]) === 'object') {
      const newInitalValue = !Array.isArray(obj[key]) ? [obj[key]] : [];

      return results.concat(flattenObject(obj[key], newInitalValue));
    }

    return results;
  }, initialValue);
