import {Component, Input} from '@angular/core';

import {EvseStatusNotification} from '../../../models/entity-states';

@Component({
  selector: 'cs-notification-popup',
  templateUrl: './cs-notification-popup.component.html',
  styleUrls: ['./cs-notification-popup.component.scss'],
})
export class CSNotificationsPopupComponent {
  @Input() public name: string;
  @Input() public notification: EvseStatusNotification;
}
