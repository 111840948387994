import {Component, Input, OnChanges, OnInit} from '@angular/core';

export interface DesignBarConfig {
  value: number;
  label?: string;
  itemStyle: {
    color: string;
    hatched?: boolean;
    removeGap?: boolean;
  };
  labelStyle?: {
    formatter: (param: any) => string;
    unit: string;
    alignVertical?: string;
    alignHorizontal?: string;
  };
}

@Component({
  selector: 'design-bar',
  templateUrl: './design-bar.component.html',
  styleUrls: ['./design-bar.component.scss'],
})
export class DesignBarComponent implements OnInit, OnChanges {
  @Input() public config: DesignBarConfig[];

  public height = 100;
  public maxWidth: number;
  public bars = [];

  public ngOnInit() {
    this.calcs();
  }

  public ngOnChanges() {
    this.calcs();
  }

  private calcs() {
    if (this.config?.length > 0) {
      const total = this.config
        .reduce((previousValue, currentValue) => previousValue + currentValue.value, 0);

      this.bars = this.config.map(input => {
        const output: any = Object.assign({}, input);
        output.label = input.label || '';
        output.percentage = Math.round((output.value / total) * 1000) / 10;

        if (input.labelStyle?.formatter) {
          output.labelHtml = input.labelStyle.formatter(input);
        } else {
          output.labelHtml = `<span>${output.label}</span>`;
        }

        return output;
      });
    }
  }
}
