import {Component, Input, OnChanges} from '@angular/core';

import {Facility} from '../../../../shared/models/entities';
import {ExpandedFacilityState} from '../../../../shared/models/entity-states';
import {DesignBarConfig} from '../../../../shared/components/design-bar/design-bar.component';
import {calculateColorOnLinearGradient, colorToStringRgb} from '../../../../shared/utils/color';
import {OptimisationUnit} from '../../../../shared/models/enums';

const ragRed = {r: 213, g: 40, b: 60};
const ragAmber = {r: 243, g: 174, b: 78};

const labelFormatter = (param: any) => {
  const first = `<div class="white-graph-label" slot="label">${param.label}</div>`;
  const second = `<div class="white-graph-label g-bolder g-bigger" slot="label">${param.value}</span><span class="smaller">${param.labelStyle.unit}</span></div>`;
  return first + second;
};

const facilityLoadGraphConfig = (value: number, unit: OptimisationUnit): DesignBarConfig => ({
  value,
  label: 'Facility Load',
  itemStyle: {
    color: '#959595',
  },
  labelStyle: {
    formatter: labelFormatter,
    unit,
  },
});

const evLoadGraphConfig = (unit: OptimisationUnit, value?: number): DesignBarConfig => ({
  value: value || 0,
  itemStyle: {
    color: '#f3ae4e',
  },
  label: 'EV Load',
  labelStyle: {
    alignVertical: 'top',
    formatter: labelFormatter,
    unit,
  },
});

const spareBufferConfig = (facilityState: ExpandedFacilityState, hasLowValues: boolean, unit: OptimisationUnit) => {
  const spareColor = '#4ab471';
  const configs: DesignBarConfig[] = [];
  const ragAmberRgbString = colorToStringRgb(ragAmber);
  let bufferColor = ragAmberRgbString;

  if (facilityState.calculatedMeta) {
    const {bufferUsed, bufferRemaining, spare} = facilityState.calculatedMeta[0];
    if (bufferUsed > 0) {
      const bufferPercentage = bufferUsed / (bufferUsed + bufferRemaining);
      let colorToRedRgbString = ragAmberRgbString;

      const gradientColor = calculateColorOnLinearGradient(ragAmber, ragRed, bufferPercentage);
      if (gradientColor) colorToRedRgbString = colorToStringRgb(gradientColor);

      bufferColor = `linear-gradient(90deg, ${ragAmberRgbString}, ${colorToRedRgbString})`;
    }

    if (spare > 0) {
      configs.push({
        value: spare,
        label: 'Spare',
        itemStyle: {
          color: spareColor,
        },
        labelStyle: {
          formatter: labelFormatter,
          unit,
          ...(hasLowValues ? {alignHorizontal: 'right'} : {}),
        },
      });
    }

    const bufferConfig = {
      value: bufferUsed,
      itemStyle: {
        color: bufferColor,
        hatched: true,
        removeGap: true,
      },
    };

    const bufferRemainingConfig = {
      value: bufferRemaining,
      label: 'Buffer',
      itemStyle: {
        color: 'rgba(0,0,0,0.0)',
        hatched: true,
      },
      labelStyle: {
        alignVertical: 'top',
        alignHorizontal: 'right',
        formatter: labelFormatter,
        unit,
      },
    };

    configs.push(bufferConfig, bufferRemainingConfig);
  }

  return configs;
};

@Component({
  selector: 'facility-overview-graph',
  templateUrl: './facility-overview-graph.component.html',
  styleUrls: ['./facility-overview-graph.component.scss'],
})
export class FacilityOverviewGraphComponent implements OnChanges {
  @Input() facility: Facility;
  @Input() facilityState: ExpandedFacilityState;
  @Input() facilityStateLoaded: boolean;
  @Input() optimisationUnit: OptimisationUnit;

  config: any;
  collapsed: boolean = true;

  public ngOnChanges() {
    this.graphSettings();
  }

  private graphSettings() {
    const state = this.facilityState;
    if (state && state.facilityHeadroom && state.calculatedMeta) {
      const hasLowValues = this.doesFacilityHaveLowValues(state.calculatedMeta);

      this.config = [
        facilityLoadGraphConfig(state.calculatedMeta[0].facilityLoad, this.optimisationUnit),
        evLoadGraphConfig(this.optimisationUnit, state.calculatedMeta[0].evLoad),
        ...spareBufferConfig(state, hasLowValues, this.optimisationUnit),
      ];
    } else {
      delete this.config;
    }
  }

  private doesFacilityHaveLowValues(calculatedMeta: any, percentConsideredLow = 10) {
    const facilityValuesKeys = ['facilityLoad', 'evLoad'];

    const {facilityValues, totalValues} = Object.keys(calculatedMeta)
      .reduce((results, key) => {
        const value = parseFloat(calculatedMeta[key]);

        if (!isNaN(value)) {
          if (facilityValuesKeys.includes(key)) {
            results.facilityValues += value;
          }

          results.totalValues += value;
        }

        return results;
      }, {
        facilityValues: 0,
        totalValues: 0,
      });

    const lowValue = (totalValues - facilityValues) * (percentConsideredLow / 100);

    return facilityValues < lowValue;
  }
}
