import {Observable, Subject, timer} from 'rxjs';

export enum RetryType {
  none,
  fixed,
  linear,
  exponential
}

export class ConnectionConfig {
  retryCount? = 5;
  jsonOnly? = true;
  preCloseHook?: (ws: WebSocket) => void; // Not asynchronous, ok for now, consider changing later if needed
}

export interface Socket {
  messages: Observable<any>;
  close: () => void;
  reestablish: (url: string, userConfig?: ConnectionConfig) => void;
}

/**
 * An abstraction for a websocket, to the caller this appears simple object with an objservable for data and a reestablish method
 * It handles reconnection based on the given config / default config
 *
 * @param url the url to connect to
 * @param userConfig defaults to ConnectionConfig:{retryCount: 5, jsonOnly: true}
 */
export function createSocket(url: string, userConfig = {}): Socket {
  if (!url) {
    throw new Error('url is required');
  }

  let connectTo = url;
  let reconnection = {attempt: 0, timer: 1000};
  let config = Object.assign(new ConnectionConfig(), userConfig);

  const messages = new Subject<any>();
  let ws: WebSocket;

  const connect = () => {
    ws = new WebSocket(connectTo);

    ws.onerror = (event) => {
      console.error('Live data connection error', event);
      reconnect();
    };

    ws.onopen = () => {
      ws.onmessage = ({data}) => {
        if (config.jsonOnly) {
          try {
            data = JSON.parse(data);
            messages.next(data);
          } catch (err) {// parsing error
          }
        } else {
          messages.next(data);
        }
      };

      ws.onclose = (event) => {
        if (event.code === 1000) return;
        reconnect();
      };
    };
  };

  const reconnect = () => {
    if (ws) {
      ws.close(1000);
    }

    reconnection.attempt++;
    reconnection.timer = reconnection.timer * 1.5;

    timer(reconnection.timer).subscribe(_ => {
      connect();
    });
  };

  const reestablish = (newUrl: string, reestablishConfig = {}) => {
    close();

    connectTo = newUrl;
    reconnection = {attempt: 0, timer: 1000};
    config = Object.assign(new ConnectionConfig(), reestablishConfig);

    connect();
  };

  const close = () => {
    if (!ws) return;
    if (ws.readyState !== 1) return;
    if (config.preCloseHook) {
      config.preCloseHook(ws);
    }
    ws.close(1000);
  };

  connect();

  return {
    messages,
    reestablish,
    close,
  };
}
