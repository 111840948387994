import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {TableColumn} from '../../../../shared/components/table-addons/table-column.model';
import {ToastService} from '../../../../shared/services/toast.service';
import {EvsesStatusTableSettingsService} from './evses-status-table-settings.service';

interface CurrentValues {
  pageSize?: number;
  tableColumns?: string[];
}

export interface VehiclesTableSettings {
  tableColumns: TableColumn[];
  currentValues: CurrentValues;
}

@Component({
  selector: 'evses-status-table-settings',
  templateUrl: './evses-status-table-settings.component.html',
  styleUrls: ['./evses-status-table-settings.component.scss'],
})
export class EvsesStatusTableSettingsComponent implements OnInit {
  public optionsForm: UntypedFormGroup;
  public tableColumns: TableColumn[];
  public currentValues: CurrentValues;
  public lastErrorMessage: string;

  @HostListener('keydown.esc')
  public onEsc() {
    this.close();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VehiclesTableSettings,
    private dialogRef: MatDialogRef<EvsesStatusTableSettingsComponent>,
    private formBuilder: UntypedFormBuilder,
    private toastService: ToastService,
    private evsesStatusTableSettingsService: EvsesStatusTableSettingsService,
  ) {
  }

  public ngOnInit() {
    this.tableColumns = this.data.tableColumns;
    this.currentValues = this.data.currentValues;

    this.optionsForm = this.createOptionsForm();
  }

  public onSubmit() {
    if (!this.optionsForm.valid) {
      this.optionsForm.markAllAsTouched();
      return;
    }

    this.lastErrorMessage = '';

    const success = async () => {
      this.toastService.toast('Settings updated');

      this.close();
    };
    const failure = (err: HttpErrorResponse) => {
      this.lastErrorMessage = (typeof err.error === 'string')
        ? err.error
        : 'An unknown error occurred';

      console.error(err);
    };

    const values = this.optionsForm.getRawValue();
    const {pageSize} = values;

    const tableColumns = Object.keys(values.tableColumns)
      .map(key => values.tableColumns[key] ? key : null)
      .filter(value => value);

    this.evsesStatusTableSettingsService.updateValues(tableColumns, pageSize)
      .subscribe(success, failure);
  }

  public close() {
    this.dialogRef.close();
  }

  private createOptionsForm() {
    return this.formBuilder.group({});
  }
}
