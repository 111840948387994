<div mat-dialog-title class="evses-status-table-settings__title bdd-id--vehicles-table-settings--title">
    EVSE Status Table Settings
</div>

<mat-dialog-content [formGroup]="optionsForm" (ngSubmit)="onSubmit()">
    <div *ngIf="data" class="evses-status-table-settings__container bdd-id--vehicles-table-settings--container">
        <error-message-banner
            class="bdd-id--vehicles-table-settings--error-message-banner"
            [message]="lastErrorMessage">
        </error-message-banner>

        <div class="evses-status-table-settings__options">
            <div class="evses-status-table-settings__row">
                <div class="evses-status-table-settings__column">
                    <settings-options-table-columns
                        class="mb-2"
                        label="Table Columns"
                        formKey="tableColumns"
                        [formGroup]="optionsForm"
                        [tableColumns]="tableColumns"
                        [tableColumnsSelected]="currentValues?.tableColumns"
                        maxLimit="10">
                    </settings-options-table-columns>

                    <settings-options-dropdown
                        label="Page Size"
                        formKey="pageSize"
                        [formGroup]="optionsForm"
                        [options]="[10, 25]"
                        [value]="currentValues?.pageSize"
                        [isRequired]="true">
                    </settings-options-dropdown>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end align-items-center">
    <action-btn
        class="bdd-id--vehicles-table-settings--cancel-button"
        type="cancel"
        label="Cancel"
        (click)="close()">
    </action-btn>

    <button
        class="bdd-id--vehicles-table-settings--save-button"
        type="button"
        class="save-btn"
        [disabled]="!optionsForm.valid || !optionsForm.dirty"
        (click)="onSubmit()"
    >
        <svg-icon type="check-circled"></svg-icon>
        <span>Save Changes</span>
    </button>
</mat-dialog-actions>
