<div class="main bdd-id--table-paginator--main" *ngIf="pageSize && itemsCount && matPaginator">
    <!--  the below element is added only for positioning purpose -->
    <div></div>

    <div class="page-selection-panel">
        <div class="arrow-wrapper bdd-id--table-paginator--arrow-wrapper">
            <div *ngIf="firstPageNo > 1" class="arrow" (click)="moveBack()">
                <svg-icon type="left-circled"></svg-icon>
            </div>
        </div>

        <div class="page-buttons-group d-flex justify-content-around bdd-id--table-paginator--page-buttons-group">
            <div *ngFor="let pageOffset of [0, 1, 2, 3, 4]">
                <div
                    *ngIf="firstPageNo + pageOffset <= maxPageCount"
                    class="page-button d-flex justify-content-center align-items-center"
                    [ngClass]="{'isSelected': firstPageNo + pageOffset === chosenPageNo}"
                    (click)="selectPage(firstPageNo + pageOffset)"
                >
                    {{ firstPageNo + pageOffset }}
                </div>
            </div>
        </div>

        <div class="arrow-wrapper bdd-id--table-paginator--arrow-wrapper">
            <div *ngIf="firstPageNo + 4 < maxPageCount" class="arrow" (click)="moveForward()">
                <svg-icon type="right-circled"></svg-icon>
            </div>
        </div>
    </div>

    <div>
        page {{ chosenPageNo }}/{{ maxPageCount }} &nbsp;
    </div>
</div>
