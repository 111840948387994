import {Component} from '@angular/core';
import {FeatureFlagService} from '../shared/services/feature-flag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private featureFlagService: FeatureFlagService) {
    this.featureFlagService.initialize();
  }
}
