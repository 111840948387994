<div class="asset-tags-input__container">
    <mat-accordion multi="false">
        <mat-expansion-panel
            class="bdd-id--facility-edit-peak-reduction--day-of-week-panel"
            [expanded]="expanded"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <strong>Tags ({{ tagsFormArray.controls.length }})</strong>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="tagsFormArray.controls.length">
                <div class="d-flex flex-row">
                    <div class="asset-tags-input__column mr-3">
                        <span class="label">Key</span>
                    </div>

                    <div class="asset-tags-input__column mr-3">
                        <span class="label">Value</span>
                    </div>

                    <div class="asset-tags-input__column"></div>
                </div>

                <div
                    *ngFor="let tagsFormGroup of tagsFormArray.controls; let i = index"
                    [formGroup]="tagsFormGroup"
                    class="d-flex flex-row mb-2"
                >
                    <div class="asset-tags-input__column mr-3">
                        <input
                            type="text"
                            class="full-size bdd-id--asset-tags-input--key-input"
                            formControlName="key"
                        />
                    </div>

                    <div class="asset-tags-input__column mr-3">
                        <input
                            type="text"
                            class="full-size bdd-id--asset-tags-input--value-input"
                            formControlName="value"
                            (keydown.tab)="onTab(i)"
                        />
                    </div>

                    <div class="asset-tags-input__column">
                        <button
                            type="button"
                            class="action-btn bdd-id--asset-tags-input--remove-button"
                            (click)="handleRemoveRow(i)"
                            tabIndex="-1"
                        >
                            <svg-icon type="cross-circled"></svg-icon>
                            <span>Remove</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row">
                <div class="asset-tags-input__column mr-3">
                    <button type="button" class="action-btn bdd-id--asset-tags-input--add-button"
                            (click)="handleAddRow()">
                        <svg-icon type="plus-circled"></svg-icon>
                        <span>Add</span>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
