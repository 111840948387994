<div class="table-filters__main bdd-id--table-filters--main" *ngIf="tableFilterConfig">
    <button
        class="filter-btn table-filters__add-button"
        (click)="showPopup = !showPopup"
    >
        <svg-icon type="plus-circled"></svg-icon>
        <span>Add filter</span>
    </button>

    <div class="filter-item bdd-id--table-filters--filter-item" *ngFor="let filter of filters; let i = index">
        <svg-icon type="cross-circled" (click)="removeFilter(i)"></svg-icon>
        <span>{{ filter.label }}</span>
    </div>

    <div class="table-filters__options-container" *ngIf="showPopup">
        <div class="table-filters__window table-filters__add-window">
            <ng-container *ngFor="let option of tableFilterConfig">
                <div
                    *ngIf="!option.isHidden"
                    class="table-filters__option-item bdd-id--table-filters--option-item"
                    [class.selected]="selectedOption === option"
                    (click)="selectOption(option)"
                >
                    {{ option.label }}
                </div>
            </ng-container>
            
        </div>

        <ng-container [ngSwitch]="selectedOption.type" *ngIf="selectedOption">
            <table-filters-string-type *ngSwitchCase="'string'" [config]="selectedOption"></table-filters-string-type>
            <table-filters-number-type *ngSwitchCase="'number'" [config]="selectedOption"></table-filters-number-type>
        </ng-container>
    </div>
</div>
