import {NgModule} from '@angular/core';

import {CustomUnitPipe} from './custom-unit.pipe';

@NgModule({
  declarations: [
    CustomUnitPipe,
  ],
  exports: [
    CustomUnitPipe,
  ],
})
export class CustomUnitPipeModule {
}
