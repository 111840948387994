import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MobileFacilitySelectorComponent} from './mobile-facility-selector.component';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
  declarations: [
    MobileFacilitySelectorComponent,
  ],
  imports: [
    CommonModule,
    MatSelectModule,
  ],
  exports: [
    MobileFacilitySelectorComponent,
  ],
})
export class MobileFacilitySelectorModule {
}
