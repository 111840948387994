import {OptimisationUnit} from '../../shared/models/enums';
import {ChargingStation} from '../../shared/models/entities';
import {FvData} from './fv-data';

export class FacilityViewChargingStation {
  chargingStation: ChargingStation;
  evseLeft?: FvData;
  evseRight?: FvData;
  evses?: FvData[];
  optimisationUnit?: OptimisationUnit;
}
