import {Injectable} from '@angular/core';
import {Amplify} from '@aws-amplify/core';
import {Auth} from '@aws-amplify/auth';
import {CognitoUserInterface} from '@aws-amplify/ui-components';
import {BrowserStorageCache} from '@aws-amplify/cache';
import {interval, Subject, Subscription} from 'rxjs';

import {environment} from '../../../environments/environment';
import {CurrentSessionService} from './current-session.service';
import {UserPreferencesService} from './user-preferences.service';
import {AuthPermissionService} from './auth-permission.service';

export enum LoginStateAction {
  Logout = 'logout',
}

interface TenantConfig {
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public loginState$ = new Subject<LoginStateAction>();

  private user: CognitoUserInterface;
  private refreshTokenIntervalMinutes = 30;
  private refreshToken$: Subscription;

  constructor(
    private currentSession: CurrentSessionService,
    private authPermissionService: AuthPermissionService,
    private userPreferencesService: UserPreferencesService,
  ) {
  }

  public getUser() {
    return this.user;
  }

  public setUser(user: CognitoUserInterface) {
    this.user = user;
  }

  public async signIn(username: string, password: string) {
    const user = await Auth.signIn(username, password);

    this.setUser(user);

    return user;
  }

  public async signOut() {
    await Auth.signOut();

    if (this.refreshToken$) {
      this.refreshToken$.unsubscribe();
    }

    this.clearUserToken();
    this.clearUserSessionData();
  }

  public async onLogin() {
    const idToken = this.user.signInUserSession.idToken;
    const minutesTillTokenExpired = (idToken.payload.exp * 1000 - Date.now()) / 1000 / 60;

    console.debug(`minutesTillTokenExpired: ${minutesTillTokenExpired}`);
    if (minutesTillTokenExpired / 2 > this.refreshTokenIntervalMinutes) {
      console.debug('Caching the idToken without refreshing.');

      this.setCachedTokenValues(idToken);
    } else {
      console.debug('Refreshing the idToken.');

      await this.refreshToken();
    }

    this.refreshToken$ = interval(this.refreshTokenIntervalMinutes * 60 * 1000)
      .subscribe(async () => {
        await this.refreshToken();
      });

    console.debug(`Set up refresh token interval to run every ${this.refreshTokenIntervalMinutes} minutes.`);
  }

  public clearUserToken() {
    const currentUserIdToken = BrowserStorageCache.getItem('currentUserIdToken');
    if (currentUserIdToken) {
      BrowserStorageCache.removeItem('currentUserIdToken');
      console.debug('Removed obsolete currentUserIdToken from cache:');
      console.debug(currentUserIdToken);
    } else {
      console.debug('No currentUserIdToken to clean up.');
    }

    this.clearUserSessionData();
  }

  public async setupAuthenticationLibrary(tenantConfig: TenantConfig) {
    environment.auth = Object.assign(environment.auth, tenantConfig);

    Amplify.configure(environment.auth);
    Auth.configure(environment.auth);

    // if there is a valid user session, retrieve the token and redirect the user to the application
    try {
      this.user = await Auth.currentAuthenticatedUser();

      this.setCachedTokenValues(this.user.signInUserSession.idToken);
    } catch (e) {
      console.error('No valid user session: ' + e);
    }
  }

  private clearUserSessionData() {
    this.currentSession.clearAll();
    this.authPermissionService.clearPermissions();
    this.userPreferencesService.clearUserPreferences();
    sessionStorage.clear();
  }

  private refreshToken() {
    return new Promise((resolve, reject) => {
      this.user.refreshSession(this.user.signInUserSession.refreshToken, (err, session) => {
        if (err !== null) {
          console.error('Unable to refresh User Session', err);
          return reject(err);
        }

        console.debug('Refreshed idToken:');
        this.setCachedTokenValues(session.idToken);

        return resolve(true);
      });
    });
  }

  private setCachedTokenValues(idToken: any) {
    BrowserStorageCache.setItem('currentUserIdToken', idToken);

    if (idToken && idToken.payload) {
      const {
        'custom:userId': userId,
        'custom:restrictedFacAccess': restrictedFacilityAccess,
        'custom:facilityAccessList': facilityAccessList,
      } = idToken.payload;

      this.currentSession.setUserId(userId);
      this.currentSession.setRestrictedFacilityAccess(restrictedFacilityAccess);
      this.currentSession.setFacilityAccessList(facilityAccessList);
    }
  }
}
