import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import tippy from 'tippy.js';

import {TooltipIconComponent} from './tooltip-icon/tooltip-icon.component';
import {TooltipDirective} from './tooltip.directive';

tippy.setDefaultProps({
  arrow: true,
  theme: 'light',
});

@NgModule({
  declarations: [
    TooltipIconComponent,
    TooltipDirective,
  ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [
    TooltipIconComponent,
    TooltipDirective,
  ],
})
export class TooltipsModule {
}
