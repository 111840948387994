<div class="evses-status-table-row__table-row bdd-id--evses-status-table-row--table-row">
    <div class="d-flex flex-column">
        <div class="table-common__row table-common__row--condensed bdd-id--evses-status-table-row--table-row-values">
            <ng-container  *ngFor="let column of tableColumns">
                <div *ngIf="!column.isHidden"
                    class="table-common__row-cell"
                    [style.width.%]="column.widthPerc"
                    [style.flex]="isMobileSize ? (column.mobileWidthFlex || column.widthFlex) : column.widthFlex"
                    [ngSwitch]="column.id"
                    [ngClass]="{'column-align-right': column.alignRight}"
                >
                    <div *ngSwitchCase="'sessionId'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                            <a (click)="navigateToSessionPage()" [tooltipTitle]="evseView.sessionId" *ngIf="evseView.isPluggedIn && evseView.sessionId">
                                {{evseView.sessionId}}
                            </a>
                    </div>

                    <div *ngSwitchCase="'sessionStart'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                            <span [tooltipTitle]="evseView.sessionStart| date: 'd MMM yy HH:mm:ss' " *ngIf="evseView.isPluggedIn && evseView.sessionId">
                                {{evseView.sessionStart| date: 'd MMM yy HH:mm:ss' }}
                            </span>
                    </div>

                <!-- Link to charging group? todo-->
                    <div *ngSwitchCase="'chargingGroup.name'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span
                        [tooltipTitle]="evseView.chargingGroup?.name">{{ evseView.chargingGroup?.name }}</span> 
                    </div>

                    <div *ngSwitchCase="'chargingStation.name'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <a (click)="navigateToChargingStationPage()"
                        [tooltipTitle]="evseView.chargingStation.name">{{ evseView.chargingStation.name }}</a>
                    </div>

                    <div *ngSwitchCase="'vehicleGroup.name'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span
                        [tooltipTitle]="evseView.vehicleGroup?.name">{{ evseView.vehicleGroup?.name }}</span> 
                    </div>

                    <div *ngSwitchCase="'extEvseId'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <a (click)="navigateToChargingStationPage()" [tooltipTitle]="evseView.extEvseId">
                            {{ evseView.extEvseId }}
                        </a>
                        <i class="fa-solid fa-arrow-up priority-icon" *ngIf="evseView.chargingPriority === 1" [tooltipTitle]="'Priority EVSE'"></i>
                    </div>

                    <div *ngSwitchCase="'chargingStation.maxPower'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.chargingStation.maxPower | number:'1.0-2' | customUnit:'kW'">
                            {{ evseView.chargingStation.maxPower | number:'1.2-2' | customUnit:'kW' }}
                        </span>
                    </div>

                    <div *ngSwitchCase="'maxPower'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.maxPower | number:'1.0-2' | customUnit:'kW'">
                            {{ evseView.maxPower | number:'1.2-2' | customUnit:'kW' }}
                        </span>
                    </div>

                    <div *ngSwitchCase="'defaultPower'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.defaultPower | number:'1.0-2' | customUnit:'kW'">
                            {{ evseView.defaultPower | number:'1.2-2' | customUnit:'kW' }}
                        </span>
                    </div>

                    <div *ngSwitchCase="'chargingStation.manufacturer'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span
                            [tooltipTitle]="evseView.chargingStation.manufacturer">{{ evseView.chargingStation.manufacturer }}</span>
                    </div>

                    <div *ngSwitchCase="'chargingStation.model'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.chargingStation.model">{{ evseView.chargingStation.model }}</span>
                    </div>

                    <div *ngSwitchCase="'chargingStation.extChargingStationId'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span
                            [tooltipTitle]="evseView.chargingStation.extChargingStationId">{{ evseView.chargingStation.extChargingStationId }}</span>
                    </div>

                    <div *ngSwitchCase="'chargingStation.firmwareVersion'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span
                            [tooltipTitle]="evseView.chargingStation.firmwareVersion">{{ evseView.chargingStation.firmwareVersion }}</span>
                    </div>

                    <div *ngSwitchCase="'chargingStation.phase'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.chargingStation.phase">{{ evseView.chargingStation.phase }}</span>
                    </div>

                    <div *ngSwitchCase="'chargingStation.powerType'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.chargingStation.powerType">{{ evseView.chargingStation.powerType }}</span>
                    </div>

                    <div *ngSwitchCase="'chargingStation.connectorTypes'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-{{ column.id }}">
                        <span *ngIf="evseView.chargingStation.connectorTypes.length"
                            [tooltipTitle]="formatConnectorTypes(evseView.chargingStation.connectorTypes)">
                            {{ formatConnectorTypes(evseView.chargingStation.connectorTypes) }}
                        </span>
                    </div>

                    <div *ngSwitchCase="'power'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <svg-icon *ngIf="evseView.power" type="powerflow" class="mr-1  d-none d-md-inline-flex"></svg-icon>
                        <span [tooltipTitle]="evseView.power | number:'1.0-2' | customUnit:'kW'">
                            {{ evseView.power | number:'1.2-2' | customUnit:'kW' }}
                        </span>
                    </div>

                    <div *ngSwitchCase="'chargingStation.maxCurrent'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.chargingStation.maxCurrent | number:'1.0-2' | customUnit:'A'">
                            {{ evseView.chargingStation.maxCurrent | number:'1.2-2' | customUnit:'A' }}
                        </span>
                    </div>

                    <div *ngSwitchCase="'maxCurrent'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.maxCurrent | number:'1.0-2' | customUnit:'A'">
                            {{ evseView.maxCurrent | number:'1.2-2' | customUnit:'A' }}
                        </span>
                    </div>

                    <div *ngSwitchCase="'defaultCurrent'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.defaultCurrent | number:'1.0-2' | customUnit:'A'">
                            {{ evseView.defaultCurrent | number:'1.2-2' | customUnit:'A' }}
                        </span>
                    </div>

                    <div *ngSwitchCase="'current'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.current | number:'1.0-2' | customUnit:'A'">
                            {{ evseView.current | number:'1.2-2' | customUnit:'A' }}
                        </span>
                    </div>

                    <div *ngSwitchCase="'energy'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.energy | number:'1.0-2' | customUnit:'kWh'">
                            {{ evseView.energy | number:'1.2-2' | customUnit:'kWh' }}
                        </span>
                    </div>

                    <div *ngSwitchCase="'regNo'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <a (click)="navigateToVehiclePage()" [tooltipTitle]="evseView.regNo" *ngIf="evseView.isPluggedIn && evseView.regNo">
                            {{ evseView.regNo }}
                        </a>
                        <i class="fa-solid fa-arrow-up priority-icon" *ngIf="evseView.regNo && !!evseView.vehicle?.chargingPriority" [tooltipTitle]="'Priority Vehicle'"></i>
                        <span [tooltipTitle]="'Unregistered EV'" *ngIf="evseView.isPluggedIn && !evseView.regNo">Unregistered EV</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.evIdentifier'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span *ngIf="evseView.vehicle?.evIdentifier" [tooltipTitle]="evseView.vehicle?.evIdentifier">{{ evseView.vehicle?.evIdentifier }}</span>
                        <span *ngIf="!evseView.vehicle?.evIdentifier && evseView.isPluggedIn"[tooltipTitle]="'Unregistered EV'">{{ 'Unregistered EV' }}</span>
                        <i class="fa-solid fa-arrow-up priority-icon" *ngIf="evseView.regNo && !!evseView.vehicle?.chargingPriority" [tooltipTitle]="'Priority Vehicle'"></i>
                    </div>

                    <div *ngSwitchCase="'vehicle.manufacturer'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.vehicle?.manufacturer">{{ evseView.vehicle?.manufacturer }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.model'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.vehicle?.model">{{ evseView.vehicle?.model }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.authenticationId'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span *ngIf="evseView.vehicle"
                            [tooltipTitle]="evseView.vehicle?.idTokenType + '/' + evseView.vehicle?.idToken">{{ evseView.vehicle?.idTokenType }}
                            /{{ evseView.vehicle?.idToken }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.year'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.vehicle?.year">{{ evseView.vehicle?.year }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.month'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.vehicle?.month">{{ evseView.vehicle?.month }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.name'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.vehicle?.name">{{ evseView.vehicle?.name }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.acChargingPhase'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.vehicle?.acChargingPhase">{{ evseView.vehicle?.acChargingPhase }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.batteryCapacity'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span
                            [tooltipTitle]="evseView.vehicle?.batteryCapacity | number:'1.0-2' | customUnit:'kWh'">{{ evseView.vehicle?.batteryCapacity | number:'1.2-2' | customUnit:'kWh' }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.idToken'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.vehicle?.idToken">{{ evseView.vehicle?.idToken }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.maxPower'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span
                            [tooltipTitle]="evseView.vehicle?.maxPower | number:'1.0-2' | customUnit:'kW'">{{ evseView.vehicle?.maxPower | number:'1.2-2' | customUnit:'kW' }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.maxCurrent'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span
                            [tooltipTitle]="evseView.vehicle?.maxCurrent | number:'1.0-2' | customUnit:'A'">{{ evseView.vehicle?.maxCurrent | number:'1.2-2' | customUnit:'A' }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.minPower'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span
                            [tooltipTitle]="evseView.vehicle?.minPower | number:'1.0-2' | customUnit:'kW'">{{ evseView.vehicle?.minPower | number:'1.2-2' | customUnit:'kW' }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.minCurrent'"
                    class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                    <span
                        [tooltipTitle]="evseView.vehicle?.minCurrent | number:'1.0-2' | customUnit:'A'">{{ evseView.vehicle?.minCurrent | number:'1.2-2' | customUnit:'A' }}</span>
                </div>

                    <div *ngSwitchCase="'vehicle.maxSoc'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span
                            [tooltipTitle]="evseView.vehicle?.maxSoc | number:'1.0-2' | customUnit:'%'">{{ evseView.vehicle?.maxSoc | number:'1.2-2' | customUnit:'%' }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.telematicsProviderName'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span
                            [tooltipTitle]="evseView.vehicle?.telematicsProviderName">{{ evseView.vehicle?.telematicsProviderName }}</span>
                    </div>

                    <div *ngSwitchCase="'stateOfChargeInPercent'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-{{ column.id }}">
                        <span *ngIf="evseView.stateOfChargeInPercent"
                            [tooltipTitle]="evseView.stateOfChargeInPercent | number:'1.0-2' | customUnit:'%'">{{ evseView.stateOfChargeInPercent | number:'1.2-2' | customUnit:'%' }}</span>
                    </div>

                    <div *ngSwitchCase="'vehicle.tags'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-{{ column.id }}">
                        <asset-tags-icon *ngIf="evseView.vehicle?.tags" [tags]="evseView.vehicle?.tags"></asset-tags-icon>
                    </div>

                    <div *ngSwitchCase="'setpoint'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-{{ column.id }}">
                        <ng-container *ngIf="!evseView.hideSetpoint">
                            <svg-icon *ngIf="evseView.setpoint != null" type="setpoint" class="mr-1"></svg-icon>
                            <span [tooltipTitle]="evseView.setpoint | number:'1.0-2' | customUnit: evseView.optimisationUnit">
                                {{ evseView.setpoint | number:'1.2-2' | customUnit: evseView.optimisationUnit }}
                            </span>
                        </ng-container>
                    </div>

                    <div *ngSwitchCase="'status'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <div class="m-auto d-flex align-items-center">
                            <cs-notification-icon
                                *ngIf="evseView?.evseStatusNotification"
                                [name]="evseView?.chargingStation?.name"
                                [notification]="evseView?.evseStatusNotification"
                                class="mx-1">
                            </cs-notification-icon>
                            <div *ngIf="evseView?.online != undefined && !evseView?.online" class="mx-1">
                                <svg-icon type="charger-offline" tooltipTitle="Offline" class="offline-icon d-block"></svg-icon>
                            </div>
                        </div>
                    </div>

                    <div *ngSwitchCase="'fvEvseStatus'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-{{ column.id }}">
                        <span *ngIf="evseView.fvEvseStatus"
                            [tooltipTitle]="evseView.fvEvseStatus | sentenceCase">{{ evseView.fvEvseStatus | sentenceCase }}</span>
                    </div>

                    <div *ngSwitchCase="'timestamp'"
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span [tooltipTitle]="evseView.timestamp | date:'d MMM yy HH:mm:ss'">
                            {{ latestTimestamp | relativeDate:1:forceTimeUpdate }}
                        </span>
                    </div>

                    <div *ngSwitchDefault
                        class="table-common__row-cell-values bdd-id--evses-status-table-row--table-cell-{{ column.id }}">
                        <span *ngIf="evseView[column.id]"
                            [tooltipTitle]="evseView[column.id]">{{ evseView[column.id] }}</span>
                    </div>
                </div>
            </ng-container>
        </div>

        <div #stateOfChargeBarElement>
            <div class="table-row-soc-bar">
                <div class="table-row-soc-bar-stripes"
                     [style.left.px]="evseView.stateOfChargeInPercent ? (evseView.stateOfChargeInPercent / 100) * stateOfChargeBarWidth : 0"></div>
            </div>
        </div>
    </div>

    <div
        *ngIf="evseView.isAnimated === true && evseView.isPluggedIn === true"
        class="evse-event evse-event-plugin d-flex justify-content-center align-items-center bdd-id--evses-status-table-row--evse-event-plugin"
    >
        <svg-icon type="plugin" class="mr-1"></svg-icon>
        Vehicle plugged-in
    </div>

    <div
        *ngIf="evseView.isAnimated === true && evseView.isPluggedIn === false"
        class="evse-event evse-event-plugout d-flex justify-content-center align-items-center bdd-id--evses-status-table-row--evse-event-plugout"
    >
        <svg-icon type="plugout" class="mr-1"></svg-icon>
        Vehicle {{ evseView.regNo }} plugged-out
    </div>
</div>
