<div class="main bdd-id--tab-switch-header--main">
    <div class="d-flex flex-column col-12">
        <div class="d-flex flex-row">
            <ng-container *ngFor="let tab of tabs">
                <div
                    class="d-flex flex-column justify-content-between tab-item bdd-id--tab-switch-header--tab-item"
                    *authPermission="tab.permission"
                    (click)="select(tab.value, tab.redirectToFacility)"
                    [attr.data-bdd-value]="tab.value"
                >
                    <div class="tab-item-label nav-label"
                         [ngClass]="{'tab-item-label-selected': selectedTabValue === tab.value}">
                        {{ tab.label }}
                    </div>

                    <div *ngIf="selectedTabValue === tab.value" class="tab-item-selected"></div>
                </div>
            </ng-container>
        </div>

        <div class="header-underline"></div>
    </div>
</div>
