import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {auditTime, takeUntil} from 'rxjs/operators';
import {InfoFilterComponent} from 'src/app/shared/components/info-filter/info-filter.component';
import {Facility, Vehicle} from '../../shared/models/entities';

import {FleetLocationState} from '../../shared/models/entity-states';
import {FleetLocationMapFilterService} from '../fleet-location-map-filter/fleet-location-map-filter.service';

export interface VehicleExtended extends Vehicle {
  hasValidCoordinates?: boolean;
}

@Component({
  selector: 'fleet-location-overview',
  templateUrl: './fleet-location-overview.component.html',
  styleUrls: ['./fleet-location-overview.component.scss'],
})
export class FleetLocationOverviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() facilityState: FleetLocationState;
  @Input() stateOfChargeChartData;
  @Input() pluggedInStatus;
  @Input() facilities: Facility[] = [];
  @Input() set selectedFacility(value: Facility){
    if (value) {
      this.selectedFacilityId = value.facilityId;
    }
  }

  @Output() facilityChanged = new EventEmitter<number>();
  @Output() filterChanged = new EventEmitter<any>();

  @ViewChild('statusSelector') statusSelector: InfoFilterComponent;
  @ViewChild('socSelector') socSelector: InfoFilterComponent;
  @ViewChild('filterInput') filterInput: ElementRef;

  public filterableFacilityState: FleetLocationState;
  public selectedFacilityId: number;
  public selectedCharge = [];
  public selectedPlugInStatus: string[];
  public filterableSocData = [];
  public vehicles: VehicleExtended[] = [];

  public selectedVehicleControl = new UntypedFormControl();
  public textFilterControl = new UntypedFormControl();

  private updateMapFilters$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  constructor(private fleetLocationMapFilterService: FleetLocationMapFilterService) {

  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  ngOnInit() {
    this.fleetLocationMapFilterService.mapFiltersActionSubject()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        if (value.type === 'clear-vehicle') {
          this.selectedVehicleControl.reset(null, {emitEvent: false});
        }
      });

    this.selectedVehicleControl
      .valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        this.fleetLocationMapFilterService.selectFacilityVehicle(value.evId);
      });

    this.updateMapFilters$
      .pipe(
        auditTime(200),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.updateVehicles();
      });

    if (this.facilityState) {
      this.updateMapFilters$.next();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.facilityState?.currentValue !== changes.facilityState?.previousValue && this.facilityState) {
      this.filterFacilityState();
      this.updateMapFilters$.next();
    }
  }

  private filterFacilityState() {
    this.filterableFacilityState = this.facilityState
  }

  public filterChange(key: string, selection: string[]) {
    if (key === 'soc') this.selectedCharge = selection;
    else if (key === 'plugInStatus') this.selectedPlugInStatus = selection;

    this.updateMapFilters();
  }

  private updateMapFilters() {
    const filterToApply: { [key: string]: string[] | string } = {};

    if (this.selectedPlugInStatus) {
      filterToApply.pluggedIn = this.selectedPlugInStatus;
    }

    if (this.selectedCharge) {
      filterToApply.stateOfCharge = this.selectedCharge;
    }

    this.fleetLocationMapFilterService.updateMapFilters(filterToApply);
    this.filterChanged.emit(filterToApply);
  }

  public clearAllFilters() {
    this.selectedCharge = [];
    this.selectedPlugInStatus = [];
    this.socSelector.clear();
    this.statusSelector.clear();
    this.updateMapFilters()
  }

  public handleSelectOpenChanged(value: boolean) {
    if (value === true && !this.selectedVehicleControl.value) {
      this.filterInput.nativeElement.focus();
    }
  }

  private updateVehicles() {
    const {vehicles, evState} = this.facilityState;

    const vehiclesHashMap = new Map((vehicles as VehicleExtended[]).map(vehicle => [vehicle.evId, vehicle]));

    evState.forEach(item => {
      const vehicle = vehiclesHashMap.get(item.evId);
      if (vehicle) {
        vehicle.hasValidCoordinates = item.latitude != null && item.longitude != null;
      }
    });
  
    this.vehicles = Array.from(vehiclesHashMap.values()).sort((a, b) => {
      return a.evIdentifier.localeCompare(b.evIdentifier, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
  }

  public selectFacility() {
    this.fleetLocationMapFilterService.selectFacility();
  }

  public vehicleSelected(event) {
    this.selectedVehicleControl.reset(null, {emitEvent: false});
    this.filterInput.nativeElement.value = '';
  }
}
