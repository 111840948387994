import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {FacilitySelectRouterOutletComponent} from '../shared/components/facility-select-router-outlet/facility-select-router-outlet.component';
import {AuthPermissionGuard} from './guards/auth-permission.guard';

import {NotFoundComponent} from './components/not-found/not-found.component';
import {LandingComponent} from './components/landing/landing.component';
import {ChargersOutletComponent} from './components/chargers-outlet/chargers-outlet.component';
import {VehiclesOutletComponent} from './components/vehicles-outlet/vehicles-outlet.component';
import {TenantGuard} from './guards/tenant.guard';
import {HomepageComponent} from './components/homepage/homepage.component';
import {FleetLocationHostComponent} from '../fleet-location/fleet-location-host/fleet-location-host.component';
import {ConfigurationsOutletComponent} from './components/configurations-outlet/configurations-outlet.component';

const routes: Routes = [
  {path: 'not-found', component: NotFoundComponent},
  {path: '', component: NotFoundComponent},
  {
    path: ':tenant',
    canActivate: [TenantGuard],
    children: [
      {
        path: '',
        component: LandingComponent,
        children: [
          {path: '', pathMatch: 'full', component: HomepageComponent},
          {
            path: 'dashboard',
            loadChildren: () => import('../home/home.module').then(m => m.HomeModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'fleet-location',
            component: FleetLocationHostComponent,
            canActivate: [AuthPermissionGuard],

          },
          {
            path: 'fleet-location/:facilityId',
            component: FleetLocationHostComponent,
            canActivate: [AuthPermissionGuard],

          },
          {
            path: 'facility-view',
            loadChildren: () => import('../facility-view/facility-view.module').then(m => m.FacilityViewModule),
            canActivate: [AuthPermissionGuard],
          },
          {
            path: 'facilities',
            loadChildren: () => import('../facilities/facilities.module').then(m => m.FacilitiesModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'chargers',
            component: ChargersOutletComponent,
            children: [
              {
                path: '',
                redirectTo: 'facility-charging-stations',
                pathMatch: 'full',
              },
              {
                path: 'facility-charging-stations',
                component: FacilitySelectRouterOutletComponent,
                pathMatch: 'full',
              },
              {
                path: 'facility-charging-stations/:facilityId',
                component: FacilitySelectRouterOutletComponent,
                pathMatch: 'full',
                loadChildren: () => import('../charging-stations/charging-stations.module').then(m => m.ChargingStationsModule),
              },
              {
                path: 'charger-types',
                pathMatch: 'full',
                loadChildren: () => import('../charger-types/charger-types.module').then(m => m.ChargerTypesModule),
              },
            ],
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'chargers/facility-charging-stations/:facilityId',
            loadChildren: () => import('../charging-stations/charging-stations.module').then(m => m.ChargingStationsModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'chargers/charger-types',
            loadChildren: () => import('../charger-types/charger-types.module').then(m => m.ChargerTypesModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'vehicles',
            component: VehiclesOutletComponent,
            children: [
              {
                path: '',
                redirectTo: 'facility-vehicles',
                pathMatch: 'full',
              },
              {
                path: 'facility-vehicles',
                component: FacilitySelectRouterOutletComponent,
                pathMatch: 'full',
              },
              {
                path: 'facility-vehicles/:facilityId',
                component: FacilitySelectRouterOutletComponent,
                pathMatch: 'full',
                loadChildren: () => import('../vehicles/vehicles.module').then(m => m.VehiclesModule),
              },
              {
                path: 'vehicle-types',
                pathMatch: 'full',
                loadChildren: () => import('../vehicle-types/vehicle-types.module').then(m => m.VehicleTypesModule),
              },
            ],
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'vehicles/facility-vehicles/:facilityId',
            loadChildren: () => import('../vehicles/vehicles.module').then(m => m.VehiclesModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'vehicles/vehicle-types',
            loadChildren: () => import('../vehicle-types/vehicle-types.module').then(m => m.VehicleTypesModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'vehicles',
            loadChildren: () => import('../vehicles/vehicles.module').then(m => m.VehiclesModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'sessions/:facilityId',
            loadChildren: () => import('../sessions/session.module').then(m => m.SessionModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'sessions',
            loadChildren: () => import('../sessions/session.module').then(m => m.SessionModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'configurations',
            component: ConfigurationsOutletComponent,
            children: [
              {
                path: '',
                redirectTo: 'charging-groups',
                pathMatch: 'full',
              },
              {
                path: 'charging-groups',
                component: FacilitySelectRouterOutletComponent,
                pathMatch: 'full',
              },
              {
                path: 'charging-groups/:facilityId',
                component: FacilitySelectRouterOutletComponent,
                pathMatch: 'full',
                loadChildren: () => import('../charging-groups/charging-groups.module').then(m => m.ChargingGroupsModule), 
              },
              {
                path: 'vehicle-groups',
                component: FacilitySelectRouterOutletComponent,
                pathMatch: 'full',
              },
              {
                path: 'vehicle-groups/:facilityId',
                component: FacilitySelectRouterOutletComponent,
                pathMatch: 'full',
                loadChildren: () => import('../vehicle-groups/vehicle-groups.module').then(m => m.VehicleGroupsModule), 
              },
            ],
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'configurations/charging-groups/:facilityId',
            loadChildren: () => import('../charging-groups/charging-groups.module').then(m => m.ChargingGroupsModule), 
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'configurations/vehicle-groups/:facilityId',
            loadChildren: () => import('../vehicle-groups/vehicle-groups.module').then(m => m.VehicleGroupsModule), 
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'schedules',
            loadChildren: () => import('../schedules/schedules.module').then(m => m.SchedulesModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'users',
            loadChildren: () => import('../users/users.module').then(m => m.UsersModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'analytics/:facilityId',
            loadChildren: () => import('../analytics/analytics.module').then(m => m.AnalyticsModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'analytics',
            loadChildren: () => import('../analytics/analytics.module').then(m => m.AnalyticsModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
          {
            path: 'profile',
            loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule),
            canActivate: [AuthPermissionGuard],
            canActivateChild: [AuthPermissionGuard],
          },
        ],
      },
      {path: '**', component: NotFoundComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
