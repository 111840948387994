<div class="fleet-location-vehicle-filter">
    <div class="mr-4">
        <button class="filter-btn" (click)="selectFacility()">
            <img src="/assets/images/map/facilities.svg" alt="Select Facility"/>
        </button>

        <button class="filter-btn" (click)="clearFilters()">
            <span>Clear Filters</span>
        </button>
    </div>

    <div class="mr-4">
        <button
            class="filter-btn"
            [class.filter-btn--selected]="pluggedInSelected === PluggedInOptions.PluggedIn"
            (click)="selectPluggedInOption(PluggedInOptions.PluggedIn)"
        >
            <svg-icon type="connector"></svg-icon>
        </button>

        <button
            class="filter-btn"
            [class.filter-btn--selected]="pluggedInSelected === PluggedInOptions.NotPluggedIn"
            (click)="selectPluggedInOption(PluggedInOptions.NotPluggedIn)"
        >
            <svg-icon type="connector-cross"></svg-icon>
        </button>
    </div>

    <div class="mr-4">
        <button
            class="filter-btn"
            [class.filter-btn--selected]="stateOfChargeSelected === StateOfChargeOptions.Low"
            (click)="selectStateOfChargeOption(StateOfChargeOptions.Low)"
        >
            <span>Low SoC</span>
        </button>

        <button
            class="filter-btn"
            [class.filter-btn--selected]="stateOfChargeSelected === StateOfChargeOptions.Medium"
            (click)="selectStateOfChargeOption(StateOfChargeOptions.Medium)"
        >
            <span>Medium SoC</span>
        </button>

        <button
            class="filter-btn"
            [class.filter-btn--selected]="stateOfChargeSelected === StateOfChargeOptions.High"
            (click)="selectStateOfChargeOption(StateOfChargeOptions.High)"
        >
            <span>High SoC</span>
        </button>
    </div>

    <mat-select class="full-size filter-select" [formControl]="selectedVehicleControl"
                (openedChange)="handleSelectOpenChanged($event)" placeholder="Vehicles">
        <div class="p-2">
            <input #filterInput class="full-size" type="text" [formControl]="textFilterControl"/>
        </div>

        <mat-option
            *ngFor="let vehicle of vehicles"
            [value]="vehicle"
            [class.fleet-location-vehicle-filter__hide-option]="filterInput.value !== '' && vehicle.evIdentifier.toLowerCase().indexOf(filterInput.value.toLowerCase()) === -1">
            {{ vehicle.evIdentifier }}
        </mat-option>
    </mat-select>
</div>
