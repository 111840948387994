<div class="filter-text d-md-none">Filter EVSEs:</div>
<div class="main d-flex justify-content-between bdd-id--evses-status-filters--main">
    <button
        class="filter-btn"
        [class.filter-btn--selected]="evseStatusSelected(EvseStatus.InUse)"
        (click)="selectEvseStatus(EvseStatus.InUse)"
    >
        <svg-icon type="charger-inuse"></svg-icon>
        <span class="d-none d-lg-block">Chargers in use</span>
        <span class="d-lg-none d-block">In use</span>
    </button>

    <button
        class="filter-btn"
        [class.filter-btn--selected]="evseStatusSelected(EvseStatus.Available)"
        (click)="selectEvseStatus(EvseStatus.Available)"
    >
        <svg-icon type="charger-available"></svg-icon>
        <span class="d-none d-lg-block">Available chargers</span>
        <span class="d-lg-none d-block">Available</span>
    </button>

    <button
        class="filter-btn"
        [class.filter-btn--selected]="evseStatusSelected(EvseStatus.Unavailable)"
        (click)="selectEvseStatus(EvseStatus.Unavailable)"
    >
        <svg-icon type="charger-unavailable"></svg-icon>
        <span class="d-none d-lg-block">Unavailable chargers</span>
        <span class="d-lg-none d-block">Unavailable</span>
    </button>

    <button
        class="filter-btn"
        [class.filter-btn--selected]="selectedFilters.offlineStatus"
        (click)="selectOfflineStatus()"
    >
        <svg-icon type="charger-offline"></svg-icon>
        <span class="d-none d-lg-block">Offline only</span>
        <span class="d-lg-none d-block">Offline</span>
    </button>
</div>
