import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {QRCodeModule} from 'angularx-qrcode';

import {ActionBtnModule} from '../../../shared/components/action-btn/action-btn.module';
import {PasswordRequirementsModule} from '../../../shared/components/password-requirements/password-requirements.module';

import {LandingComponent} from './landing.component';
import {HeaderModule} from '../header/header.module';

@NgModule({
  declarations: [
    LandingComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    QRCodeModule,
    ActionBtnModule,
    HeaderModule,
    PasswordRequirementsModule,
  ],
  exports: [
    LandingComponent,
  ],
})
export class LandingModule {
}
