import {NgModule} from '@angular/core';

import {SanitiseHtmlPipe} from './sanitise-html.pipe';

@NgModule({
  declarations: [
    SanitiseHtmlPipe,
  ],
  exports: [
    SanitiseHtmlPipe,
  ],
})
export class SanitiseHtmlPipeModule {
}
