import {NgModule} from '@angular/core';

import {SentenceCasePipe} from './sentence-case.pipe';

@NgModule({
  declarations: [
    SentenceCasePipe,
  ],
  exports: [
    SentenceCasePipe,
  ],
})
export class TextFormattersPipesModule {
}
