import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {auditTime, filter, takeUntil} from 'rxjs/operators';

import {parseTags} from '../asset-tags.utils';

export interface AssetTagData {
  tags: object;
  tagsAsString: string;
}

@Component({
  selector: 'asset-tags-input',
  templateUrl: './asset-tags-input.component.html',
  styleUrls: ['./asset-tags-input.component.scss'],
})
export class AssetTagsInputComponent implements OnInit, OnDestroy {
  @Input() tags: string | { [key: string]: string };
  @Input() expanded = false;
  @Output() updatedTagData = new EventEmitter<AssetTagData>();

  public tagsForm: UntypedFormGroup;

  private destroyed$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  get tagsFormArray() {
    return this.tagsForm.get('tags') as UntypedFormArray;
  }

  public ngOnInit() {
    this.tagsForm = this.createTagsForm();

    this.tagsForm.statusChanges
      .pipe(
        auditTime(200),
        filter(status => status === 'VALID'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.handleEmitData(this.tagsForm.value.tags);
      });

    if (this.tags) {
      this.populateForm();
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public handleAddRow() {
    this.tagsFormArray.push(this.createFormRow());
  }

  public handleRemoveRow(index: number) {
    this.tagsFormArray.removeAt(index);
  }

  public onTab(i: number) {
    if ((i + 1) === this.tagsFormArray.length) {
      this.handleAddRow();
    }
  }

  private populateForm() {
    const tagItems = this.tagsForm.get('tags') as UntypedFormArray;
    tagItems.clear({emitEvent: false});

    this.convertTagsToObjectArray(this.tags)
      .forEach(tag => {
        tagItems.push(this.createFormRow(tag.key, tag.value), {emitEvent: false});
      });
  }

  private handleEmitData(values: { key: string, value: string }[]) {
    const tags = this.convertFormObjectToTags(values);
    const tagsAsString = JSON.stringify(tags);

    this.updatedTagData.emit({tags, tagsAsString});
  }

  private createTagsForm() {
    return this.formBuilder.group({
      tags: this.formBuilder.array([]),
    });
  }

  private createFormRow(key = '', value = '') {
    return this.formBuilder.group({
      key: [key || '', [Validators.required]],
      value: [value || '', [Validators.required]],
    });
  }

  private convertTagsToObjectArray(tags: string | { [key: string]: string }) {
    const tagsObject = parseTags(tags);

    return Object.entries(tagsObject)
      .filter(([, value]) => typeof value === 'string')
      .map(([key, value]) => ({key, value}));
  }

  private convertFormObjectToTags(values: { key: string, value: string }[]) {
    const mappedValues = values.map(obj => {
      const key = obj.key.trim();
      const value = obj.value.trim();

      return {[key]: value};
    });

    return Object.assign({}, ...mappedValues);
  }
}
