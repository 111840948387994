import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {NavigationHelperService} from '../../../shared/services/navigation-helper.service';

@Component({
  selector: 'dashboard-selector-router-outlet',
  templateUrl: './dashboard-selector-router-outlet.component.html',
  styleUrls: ['./dashboard-selector-router-outlet.component.scss'],
})
export class DashboardSelectorRouterOutletComponent implements OnInit, OnDestroy {
  @Input() public facilityId: number;
  @Input() public selectedTabValue: string;

  public tabs = [
    {label: 'Facility Dashboard', value: 'facility-dashboard', permission: 'component/dashboard/fleet-location'},
  ];

  private destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationHelper: NavigationHelperService,
  ) {
  }

  public ngOnInit() {
    this.updateSelectedTab();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.updateSelectedTab();
      });

    const facilityId = parseInt(this.route.snapshot.paramMap.get('facilityId'), 10);
    if (facilityId) {
      this.updateFacilitySelection(facilityId);
    }

    this.route.paramMap
      .pipe(takeUntil(this.destroyed$))
      .subscribe(params => {
        const newFacilityId = parseInt(params.get('facilityId'), 10);
        this.updateFacilitySelection(newFacilityId);
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  public edit() {
    this.router.navigate([`../facilities/${this.facilityId}/edit`], {relativeTo: this.route.parent.parent});
  }

  public select(tabValue: string) {
    const commands = tabValue === 'facility-dashboard'
      ? ['dashboard', this.facilityId]
      : ['dashboard', this.facilityId, tabValue];

    this.navigationHelper.navigateToTenantPath(commands)
      .then(() => this.selectedTabValue = tabValue);
  }

  private updateSelectedTab() {
    const matchingTab = this.tabs.find(tab => this.router.url.includes(tab.value));
    this.selectedTabValue = matchingTab?.value || 'facility-dashboard';
  }

  private updateFacilitySelection(facilityId: number) {
    if (facilityId === this.facilityId || isNaN(facilityId)) {
      return;
    }

    this.facilityId = facilityId;
  }
}
