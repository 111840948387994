import {Component, Input, OnInit} from '@angular/core';

export type ButtonType = 'create' | 'delete' | 'delete-grey' | 'save' | 'cancel' | 'edit-white' | 'edit-grey' | 'edit-blue' | 'preview' | 'synchronise'
export type ButtonSize = 'normal' | 'small'

@Component({
  selector: 'action-btn',
  templateUrl: './action-btn.component.html',
  styleUrls: ['./action-btn.component.scss'],
})
export class ActionBtnComponent implements OnInit {
  @Input() type: ButtonType;
  @Input() size: ButtonSize = 'normal';
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() buttonType = 'button';

  public iconType: string;

  public ngOnInit() {
    this.iconType = this.getIconType();
  }

  private getIconType() {
    switch (this.type) {
      case 'delete':
      case 'delete-grey':
      case 'cancel':
        return 'cross-circled';

      case 'create':
        return 'plus-circled';

      case 'save':
        return 'check-circled';

      case 'edit-white':
      case 'edit-blue':
      case 'edit-grey':
        return 'edit';

      case 'preview':
        return 'preview';

      case 'synchronise':
        return 'synchronise';
    }
  }
}
