<div class="d-flex" [formGroup]="formGroup">
    <input
        class="full-size bdd-id--table-filters-input--{{ name }}"
        [type]="type"
        [formControlName]="name"
    />

    <span *ngIf="unit" class="p-2 table-filters-input__unit">{{ unit }}</span>

    <div class="units-selector-wrapper" *ngIf="unitsList?.length > 0">
        <mat-select [formControl]="formGroup.get('unit')" name="unit">
          <mat-option *ngFor="let unit of unitsList" [value]="unit">
            {{unit}}
          </mat-option>
        </mat-select>
    </div>
    
</div>
