import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouterHelperService {
  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private router: Router,
    private location: Location,
  ) {
    this.currentUrl = this.router.url;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  public handleErrorStatus(errorStatus: number, targetUrl: string) {
    return this.toRouteNoLocationChange('/not-found', errorStatus, targetUrl);
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  private async toRouteNoLocationChange(routePath: string, status: number, targetUrl: string) {
    this.previousUrl = this.currentUrl;

    return this.router.navigate([routePath, {status}], {skipLocationChange: true})
      .then(() => this.location.replaceState(targetUrl));
  }
}
