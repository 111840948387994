export function updateTableColumnsFromChargeToPower(tableColumns: string[]) {
  const modifyMap = {
    'defaultPower': 'defaultPower',
    'maxPower': 'maxPower',
    'minPower': 'minPower',
    'vehicle.maxChargeRate': 'vehicle.maxPower',
    'vehicle.minChargeRate': 'vehicle.minPower',
    'chargingStation.maxChargeRate': 'chargingStation.maxPower',
    'chargingStation.minChargeRate': 'chargingStation.minPower',
  };
  return tableColumns.map(v => modifyMap[v] ? modifyMap[v] : v);
}
