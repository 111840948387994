import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

import {FacilitySelectionComponent} from './facility-selection.component';
import {FacilitySelectionItemComponent} from './facility-selection-item/facility-selection-item.component';
import {TooltipsModule} from '../tooltips/tooltips.module';

@NgModule({
  declarations: [
    FacilitySelectionComponent,
    FacilitySelectionItemComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    TooltipsModule,
  ],
  exports: [
    FacilitySelectionComponent,
  ],
})
export class FacilitySelectionModule {
}
