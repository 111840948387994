import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {NgxEchartsModule} from 'ngx-echarts';

import {NoValueDashPipeModule} from '../shared/pipes/no-value-dash/no-value-dash-pipe.module';
import {TooltipsModule} from '../shared/components/tooltips/tooltips.module';
import {SvgIconModule} from '../shared/components/svg-icon/svg-icon.module';

import {FleetLocationComponent} from './fleet-location.component';
import {FleetLocationOverviewComponent} from './fleet-location-overview/fleet-location-overview.component';
import {FleetLocationDonutChartComponent} from './fleet-location-donut-chart/fleet-location-donut-chart.component';
import {FleetLocationMapComponent} from './fleet-location-map/fleet-location-map.component';
import {FleetLocationMapService} from './fleet-location-map/fleet-location-map.service';
import {FleetLocationMapFilterService} from './fleet-location-map-filter/fleet-location-map-filter.service';
import {FleetLocationMapFilterComponent} from './fleet-location-map-filter/fleet-location-map-filter.component';
import {FleetLocationHostComponent} from './fleet-location-host/fleet-location-host.component';
import {InfoFilterModule} from '../shared/components/info-filter/info-filter.module';
import {ChargingStationPopupModule} from '../facility-view/components/charging-station-popup/charging-station-popup.module';
import {FleetLocationVehiclePopupModule} from '../shared/components/fleet-location-vehicle-popup/fleet-location-vehicle-popup.module';

@NgModule({
  declarations: [
    FleetLocationComponent,
    FleetLocationOverviewComponent,
    FleetLocationDonutChartComponent,
    FleetLocationMapComponent,
    FleetLocationMapFilterComponent,
    FleetLocationHostComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonModule,
    NgxEchartsModule,
    NoValueDashPipeModule,
    TooltipsModule,
    SvgIconModule,
    InfoFilterModule,
    ChargingStationPopupModule,
    FleetLocationVehiclePopupModule,
  ],
  providers: [
    FleetLocationMapService,
    FleetLocationMapFilterService,
  ],
})
export class FleetLocationModule {
}
