import {Feature, FeatureCollection} from 'geojson';
import mapboxgl, {GeoJSONSource, LngLat, Popup} from 'mapbox-gl';
import pointOnFeature from '@turf/point-on-feature';
import {lineString}from '@turf/helpers';
import bbox from '@turf/bbox';
import distance from '@turf/distance';
import {FeatureCollection as GeoJSONFeatureCollection} from 'geojson';

import {flattenObject} from '../../utils/object';
import {FacilityZone} from '../../models/entities';
import {environment} from '../../../../environments/environment';
import {getColour} from '../../utils/color';
import {evseStates} from '../../constants';

const zoneStatusMarkerWidth = 130;
const zoneStatusMarkerHeight = 170;

// General map helpers
export const createMapObject = (
  coords: [number, number],
  zoom = 13,
  style = 'mapbox://styles/mapbox/streets-v11',
) => {
  return new mapboxgl.Map({
    accessToken: environment.mapbox.accessToken,
    container: 'map',
    style,
    zoom,
    center: coords,
  });
};

export const addZoneStatusMarker = (
  map: mapboxgl.Map,
  coords: [number, number],
  vcLevel: number[],
  chargingStationStatus: number[],
  zoneId: number,
  zoneName: string,
  locationData: any,
) => {
  function changeviewTypeOnZoom() {
    const currentZoomLevel = map.getZoom();
    const element = document.getElementById(zoneId + '-zone-status-marker');

    if (!element) {
      map.off('zoom', changeviewTypeOnZoom);
      return;
    }

    const headerElement = document.getElementById(zoneId + '-zone-header');
    headerElement.style.maxWidth = getFirstViewZoneNameWidth(width, bboxCoords, currentZoomLevel) + 'px';

    if (currentZoomLevel >= zoomLevelShort && element.classList.contains('show-short-view')) {
      element.classList.remove('show-short-view');
      element.classList.add('show-full-view');
    } else if (currentZoomLevel < zoomLevelShort && element.classList.contains('show-full-view')) {
      element.classList.remove('show-full-view');
      element.classList.add('show-short-view');
    }
  }

  const {width, bboxCoords, height} = getRealSizeOfZone(locationData);

  const zoomToFitWidth = shortSizeZoomLevel(bboxCoords[1], width, zoneStatusMarkerWidth);
  const zoomToFitHeight = shortSizeZoomLevel(bboxCoords[1], height, zoneStatusMarkerHeight);
  const zoomLevelShort = Math.max(zoomToFitWidth, zoomToFitHeight);

  const headerAllowedWidth = getFirstViewZoneNameWidth(width, bboxCoords, map.getZoom());
  const currentClassName = map.getZoom() >= zoomLevelShort ? 'show-full-view' : 'show-short-view';
  const element = getZoneStatusMarkerHTML(vcLevel, chargingStationStatus, zoneId, zoneName, currentClassName, headerAllowedWidth);

  map.on('zoom', changeviewTypeOnZoom);

  return new mapboxgl.Marker(element)
        .setLngLat(coords)
        .addTo(map);
};

export const updateZoneStatusMarker = (
  vcLevel: number[],
  csStatus: number[],
  zoneId: number,
) => {
  for (let index = 0; index < vcLevel.length; index++) {
    const element = document.getElementById(zoneId + '-vc-' + index);
    element.innerText = vcLevel[index].toString();
  }

  for (let index = 0; index < csStatus.length; index++) {
    const element = document.getElementById(zoneId + '-cs-' + index);
    element.innerText = csStatus[index].toString();
  }

  const vcLevelTotal = vcLevel.reduce((p, c) => p + c, 0);
  const csStatusTotal = csStatus.reduce((p, c) => p + c, 0);

  let element = document.getElementById(zoneId + '-vc-total');
  element.innerText = vcLevelTotal.toString();
  element = document.getElementById(zoneId + '-cs-total');
  element.innerText = csStatusTotal.toString();
};

const getZoneStatusMarkerHTML = (
  vcLevel: number[],
  csStatus: number[],
  zoneId: number,
  zoneName: string,
  className: string,
  zoneHeaderWidth: number,
) => {
  const vcLevelTotal = vcLevel.reduce((p, c) => p + c, 0);
  const csStatusTotal = csStatus.reduce((p, c) => p + c, 0);

  const element = document.createElement('div');
  element.innerHTML = `
  <div id="${zoneId}-zone-status-marker" class="d-flex flex-column align-items-center ${className}">
  <div class="zone-header-wrapper"><div id="${zoneId}-zone-header" class="zone-header" style="max-width: ${zoneHeaderWidth}px">${zoneName}</div></div>
    <div class="zone-status-marker">
      <div class="d-flex flex-row zone-status-marker-full">
        <div class="d-flex flex-column">
          <div><span class="zone-marker-label">Vehicles</span></div>
          <div class="d-flex justify-content-between align-items-center car-info-wrapper" id="${zoneId}-vc-0-block">
            <div class="circle-marker charged-circle" id="${zoneId}-vc-0">${vcLevel[0]}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center car-info-wrapper" id="${zoneId}-vc-1-block">
            <div class="circle-marker charged-medium-circle" id="${zoneId}-vc-1">${vcLevel[1]}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center car-info-wrapper" id="${zoneId}-vc-2-block">
            <div class="circle-marker charged-low-circle" id="${zoneId}-vc-2">${vcLevel[2]}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center car-info-wrapper" id="${zoneId}-vc-3-block">
            <div class="circle-marker unknown-circle" id="${zoneId}-vc-3">${vcLevel[3]}</div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-end">
          <div><span class="zone-marker-label">EVSEs</span></div>
          <div class="d-flex justify-content-between align-items-center cs-info-wrapper" id="${zoneId}-cs-0-block">
            <div class="square-marker occupied-square" id="${zoneId}-cs-0">${csStatus[0]}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center cs-info-wrapper" id="${zoneId}-cs-1-block">
            <div class="square-marker unoccupied-square" id="${zoneId}-cs-1">${csStatus[1]}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center cs-info-wrapper" id="${zoneId}-cs-2-block">
            <div class="square-marker unavailable-square" id="${zoneId}-cs-2">${csStatus[2]}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center cs-info-wrapper" id="${zoneId}-cs-3-block">
            <div class="square-marker blocked-square" id="${zoneId}-cs-3">${csStatus[3]}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="zone-status-marker-short d-flex flex-column align-items-center">
      <span class="zone-marker-label mb-1"><span id="${zoneId}-vc-total">${vcLevelTotal}</span> Vehicles</span>
      <span class="zone-marker-label"><span id="${zoneId}-cs-total">${csStatusTotal}</span> EVSEs</span>
    </div>
  </div>`;

  return element;
}

export const addChargerMarker = (
  map: mapboxgl.Map,
  coords: [number, number],
  chargersStatus: string[],
  csPopupHtml: string,
  csId :number,
) => {
  const element = getChargerMarkerHTMLElement(chargersStatus, csId);

  const popup = new mapboxgl.Popup({maxWidth: '450', className: 'cs-popup', closeButton: false}).setHTML(csPopupHtml);

  let openByClick = false;
  let wasClosedafterClickFlag = false;

  const marker = new mapboxgl.Marker(element)
  .setLngLat(coords)
  .setPopup(popup);

  const markerEl = marker.getElement();

  markerEl.addEventListener('mouseenter', () => {
    if (!popup.isOpen()) {
      popup.addTo(map);
    }
  });

  markerEl.addEventListener('mouseleave', () => {
    if (!openByClick) {
      popup.remove();
    }
  });

  popup.on('close', () => {
    if (openByClick && !wasClosedafterClickFlag) {
      wasClosedafterClickFlag = true;
    } else {
      openByClick = false;
    }
  });

  markerEl.addEventListener('click', () => {
    openByClick = true;
    wasClosedafterClickFlag = false;
  });

  return marker.addTo(map);
};

export const updateChargerMarker = (
  marker: mapboxgl.Marker,
  chargersStatus: string[],
  csPopupHtml: string,
  csId: number,
) => {
  if (chargersStatus.length === 1) {
    const evseImg = document.getElementById(csId + '-evse');
    evseImg.classList.remove(...evseStates);
    evseImg.classList.add(chargersStatus[0]);
  } else {
    const evseImgLeft = document.getElementById(csId + '-evse-left');
    const evseImgRight = document.getElementById(csId + '-evse-right');
    evseImgLeft.classList.remove(...evseStates);
    evseImgRight.classList.remove(...evseStates);
    evseImgLeft.classList.add(chargersStatus[0]);
    evseImgRight.classList.add(chargersStatus[1]);
  }

  marker.getPopup().setHTML(csPopupHtml);
};

const getChargerMarkerHTMLElement = (chargersStatus: string[], csId) => {
  const element = document.createElement('div');

  if (chargersStatus.length === 1) {
    element.innerHTML = `
    <div class="d-flex flex-row justify-content-between align-items-center charger-marker">
      <div id="${csId}-evse" class="charger-img charger-img-full ${chargersStatus[0]}">
        ${chargerSVG()}
      </div>
    </div>`;
  } else {
    element.innerHTML = `
    <div class="d-flex flex-row justify-content-between align-items-center charger-marker">
      <div class="d-flex flex-column">
        <div id="${csId}-evse-left" class="charger-img charger-img-half ${chargersStatus[0]}">${chargerTopSVG()}</div>
        <div id="${csId}-evse-right" class="charger-img charger-img-half ${chargersStatus[1]}">${chargerBottomSVG()}</div>
      </div>
    </div>`;
  }

  return element;
};

export const addFacilityMarker = (
  map: mapboxgl.Map,
  coords: [number, number],
  facilityName: string,
) => {
  const element = document.createElement('div');
  element.className = 'marker';

  return new mapboxgl.Marker(element)
    .setLngLat(coords)
    .setPopup(
      new Popup({
        offset: {
          top: [0, 12],
          'top-left': [0, 12],
          'top-right': [0, 12],
          bottom: [0, -12],
          'bottom-left': [0, -12],
          'bottom-right': [0, -12],
          left: [12, 0],
          right: [-12, 0],
        },
      })
        .setHTML(
          `<div class="popup">
            <div class="title">Facility</div>
            <div class="name">${facilityName}</div>
          </div>`,
        )
        .on('open', () => {
          map.flyTo({center: coords});
        }),
    )
    .addTo(map);
};

export const addChargerLocationMarker = (
  map: mapboxgl.Map,
  coords: [number, number],
) => {
  const element = document.createElement('div');
  element.className = 'charger-marker-icon';

  return new mapboxgl.Marker(element)
    .setLngLat(coords)
    .addTo(map);
};

export const addTransitionTextMarker = (
  map: mapboxgl.Map,
  coords: LngLat,
) => {
  const element = document.createElement('div');
  element.className = 'transition-text-marker';
  element.innerHTML = `Click to view in Facility View`;

  return new mapboxgl.Marker(element)
    .setLngLat(coords)
    .addTo(map);
};

export const addZoneNameMarker = (
  map: mapboxgl.Map,
  coords: [number, number],
  zoneName: string,
  zoneId: number,
  locationData: any,
) => {

  const changeHeaderWidthDynamically = () => {
    const currentZoomLevel = map.getZoom();
    const headerElement = document.getElementById(zoneId + '-zone-header');

    if (!headerElement) {
      map.off('zoom', changeHeaderWidthDynamically);
      return;
    }

    headerElement.style.maxWidth = getSecondViewZoneNameWidth(width, bboxCoords, currentZoomLevel) + 'px';
  };

  const {width, bboxCoords} = getRealSizeOfZone(locationData);
  const headerAllowedWidth = getSecondViewZoneNameWidth(width, bboxCoords, map.getZoom());

  const element = document.createElement('div');
  element.innerHTML = `<div id="${zoneId}-zone-header" class="zone-header" style="max-width: ${headerAllowedWidth}px">${zoneName}</div>`;

  map.on('zoom', changeHeaderWidthDynamically);

  return new mapboxgl.Marker(element)
    .setLngLat(coords)
    .addTo(map);
};

export const getPointOnGeometry = (geometry: FeatureCollection | Feature) => {
  try {
    return pointOnFeature(geometry as any);
  } catch (e) {
    return false;
  }
};

export const getFeaturesFromGeoJSON = (geojson: FeatureCollection | Feature) => {
  if (geojson.type === 'Feature') {
    return [geojson];
  } else if (geojson.type === 'FeatureCollection' && geojson.features.length) {
    return [...geojson.features];
  }

  return [];
};

export const clearFacilityZoneLayers = (map: mapboxgl.Map, facilityZoneIdPrefix: string) => {
  map.getStyle().layers
    .filter(layer => layer.id.startsWith(facilityZoneIdPrefix))
    .forEach(layer => {
      map.removeLayer(layer.id);
    });
};

export const drawFacilityZone = (
  map: mapboxgl.Map,
  facilityZone: FacilityZone,
  facilityZoneIdPrefix: string,
  facilityZoneId: number,
  zoomLevelToHideLayers = 16,
  shouldAddZoneName = true,
  beforeLayer?: string,
) => {
  const dashedZoneName = facilityZone.name.split(' ').join('-');

  const addOrUpdateSource = (sourceId: string, data: Feature | FeatureCollection) => {
    const sourceObject = map.getSource(sourceId) as GeoJSONSource;
    if (sourceObject) {
      sourceObject.setData(data);
    } else {
      map.addSource(sourceId, {
        type: 'geojson',
        data,
      });
    }
  };

  const addZoneAreaLayer = (fillLayerId: string, sourceId: string, colour: string) => {
    map.addLayer({
      id: fillLayerId,
      type: 'fill',
      source: sourceId,
      layout: {},
      paint: {
        'fill-color': colour,
        'fill-opacity': 0.2,
      },
      filter: ['>=', ['zoom'], zoomLevelToHideLayers],
    }, beforeLayer);
  };

  const generateTextLayerDataPoints = (geojson: FeatureCollection | Feature): FeatureCollection | false => {
    const dataPoints = getFeaturesFromGeoJSON(geojson)
      .map(feature => getPointOnGeometry(feature) || null)
      .filter(pointData => pointData === pointData);

    if (!dataPoints.length) {
      return false;
    }

    return {
      type: 'FeatureCollection',
      features: dataPoints,
    };
  };

  const addZoneName = (sourceId: string, geojson: FeatureCollection | Feature) => {
    const textSourceId = createId(sourceId, 'text');
    const textLayerId = createId(sourceId, 'text-layer');

    const textLayerDataPoints = generateTextLayerDataPoints(geojson);
    if (textLayerDataPoints) {
      addOrUpdateSource(textSourceId, textLayerDataPoints);
    }

    const sourceIdToUse = textLayerDataPoints ? textSourceId : sourceId;

    map.addLayer({
      id: textLayerId,
      type: 'symbol',
      source: sourceIdToUse,
      layout: {
        'text-size': 14,
        'text-allow-overlap': false,
        'text-field': facilityZone.name,
        'text-transform': 'uppercase',
        'text-letter-spacing': 0.05,
        'text-font': [
          'Open Sans Bold',
          'Arial Unicode MS Bold',
        ],
      },
      paint: {
        'text-color': '#222',
        'text-halo-width': 2,
        'text-halo-color': '#fff',
      },
      filter: ['>=', ['zoom'], zoomLevelToHideLayers],
    });
  };

  const createId = (...args: unknown[]) => [facilityZoneIdPrefix, dashedZoneName, ...args]
    .filter(value => typeof value === 'string' || typeof value === 'number')
    .join('-');

  const sourceId = createId();
  const fillLayerId = createId(facilityZoneId, 'fill-layer');

  addOrUpdateSource(sourceId, facilityZone.location);
  addZoneAreaLayer(fillLayerId, sourceId, facilityZone.colour || getColour(facilityZoneId));
  shouldAddZoneName && addZoneName(sourceId, facilityZone.location);
};

export const addVehiclesLayer = (map: mapboxgl.Map, vehiclesLabelsLayerId: string, vehicleIconsLayerId: string) =>  {
  map.addLayer({
    id: vehiclesLabelsLayerId,
    type: 'symbol',
    source: 'vehicles',
    layout: {
      'symbol-z-order': 'source',
      'icon-size': 1,
      'icon-allow-overlap': true,
      'icon-image': 'label-box',
      'icon-text-fit': 'both',
      'icon-text-fit-padding': [2, 4, 2, 4],
      'text-size': 10,
      'text-allow-overlap': false,
      'text-field': ['upcase', ['get', 'evIdentifier']],
      'text-font': [
        'Open Sans Bold',
        'Arial Unicode MS Bold',
      ],
      'text-offset': [
        'case',
        ['==', ['get', 'isCharging'], true],
        ['literal', [2, 0]],
        ['literal', [1.6, 0]],
      ],
      'text-variable-anchor': ['right', 'bottom-right', 'top-right'],
    },
    filter: ['!=', 'cluster', true],
  });

  map.addLayer({
    id: vehicleIconsLayerId,
    type: 'symbol',
    source: 'vehicles',
    layout: {
      'symbol-z-order': 'source',
      'icon-size': 0.4,
      'icon-allow-overlap': true,
      'icon-image': [
        'case',
        ['all', ['>=', ['get', 'soc'], 34], ['<', ['get', 'soc'], 66], ['==', ['get', 'isCharging'], true]],
        'vehicle-amber-charging',
        ['all', ['>=', ['get', 'soc'], 34], ['<', ['get', 'soc'], 66]],
        'vehicle-amber',
        ['all', ['>=', ['get', 'soc'], 66], ['==', ['get', 'isCharging'], true]],
        'vehicle-green-charging',
        ['>=', ['get', 'soc'], 66],
        'vehicle-green',
        ['==', ['get', 'isCharging'], true],
        'vehicle-red-charging',
        'vehicle-red',
      ],
    },
  }, vehiclesLabelsLayerId);
}

export const addVehicleSourcesToMap = (data: GeoJSONFeatureCollection, map: mapboxgl.Map, addIso = true) => {
  map.addSource('vehicles', {
    type: 'geojson',
    data,
    cluster: true,
    clusterRadius: 40,
    clusterMinPoints: 2,
    clusterProperties: {
      red: ['+', ['case', ['<=', ['get', 'soc'], 33], 1, 0]],
      amber: ['+', ['case', ['all', ['>=', ['get', 'soc'], 34], ['<', ['get', 'soc'], 66]], 1, 0]],
      green: ['+', ['case', ['>=', ['get', 'soc'], 66], 1, 0]],
    },
  });

  if (addIso) {
    map.addSource('iso', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }
}

export const addChargingStationSourcesToMap = (data: GeoJSONFeatureCollection, map: mapboxgl.Map) => {
  map.addSource('chargingStations', {
    type: 'geojson',
    data,
    cluster: false,
  });
}

export const addChargingStationLayers = (map: mapboxgl.Map, csLayerId: string, zoomLevel: number, vehicleIconsLayerId?: string) => {
  map.addLayer({
    id: csLayerId,
    source: 'chargingStations',
    type: 'symbol',
    layout: {
      'symbol-z-order': 'source',
      'icon-size': 1,
      'icon-allow-overlap': true,
      'icon-image': ['get', 'iconName'],
    },
    filter: ['>=', ['zoom'], zoomLevel],
  }, vehicleIconsLayerId);
}

export const addEvseSourcesToMap = (data: GeoJSONFeatureCollection, map: mapboxgl.Map) => {
  map.addSource('evses', {
    type: 'geojson',
    data,
    cluster: false,
  });
}

export const addEvseLayers = (map: mapboxgl.Map, csLayerId: string, zoomLevel: number, vehicleIconsLayerId?: string) => {
  map.addLayer({
    id: csLayerId,
    source: 'evses',
    type: 'symbol',
    layout: {
      'symbol-z-order': 'source',
      'icon-size': 1,
      'icon-allow-overlap': true,
      'icon-image': ['get', 'iconName'],
    },
    filter: ['>=', ['zoom'], zoomLevel],
  }, vehicleIconsLayerId);
}

export const addChargingStationLayersWithSimpleCharger = (map: mapboxgl.Map, csLayerId: string) => {
  map.addLayer({
    id: csLayerId,
    source: 'chargingStations',
    type: 'symbol',
    layout: {
      'symbol-z-order': 'source',
      'icon-size': 0.7,
      'icon-allow-overlap': true,
      'icon-image': 'charger',
    },
  });
}

export const zoomToFitFacilityZones = (
  map: mapboxgl.Map,
  facilityZones: FacilityZone[],
  padding = 50,
) => {
  if (!facilityZones?.length) {
    return;
  }

  const getCoordsValues = (data: object[]) => {
    const coords = [];

    const isLngLat = (obj: object) => Array.isArray(obj) && obj.length == 2
      && typeof obj[0] === 'number' && typeof obj[1] === 'number';

    const traverseObject = (obj: object) => {
      if (!obj || typeof obj !== 'object') {
        return;
      }

      Object.values(obj)
        .forEach(value => {
          if (!Array.isArray(value)) {
            return;
          }

          if (isLngLat(value)) {
            coords.push(value);
          } else {
            traverseObject(value);
          }
        });
    };

    traverseObject(data);

    return coords;
  };

  const coordinateObjects = flattenObject(facilityZones)
    .filter((item: { coordinates?: object[] }) => item.coordinates && Array.isArray(item.coordinates))
    .map((item: { coordinates: object[] }) => item.coordinates);

  const coords = getCoordsValues(coordinateObjects);
  if (!coords.length) {
    return;
  }

  const bounds = new mapboxgl.LngLatBounds();

  coords.forEach(item => bounds.extend(item));

  map.fitBounds(bounds, {padding});
};

export function getFirstViewZoneNameWidth(width: number, bboxCoords, currentZoomLevel: number) {
  const margin = 20;
  const aproxWidthOfStatsContainer = 100;
  const allowedWidth = width / metersPerPixel(bboxCoords[1], currentZoomLevel);
  return Math.max(Math.round(allowedWidth - margin), aproxWidthOfStatsContainer);
}

export function getSecondViewZoneNameWidth(width: number, bboxCoords, currentZoomLevel: number) {
  const margin = 20;
  const allowedWidth = width / metersPerPixel(bboxCoords[1], currentZoomLevel);
  return Math.round(allowedWidth - margin)
}

export function getRealSizeOfZone(locationData: any) {
  const zoneBorder = lineString(locationData.features[0].geometry.coordinates[0]);
  const bboxCoords = bbox(zoneBorder);
  const width = distance([bboxCoords[0], (bboxCoords[1] + bboxCoords[3]) / 2], [bboxCoords[2], (bboxCoords[1] + bboxCoords[3]) / 2], { units: 'meters' });
  const height = distance([(bboxCoords[0] + bboxCoords[2]) / 2, bboxCoords[1]], [(bboxCoords[0] + bboxCoords[2]) / 2, bboxCoords[3]], { units: 'meters' });
  return {width, height, bboxCoords};
}

export function shortSizeZoomLevel (latitude, meters, pxToFit) {
  const earthCircumference = 40075017;
  const latitudeRadians = latitude * (Math.PI/180);
  return Math.log2(earthCircumference * Math.cos(latitudeRadians) * pxToFit / meters) - 9;
}

export function metersPerPixel(latitude, zoomLevel) {
  const earthCircumference = 40075017;
  const latitudeRadians = latitude * (Math.PI/180);
  return earthCircumference * Math.cos(latitudeRadians) / Math.pow(2, zoomLevel + 9);
}

function chargerSVG() {
  return `
  <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="background" d="M18 16L1.58097e-06 16L8.81588e-07 8C4.9533e-07 3.58172 3.58172 5.61103e-07 8 1.74845e-07L10 0C14.4183 -3.86258e-07 18 3.58172 18 8L18 16Z" fill="#FEE2DF"/>
    <path class="border" fill-rule="evenodd" clip-rule="evenodd" d="M17 8.53333L17 16H18V8.53333C18 3.8205 14.4183 -5.79387e-07 10 0L8 1.74845e-07C3.58172 7.54232e-07 2.08197e-06 3.82051 2.69998e-06 8.53333L1.58097e-06 16L1 16L1 8.53333C1 4.40961 4.13401 1.06667 8 1.06667L10 1.06667C13.866 1.06667 17 4.40961 17 8.53333Z" fill="#C31C00"/>
    <path class="background" d="M1.58097e-06 16L18 16L18 24C18 28.4183 14.4183 32 10 32H8C3.58172 32 3.73004e-07 28.4183 3.73004e-07 24L1.58097e-06 16Z" fill="#FEE2DF"/>
    <path class="border" fill-rule="evenodd" clip-rule="evenodd" d="M1 23.4667L1 16L1.58097e-06 16L0 23.4667C2.06004e-07 28.1795 3.58172 32 8 32H10C14.4183 32 18 28.1795 18 23.4667L18 16H17L17 23.4667C17 27.5904 13.866 30.9333 10 30.9333H8C4.13401 30.9333 1 27.5904 1 23.4667Z" fill="#C31C00"/>
    <path class="border" d="M11 18.6667H7.89333L5.55333 16.3333H4.33333V15.6667H5.55333L7.9 13.3333H11L11 18.6667ZM15 18.6667V17.3333H12.3333V14.6667H15V13.3333H12.3333V13.3267C12.34 12.6 11.74 12 11.0133 12H7.33333L5 14.3333H3V17.6667H5L7.34 20L11 20C11.7333 20 12.3333 19.4 12.3333 18.6667H15Z" fill="#C31C00"/>
  </svg>
  `;
}

function chargerTopSVG() {
  return `
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="background" d="M18 16L6.99383e-07 16L0 8C-3.86258e-07 3.58172 3.58172 5.61103e-07 8 1.74845e-07L10 0C14.4183 -3.86258e-07 18 3.58172 18 8V16Z" fill="#FEE2DF"/>
    <path class="border" d="M11 12.6667H7.89333L5.55333 10.3333H4.33333V9.66667H5.55333L7.9 7.33333H11L11 12.6667ZM15 12.6667V11.3333H12.3333V8.66667H15V7.33333H12.3333V7.32667C12.34 6.6 11.74 6 11.0133 6H7.33333L5 8.33333H3L3 11.6667H5L7.34 14H11C11.7333 14 12.3333 13.4 12.3333 12.6667H15Z" fill="#C31C00"/>
    <path class="border" fill-rule="evenodd" clip-rule="evenodd" d="M17 8.53333V16H18L18 8.53333C18 3.8205 14.4183 -5.79387e-07 10 0L8 1.74845e-07C3.58172 7.54232e-07 1.20038e-06 3.82051 1.81839e-06 8.53333L6.99383e-07 16L1 16L1 8.53333C1 4.40961 4.13401 1.06667 8 1.06667L10 1.06667C13.866 1.06667 17 4.40961 17 8.53333Z" fill="#C31C00"/>
  </svg>
  `;
}

function chargerBottomSVG() {
  return `
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="background" d="M3.73004e-07 1.5299e-06H18V8C18 12.4183 14.4183 16 10 16H8C3.58172 16 3.73004e-07 12.4183 3.73004e-07 8V1.5299e-06Z" fill="#FFF0D0"/>
    <path class="border" d="M11 8.66667L7.89333 8.66667L5.55333 6.33333H4.33333V5.66667H5.55333L7.9 3.33333L11 3.33333L11 8.66667ZM15 8.66667V7.33333L12.3333 7.33333V4.66667H15V3.33333L12.3333 3.33333V3.32667C12.34 2.6 11.74 2 11.0133 2H7.33333L5 4.33333L3 4.33333V7.66667L5 7.66667L7.34 10L11 10C11.7333 10 12.3333 9.4 12.3333 8.66667H15Z" fill="#D86900"/>
    <path class="border" fill-rule="evenodd" clip-rule="evenodd" d="M1 7.46667V6.99383e-07L3.73004e-07 1.5299e-06L0 7.46667C2.06004e-07 12.1795 3.58172 16 8 16H10C14.4183 16 18 12.1795 18 7.46667V1.5299e-06L17 0V7.46667C17 11.5904 13.866 14.9333 10 14.9333H8C4.13401 14.9333 1 11.5904 1 7.46667Z" fill="#D86900"/>
  </svg>
  `;
}
