import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/portal';

@Injectable()
export class SettingsService {
  constructor(
    private dialog: MatDialog,
  ) {
  }

  public openDialog<T>(component: ComponentType<T>, data: any) {
    return this.dialog.open(component, {
      data,
      width: '600px',
      autoFocus: false,
    });
  }
}
