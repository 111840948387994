<div class="main bdd-id--facility-dashboard--main" style="width: 100%;">
    <div class="d-flex flex-column">
        <div>
            <facility-overview
                class="bdd-id--facility-dashboard--facility-overview"
                [facilityStateLoaded]="facilityStateLoaded"
                [facility]="selectedFacility"
                [state]="selectedFacilityState"
                [offlineData]="offlineData"
                [filterableCsData]="filterableCsData"
                >
            </facility-overview>
        </div>

        <div>
            <evses-status-table
                class="bdd-id--facility-dashboard--evses-status-table"
                [facility]="selectedFacility"
                [chargingGroups]="chargingGroups"
                [vehicleGroups]="vehicleGroups"
                >
            </evses-status-table>
        </div>
    </div>
</div>
