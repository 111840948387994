import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

interface PreferencesResponse {
  userId: number;
  preferences: { [key: string]: any };
}

const isBoolean = (value: string) => value === 'true' || value === 'false';
const isNumeric = (value: string) => !isNaN(value as any) && !isNaN(parseFloat(value));

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  public updateItemSubject$: Subject<{ key: string, value: any }> = new Subject();

  private readonly preferencesUrl = `${environment.api.uam.http.proxy}/preferences`;
  private readonly userPreferencesUrl = `${environment.api.uam.http.proxy}/user-preferences`;
  private preferences: { [key: string]: any };

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  public get(key: string, property?: string) {
    const values = this.preferences && this.preferences[key]
      ? this.returnValue(this.preferences[key])
      : null;

    if (!property) {
      return values;
    }

    return values && values.hasOwnProperty(property) ? values[property] : null;
  }

  public hasUserPreferences() {
    return !!this.preferences;
  }

  public clearUserPreferences() {
    this.preferences = undefined;
  }

  public async initaliseUserPreferences() {
    if (this.hasUserPreferences()) {
      return;
    }

    try {
      await this.hydrateUserPreferences();
    } catch (e) {
      console.error(e);
    }
  }

  public async hydrateUserPreferences() {
    this.preferences = await this.getUserPreferences();
  }

  public async getUserPreferences() {
    const response = await this.httpClient.get<PreferencesResponse>(`${this.preferencesUrl}?withDefaults=true`)
      .toPromise();

    return response.preferences;
  }

  public setUserPreferences(key: string, value: any) {
    return this.httpClient.put(this.userPreferencesUrl, {name: key, value}, {responseType: 'text'})
      .pipe(
        tap(() => {
          this.set(key, value);
          this.updateItemSubject$.next({key, value});
        }),
      );
  }

  private set(key: string, values: any) {
    this.preferences[key] = values;
  }

  private returnValue(value: any) {
    if (typeof value === 'string') {
      if (isNumeric(value)) {
        return parseFloat(value);
      }

      if (isBoolean(value)) {
        return value === 'true' ? true : false;
      }
    }

    return value;
  }
}
