<div class="settings-options-table-columns" [formGroup]="tableColumnsGroup">
    <div class="label mb-2" *ngIf="label">
        {{ label }}<span *ngIf="minRequired">*</span>
    </div>

    <ng-container *ngFor="let column of tableColumns">
        <div *ngIf="!column.isHidden" class="settings-options-table-columns__row">
            <label>{{ column.label }}</label>
    
            <mat-slide-toggle
                *ngIf="!column.isMandatory"
                class="bdd-id--settings-options-table-columns--table-column-toggle"
                [formControlName]="column.id">
            </mat-slide-toggle>
    
            <small *ngIf="column.isMandatory">n/a</small>
        </div>
    </ng-container>

    <div *ngIf="tableColumnsGroup.invalid && (tableColumnsGroup.dirty || tableColumnsGroup.touched)"
         class="text-danger">
        <div *ngIf="tableColumnsGroup.errors?.minRequired">
            Requires a minumum of {{ minRequired }} to be selected.
        </div>

        <div *ngIf="tableColumnsGroup.errors?.maxLimit">
            A maximum of {{ maxLimit }} options should be selected.
        </div>
    </div>
</div>
