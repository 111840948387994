import {ChargePointStatus} from '../../shared/models/entity-states';
import {blocked, occupied, unavailable, unoccupied} from '../consts/text.consts';
import {FacilityViewChargingStation, FvData, FvDataState} from '../models';
import {availableColor, blockedColour, chargingColor, faultedColor, finishingColor, occupiedColour, preparingColor, reservedColor, suspendedEVColor, suspendedEVSEColor, unavailableColour, unknownColor, unoccupiedColour} from '../consts/colour.consts';

export type EvseStatus = typeof occupied | typeof unoccupied | typeof unavailable | typeof blocked;

export function getEvseStatus(data: FvData): EvseStatus | undefined {
  if (!data) return;
  if (data.evseState?.evseStatusNotification?.status === ChargePointStatus.Faulted
    || data.evseState?.evseStatusNotification?.status === ChargePointStatus.Unavailable) {
    return unavailable;
  }
  if (data.evseState?.isPluggedIn === true) return occupied;
  if (data.state === FvDataState.BLOCKED) return blocked;

  return unoccupied;
}

export function constructEvseStateData(csList: FacilityViewChargingStation[] | { [key: number]: FacilityViewChargingStation }, asMap?: boolean): any {//fix return type
  const evseState = {
    [occupied]: {value: 0, color: occupiedColour},
    [unoccupied]: {value: 0, color: unoccupiedColour},
    [unavailable]: {value: 0, color: unavailableColour},
    [blocked]: {value: 0, color: blockedColour},
  };

  const accumulateStatuses = (data: FvData) => {
    if (!data) return;

    const statusType = getEvseStatus(data);
    if (statusType) evseState[statusType].value++;
  };

  Object
    .values(csList)
    .forEach(fvcs => {
      fvcs.evses.forEach(x => {
        accumulateStatuses(x)
      });
    });

  if (asMap) return evseState;
  else return Object.keys(evseState).map(key => ({name: key, ...evseState[key]}));
}

export function getEvseNotificationStatus(data: FvData): ChargePointStatus | undefined {
  if (!data) return;

  return data.evseState?.evseStatusNotification?.status || ChargePointStatus.Unknown;
}

export function constructEvseNotificationStateData(csList: FacilityViewChargingStation[] | { [key: number]: FacilityViewChargingStation }, asMap?: boolean): any {//fix return type
  const evseNotificationState = {
    [ChargePointStatus.Available]: {value: 0, color: availableColor},
    [ChargePointStatus.Preparing]: {value: 0, color: preparingColor},
    [ChargePointStatus.Charging]: {value: 0, color: chargingColor},
    [ChargePointStatus.SuspendedEVSE]: {value: 0, color: suspendedEVSEColor},
    [ChargePointStatus.SuspendedEV]: {value: 0, color: suspendedEVColor},
    [ChargePointStatus.Finishing]: {value: 0, color: finishingColor},
    [ChargePointStatus.Reserved]: {value: 0, color: reservedColor},
    [ChargePointStatus.Unavailable]: {value: 0, color: unavailableColour},
    [ChargePointStatus.Faulted]: {value: 0, color: faultedColor},
    [ChargePointStatus.Unknown]: {value: 0, color: unknownColor},
  };

  const accumulateStatuses = (data: FvData) => {
    if (!data) return;

    const statusType = getEvseNotificationStatus(data);
    if (statusType) evseNotificationState[statusType].value++;
  };

  Object
    .values(csList)
    .forEach(fvcs => {
      fvcs.evses.forEach(x => {
        accumulateStatuses(x)
      });
    });

  if (asMap) return evseNotificationState;
  else return Object.keys(evseNotificationState).map(key => ({name: key, ...evseNotificationState[key]}));
}