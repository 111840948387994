import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfoPieComponent} from './info-pie.component';
import {NgxEchartsModule} from 'ngx-echarts';

@NgModule({
  declarations: [
    InfoPieComponent,
  ],
  imports: [
    CommonModule,
    NgxEchartsModule,
  ],
  exports: [
    InfoPieComponent,
  ],
})
export class InfoPieModule {
}
