import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import moment from 'moment';

import {handleError} from '../utils/errorHandling';
import {environment} from '../../../environments/environment';
import {CurrentSessionService} from './current-session.service';
import {
  ChargerType,
  ChargingStation,
  ChargingStationAsset,
  ConnectorType,
  Facility,
  FacilityZone,
  FEMSProvider,
  OptimisationMode,
  OptimisationSettings,
  PeakReductionProfile,
  PeakReductionProfileData,
  TelematicsProvider,
  Vehicle,
} from '../models/entities';
import {UserPreferencesService} from './user-preferences.service';
import {ChargingGroup} from '../models/entities/charging-group.model';
import {VehicleGroup} from '../models/entities/vehicle-group.model';

@Injectable({
  providedIn: 'root',
})
export class AssetManagerService {
  constructor(
    private http: HttpClient,
    private currentSession: CurrentSessionService,
    private userPreferencesService: UserPreferencesService,
  ) {
  }

  private static getFacilitiesUrl() {
    return `${environment.api.am.http.proxy}/facilities`;
  }

  private static getFacilityUrl(facilityId: number) {
    return `${environment.api.am.http.proxy}/facilities/${facilityId}`;
  }

  private static getFacilityElectricVehiclesUrl(facilityId: number) {
    return `${environment.api.am.http.proxy}/facilities/${facilityId}/electric-vehicles`;
  }

  private static getFacilityVehicleGroupsUrl(facilityId: number) {
    return `${environment.api.am.http.proxy}/facilities/${facilityId}/vehicle-groups`;
  }

  private static getFacilityChargingStationsUrl(facilityId: number) {
    return `${environment.api.am.http.proxy}/facilities/${facilityId}/charging-stations`;
  }

  private static getTenantChargerTypes() {
    return `${environment.api.am.http.proxy}/charger-types`;
  }

  private static getFacilityChargingStationUrl(facilityId: number, chargingStationId: number) {
    return `${environment.api.am.http.proxy}/facilities/${facilityId}/charging-stations/${chargingStationId}`;
  }

  private static getFacilityChargingGroupsUrl(facilityId: number) {
    return `${environment.api.am.http.proxy}/facilities/${facilityId}/charging-groups`;
  }

  private static getFacilityOptimisationSettingsUrl(facilityId: number) {
    return `${environment.api.am.http.proxy}/facilities/${facilityId}/optimisation-setting`;
  }

  private static getTelematicsProvidersUrl() {
    return `${environment.api.am.http.proxy}/telematics-providers`;
  }

  private static getFemsUrl() {
    return `${environment.api.am.http.proxy}/facility-energy-management-systems`;
  }

  private static getFacilityPeakReductionProfileUrl(facilityId: number) {
    return `${environment.api.am.http.proxy}/facilities/${facilityId}/peak-reduction-profile`;
  }

  private static getConnectorTypesUrl() {
    return `${environment.api.am.http.proxy}/connector-types`;
  }

  private static getOptimisationModesUrl() {
    return `${environment.api.am.http.proxy}/optimisation-modes`;
  }

  /*
   /// Public service methods
   */
  public getAllFacilities() {
    const isRestricted = this.currentSession.getRestrictedFacilityAccess();
    const facilityAccessList = this.currentSession.getFacilityAccessList();

    const params = isRestricted
      ? '?facilityIds=' + facilityAccessList.join(',')
      : '';

    return this.http.get<Facility[]>(AssetManagerService.getFacilitiesUrl() + params);
  }

  public getFacilityById(facilityId: number) {
    return this.http.get<Facility>(AssetManagerService.getFacilityUrl(facilityId));
  }

  public getElectricVehiclesByFacilityId(facilityId: number) {
    return this.http.get<Vehicle[]>(AssetManagerService.getFacilityElectricVehiclesUrl(facilityId))
      .pipe(
        map(vehicles => {
          const evNameTypePreference = this.userPreferencesService.get('evNameType');
          console.debug(this.userPreferencesService.get('evNameType'));

          if (evNameTypePreference === 'name') {
            vehicles.forEach(vehicle => vehicle.evIdentifier = vehicle.name);
          } else {
            vehicles.forEach(vehicle => vehicle.evIdentifier = vehicle.regNo);
          }
          return vehicles;
        }),
      );
  }

  public getVehicleGroupsByFacilityId(facilityId: number): Observable<VehicleGroup[]> {
    return this.http.get<VehicleGroup[]>(AssetManagerService.getFacilityVehicleGroupsUrl(facilityId));
  }

  private csAssetToCsMapper = asset => new ChargingStation(asset);
  private csAssetsToCsMapper = csAssets => csAssets.map(this.csAssetToCsMapper);

  public getAllChargingStationsByFacilityId(facilityId: number): Observable<ChargingStation[]> {
    return this.http
      .get<ChargingStationAsset[]>(AssetManagerService.getFacilityChargingStationsUrl(facilityId))
      .pipe(map(this.csAssetsToCsMapper));
  }

  public getChargingStationByFacilityIdAndChargingStationId(facilityId: number, chargingStationId: number): Observable<ChargingStation> {
    return this.http
      .get<ChargingStationAsset>(AssetManagerService.getFacilityChargingStationUrl(facilityId, chargingStationId))
      .pipe(map(this.csAssetToCsMapper));
  }

  public getChargingGroupsByFacilityId(facilityId: number): Observable<ChargingGroup[]> {
    return this.http.get<ChargingGroup[]>(AssetManagerService.getFacilityChargingGroupsUrl(facilityId));
  }

  public getAllChargerTypes() {
    return this.http.get<ChargerType[]>(AssetManagerService.getTenantChargerTypes());
  }

  public getFacilityOptimisationSettingsByFacilityId(facilityId: number): Observable<OptimisationSettings> {
    return this.getFacilityOptimisationSettings(AssetManagerService.getFacilityOptimisationSettingsUrl(facilityId), 'getFacilityOptimisationSettingsByFacilityId');
  }

  public getFacilityPeakReductionProfileByFacilityId(facilityId: number) {
    return this.http.get<PeakReductionProfile>(AssetManagerService.getFacilityPeakReductionProfileUrl(facilityId))
      .pipe(
        map(profile => {
          profile.profileWeekData = this.convertPeakReductionUtcToLocalTime(profile.profileWeekData);
          return profile;
        }),
      );
  }

  public getFacilityZonesByFacilityId(facilityId: number) {
    const url = `${environment.api.am.http.proxy}/facilities/${facilityId}/zones`;

    return this.http.get<FacilityZone[]>(url);
  }

  public getFacilityZoneByFacilityIdAndFacilityZoneId(facilityId: number, facilityZoneId: number) {
    const url = `${environment.api.am.http.proxy}/facilities/${facilityId}/zones/${facilityZoneId}`;

    return this.http.get<FacilityZone>(url);
  }

  public getAllTelematicsProviders(): Observable<TelematicsProvider[]> {
    return this.httpRequestHandleArray(AssetManagerService.getTelematicsProvidersUrl(), 'getAllTelematicsProviders');
  }

  public getAllFemsProviders() {
    return this.http.get<FEMSProvider[]>(AssetManagerService.getFemsUrl());
  }

  public getAllConnectorTypes(): Observable<ConnectorType[]> {
    return this.httpRequestHandleArray(AssetManagerService.getConnectorTypesUrl(), 'getAllConnectorTypes');
  }

  public getAllOptimisationModes(): Observable<OptimisationMode[]> {
    return this.httpRequestHandleArray(AssetManagerService.getOptimisationModesUrl(), 'getAllOptimisationModes');
  }

  /*
   /// Private service methods
   */
  private getFacilityOptimisationSettings(url: string, operation: string): Observable<OptimisationSettings> {
    return this.http.get<OptimisationSettings>(url)
      .pipe(
        map(optimisationSettings => {
          // The below conversion is to handle the format produced by Scheduler (missing timezone 'Z')
          if (optimisationSettings.optimisationSchedule && optimisationSettings.optimisationSchedule.lastExecution) {
            optimisationSettings.optimisationSchedule.lastExecution =
              moment.utc(optimisationSettings.optimisationSchedule.lastExecution).toDate();
          }

          return optimisationSettings;
        }),
        catchError(handleError<OptimisationSettings>(operation)),
      );
  }

  private httpRequestHandleArray<T>(url: string, operation: string): Observable<T[]> {
    return this.http.get<T[]>(url)
      .pipe(catchError(handleError<T[]>(operation)));
  }

  private convertPeakReductionUtcToLocalTime(peakReductionDataUtc: PeakReductionProfileData[]) {
    const peakReductionLocalTime = peakReductionDataUtc.map(a => {return {...a}});
    const ptuDiff = new Date().getTimezoneOffset() / 15;

    for (let i = 0; i < peakReductionDataUtc.length; i++) {
      peakReductionLocalTime[(672 + i - ptuDiff)% 672].peakReduction = peakReductionDataUtc[i].peakReduction;
    }

    return peakReductionLocalTime;
  }
}
