import {Component, Input, OnInit} from '@angular/core';

import {parseTags} from '../asset-tags.utils';

@Component({
  selector: 'asset-tags-view',
  templateUrl: './asset-tags-view.component.html',
  styleUrls: ['./asset-tags-view.component.scss'],
})
export class AssetTagsViewComponent implements OnInit {
  @Input() type: 'tooltip' | 'details' = 'tooltip';
  @Input() tags: string | { [key: string]: string } = {};

  public tagValues: { [key: string]: string };

  public ngOnInit() {
    const tagValues = parseTags(this.tags);

    if (Object.keys(tagValues).length) {
      this.tagValues = tagValues;
    }
  }
}
