import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly emitter: EventEmitter<any> = new EventEmitter();

  public listener() {
    return this.emitter;
  }

  public toast(message: string, options?) {
    this.emitter.emit({message, options});
  }
}
