import {Component, Host, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {TableFiltersComponent} from '../table-filters.component';
import {TableFilter, TableFilterConfig, TableFilterOperators} from '../table-filters.model';
import {TableFiltersService} from '../table-filters.service';

interface FormValues {
  operator: TableFilterOperators;
  valueContains: string;
  valueDoesNotContain: string;
  valueEquals: string;
}

@Component({
  selector: 'table-filters-string-type',
  templateUrl: './table-filters-string-type.component.html',
  styleUrls: ['./table-filters-string-type.component.scss'],
})
export class TableFiltersStringTypeComponent implements OnInit, OnDestroy {
  @Input() config: TableFilterConfig;

  public optionsForm: UntypedFormGroup;
  public readonly TableFilterOperators = TableFilterOperators;
  public disableEquals = false;

  private destroyed$ = new Subject<void>();

  constructor(
    @Host() private parent: TableFiltersComponent,
    private formBuilder: UntypedFormBuilder,
    private tableFiltersService: TableFiltersService,
  ) {
  }

  get operator() {
    return this.optionsForm.get('operator');
  }

  public ngOnInit() {
    if (this.config?.disableEquals === true) {
      this.disableEquals = true;
    }

    this.optionsForm = this.createOptionsForm();

    this.optionsForm.get('operator').valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        const fieldMap = {
          [TableFilterOperators.Contains]: 'valueContains',
          [TableFilterOperators.DoesNotContain]: 'valueDoesNotContain',
          [TableFilterOperators.Equals]: 'valueEquals',
        };

        Object.values(fieldMap)
          .forEach(fieldName => {
            const control = this.optionsForm.get(fieldName);

            if (fieldName === fieldMap[value]) {
              control.setValidators([Validators.required]);
            } else {
              control.clearValidators();
            }

            control.updateValueAndValidity();
          });
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onSubmit() {
    if (!this.optionsForm.valid) {
      this.optionsForm.markAllAsTouched();
      return;
    }

    const filter = this.buildFilterObject(this.optionsForm.value);
    if (!filter) {
      return;
    }

    this.parent.addFilter(filter);
  }

  public cancel() {
    this.parent.deselectOption();
  }

  private createOptionsForm() {
    return this.formBuilder.group({
      operator: [TableFilterOperators.Contains],
      valueContains: ['', [Validators.required]],
      valueDoesNotContain: [''],
      valueEquals: [''],
    });
  }

  private buildFilterObject(formValues: FormValues): TableFilter {
    const getValue = () => {
      if (formValues.operator === TableFilterOperators.Contains) {
        return formValues.valueContains;
      }

      if (formValues.operator === TableFilterOperators.DoesNotContain) {
        return formValues.valueDoesNotContain;
      }

      if (formValues.operator === TableFilterOperators.Equals) {
        return formValues.valueEquals;
      }
    };

    const value = getValue();
    if (!value) {
      return;
    }

    const label = this.tableFiltersService.getLabel(formValues.operator, this.config.label, value, this.config.unit);

    return {
      label,
      data: {
        field: this.config.field,
        operator: formValues.operator,
        value,
      },
    };
  }
}
