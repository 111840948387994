import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TooltipsModule} from '../../components/tooltips/tooltips.module';
import {TextFormattersPipesModule} from '../../pipes/text-formatters/text-formatters.module';
import {SvgIconModule} from '../svg-icon/svg-icon.module';

import {CSNotificationsPopupComponent} from './cs-notification-popup/cs-notification-popup.component';
import {CSNotificationIconComponent} from './cs-notification-icon/cs-notification-icon.component';

@NgModule({
  declarations: [
    CSNotificationsPopupComponent,
    CSNotificationIconComponent,
  ],
  imports: [
    CommonModule,
    TooltipsModule,
    TextFormattersPipesModule,
    SvgIconModule,
  ],
  exports: [
    CSNotificationsPopupComponent,
    CSNotificationIconComponent,
  ],
})
export class CSNotificationsModule {
}
