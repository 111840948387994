import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Subscription, timer} from 'rxjs';
import {OptimisationUnit} from '../../../shared/models/enums';
import {CHART_ICON} from '../../../analytics/consts/icon.consts';

import {Facility, OptimisationSettings} from '../../../shared/models/entities';
import {ExpandedFacilityState} from '../../../shared/models/entity-states';
import {AssetManagerService} from '../../../shared/services/asset-manager.service';
import {toPng} from 'html-to-image';

@Component({
  selector: 'facility-overview',
  templateUrl: './facility-overview.component.html',
  styleUrls: ['./facility-overview.component.scss'],
})
export class FacilityOverviewComponent implements OnChanges, OnDestroy {
  @Input() facility: Facility;
  @Input() state: ExpandedFacilityState;
  @Input() facilityStateLoaded: boolean;
  @Input() offlineData: any[];
  @Input() filterableCsData: any[];

  public facilityOptimisationSettings: OptimisationSettings;
  public showkWChart: boolean;
  public showAChart: boolean;

  private timer$: Subscription;

  public showCapacityGraph = false;

  photoIconBase64 = this.dom.bypassSecurityTrustUrl(CHART_ICON.photo.replace('image://', ''));

  constructor(
    private assetManagerService: AssetManagerService,
    private dom: DomSanitizer,
  ) {
  }

  public ngOnDestroy() {
    if (this.timer$) {
      this.timer$.unsubscribe();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.facility?.currentValue !== changes.faclity?.previousValue) {
      this.update();
    }

    if (changes.state?.currentValue) {
      this.state.calculatedMeta.forEach(x => {
        if (x.chargingGroupId === -1) {
          x.chargingGroupName = this.facility.name;
        }
      });
      
      const units = this.state.calculatedMeta.map(x => x.unit);
      this.showAChart = units.includes(OptimisationUnit.amps);
      this.showkWChart = units.includes(OptimisationUnit.kW);
    }
  }

  private pollFacilityOptimisationSchedule(facilityId: any) {
    this.assetManagerService.getFacilityOptimisationSettingsByFacilityId(facilityId)
      .subscribe(facilityOptimisationSettings => {
        this.facilityOptimisationSettings = facilityOptimisationSettings;

        const timerValue = this.getNextTimerValue(facilityOptimisationSettings);

        this.timer$ = timer(timerValue)
          .subscribe(() => {
            this.pollFacilityOptimisationSchedule(facilityId);
          });
      });
  }

  private update() {
    if (this.timer$) {
      this.timer$.unsubscribe();
    }

    this.pollFacilityOptimisationSchedule(this.facility.facilityId);
  }

  private getNextTimerValue(optimisationSettings: OptimisationSettings) {
    const {lastExecution, interval} = optimisationSettings?.optimisationSchedule || {};

    return lastExecution && interval
      ? (new Date(lastExecution).getTime() + interval * 1000 + 2000) - new Date().getTime()
      : 60 * 1000;
  }

  downloadImage(nativeElement, imgName){
    toPng(nativeElement).then(dataUrl => {
      const link = document.createElement('a')
      link.download = `${imgName}.png`
      link.href = dataUrl
      link.click()
    });
  }
}
