import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss'],
})
export class TableSearchComponent {
  @Input() public inlineStyle = false;
  @Output() public searched = new EventEmitter<string>();

  public search(event: { target: HTMLInputElement }) {
    this.searched.emit(event.target.value);
  }
}
