import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BrowserStorageCache} from '@aws-amplify/cache';

import {CurrentSessionService} from '../../shared/services/current-session.service';
import {AuthPermissionService} from '../../shared/services/auth-permission.service';
import {UserPreferencesService} from '../../shared/services/user-preferences.service';
import {LoginService} from '../../shared/services/login.service';
import {environment} from '../../../environments/environment';

export interface TenantConfig {
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppBootstrapService {
  constructor(
    private http: HttpClient,
    private currentSession: CurrentSessionService,
    private permissionService: AuthPermissionService,
    private userPreferencesService: UserPreferencesService,
    private loginService: LoginService,
  ) {
  }

  public async initialiseTenant(tenant: string) {
    this.currentSession.setTenantId(tenant);

    const tenantConfig = await this.getTenantConfiguration(tenant);
    if (!tenantConfig) {
      return false;
    }

    await this.loginService.setupAuthenticationLibrary(tenantConfig);

    return tenantConfig;
  }

  public async initialiseDataServices() {
    if (!this.hasValidToken()) {
      return;
    }

    const initaliseAuthPermission = () => this.permissionService.initaliseAuthPermission();
    const initaliseUserPreferences = () => this.userPreferencesService.initaliseUserPreferences();

    return Promise.all([
      initaliseAuthPermission(),
      initaliseUserPreferences(),
    ]);
  }

  private hasValidToken() {
    const idToken = BrowserStorageCache.getItem('currentUserIdToken');
    if (!idToken || !idToken.payload || !idToken.payload.exp) {
      return false;
    }

    const time = Math.floor(new Date().valueOf() / 1000);
    if (time > idToken.payload.exp) {
      return false;
    }

    return true;
  }

  private async getTenantConfiguration(tenant: string) {
    const response = await this.http.get<TenantConfig>(`${environment.login.tenantInfo}/${tenant}`)
      .toPromise();

    return response && response.aws_user_pools_id && response.aws_user_pools_web_client_id
      ? response
      : false;
  }
}
