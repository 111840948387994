import {Injectable} from '@angular/core';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import {LDFlagValue} from 'launchdarkly-js-client-sdk';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

const CONTEXT_KEY = "optimisation-manager-key";
const CONTEXT_NAME = "optimisation-manager";
const PLATFORM = "aws";

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  client: LaunchDarkly.LDClient;

  constructor() {}

  public initialize(): void {
    const context = {
      kind: 'user',
      key: CONTEXT_KEY, // Unique identifier for the context
      name: CONTEXT_NAME, // Name of the context
      platform: PLATFORM,
    };

    this.client = LaunchDarkly.initialize(environment.launchDarklyClientId, context);
  }

  public getFlag(flagKey: string, defaultValue: LDFlagValue = false): Observable<LDFlagValue> {
    return of(this.client.waitUntilReady()).pipe(
      map(() => {
        return this.client.variation(flagKey, defaultValue);
      }),
    );
  }

  public changeContext(contextProps: { [key: string]: any }) {
    const newContext: LaunchDarkly.LDContext = {
      kind: 'user',
      key: CONTEXT_KEY,
      name: CONTEXT_NAME,
      platform: PLATFORM,
      ...contextProps,
    };

    return this.client.identify(newContext);
  }
}
